import React from 'react';
import { Redirect } from 'react-router-dom';

import { Button, LoadingIndicator } from 'components/atoms';

import { isEqual } from 'helpers/utility';

import { LOGIN_REDIRECT_TYPES } from 'utils/constants';
import { keycloak } from 'utils/keycloak-adapter';

import './Login.sass';

const Login = ({ location, history, user }) => {
  React.useLayoutEffect(() => {
    const { state } = location;
    if (state && state.redirectType === LOGIN_REDIRECT_TYPES.NO_TOKEN) {
      kcLogout();
    }
  });

  const kcLogout = () => {
    history.push('/');
    keycloak.logout();
  };

  if (isEqual(location.state?.redirectType, LOGIN_REDIRECT_TYPES.INVALID_ROLE)) {
    return (
      <div className="Login">
        <div className="error-message-container">
          <h2>Nezadostne pravice za uporabo aplikacije...</h2>
          <p>
            {user.name}, za uporabo te aplikacije <strong>nimate zadostnih dovoljenj</strong>. Prosimo preverite, da se
            v aplikacijo prijavljate z ustreznim računom.
          </p>
          <Button onClick={kcLogout}>Nazaj na prijavo</Button>
        </div>
      </div>
    );
  }

  if (user) {
    return <Redirect to="/" />;
  }

  return <LoadingIndicator fullscreen large type="primary" />;
};

export default Login;
