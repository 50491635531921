import { createReducer } from '@reduxjs/toolkit';

import {
  createActionTypeMap,
  gatherReducerCasesFromRoutines,
  getEntitiesByIdFromApiResponse,
  getEntitiesByIdFromParentRelationResponse,
  getEntitiesFromPageableApiResponse,
  mergeWithCurrentState,
} from 'helpers/redux';

import type { Id } from 'interfaces/common';
import type { ProductPriceDTO } from 'interfaces/products';

import { fetchProductPriceRoutine, patchProductPriceRoutine } from 'store/routines/productPrices';
import { fetchProductsRoutine, fetchSingleProductRoutine, putProductRoutine } from 'store/routines/products';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

const { successCases } = gatherReducerCasesFromRoutines([patchProductPriceRoutine, fetchProductPriceRoutine]);
const { successCases: parentPageableSuccessCases } = gatherReducerCasesFromRoutines([fetchProductsRoutine]);
const { successCases: parentSingleSuccessCases } = gatherReducerCasesFromRoutines([
  fetchSingleProductRoutine,
  putProductRoutine,
]);
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Product Prices reducers: by Id reducer
 */
const productPricesByIdReducer = createReducer<Record<Id, ProductPriceDTO>>({}, (builder) => {
  const successCasesMap = createActionTypeMap(successCases);
  const parentPageableSuccessCasesMap = createActionTypeMap(parentPageableSuccessCases);
  const parentSingleSuccessCasesMap = createActionTypeMap(parentSingleSuccessCases);
  const resetCasesMap = createActionTypeMap(resetCases);

  return builder
    .addMatcher(
      ({ type }) => successCasesMap[type],
      (state, action) => mergeWithCurrentState(state, getEntitiesByIdFromApiResponse(action.payload))
    )
    .addMatcher(
      ({ type }) => parentPageableSuccessCasesMap[type],
      (state, action) => {
        return mergeWithCurrentState(
          state,
          getEntitiesByIdFromParentRelationResponse(action.payload, 'productPrice', {
            mapper: getEntitiesFromPageableApiResponse,
          })
        );
      }
    )
    .addMatcher(
      ({ type }) => parentSingleSuccessCasesMap[type],
      (state, action) => {
        return mergeWithCurrentState(state, getEntitiesByIdFromParentRelationResponse(action.payload, 'productPrice'));
      }
    )
    .addMatcher(
      ({ type }) => resetCasesMap[type],
      () => ({})
    );
});

export { successCases, parentPageableSuccessCases, parentSingleSuccessCases, resetCases };
export default productPricesByIdReducer;
