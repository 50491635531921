import { IconNames } from 'enums/icons';
import { UserRole } from 'enums/roles';
import { MerchantsRoutes, ProductsRoutes, PurchaseOrdersRoutes, SettingsRoutes } from 'enums/routes';
import { Intent } from 'enums/ui';

import { isOneOfUserRoleMatch, isUserRoleOperator } from './roles';

/**
 * Returns Sidebar User Menu Logout action
 * @param {Function} onLogout
 * @returns {Object}
 */
export const getSidebarLogoutAction = (onLogout) => ({
  intent: Intent.ERROR,
  callback: onLogout,
  name: 'Odjava',
  icon: IconNames.SIGN_OUT,
});

/**
 * Returns formatted list of user dropdown actions based on the
 * passed user roles
 * @param {Object} options
 * @param {History} options.history
 * @param {Function} options.onClearSelectedMerchant
 * @param {Function} options.onLogout
 * @param {User} options.user
 * @returns {Object[]}
 */
export const getSidebarUserMenuActions = ({ history, user, onClearSelectedMerchant, onLogout }) => {
  // Signout action is available to every user role
  const actions = [getSidebarLogoutAction(onLogout)];

  if (isUserRoleOperator(user.roles)) {
    // Change merchant action is only available to the user with the role OPERATOR
    const changeMerchantAction = {
      intent: Intent.INFO,
      callback: () => {
        onClearSelectedMerchant();
        history.push(MerchantsRoutes.ROOT);
      },
      name: 'Spremeni izbranega ponudnika',
      icon: IconNames.CHANGE_ARROWS,
    };

    actions.splice(0, 0, changeMerchantAction);
  }

  return actions;
};

/**
 * Returns formatted list of links used in action bar based on
 * passed user roles
 * @param {Object} options
 * @param {User} options.user
 * @param {Boolean} options.isMerchantSelected
 * @returns {Object[]}
 */
export const getSidebarNavigationLinks = ({ user, isMerchantSelected }) => {
  const links = [];

  if (isOneOfUserRoleMatch(user.roles, [UserRole.OPERATOR, UserRole.MERCHANT])) {
    const lockedTooltipPropsWhenNoMerchant = {
      content: 'Preden lahko obiščete to stran morate izbrati ponudnika',
    };

    links.push({
      icon: IconNames.LIST,
      path: PurchaseOrdersRoutes.ROOT,
      title: 'Naročila',
      isLocked: !isMerchantSelected,
      lockedTooltipProps: lockedTooltipPropsWhenNoMerchant,
    });
    links.push({
      icon: IconNames.STORE,
      path: ProductsRoutes.ROOT,
      title: 'Spletna trgovina',
      isLocked: !isMerchantSelected,
      lockedTooltipProps: lockedTooltipPropsWhenNoMerchant,
    });
    links.push({
      icon: IconNames.COG,
      path: SettingsRoutes.ROOT,
      title: 'Nastavitve',
      isLocked: !isMerchantSelected,
      lockedTooltipProps: lockedTooltipPropsWhenNoMerchant,
    });
  }

  return links;
};
