import React from 'react';
import { components } from 'react-select';

import { Icon } from 'components/atoms';

import { IconNames } from 'enums/icons';

import './LockedIndicator.scss';

/**
 * Component rendering locked Select indicator
 * @param {Object} props
 * @returns {StatelessComponent}
 */
const LockedIndicator = (props) => (
  <components.DropdownIndicator
    className="select__indicator select__dropdown-indicator select__indicator--locked"
    {...props}
  >
    <Icon icon={IconNames.LOCK} />
  </components.DropdownIndicator>
);

export default LockedIndicator;
