import { createIsFetchingReducer } from 'helpers/redux';

import { fetchDeliveryDaysRoutine } from 'store/routines/deliveryDays';

/**
 * Reducer containing info about Delivery Days entity fetching state
 */
const isFetchingReducer = createIsFetchingReducer({
  endCases: [fetchDeliveryDaysRoutine.FAILURE, fetchDeliveryDaysRoutine.SUCCESS],
  requestCases: [fetchDeliveryDaysRoutine.REQUEST],
});

export default isFetchingReducer;
