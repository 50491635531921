import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { userStateSelector } from 'store/selectors/userSelectors';

import SelectMerchant from './SelectMerchant';

export const mapStateToProps = createStructuredSelector({
  user: userStateSelector,
});

export default connect(mapStateToProps)(SelectMerchant);
