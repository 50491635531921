import { createIsFetchingReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';
import { fetchProductPriceRoutine } from 'store/routines/productPrices';

/**
 * Map of cases from routines
 */
const { requestCases, successCases, failureCases } = gatherReducerCasesFromRoutines([fetchProductPriceRoutine]);

/**
 * Product prices reducers: Is fetching reducer
 */
const productPricesIsFetchingReducer = createIsFetchingReducer({
  endCases: [...successCases, ...failureCases],
  requestCases,
});

export { successCases, requestCases, failureCases };
export default productPricesIsFetchingReducer;
