import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect, { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { Icon, Tooltip } from 'components/atoms';
import { IconNames } from 'enums/icons';
import { TextAlign } from 'enums/typography';
import { InputSize } from 'enums/ui';

import { allValues } from 'helpers/utility';

import { LockedIndicator, NoOptionsMessage, SelectControl } from './components';

import './Select.scss';

/**
 * Component rendering custom Dropdown-Select combo field
 * @param {Object} props
 * @param {String} [props.className]
 * @param {String} [props.error]
 * @param {Boolean} [props.fullwidth]
 * @param {Boolean} [props.hasError=false]
 * @param {IconNames} [props.icon]
 * @param {Boolean} [props.isClearable=true]
 * @param {Boolean} [props.isCompact]
 * @param {Boolean} [props.isLoading=false]
 * @param {Boolean} [props.isMulti=false]
 * @param {String} [props.label]
 * @param {String} props.name
 * @param {String} [props.placeholder="Izberi iz seznama..."]
 * @param {InputSize} [props.size=InputSize.NORMAL]
 * @param {TextAlign} [props.textAlign=TextAlign.LEFT]
 * @param {Object} props.rest
 * @return {StatelessComponent}
 */
const Select = ({
  className,
  defaultValue: defaultValueProp,
  defaultValueGetter,
  error,
  fullwidth,
  hasError,
  icon,
  innerRef,
  isAsync,
  isClearable,
  isCompact,
  isDisabled,
  isMulti,
  isLoading,
  isTransparent,
  label,
  name,
  options: optionsProp,
  optionsFormatter,
  placeholder,
  size,
  textAlign,
  tooltipProps,
  ...rest
}) => {
  const hasLabel = Boolean(label);

  const Component = React.useMemo(() => (isAsync ? AsyncSelect : ReactSelect), [isAsync]);
  const DropdownIndicator = React.useMemo(() => (isDisabled ? LockedIndicator : components.DropdownIndicator), [
    isDisabled,
  ]);

  const options = React.useMemo(() => {
    if (!optionsFormatter) {
      return optionsProp;
    }

    return optionsFormatter(optionsProp);
  }, [optionsProp, optionsFormatter]);

  const defaultValue = React.useMemo(() => {
    if (!defaultValueGetter) {
      return defaultValueProp;
    }

    return defaultValueGetter(options, defaultValueProp);
  }, [defaultValueProp, defaultValueGetter, options]);

  return (
    <div className="select-outer">
      {hasLabel && (
        <label className="select-outer__label" htmlFor={name}>
          {Boolean(tooltipProps) && (
            <Tooltip {...tooltipProps}>
              <Icon icon={IconNames.INFO_CIRCLE} />
            </Tooltip>
          )}
          {label}
        </label>
      )}

      <Component
        {...rest}
        ref={innerRef}
        blurInputOnSelect={!isMulti}
        className={classNames(
          'select-container',
          `select-container--size-${size}`,
          `select-container--text-align-${textAlign}`,
          {
            'select-container--has-error': hasError,
            'select-container--fullwidth': fullwidth,
            'select-container--is-transparent': isTransparent,
            'select-container--is-compact': isCompact,
            'select-container--has-label': hasLabel,
            [className]: Boolean(className),
          }
        )}
        classNamePrefix="select"
        closeMenuOnSelect={!isMulti}
        components={{ Control: SelectControl, DropdownIndicator }}
        defaultValue={defaultValue}
        error={error}
        hasError={hasError}
        icon={icon}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        name={name}
        noOptionsMessage={NoOptionsMessage}
        options={options}
        placeholder={placeholder}
        size={size}
      />
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  defaultValueGetter: PropTypes.func,
  error: PropTypes.string,
  fullwidth: PropTypes.bool,
  hasError: PropTypes.bool,
  icon: PropTypes.oneOf(allValues(IconNames)),
  isClearable: PropTypes.bool,
  isCompact: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  optionsFormatter: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(allValues(InputSize)),
  textAlign: PropTypes.oneOf(allValues(TextAlign)),
};

Select.defaultProps = {
  hasError: false,
  isClearable: true,
  isLoading: false,
  isMulti: false,
  placeholder: 'Izberi iz seznama...',
  size: InputSize.NORMAL,
  textAlign: TextAlign.LEFT,
};

export default Select;
