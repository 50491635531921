import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, LoadingIndicator, ScrollableList } from 'components/atoms';
import { MerchantCard } from 'components/elements/MerchantCard';

import { PurchaseOrdersRoutes } from 'enums/routes';
import { GridColumnCount, GridGapSize, LoadingIndicatorType } from 'enums/ui';

/**
 * Component rendering the list (grid) of Merchants stored in the
 * global state.
 * @param {ComponentProps} props
 * @param {boolean} props.isFetching
 * @param {boolean} props.isInitialized
 * @param {Merchant[]} props.merchants
 * @param {Function} props.onFetchMerchants
 * @param {Function} props.onFetchMerchantsNextPage
 * @param {Function} props.onSelectMerchant
 * @param {ApiPaginationState} props.pagination
 * @param {ReactRef} [props.scrollableContainer]
 * @return {FunctionComponent}
 */
const MerchantsGrid = ({
  isFetching,
  isInitialized,
  merchants,
  onFetchMerchants,
  onFetchMerchantsNextPage,
  onSelectMerchant,
  pagination,
  scrollableContainer,
}) => {
  const history = useHistory();

  const handleMerchantCardClick = (merchantId) => {
    onSelectMerchant(merchantId);
    history.push(PurchaseOrdersRoutes.ROOT);
  };

  // Fetch merchants if we don't have merchants info in the state
  // yet
  React.useEffect(() => {
    if (!isInitialized && !isFetching) {
      onFetchMerchants();
    }
  }, [isInitialized, isFetching, onFetchMerchants]);

  if (isFetching) {
    return <LoadingIndicator fullscreen={true} type={LoadingIndicatorType.PRIMARY} />;
  }

  return (
    <ScrollableList
      data={merchants}
      fetchNext={onFetchMerchantsNextPage}
      loader={<LoadingIndicator contained={true} />}
      pagination={pagination}
      parrent={scrollableContainer}
    >
      <Grid gap={GridGapSize.LARGE} numOfCols={GridColumnCount.FOUR}>
        {merchants.map((merchant) => (
          <MerchantCard key={merchant.id} merchant={merchant} onClick={handleMerchantCardClick} />
        ))}
      </Grid>
    </ScrollableList>
  );
};

MerchantsGrid.propTypes = {
  merchants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool,
  isInitialized: PropTypes.bool,
  onFetchMerchants: PropTypes.func.isRequired,
  onFetchMerchantsNextPage: PropTypes.func.isRequired,
  onSelectMerchant: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}).isRequired,
};

MerchantsGrid.defaultProps = {
  isFetching: false,
  isInitialized: false,
};

export default MerchantsGrid;
