import { combineReducers } from 'redux';

import type { DeliveryDaysState } from 'store/interfaces/deliveryDaysState.types';

import deliveryDaysAllIdsReducer from './deliveryDaysAllIdsReducer';
import deliveryDaysByIdReducer from './deliveryDaysByIdReducer';
import deliveryDaysErrorReducer from './deliveryDaysErrorReducer';
import deliveryDaysLastFetchedReducer from './deliveryDaysLastFetchedReducer';
import isFetchingReducer from './isFetchingReducer';

/**
 * Delivery days reducers: Root reducer
 */
const deliveryDaysReducer = combineReducers<DeliveryDaysState>({
  allIds: deliveryDaysAllIdsReducer,
  byId: deliveryDaysByIdReducer,
  error: deliveryDaysErrorReducer,
  isFetching: isFetchingReducer,
  lastFetched: deliveryDaysLastFetchedReducer,
});

export default deliveryDaysReducer;
