import { createReducer } from '@reduxjs/toolkit';
import type { Routine } from 'redux-saga-routines';
import { spawn, take } from 'redux-saga/effects';

import { DEFAULT_PAGINATION_STATE } from 'constants/api';
import { purchaseOrderTypeForPurchaseOrderStatusMap } from 'constants/purchaseOrders';

import { PurchaseOrderType } from 'enums/purchaseOrders';

import type {
  ApiPageableResponse,
  GenericApiPayload,
  GenericEntity,
  MapApiPayload,
  StatePagination,
} from 'interfaces/api';
import type { Id, Timestamp } from 'interfaces/common';
import type { AnyPayloadAction, Builder, CaseWithReplaceStateFlag } from 'interfaces/redux';

import { isEqual } from './utility';

/**
 * Returns merged current and new state object
 * @param state - Existing state object
 * @param newState - New object that we want to merge with the existing one
 */
export const mergeWithCurrentStateObject = <S extends Record<Id, unknown>, N extends Record<Id, unknown>>(
  state: S,
  newState: N
): S & N => ({ ...state, ...newState });

/**
 * Returns merged current and new state array
 * @param state - Existing state array
 * @param newState - New array that we wwant to merge with the existing one
 */
export const mergeWithCurrentStateArray = (state: Id[], newState: Id[]): Id[] => [...state, ...newState];

/**
 * Returns merged current state and new state. Based on the passed state
 * merged result can be either array or an object.
 * @param state
 * @param newState
 */
export const mergeWithCurrentState = (state, newState) => {
  if (Array.isArray(state)) {
    return mergeWithCurrentStateArray(state, newState);
  }

  return mergeWithCurrentStateObject(state, newState);
};

/**
 * Transform actions types into lookup dictionary
 * @param types
 */
export const createActionTypeMap = (types: string[] = []): { [x: string]: boolean } => {
  return types.reduce((acc, type) => ({ ...acc, [type]: true }), {});
};

/**
 * Transform actions types into lookup dictionary with isMatch and shouldReplaceState
 * flags
 * @param types
 */
export const createActionTypeMapWithReplaceStateFlag = (
  types: CaseWithReplaceStateFlag[]
): { [x: string]: { isMatch: boolean; shouldReplaceState: boolean } } => {
  return types.reduce(
    (acc, type) => ({ ...acc, [type.type]: { isMatch: true, shouldReplaceState: Boolean(type.shouldReplaceState) } }),
    {}
  );
};

/**
 * Returns pagination properties from the Pageable API payload or default values
 * if payload is not provided
 * @param payload
 */
export const getStatePaginationFromPageableResponsePayload = (
  payload: ApiPageableResponse<GenericEntity>
): StatePagination => ({
  currentPage: payload?.number || 0,
  hasNextPage: !payload.last,
  hasPreviousPage: !payload.first,
  totalPages: payload?.totalPages || 0,
});

/**
 * Returns a list of all ids gathered from an api response
 * @param payload - Api response
 */
export const getIdsFromApiResponse = (payload: GenericApiPayload): Id[] => {
  let ids: Id[] = [];

  if (Array.isArray(payload)) {
    // This is a generic list api response.
    ids = payload.map((entity) => entity.id);
  } else if ('content' in payload && Array.isArray(payload.content)) {
    // This is pageable api response.
    ids = payload.content.map((entity) => entity.id);
  } else if ('id' in payload) {
    // This is single api response
    ids = [payload.id];
  }

  return ids;
};

/**
 * Returns a list of keys from Map API Payload
 * @param payload
 */
export const getKeysFromMapApiResponse = (payload: MapApiPayload): string[] => {
  return Object.keys(payload);
};

/**
 * Given list of ids and map of entities by id, returns list of entities
 * @param allIds - List of all ids
 * @param byId - Object (map) of entities by id
 */
export const getMappedStateIds = <Entity extends unknown>(allIds?: Id[], byId?: Record<Id, Entity>) => {
  if (!allIds || !byId) {
    return [];
  }

  return allIds.map((id) => byId[id]);
};

/**
 * Returns entities from pageable API response
 * @param {Payload} payload
 * @param {?EntityLike[]} payload.content
 * @returns {EntityLike[]}
 */
export const getEntitiesFromPageableApiResponse = (payload) => payload?.content || [];

/**
 * Returns object from Pageable API response in a single object
 * with entity.id as a key for a given entity
 * @param {Object[]} payload
 * @returns {Object}
 */
export const getEntitiesByIdFromListResponse = (payload) => {
  return payload.reduce((acc, entity) => ({ ...acc, [entity.id]: entity }), {});
};

/**
 * Returns object from Pageable API response in a single object
 * with entity.id as a key for a given entity
 * @param {Payload} payload
 * @param {Object[]} payload.content
 * @returns {Object}
 */
export const getEntitiesByIdFromPageableApiResponse = ({ content }) => getEntitiesByIdFromListResponse(content);

/**
 * Returns object from Single Entity API response in a single object
 * with entity.id as a key for a given entity
 * @param {Payload} entity
 * @param {Id} entity.id
 * @returns {Object}
 */
export const getEntityByIdFromSingleEntityApiResponse = (entity) => ({
  [entity.id]: entity,
});

/**
 * Returns by id lookup table of entities received from the API response
 * @param payload
 */
export const getEntitiesByIdFromApiResponse = (payload: GenericApiPayload) => {
  // If the API payload has content property, does not have an id property and
  // the content property is an array, we are dealing with pageable API response
  if (!('id' in payload) && 'content' in payload && Array.isArray(payload.content)) {
    return getEntitiesByIdFromListResponse(payload.content);
  }

  // If API payload is an array, we are dealing with list API response
  if (Array.isArray(payload)) {
    return getEntitiesByIdFromListResponse(payload);
  }

  // If payload is not a pageable API payload and not an array,
  // we are dealing with a single entity response
  return getEntityByIdFromSingleEntityApiResponse(payload as GenericEntity);
};

/**
 * Returns list of entities received from the API response
 * @param payload
 */
export const getEntitiesFromApiResponse = (payload: GenericApiPayload) => {
  // If the API payload has content property, does not have an id property and
  // the content property is an array, we are dealing with pageable API response
  if (!('id' in payload) && 'content' in payload && Array.isArray(payload.content)) {
    return payload.content;
  }

  // If API payload is an array, we are dealing with list API response
  if (Array.isArray(payload)) {
    return payload;
  }

  // If payload is not a pageable API payload and not an array,
  // we are dealing with a single entity response
  return [payload as GenericEntity];
};

/**
 * Returns map of entities extracted from parent relation API response
 * @param payload
 * @param key
 * @param options
 */
export const getEntitiesByIdFromParentRelationResponse = (
  payload: any,
  key: string,
  options?: { mapper: (payload: any) => any[] }
) => {
  let parentEntities;

  if (options?.mapper) {
    parentEntities = options.mapper(payload);
  } else {
    parentEntities = Array.isArray(payload) ? payload : [payload];
  }

  const entities = parentEntities.map((entity) => entity[key]);

  return getEntitiesByIdFromListResponse(entities);
};

/**
 * Boilerplate function for creating isDoing reducer
 * @param options
 */
export const createIsDoingReducer = ({ endCases, requestCases }: { endCases: string[]; requestCases: string[] }) => {
  const endCasesMap = createActionTypeMap(endCases);
  const requestCasesMap = createActionTypeMap(requestCases);

  return createReducer<boolean>(false, (builder) => {
    return builder
      .addMatcher(
        // This is either SUCCESS or FAILURE, it means that the action is
        // no longer taking place
        ({ type }) => endCasesMap[type],
        () => false
      )
      .addMatcher(
        // Most often, this is REQUEST, it means that the action is currently
        // taking place
        ({ type }) => requestCasesMap[type],
        () => true
      );
  });
};

/**
 * Boilerplate function for creating isFetching reducer
 * @param options
 */
export const createIsFetchingReducer = createIsDoingReducer;

/**
 * Boilerplate function for creating isSubmitting reducer
 * @param options
 */
export const createIsSubmittingReducer = createIsDoingReducer;

/**
 * Boilerplate function for creating isInitialized reducer
 * @param endCases
 */
export const createIsInitializedReducer = ({ endCases }: { endCases: string[] }) => {
  const endCasesMap = createActionTypeMap(endCases);

  return createReducer<boolean>(false, (builder) => {
    return builder.addMatcher(
      ({ type }) => endCasesMap[type],
      () => true
    );
  });
};

/**
 * Boilerplate function for creating error reducer
 * @param options
 */
export const createErrorReducer = ({ failureCases, resetCases }: { failureCases: string[]; resetCases: string[] }) => {
  const failureCasesMap = createActionTypeMap(failureCases);
  const resetCasesMap = createActionTypeMap(resetCases);

  return createReducer<string | null>(null, (builder) => {
    return builder
      .addMatcher(
        ({ type }) => failureCasesMap[type],
        (_, { payload }) => payload
      )
      .addMatcher(
        ({ type }) => resetCasesMap[type],
        () => null
      );
  });
};

/**
 * Boilerplate function for creating allIds reducer from pageable API response
 * @param {Object[]} successCases
 * @param {string[]} [resetStateCases]
 * @returns {ReduxReducer}
 */
export const createAllIdsReducer = ({
  successCases,
  resetCases,
}: {
  successCases: CaseWithReplaceStateFlag[];
  resetCases?: string[];
}) => {
  const successCasesMap = createActionTypeMapWithReplaceStateFlag(successCases);
  const resetCasesMap = createActionTypeMap(resetCases);

  return createReducer<Id[]>([], (builder) => {
    return builder
      .addMatcher(
        ({ type }) => successCasesMap[type]?.isMatch,
        (state, action) => {
          const { shouldReplaceState } = successCasesMap[action.type] || {};
          const ids = getIdsFromApiResponse(action.payload);

          if (shouldReplaceState) {
            return ids;
          }

          return Array.from(new Set([...state, ...ids]));
        }
      )
      .addMatcher(
        ({ type }) => resetCasesMap[type],
        () => []
      );
  });
};

/**
 * Boilerplate function for creating byId reducer
 * @param options
 * @param options.successCases - list of success cases
 * @param options.resetCases - list of reset cases
 */
export const createByIdReducer = <Entity = GenericEntity>({
  successCases,
  resetCases = [],
  extraCases,
}: {
  successCases: string[];
  resetCases?: string[];
  extraCases?: (builder: Builder<Record<Id, Entity>>) => Builder<Record<Id, Entity>>;
}) => {
  const successCasesMap = createActionTypeMap(successCases);
  const resetCasesMap = createActionTypeMap(resetCases);

  return createReducer<Record<Id, Entity>>({}, (builder) => {
    if (extraCases) {
      builder = extraCases(builder);
    }

    return builder
      .addMatcher(
        ({ type }) => successCasesMap[type],
        (state, { payload }) => mergeWithCurrentState(state, getEntitiesByIdFromApiResponse(payload))
      )
      .addMatcher(
        ({ type }) => resetCasesMap[type],
        () => ({})
      );
  });
};

/**
 * Boilerplate reducer for capturing the entity's pagination state
 * @param options
 */
export const createPaginationReducer = ({
  successCases,
  resetCases,
}: {
  successCases: string[];
  resetCases?: string[];
}) => {
  const successCasesMap = createActionTypeMap(successCases);
  const resetCasesMap = createActionTypeMap(resetCases);

  return createReducer<StatePagination>(DEFAULT_PAGINATION_STATE, (builder) => {
    return builder
      .addMatcher(
        ({ type }) => successCasesMap[type],
        (_, { payload }) => getStatePaginationFromPageableResponsePayload(payload)
      )
      .addMatcher(
        ({ type }) => resetCasesMap[type],
        () => DEFAULT_PAGINATION_STATE
      );
  });
};

/**
 * Boilerplate function for creating lastFetched reducer
 * @param options
 */
export const createLastFetchedReducer = ({
  successCases,
  resetCases = [],
}: {
  successCases: string[];
  resetCases?: string[];
}) => {
  const successCasesMap = createActionTypeMap(successCases);
  const resetCasesMap = createActionTypeMap(resetCases);

  return createReducer<Timestamp | null>(null, (builder) => {
    return builder
      .addMatcher(
        ({ type }) => successCasesMap[type],
        () => Date.now()
      )
      .addMatcher(
        ({ type }) => resetCasesMap[type],
        () => null
      );
  });
};

/**
 * Boilerplate function for creating status aware by id reducer
 * @param options
 */
export const createStatusAwareAllIdsReducer = ({
  resetStateCases = [],
  statusCheckFunction,
  successCases = [],
}: {
  resetStateCases?: string[];
  statusCheckFunction: (action: AnyPayloadAction) => boolean;
  successCases?: CaseWithReplaceStateFlag[];
}) => {
  const successCasesMap = createActionTypeMapWithReplaceStateFlag(successCases);
  const resetCasesMap = createActionTypeMap(resetStateCases);

  return createReducer<Id[]>([], (builder) => {
    return builder
      .addMatcher(
        ({ type }) => successCasesMap[type]?.isMatch,
        (state, action: AnyPayloadAction) => {
          const { shouldReplaceState } = successCasesMap[action.type] || {};
          const ids = getIdsFromApiResponse(action.payload);

          // We first want to check if the action is related to the expected
          // status/condition/property
          if (!statusCheckFunction(action)) {
            // Single entity response - if id currently exist in the
            // state, we want to remove it if not related to the expected status
            if ('id' in action.payload) {
              return state.filter((id) => id !== action.payload.id);
            }

            // In other cases, we just want to ignore the action and return
            // the state as-is
            return state;
          }

          // Return only new ids if we should replace the state
          if (shouldReplaceState) {
            return ids;
          }

          // Return merged olds ids and new ids, without duplicates
          return Array.from(new Set([...state, ...ids]));
        }
      )
      .addMatcher(
        // If we want to reset the state, we don't care if the action is
        // related to a specific status check function
        ({ type }) => resetCasesMap[type],
        () => []
      );
  });
};

/**
 * Boilerplate function for creating status aware by id reducer
 * @param options
 */
export const createStatusAwareByIdReducer = <Entity = GenericEntity>({
  resetCases = [],
  statusCheckFunction,
  successCases,
}: {
  resetCases?: string[];
  statusCheckFunction: (action: AnyPayloadAction) => boolean;
  successCases: string[];
}) => {
  const successCasesMap = createActionTypeMap(successCases);
  const resetCasesMap = createActionTypeMap(resetCases);

  return createReducer<Record<Id, Entity>>({}, (builder) => {
    return builder
      .addMatcher(
        ({ type }) => successCasesMap[type],
        (state, { type, payload }) => {
          if (!statusCheckFunction({ type, payload })) {
            // Single cases - if received entity is not related to the expected status
            // remove it from the state (if it exists). If it is, added it
            if ('id' in payload) {
              delete state[payload.id];
              return state;
            }

            // If it is not a single case, we just want to ignore this action alltogether
            return state;
          }

          // If status check function has passed, we want to return the updated
          // state
          return mergeWithCurrentState(state, getEntitiesByIdFromApiResponse(payload));
        }
      )
      .addMatcher(
        // If we want to reset the state, we don't care if the action is
        // related to a specific status check function
        ({ type }) => resetCasesMap[type],
        () => ({})
      );
  });
};

/**
 * Boilerplate function for creating status aware if fetching reducer
 * @param options
 */
export const createStatusAwareIsFetchingReducer = ({
  endCases,
  requestCases,
  resetCases = [],
  statusCheckFunction,
}: {
  endCases: string[];
  requestCases: string[];
  resetCases?: string[];
  statusCheckFunction: (action: AnyPayloadAction) => boolean;
}) => {
  const resetCasesMap = createActionTypeMap(resetCases);
  // We create standard isFetching reducer and we use it if action is related
  // to the provided status check
  const reducer = createIsFetchingReducer({
    endCases,
    requestCases,
  });

  return (state = false, action: AnyPayloadAction) => {
    // If we want to reset the state, we don't care if the action is
    // related to the ACTIVE purchase orders type
    if (resetCasesMap[action.type]) {
      return false;
    }

    // If action is not related to the provided status check,
    // we return the unmodified state
    if (!statusCheckFunction(action)) {
      return state;
    }

    return reducer(state, action);
  };
};

export const createStatusAwarePaginationReducer = ({
  resetCases,
  statusCheckFunction,
  successCases,
}: {
  resetCases: string[];
  statusCheckFunction: (action: AnyPayloadAction) => boolean;
  successCases: string[];
}) => {
  const resetStateCasesMap = createActionTypeMap(resetCases);
  // We create standard pagination reducer and use it if action is related
  // to the provided status check
  const reducer = createPaginationReducer({ successCases });

  return (state: StatePagination = DEFAULT_PAGINATION_STATE, action: AnyPayloadAction) => {
    // If we want to reset the state, we don't care if the action is
    // related to the ACTIVE purchase orders type
    if (resetStateCasesMap[action.type]) {
      return DEFAULT_PAGINATION_STATE;
    }

    // If action is not related to the provided status check,
    // we return the unmodified state
    if (!statusCheckFunction(action)) {
      return state;
    }

    return reducer(state, action);
  };
};

/**
 * Creates a Saga watcher for given sagas
 * @param sagasToWatch - List of saga/routines we want to watch
 */
export function* sagaWatcher(sagasToWatch: { type: string; saga: (...args: any[]) => any }[]) {
  while (true) {
    const action = yield take(sagasToWatch.map((saga) => saga.type));

    const sagaToWatch = sagasToWatch.find((saga) => isEqual(saga.type, action.type));

    if (sagaToWatch) {
      yield spawn(sagaToWatch.saga, action);
    }
  }
}

/**
 * Returns true if Redux Action's payload contains passed purchase order
 * status
 * @param action - Dispatched action
 * @param purchaseOrdersStatus - Purchase order type to check against
 */
export const isPurchaseOrdersActionRelatedToStatus = (
  action: AnyPayloadAction,
  purchaseOrderStatus: PurchaseOrderType
) => {
  let status: PurchaseOrderType;

  if (action.payload?.status) {
    // This means that we received single entity, we can determine the status from
    // entity status
    status = purchaseOrderTypeForPurchaseOrderStatusMap[action.payload?.status];
  } else {
    // This property is passed in Pageable API response
    status = action.payload?.purchaseOrdersStatus;
  }

  return status === purchaseOrderStatus;
};

/**
 * Returns true if Redux Action's payload contains passed ACTIVE purchase order
 * status
 * @param action - Dispatched action
 */
export const isPurchaseOrdersActionRelatedToActiveOrders = (action: AnyPayloadAction) => {
  return isPurchaseOrdersActionRelatedToStatus(action, PurchaseOrderType.ACTIVE);
};

/**
 * Returns true if Redux Action's payload contains passed EXPIRED purchase order
 * status
 * @param action - Dispatched action
 */
export const isPurchaseOrdersActionRelatedToExpiredOrders = (action: AnyPayloadAction) => {
  return isPurchaseOrdersActionRelatedToStatus(action, PurchaseOrderType.EXPIRED);
};

/**
 * Returns true if Redux Action's payload contains passed FINALIZED purchase order
 * status
 * @param action - Dispatched action
 */
export const isPurchaseOrdersActionRelatedToFinalizedOrders = (action: AnyPayloadAction) => {
  return isPurchaseOrdersActionRelatedToStatus(action, PurchaseOrderType.FINALIZED);
};

/**
 * Helper which spreads all of the state, dispatch, and own props and also
 * takes a customizer function which does any sort of derivation.
 * @param customization - Function that takes state, dispatch and component props and returns some newly merged props
 */
export const createMergeProps = <
  S extends Record<string, unknown>,
  D extends Record<string, unknown>,
  O extends Record<string, unknown>,
  M extends Record<string, unknown>
>(
  customization: (stateProps: S, dispatchProps: D, ownProps?: O) => M
) => (stateProps: S, dispatchProps: D, ownProps?: O) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  ...customization(stateProps, dispatchProps, ownProps),
});

/**
 * Given a list of routines, returns a map of reducer cases
 * @param routines - Routines from which we want to gather reducer cases
 */
export const gatherReducerCasesFromRoutines = (routines: Routine[]) => {
  const failureCases = routines.map((routine) => routine.FAILURE);
  const requestCases = routines.map((routine) => routine.REQUEST);
  const successCases = routines.map((routine) => routine.SUCCESS);

  return {
    failureCases,
    requestCases,
    successCases,
  };
};
