import { createIsFetchingReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchUnitOfMeasurementsRoutine } from 'store/routines/unitOfMeasurements';

/**
 * Map of cases gathered from routines
 */
const { requestCases, failureCases, successCases } = gatherReducerCasesFromRoutines([fetchUnitOfMeasurementsRoutine]);

/**
 * Unit of measurements is fetching reducer
 */
const isFetchingReducer = createIsFetchingReducer({ endCases: [...successCases, ...failureCases], requestCases });

export { successCases, failureCases, requestCases };
export default isFetchingReducer;
