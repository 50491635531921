import { createErrorReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchUnitOfMeasurementsRoutine } from 'store/routines/unitOfMeasurements';

/**
 * Map of cases gathered from routines
 */
const { failureCases, requestCases, successCases } = gatherReducerCasesFromRoutines([fetchUnitOfMeasurementsRoutine]);

/**
 * Unit of measurements error reducer
 */
const errorReducer = createErrorReducer({ failureCases, resetCases: [...successCases, ...requestCases] });

export { failureCases, requestCases, successCases };
export default errorReducer;
