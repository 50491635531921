import PropTypes from 'prop-types';
import React from 'react';

import { Intent } from 'enums/ui';

import { allValues } from 'helpers/utility';

import './StatusDot.scss';

/**
 * Component rendering Status Dot which matches the passed intent prop
 * @param {Object} props
 * @param {Intent} [props.intent=Intent.NEUTRAL]
 * @return {StatelessComponent}
 */
const StatusDot = ({ intent }) => <div className={`status-dot status-dot--intent-${intent}`} />;

StatusDot.propTypes = {
  intent: PropTypes.oneOf(allValues(Intent)),
};

StatusDot.defaultProps = {
  intent: Intent.NEUTRAL,
};

export default StatusDot;
