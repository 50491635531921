import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchSinglePurchaseOrderRoutine } from 'store/routines/purchaseOrders';
import { purchaseOrderForOrderIdFromParamsSelector } from 'store/selectors/purchaseOrdersSelectors';

import { userStateSelector } from 'store/selectors/userSelectors';

import OrderDetails from './OrderDetails';

export const mapStateToProps = createStructuredSelector({
  order: purchaseOrderForOrderIdFromParamsSelector,
  user: userStateSelector,
});

export const mapDispatchToProps = {
  fetchSingleOrder: fetchSinglePurchaseOrderRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
