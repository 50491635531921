import React from 'react';

/**
 * Returns the previous value of the given argument.
 * For usage similar to componentDidUpdate's prevProps/prevState.
 * @function
 * @param {*} value
 * @return {*}
 */
const usePrevious = (value) => {
  // the ref object is a generic container whose current property is mutable,
  // and can hold any value, similar to an instance property on a class
  const ref = React.useRef();

  // store current value in ref, as a side-effect
  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  // return previous value (happens before update in useEffect above)
  return ref.current;
};

export default usePrevious;
