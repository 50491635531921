import React from 'react';
import { Field } from 'react-final-form';

import { TextInput } from 'components/elements';

import { ProductFormFields } from 'enums/formFields';

import { requiredValidator } from 'helpers/validators';

/**
 * Component rendering Product Origin form field. Must be used in scope of
 * React Final Form.
 * @returns {StatelessComponent}
 */
const ProductOriginFormField = () => (
  <Field
    component={TextInput}
    fullwidth={true}
    label="Država izvora"
    name={ProductFormFields.ORIGIN}
    validate={requiredValidator}
  />
);

export default ProductOriginFormField;
