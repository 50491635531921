import { createAllIdsReducer } from 'helpers/redux';
import {
  fetchPurchaseOrdersNextPageRoutine,
  fetchPurchaseOrdersRoutine,
  fetchSinglePurchaseOrderRoutine,
} from 'store/routines/purchaseOrders';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Success cases with replace state flag
 */
const successCases = [
  { type: fetchPurchaseOrdersRoutine.SUCCESS, shouldReplaceState: true },
  { type: fetchPurchaseOrdersNextPageRoutine.SUCCESS, shouldReplaceState: false },
  { type: fetchSinglePurchaseOrderRoutine.SUCCESS, shouldReplaceState: false },
];

/**
 * Reset state cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Purchase Orders reducers: all ids reducer
 */
const purchaseOrdersAllIdsReducer = createAllIdsReducer({ successCases, resetCases });

export { successCases, resetCases };
export default purchaseOrdersAllIdsReducer;
