import PropTypes from 'prop-types';
import React from 'react';

import { LoadingIndicator, Text } from 'components/atoms';
import { PageLayout } from 'components/modules/global';
import { ProductStatusTag } from 'components/molecules';

import { productDtoProp } from 'constants/props';

import { IconNames } from 'enums/icons';
import { ProductsRoutes } from 'enums/routes';
import { TextSize } from 'enums/typography';
import { LoadingIndicatorType } from 'enums/ui';

import { ProductEditForm } from './components';

/**
 * Page component rendering Product Edit page
 * @param {Object} props
 * @param {Boolean} props.isFetching
 * @param {RouterMatchProp} props.match
 * @param {Function} props.onFetchProduct
 * @param {Product} [props.product]
 * @returns {StatelessComponent}
 */
const ProductEdit = ({ isFetching, match, onFetchProduct, product }) => {
  const { productId } = Object(match.params);

  const shouldShowLoadingIndicator = Boolean(isFetching || !product);

  const actions = [{ icon: IconNames.REFRESH, title: 'Osveži izdelek', onClick: onFetchProduct }];

  const breadcrumbs = [
    { path: ProductsRoutes.ROOT, name: 'Spletna trgovina' },
    { path: ProductsRoutes.ROOT, name: 'Izdelki' },
    { path: match.url, name: shouldShowLoadingIndicator ? 'Nalagam izdelek...' : product.productName },
  ];

  React.useEffect(() => {
    if (!isFetching && !product) {
      onFetchProduct();
    }
  }, [isFetching, onFetchProduct, product, productId]);

  return (
    <PageLayout
      actionbarAddons={<ProductStatusTag isActive={product?.active} isLoading={shouldShowLoadingIndicator} />}
      actionbarMenuActions={actions}
      breadcrumbs={breadcrumbs}
    >
      {shouldShowLoadingIndicator ? (
        <LoadingIndicator fullcontent={true} type={LoadingIndicatorType.PRIMARY} />
      ) : (
        <>
          <Text.ExtraBold size={TextSize.LEVEL_400}>Urejanje izdelka</Text.ExtraBold>
          <ProductEditForm productId={productId} />
        </>
      )}
    </PageLayout>
  );
};

ProductEdit.propTypes = {
  isFetching: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({ productId: PropTypes.string }),
    url: PropTypes.string,
  }).isRequired,
  product: productDtoProp,
};

export default ProductEdit;
