import { KeyCode } from 'enums/inputs';

import { isEqual } from './utility';

/**
 * Prevents default event and executes callback function
 * @param {Event} event
 * @param {Function} callback
 * @returns {*}
 */
export const noDefaultEvent = (event, callback) => {
  // Prevent default event
  event.preventDefault();

  // To avoid app crashes, before calling the passed callback
  // first check if it exists
  if (callback) {
    // We pass the original event to the provided callback function
    // and return the value of that function
    return callback(event);
  }
};

/**
 * Returns true if event.key is KeyCode.ENTER
 * @param {KeyboardEvent} event
 * @returns {Boolean}
 */
export const isPressedKeyEnterKey = (event) => isEqual(event.key, KeyCode.ENTER);

/**
 * Executes passed callback if pressed key is enter key
 * @param {Event} evt
 * @param {Function} callback
 * @returns {void}
 */
export const callbackOnEnterKeyPress = (evt, callback) => {
  if (isPressedKeyEnterKey(evt)) {
    callback();
  }
};
