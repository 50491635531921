import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/atoms/Icon';
import Tooltip from 'components/atoms/Tooltip/Tooltip';

import { IconNames } from 'enums/icons';
import { IconPosition } from 'enums/ui';

import './Tag.scss';
import { valueOrEmptyObject } from 'helpers/utility';

/**
 * Component rendering tag element with text and (optionally) icon
 * @param {ComponentProps} props
 * @param {string} props.text
 * @param {string} [props.icon]
 * @param {string} [props.iconPosition=IconPosition.LEFT]
 * @return {StatelessComponent}
 */
const Tag = ({ text, icon, iconPosition, tooltipProps }) => (
  <Tooltip visible={Boolean(tooltipProps)} {...valueOrEmptyObject(tooltipProps)}>
    <div
      className={classNames('tag tag-element', {
        'tag--has-icon': Boolean(icon),
        [`tag--icon-position-${iconPosition}`]: Boolean(icon),
      })}
    >
      {Boolean(icon) && <Icon icon={icon} />}
      {text}
    </div>
  </Tooltip>
);

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(Object.values(IconNames)),
  iconPosition: PropTypes.oneOf(Object.values(IconPosition)),
  tooltipProps: PropTypes.shape({
    ...Tooltip.propTypes,
  }),
};

Tag.defaultProps = {
  iconPosition: IconPosition.LEFT,
};

export default Tag;
