import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Icon, Text } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { TextColor } from 'enums/typography';
import { isEqual } from 'helpers/utility';

import './Breadcrumb.scss';

/**
 * Component rendering single breadcrumb segment
 * @see Breadcrumbs
 *
 * @param {ComponentProps} props
 * @param {number} props.index
 * @param {number} props.numOfAllPaths
 * @param {PathProp} props.path
 * @return {StatelessComponent}
 */
const Breadcrumb = ({ index, numOfAllPaths, path }) => {
  const location = useLocation();

  const isLastPath = isEqual(numOfAllPaths - 1, index);
  const TextComponent = React.useMemo(() => {
    return isLastPath ? Text.Bold : Text.Regular;
  }, [isLastPath]);

  return (
    <>
      <Link
        key={path.path}
        className={classNames('breadcrumb', { 'breadcrumb--disabled': isLastPath })}
        to={{ pathname: path.path, state: location?.state }}
      >
        <TextComponent color={TextColor.GREY_LIGHT}>{path.name}</TextComponent>
      </Link>
      {!isLastPath && <Icon icon={IconNames.CHEVRON_RIGHT} />}
    </>
  );
};

Breadcrumb.propTypes = {
  index: PropTypes.number.isRequired,
  numOfAllPaths: PropTypes.number.isRequired,
  path: PropTypes.shape({ path: PropTypes.string, name: PropTypes.string }).isRequired,
};

export default Breadcrumb;
