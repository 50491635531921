import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { productsByIdSelector } from 'store/selectors/productsSelectors';

import OrderDetailsItems from './OrderDetailsItems';

export const mapStateToProps = createStructuredSelector({
  productsById: productsByIdSelector,
});

export default connect(mapStateToProps)(OrderDetailsItems);
