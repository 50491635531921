import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, FixedFooter } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { ProductsRoutes } from 'enums/routes';
import { ButtonType, Intent } from 'enums/ui';

/**
 * Component rendering the Footer of Product Edit Form
 * @see {ProductEditForm}
 *
 * @param {Object} props
 * @param {Function} props.handleSubmit
 * @param {Boolean} props.isSubmitting
 * @param {Boolean} props.pristine
 * @param {Boolean} props.valid
 * @returns {StatelessComponent}
 */
const ProductEditFormFooter = ({ handleSubmit, isSubmitting, pristine, valid }) => {
  const history = useHistory();

  const onBackButtonClick = () => {
    history.push(ProductsRoutes.ROOT);
  };

  return (
    <FixedFooter contentClassName="product-edit-form__footer">
      <Button icon={IconNames.ARROW_LEFT} intent={Intent.INFO} onClick={onBackButtonClick} type={ButtonType.LINK}>
        Nazaj na vse izdelke
      </Button>

      <Button disabled={pristine || !valid} icon={IconNames.SAVE} loading={isSubmitting} onClick={handleSubmit}>
        Shrani spremembe
      </Button>
    </FixedFooter>
  );
};

ProductEditFormFooter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
};

export default ProductEditFormFooter;
