import { combineReducers } from 'redux';

import type { ProductsState } from 'store/interfaces/productsState.types';

import errorReducer from './errorReducer';
import isFetchingReducer from './isFetchingReducer';
import isSubmittingReducer from './isSubmittingReducer';
import lastFetchedReducer from './lastFetchedReducer';
import paginationReducer from './paginationReducer';
import productsAllIdsReducer from './productsAllIdsReducer';
import productsByIdReducer from './productsByIdReducer';

const productsReducer = combineReducers<ProductsState>({
  allIds: productsAllIdsReducer,
  byId: productsByIdReducer,
  error: errorReducer,
  isFetching: isFetchingReducer,
  isSubmitting: isSubmittingReducer,
  lastFetched: lastFetchedReducer,
  pagination: paginationReducer,
});

export default productsReducer;
