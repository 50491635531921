import { createIsFetchingReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import {
  fetchPurchaseOrdersNextPageRoutine,
  fetchPurchaseOrdersRoutine,
  fetchSinglePurchaseOrderRoutine,
  patchPurchaseOrderStatusRoutine,
} from 'store/routines/purchaseOrders';

/**
 * Map of cases gathered from routines
 */
const { successCases, requestCases, failureCases } = gatherReducerCasesFromRoutines([
  patchPurchaseOrderStatusRoutine,
  fetchPurchaseOrdersRoutine,
  fetchPurchaseOrdersNextPageRoutine,
  fetchSinglePurchaseOrderRoutine,
]);

/**
 * Purchase orders reducers: is fetching reducer
 */
const isFetchingReducer = createIsFetchingReducer({ requestCases, endCases: [...successCases, ...failureCases] });

export { successCases, failureCases, requestCases };
export default isFetchingReducer;
