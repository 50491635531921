import React from 'react';

import { Grid } from 'components/atoms';
import { CollapsibleCard, DataEntry } from 'components/elements';

import { IconNames } from 'enums/icons';
import { GridColumnCount, GridGapSize } from 'enums/ui';

import type { OrderDetailsInfoCustomerProps } from './OrderDetailsInfoCustomer.types';

/**
 * Component rendering Purchase Order customer info details
 * @param props - Component props
 */
const OrderDetailsInfoCustomer: React.VFC<OrderDetailsInfoCustomerProps> = ({ purchaseOrder }) => {
  const { pickup } = purchaseOrder || {};

  if (!pickup) {
    return null;
  }

  return (
    <CollapsibleCard className="margin-bottom--12" title="Podatki o kupcu">
      <Grid gap={GridGapSize.MEDIUM} numOfCols={GridColumnCount.TWO}>
        <DataEntry
          gridSpan={2}
          label="Ime in priimek"
          value={`${pickup?.customerGivenName} ${pickup?.customerFamilyName}`}
        />
        <DataEntry
          icon={IconNames.EMAIL}
          label="E-pošta"
          value={pickup?.customerEMail || 'E-poštni naslov ni naveden'}
        />
        <DataEntry
          icon={IconNames.PHONE}
          label="Telefonska številka"
          value={pickup?.customerPhone || 'Telefonska številka ni navedena'}
        />
        {!!pickup?.comment && <DataEntry gridSpan={2} label="Dodatne želje in komentarji" value={pickup.comment} />}
      </Grid>
    </CollapsibleCard>
  );
};

export default OrderDetailsInfoCustomer;
