import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { ButtonType, EntityAction, IconPosition, Intent } from 'enums/ui';
import { getDeliveryEntrySidesheetConfirmButtonText } from 'helpers/ui';

import './AddDeliveryEntrySidesheetFooter.scss';

/**
 * Component rendering the footer content of the AddDeliveryEntrySidesheet
 * @param {ComponentProps} props
 * @param {EntityActionType} props.action
 * @param {Function} props.onClose
 * @param {Function} props.onSave
 * @return {StatelessComponent}
 */
const AddDeliveryEntrySidesheetFooter = ({ action, onClose, onSave }) => (
  <div className="add-delivery-entry-sidesheet-footer">
    <Button intent={Intent.INFO} onClick={onClose} type={ButtonType.LINK}>
      Prekliči in zapri
    </Button>
    <Button
      disabled={true}
      icon={IconNames.ARROW_RIGHT}
      iconPosition={IconPosition.RIGHT}
      intent={Intent.SUCCESS}
      onClick={onSave}
      type={ButtonType.PRIMARY}
    >
      {getDeliveryEntrySidesheetConfirmButtonText(action)}
    </Button>
  </div>
);

AddDeliveryEntrySidesheetFooter.propTypes = {
  action: PropTypes.oneOf([EntityAction.ADD, EntityAction.UPDATE]).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddDeliveryEntrySidesheetFooter;
