import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { createStructuredSelector } from 'reselect';

import { LocalStorageKeys } from 'enums/localStorage';

import { getFilteredOptifarmUserRoles } from 'helpers/roles';
import { logExceptionToSentry } from 'helpers/sentry';

import Router from 'pages/Router';

import { onSetSelectedMerchant, setUser } from 'store/actions';
import { fetchMerchantsRoutine } from 'store/routines/merchants';
import { fetchUnitOfMeasurementsRoutine } from 'store/routines/unitOfMeasurements';
import { fetchVatsRoutine } from 'store/routines/vats';
import { unitOfMeasurementsShouldFetchSelector } from 'store/selectors/unitOfMeasurementsSelectors';
import { userStateSelector } from 'store/selectors/userSelectors';
import { vatsShouldFetchSelector } from 'store/selectors/vatsSelectors';

/**
 * Main app entry point component
 * @param {ComponentProps} props
 * @param {Function} props.fetchMerchants
 * @param {Function} props.fetchUnitOfMeasurements
 * @param {Function} props.fetchVats
 * @param {Function} props.setSelectedMerchant
 * @param {Function} props.onSetUser
 * @param {Boolean} props.shouldFetchUnitOfMeasurements
 * @param {Boolean} props.shouldFetchVats
 * @param {User} props.user
 * @returns {FunctionComponent}
 */
export const App = ({
  fetchMerchants,
  fetchUnitOfMeasurements,
  fetchVats,
  setSelectedMerchant,
  onSetUser,
  shouldFetchUnitOfMeasurements,
  shouldFetchVats,
  user,
}) => {
  const { keycloak, initialized } = useKeycloak();

  const fetchAndSetSelectedMerchant = React.useCallback(
    (merchantId) => {
      fetchMerchants();
      setSelectedMerchant(parseInt(merchantId));
    },
    [fetchMerchants, setSelectedMerchant]
  );

  // Unit of Measurements are not tied to a merchant - we want to fetch & store them
  // as soon as possible
  React.useEffect(() => {
    if (shouldFetchUnitOfMeasurements) {
      fetchUnitOfMeasurements();
    }
  }, [fetchUnitOfMeasurements, shouldFetchUnitOfMeasurements]);

  // Vats are not tied to a merchant - we want to fetch & store them
  // as soon as possible
  React.useEffect(() => {
    if (shouldFetchVats) {
      fetchVats();
    }
  }, [fetchVats, shouldFetchVats]);

  React.useEffect(() => {
    const storedSelectedMerchantId = localStorage.getItem(LocalStorageKeys.SELECTED_MERCHANT_ID);

    if (storedSelectedMerchantId) {
      fetchAndSetSelectedMerchant(storedSelectedMerchantId);
    } else {
      const url = new URL(window.location.href);
      const companyIdQueryParam = url.searchParams.get('company_id');

      if (companyIdQueryParam) {
        localStorage.setItem(LocalStorageKeys.SELECTED_MERCHANT_ID, companyIdQueryParam);
        fetchAndSetSelectedMerchant(companyIdQueryParam);
      }
    }
  }, [fetchAndSetSelectedMerchant, fetchMerchants, setSelectedMerchant]);

  React.useEffect(() => {
    if (initialized) {
      keycloak
        .loadUserInfo()
        .then(({ name, roles, sub, family_name, given_name }) => {
          onSetUser({
            id: sub,
            name,
            firstName: given_name,
            lastName: family_name,
            roles: getFilteredOptifarmUserRoles(roles),
          });
        })
        .catch((err) => {
          logExceptionToSentry(err);

          if (!keycloak.authenticated) {
            keycloak.logout();
          }
        });
    }
  }, [initialized, keycloak, onSetUser]);

  return (
    <div className="App">
      <Router user={user} />
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  shouldFetchUnitOfMeasurements: unitOfMeasurementsShouldFetchSelector,
  shouldFetchVats: vatsShouldFetchSelector,
  user: userStateSelector,
});

const mapDispatchToProps = {
  fetchMerchants: fetchMerchantsRoutine,
  fetchUnitOfMeasurements: fetchUnitOfMeasurementsRoutine,
  fetchVats: fetchVatsRoutine,
  onSetUser: setUser,
  setSelectedMerchant: onSetSelectedMerchant,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
