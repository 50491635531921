import PropTypes from 'prop-types';
import React from 'react';

import { Grid } from 'components/atoms';
import { CollapsibleCard, DataEntry } from 'components/elements';

import { paymentMethodNameMap } from 'constants/payments';

import { IconNames } from 'enums/icons';
import { GridColumnCount, GridGapSize } from 'enums/ui';

import {
  getIconNameForPurchaseOrderPaymentStatus,
  getPurchaseOrderPaymentStatusDescription,
} from 'helpers/purchaseOrders';

/**
 * Component rendering purchase order payment info
 * @param {Object} props
 * @param {PurchaseOrderDTO} props.purchaseOrder
 * @returns {StatelessComponent}
 */
const OrderDetailsInfoPayment = ({ purchaseOrder }) => {
  const purchaseOrderStatus = getPurchaseOrderPaymentStatusDescription(purchaseOrder);
  const purchaseOrderStatusIcon = getIconNameForPurchaseOrderPaymentStatus(purchaseOrder);

  return (
    <CollapsibleCard className="margin-bottom--12" title="Podatki o plačilu">
      <Grid gap={GridGapSize.MEDIUM} numOfCols={GridColumnCount.TWO}>
        <DataEntry
          icon={IconNames.MONEY}
          label="Tip plačila"
          value={paymentMethodNameMap[purchaseOrder.paymentMethod]}
        />
        <DataEntry icon={purchaseOrderStatusIcon} label="Stanje plačila" value={purchaseOrderStatus} />
      </Grid>
    </CollapsibleCard>
  );
};

OrderDetailsInfoPayment.propTypes = {
  purchaseOrder: PropTypes.shape({
    paymentMethod: PropTypes.string,
  }).isRequired,
};

export default OrderDetailsInfoPayment;
