import PropTypes from 'prop-types';
import React from 'react';

import { paginationProp } from 'constants/props';

import { TableActions, TableTitle } from './components';

import './TableActionbar.scss';

/**
 * Component rendering Pageable Table actionbar
 * @param {Object} props
 * @param {ReactNode} [props.filters]
 * @param {Boolean} [props.isLoading]
 * @param {Boolean} [props.isSearching]
 * @param {String} [props.searchPlaceholder]
 * @param {Function} props.onGoToNextPage,
 * @param {Function} props.onGoToPreviousPage,
 * @param {Function} props.onRefresh,
 * @param {String} props.title
 * @param {Object} props.rest Rest of Props (common props between child components)
 * @return {StatelessComponent}
 */
const TableActionbar = ({
  filters,
  isLoading,
  isSearching,
  onGoToNextPage,
  onGoToPreviousPage,
  onRefresh,
  searchPlaceholder,
  title,
  ...rest
}) => (
  <div className="table-actionbar">
    <TableTitle {...rest} isLoading={isLoading || isSearching} title={title} />
    <TableActions
      {...rest}
      filters={filters}
      isLoading={isLoading}
      isSearching={isSearching}
      onGoToNextPage={onGoToNextPage}
      onGoToPreviousPage={onGoToPreviousPage}
      onRefresh={onRefresh}
      searchPlaceholder={searchPlaceholder}
    />
  </div>
);

TableActionbar.propTypes = {
  filters: PropTypes.node,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSearching: PropTypes.bool,
  onGoToNextPage: PropTypes.func.isRequired,
  onGoToPreviousPage: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  pagination: paginationProp.isRequired,
  title: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
};

export default TableActionbar;
