import PropTypes from 'prop-types';
import React from 'react';

import { FullScreenModal } from 'components/elements';
import { MerchantsGrid } from 'components/modules/merchants';
import { SidebarUserMenu } from 'components/molecules';

import { getSidebarLogoutAction } from 'helpers/navigation';

import useAuth from 'hooks/useAuth';

/**
 * Component rendering Select Merchant screen
 * @param {Object} props
 * @param {User} props.user
 * @return {StatelessComponent}
 */
const SelectMerchant = ({ user }) => {
  const { logout } = useAuth();
  const modalRef = React.useRef();

  return (
    <FullScreenModal
      ref={modalRef}
      addons={<SidebarUserMenu actions={[getSidebarLogoutAction(logout)]} user={user} />}
      subtitle="Kot uporabnik z vlogo 'operator' morate najprej izbrati ponudnika, ki ga želite upravljati."
      title="Izberi ponudnika"
    >
      <MerchantsGrid scrollableContainer={modalRef} />
    </FullScreenModal>
  );
};

SelectMerchant.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export default SelectMerchant;
