export enum AuthRoutes {
  LOGIN = '/login',
  UNAUTHORIZED = '/login/unauthorized',
}

export enum DashboardRoutes {
  ROOT = '/',
}

export enum MerchantsRoutes {
  ROOT = '/merchants',
}

export enum SettingsRoutes {
  ROOT = '/settings',
  COMPANY_GENERAL = '/settings/company/general',
  COMPANY_DELIVERY_DAYS = '/settings/company/delivery-and-post-codes',
}

export enum PurchaseOrdersRoutes {
  ROOT = '/purchase-orders',
  DETAILS = '/purchase-orders/details/:orderId',
}

export enum ProductsRoutes {
  ROOT = '/products',
  DETAILS = '/products/:productId',
}

export enum WebshopRoutes {
  ROOT = '/webshop',
  PRODUCTS = '/webshop/products',
  PRODUCT_DETAILS = '/webshop/products/:productId',
}
