import { connect } from 'react-redux';

import { fetchDeliveryDaysRoutine } from 'store/routines/deliveryDays';

import CompanyDeliveryDays from './CompanyDeliveryDays';

const mapDispatchToProps = {
  onFetchDeliveryDays: fetchDeliveryDaysRoutine.trigger,
};

export { mapDispatchToProps };
export default connect(null, mapDispatchToProps)(CompanyDeliveryDays);
