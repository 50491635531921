import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchPurchaseOrdersRoutine } from 'store/routines/purchaseOrders';
import { isPurchaseOrdersTypeLoadingFromPropsSelector } from 'store/selectors/purchaseOrdersSelectors';

import EmptyPurchaseOrdersList from './EmptyPurchaseOrdersList';

export const mapStateToProps = createStructuredSelector({
  isLoading: isPurchaseOrdersTypeLoadingFromPropsSelector,
});

export const mapDispatchToProps = {
  fetchPurchaseOrders: fetchPurchaseOrdersRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyPurchaseOrdersList);
