import { createErrorReducer } from 'helpers/redux';

import { fetchCategoriesRoutine } from 'store/routines/categories';

/**
 * List of error cases
 */
export const failureCases = [fetchCategoriesRoutine.FAILURE];

/**
 * List of reset state cases
 */
export const resetCases = [fetchCategoriesRoutine.REQUEST, fetchCategoriesRoutine.SUCCESS];

/**
 * Categories Error Reducer
 */
const errorReducer = createErrorReducer({ failureCases, resetCases });

export default errorReducer;
