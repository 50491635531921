import React from 'react';

import emptyIllustration from 'assets/illustrations/empty.svg';

import { Image } from 'components/atoms';

/**
 * Component rendering Empty List illustration
 * @return {StatelessComponent}
 */
const EmptyListSvg = () => <Image alt="Empty List" src={emptyIllustration} />;

export default EmptyListSvg;
