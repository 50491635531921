import {
  createStatusAwareIsFetchingReducer,
  gatherReducerCasesFromRoutines,
  isPurchaseOrdersActionRelatedToFinalizedOrders,
} from 'helpers/redux';

import { fetchPurchaseOrdersRoutine } from 'store/routines/purchaseOrders';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Map of cases gathered from routines
 */
const { successCases, requestCases, failureCases } = gatherReducerCasesFromRoutines([fetchPurchaseOrdersRoutine]);

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Finalized Purchase Orders Is Fetching Reducer
 * @param {ReduxState} state
 * @param {ReduxAction} action
 * @returns {boolean}
 */
const finalizedPurchaseOrdersIsFetchingReducer = createStatusAwareIsFetchingReducer({
  endCases: [...successCases, ...failureCases],
  requestCases,
  resetCases,
  statusCheckFunction: isPurchaseOrdersActionRelatedToFinalizedOrders,
});

export default finalizedPurchaseOrdersIsFetchingReducer;
