import { createIsSubmittingReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { putProductRoutine } from 'store/routines/products';

/**
 * List of end cases
 */
const { successCases, failureCases, requestCases } = gatherReducerCasesFromRoutines([putProductRoutine]);

/**
 * Products reducers: isSubmitting reducer
 */
const isSubmittingReducer = createIsSubmittingReducer({ endCases: [...successCases, ...failureCases], requestCases });

export { successCases, failureCases, requestCases };
export default isSubmittingReducer;
