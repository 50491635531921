import { createErrorReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import {
  fetchPurchaseOrdersRoutine,
  fetchPurchaseOrdersNextPageRoutine,
  fetchSinglePurchaseOrderRoutine,
  patchPurchaseOrderStatusRoutine,
} from 'store/routines/purchaseOrders';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Map of cases gathered from routines
 */
const { successCases, failureCases, requestCases } = gatherReducerCasesFromRoutines([
  fetchPurchaseOrdersRoutine,
  fetchPurchaseOrdersNextPageRoutine,
  fetchSinglePurchaseOrderRoutine,
  patchPurchaseOrderStatusRoutine,
]);

/**
 * Purchase orders reducers: Error reducer
 */
const purchaseOrdersErrorReducer = createErrorReducer({
  failureCases,
  resetCases: [...successCases, ...requestCases, REMOVE_SELECTED_MERCHANT],
});

export { successCases, failureCases, requestCases };
export default purchaseOrdersErrorReducer;
