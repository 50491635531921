/**
 * Redux action for setting the selected merchant id
 * @constant
 */
export const SET_SELECTED_MERCHANT = '@ui/SET_SELECTED_MERCHANT';

/**
 * Redux action for unsetting (removing) the selected merchant id
 * @constant
 */
export const REMOVE_SELECTED_MERCHANT = '@ui/REMOVE_SELECTED_MERCHANT';
