import { PaymentMethod } from 'enums/payments';

/**
 * Map of payment method UI names
 * @constant
 */
export const paymentMethodNameMap: Record<PaymentMethod, string> = {
  [PaymentMethod.ON_SITE]: 'Plačilo po povzetju',
  [PaymentMethod.CREDIT_CART]: 'Plačilo s kreditno kartico',
  [PaymentMethod.PREPAID]: 'Plačilo po predračunu',
  [PaymentMethod.PAY_PAL]: 'PayPal plačilo',
};
