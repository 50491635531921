import { createErrorReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchProductPriceRoutine, patchProductPriceRoutine } from 'store/routines/productPrices';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Map of cases gathered from routines
 */
const { successCases, requestCases, failureCases } = gatherReducerCasesFromRoutines([
  patchProductPriceRoutine,
  fetchProductPriceRoutine,
]);

/**
 * Product Prices reducers: Error Reducer
 */
const errorReducer = createErrorReducer({
  failureCases,
  resetCases: [...successCases, ...requestCases, REMOVE_SELECTED_MERCHANT],
});

export { successCases, failureCases, requestCases };
export default errorReducer;
