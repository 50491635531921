import { createIsFetchingReducer } from 'helpers/redux';

import { fetchCategoriesRoutine } from 'store/routines/categories';

/**
 * Reducer containing info about category entity fetching state
 */
const isFetchingReducer = createIsFetchingReducer({
  endCases: [fetchCategoriesRoutine.FAILURE, fetchCategoriesRoutine.SUCCESS],
  requestCases: [fetchCategoriesRoutine.REQUEST],
});

export default isFetchingReducer;
