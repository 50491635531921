import PropTypes from 'prop-types';
import React from 'react';

import { simpleSelectDefaultValueGetter, simpleSelectOptionsFormatter } from 'helpers/selects';

import Select from '../Select';

/**
 * Wrapper around Select component, accepting "simple" options
 * as a prop (simple -> string[])
 * @param {Object} props
 * @param {String[]} props.options
 * @returns {StatelessComponent}
 */
const SimpleSelect = ({ options, ...rest }) => (
  <Select
    {...rest}
    defaultValueGetter={simpleSelectDefaultValueGetter}
    options={options}
    optionsFormatter={simpleSelectOptionsFormatter}
  />
);

SimpleSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SimpleSelect;
