import { all, call, put, select } from 'redux-saga/effects';

import * as api from 'api/productPrices';

import { showErrorNotification, showSuccessNotification } from 'helpers/alerts';
import { getErrorMessageFromResponse } from 'helpers/errors';
import { sagaWatcher } from 'helpers/redux';

import { patchProductPriceRoutine } from 'store/routines/productPrices';
import { productPriceForProductPriceIdPropSelector } from 'store/selectors/productPricesSelectors';

/**
 * Put product saga
 * @param {Object} payload
 * @param {ProductPrice} payload.productPrice
 * @returns {Generator}
 */
export function* patchProductPriceSaga({ payload }) {
  let errorData = {};

  yield put(patchProductPriceRoutine.request());

  try {
    const { productPrice: productPriceFormValues, productId } = Object(payload);

    const currentProductPrice = yield select(productPriceForProductPriceIdPropSelector, {
      productPriceId: productPriceFormValues.id,
    });

    const response = yield call(api.patchProductPrice, productPriceFormValues, currentProductPrice);

    if (response.isSuccess) {
      yield all([
        put(patchProductPriceRoutine.success(response.data, { meta: { productId } })),
        call(showSuccessNotification, { Content: 'Cena izdelka uspešno posodobljena!' }),
      ]);
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield all([
    put(patchProductPriceRoutine.failure(getErrorMessageFromResponse(errorData))),
    call(showErrorNotification, { Content: 'Pri posodabljanju cene izdelka je prišlo do napake...' }),
  ]);
}

/**
 * Root product prices saga
 * @returns {IterableIterator}
 */
export default function* productPricesSagas() {
  yield sagaWatcher([
    {
      type: patchProductPriceRoutine.TRIGGER,
      saga: patchProductPriceSaga,
    },
  ]);
}
