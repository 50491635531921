import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { ButtonSize, ButtonType } from 'enums/ui';

import './TimelineAddEntry.scss';

/**
 * Component rendering Add new Entry button at the end
 * of the timeline column entries
 * @see TimelineColumn
 *
 * @param {ComponentProps} props
 * @param {Function} props.onAddEntry
 * @return {StatelessComponent}
 */
const TimelineAddEntry = ({ onAddEntry }) => (
  <div className="timeline-add-entry">
    <Button icon={IconNames.PLUS} onClick={onAddEntry} size={ButtonSize.SMALL} type={ButtonType.LINK}>
      Dodaj vnos
    </Button>
  </div>
);

TimelineAddEntry.propTypes = {
  onAddEntry: PropTypes.func.isRequired,
};

export default TimelineAddEntry;
