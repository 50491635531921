import { createRoutine } from 'redux-saga-routines';

import type { Id } from 'interfaces/common';

import { FETCH_SINGLE_PICKUP } from 'store/types/pickups';

/**
 * Redux routine responsible for dispatching FETCH_SINGLE_MERCHANT related actions
 */
export const fetchSinglePickupRoutine = createRoutine(FETCH_SINGLE_PICKUP, {
  trigger: (payload: { id: Id }) => payload,
});
