import classNames from 'classnames';
import React from 'react';

import { Icon, Text, Tooltip } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { TextColor, TextSize } from 'enums/typography';
import { InputSize } from 'enums/ui';

import type { TextAreaInputProps } from './TextAreaInput.types';

import './TextAreaInput.scss';

/**
 * Component rendering text area input field using React Final Form API
 */
const TextInput = ({
  className,
  disabled,
  fullwidth,
  input,
  isCompact,
  isLoading,
  isTransparent,
  label,
  meta,
  size = InputSize.NORMAL,
  tooltipProps,
  ...rest
}: TextAreaInputProps) => {
  const hasError = Boolean(meta.touched && meta.error);

  return (
    <div
      className={classNames('text-area', `text-area--size-${size}`, {
        'text-area--has-error': hasError,
        'text-area--has-focus': meta.active,
        'text-area--is-compact': isCompact,
        'text-area--is-disabled': disabled,
        'text-area--is-fullwidth': fullwidth,
        'text-area--is-transparent': isTransparent,
        [className]: Boolean(className),
      })}
    >
      {Boolean(label) && (
        <label className="text-area__label" htmlFor={input.name}>
          {Boolean(tooltipProps) && (
            <Tooltip {...tooltipProps}>
              <Icon icon={IconNames.INFO_CIRCLE} />
            </Tooltip>
          )}
          {label}
        </label>
      )}

      <div className="text-area--inner">
        <textarea {...input} {...rest} className="text-area__element" disabled={disabled} id={input.name} />
      </div>

      {hasError && (
        <div className="text-area__error">
          <Text.Regular color={TextColor.ERROR} size={TextSize.LEVEL_200}>
            {meta.error}
          </Text.Regular>
        </div>
      )}
    </div>
  );
};

export default TextInput;
