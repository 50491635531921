import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as RTToolTip } from 'react-tippy';

import { TooltipPosition } from 'enums/ui';
import { allValues, isString } from 'helpers/utility';

import 'react-tippy/dist/tippy.css';
import './Tooltip.scss';

/**
 * Component rendering tooltip over child component using react-tippy
 * tooltip component
 *
 * @param {ComponentProps} props
 * @param {boolean} [props.arrow]
 * @param {String} [props.className]
 * @param {node|string} props.content
 * @param {boolean} [props.interactive]
 * @param {string} [props.position=TooltipPosition.TOP_CENTER]
 * @param {boolean} [props.visible]
 * @return {StatelessComponent}
 */
const Tooltip = ({ children, content, visible, className, ...rest }) => {
  const contentProp = React.useMemo(() => {
    // If passed content is a string, we want to set
    // tooltip title prop
    if (isString(content)) {
      return { title: content };
    }

    // Otherwise, we want to set tooltip html prop
    return { html: content };
  }, [content]);

  return (
    <RTToolTip
      {...contentProp}
      {...rest}
      className={classNames('tooltip', { [className]: Boolean(className) })}
      disabled={!visible}
      theme="light"
      unmountHTMLWhenHide={true}
    >
      {children}
    </RTToolTip>
  );
};

Tooltip.propTypes = {
  arrow: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  interactive: PropTypes.bool,
  position: PropTypes.oneOf(allValues(TooltipPosition)),
  visible: PropTypes.bool,
};

Tooltip.defaultProps = {
  arrow: true,
  interactive: true,
  position: TooltipPosition.TOP_CENTER,
  visible: true,
};

export default Tooltip;
