import { combineReducers } from 'redux';

import { SIDESHEET_NAMES } from 'constants/ui';

import addDeliveryEntrySidesheetReducer from './addDeliveryEntrySidesheetReducer';

const sidesheetsReducer = combineReducers({
  [SIDESHEET_NAMES.addEditDeliveryDays]: addDeliveryEntrySidesheetReducer,
});

export default sidesheetsReducer;
