import { createLastFetchedReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';
import { fetchSinglePickupRoutine } from 'store/routines/pickups';

/**
 * Success cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([fetchSinglePickupRoutine]);

/**
 * Pickups reducers: Last fetched reducer
 */
const pickupsLastFetchedReducer = createLastFetchedReducer({ successCases });

export { successCases };
export default pickupsLastFetchedReducer;
