import { createRoutine } from 'redux-saga-routines';

import { FETCH_PRODUCTS, FETCH_SINGLE_PRODUCT, UPDATE_PRODUCT } from 'store/types/products';

/**
 * Redux routine responsible for dispatching FETCH_PRODUCTS related action
 */
export const fetchProductsRoutine = createRoutine(FETCH_PRODUCTS);

/**
 * Redux routine responsible for dispatching FETCH_SINGLE_PRODUCT related action
 */
export const fetchSingleProductRoutine = createRoutine(FETCH_SINGLE_PRODUCT);

/**
 * Redux routine responsible for dispatching UPDATE_PRODUCT related action
 */
export const putProductRoutine = createRoutine(UPDATE_PRODUCT);
