import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms';

import { TextSize } from 'enums/typography';

import './TimelineLabels.scss';

/**
 * Component rendering timeline labels
 * @param {ComponentProps} props
 * @param {string[]} props.labels
 * @return {StatelessComponent}
 */
const TimelineLabels = ({ labels }) => (
  <div className="timeline-labels">
    {labels.map((label, i) => (
      <div key={`${label}--${i}`} className="timeline-labels__label">
        <Text.Bold size={TextSize.LEVEL_200}>{label}</Text.Bold>
      </div>
    ))}
  </div>
);

TimelineLabels.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TimelineLabels;
