import * as texts from './texts';

/**
 * Returns text used in the add button
 * @param {String} [searchKey]
 * @returns {String}
 */
export const getAddButtonText = (searchKey) => {
  if (searchKey) {
    return `Dodaj izdelek "${searchKey}"`;
  }

  return texts.BUTTON_ADD_NEW;
};

/**
 * Returns text used in the refresh/clear search query button
 * @param {String} [searchKey]
 * @returns {String}
 */
export const getRefreshButtonText = (searchKey) => {
  return searchKey ? texts.BUTTON_SHOW_ALL : texts.BUTTON_REFRESH;
};

/**
 * Returns object with texts for the buttons used in the Products Table
 * Empty State
 * @param {String} [searchKey]
 * @returns {{ addButtonText: String; refreshButtonText: String; }}
 */
export const getButtonsTexts = (searchKey) => ({
  addButtonText: getAddButtonText(searchKey),
  refreshButtonText: getRefreshButtonText(searchKey),
});
