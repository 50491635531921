import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { DaysOfWeek } from 'enums/ui';

import { allValues, isEqual, isNotEqual } from 'helpers/utility';

import { usePrevious } from 'hooks';

import { TimelineColumn } from '../TimelineColumn';

import './Timeline.scss';

/**
 * Component rendering generic sortable timeline
 * @param {ComponentProps} props
 * @param {object[]} props.entries
 * @param {Boolean} [props.isDraggingDisabled=false]
 * @param {Function} [props.mapComponentProps]
 * @param {Function} props.onDrop
 * @param {JSX.Element} [props.sortableComponent]
 * @return {StatelessComponent}
 */
const Timeline = ({ entries, isDraggingDisabled, mapComponentProps, onAddEntry, onDrop, sortableComponent }) => {
  const prevEntries = usePrevious(entries);
  const [items, setItems] = React.useState(entries);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      // Drop outside of the dropable zone, we want to ignore that
      return;
    }

    // Extract relevant properties
    const { destination, draggableId, source } = result;

    // In timeline, we only want to update if we drop in another column
    // We compare destionation.droppableId with source.droppableId and only apply
    // logic if they are different
    if (isNotEqual(destination.droppableId, source.droppableId)) {
      const itemToUpdate = items.find((item) => isEqual(item.id, draggableId));
      const itemsWithoutUpdatedItem = items.filter((item) => isNotEqual(item.id, draggableId));

      // Set new items
      setItems([...itemsWithoutUpdatedItem, { ...itemToUpdate, dayOfWeek: destination.droppableId }]);

      // Trigger passed onDrop callback, pass current drop item and all items
      onDrop({ drop: result, items });
    }
  };

  React.useEffect(() => {
    if (isNotEqual(entries, prevEntries)) {
      setItems(entries);
    }
  }, [entries, prevEntries]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="timeline">
        {allValues(DaysOfWeek).map((day) => (
          <TimelineColumn
            key={day}
            id={day}
            isDraggingDisabled={isDraggingDisabled}
            items={items}
            mapItemToComponentProps={mapComponentProps}
            onAddEntry={onAddEntry}
            sortableComponent={sortableComponent}
          />
        ))}
      </div>
    </DragDropContext>
  );
};

Timeline.propTypes = {
  isDraggingDisabled: PropTypes.bool,
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  onAddEntry: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  mapComponentProps: PropTypes.func,
  sortableComponent: PropTypes.elementType.isRequired,
};

Timeline.defaultProps = {
  isDraggingDisabled: false,
};

export default Timeline;
