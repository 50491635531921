import PropTypes from 'prop-types';
import React from 'react';

import { formInputPropType, formMetaPropType } from 'constants/props';

import { IconNames } from 'enums/icons';

import { allValues } from 'helpers/utility';

import { NumberInput } from '../NumberInput';

/**
 * Wrapper component around NumberInput component with isFloatField prop set to number.
 * @see {NumberInput}
 *
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const FloatInput = (props) => <NumberInput {...props} isFloatField={true} />;

FloatInput.propTypes = {
  disabled: PropTypes.bool,
  fullwidth: PropTypes.bool,
  icon: PropTypes.oneOf(allValues(IconNames)),
  input: formInputPropType.isRequired,
  label: PropTypes.string,
  meta: formMetaPropType,
  placeholder: PropTypes.string,
};

export default FloatInput;
