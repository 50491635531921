import { POSTCODES_MATCHERS } from 'constants/postcodes';
import { PostNameRegions } from 'enums/postcodes';
import { capitalize } from 'helpers/textHelpers';

import { allEntries, valueOrDefault } from './utility';

/**
 * Returns region name for given postcode
 * @param {string} postCode
 * @returns {string}
 */
export const getPostCodeRegionName = (postCode) => {
  let result;

  for (const [region, pattern] of allEntries(POSTCODES_MATCHERS)) {
    if (postCode?.match(new RegExp(pattern))) {
      result = region;
      break;
    }
  }

  return result || PostNameRegions.UNKNOWN;
};

/**
 * Returns postcodes enitites grouped by region name
 * @param {object[]} postcodes
 * @returns {object<string, object[]>}
 */
export const getGroupedPostcodesData = (postcodes) => {
  return postcodes.reduce((acc, post) => {
    const postCode = post.postCode?.toString();

    if (!postCode) {
      return acc;
    }

    const regionName = getPostCodeRegionName(postCode);
    const existingRegionPostCodes = valueOrDefault(acc[regionName], []);

    acc[regionName] = [...existingRegionPostCodes, post];

    return acc;
  }, {});
};

/**
 * Returns formatted post label
 * @param {PostCodeEntity} post
 * @returns {string}
 */
export const getPostLabelFromEntry = (post) => `${post.postCode} - ${capitalize(post.postName)}`;

/**
 * Returns mapped post entity in tree select node format
 * @param {PostCodeEntity} post
 * @returns {object}
 */
export const mapPostToTreeSelectNode = (post) => ({
  label: getPostLabelFromEntry(post),
  value: post.postCode,
});

/**
 * Returns tree select node group for given posts group
 * @param {object} options
 * @param {string} options.group
 * @param {PostCodeEntity[]} options.posts
 * @returns {object}
 */
export const getPostGroupTreeSelectNode = ({ group, posts }) => {
  return { label: capitalize(group), children: posts.map(mapPostToTreeSelectNode) };
};

/**
 * Returns reduced grouped post codes in tree select node format
 * @param {object[]} currentValue
 * @param {Array.<string, PostCodeEntity[]>} postGroup
 * @returns {object[]}
 */
export const getReducedGroupedPostcodesToTreeNodes = (currentValue, [group, posts]) => {
  return [...currentValue, getPostGroupTreeSelectNode({ group, posts })];
};

/**
 * Returns tree select prepared postcodes data
 * @param {PostCodeEntity[]} postcodes
 * @returns {object[]}
 */
export const getPostcodeTreeSelectNodes = (postcodes = []) => {
  return allEntries(getGroupedPostcodesData(postcodes)).reduce(getReducedGroupedPostcodesToTreeNodes, []);
};

/**
 * Returns mapped postCode entity data ready to be used in Delivery Timeline Entry
 * @param {Object} options
 * @param {DayOfWeek} dayOfWeek
 * @param {PostCodeEntity[]} [postCodes=[]]
 * @returns {Object[]}
 */
export const getMappedPostCodesForTimelineEntry = ({ dayOfWeek, postCodes = [] }) => {
  const groupedPostcodes = getGroupedPostcodesData(postCodes);

  return Object.entries(groupedPostcodes).map(([region, regionPostCodes]) => {
    return {
      id: `${dayOfWeek}-${region}`,
      region,
      postCodes: regionPostCodes,
      dayOfWeek,
    };
  });
};
