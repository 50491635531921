import PropTypes from 'prop-types';
import React from 'react';

import './MailToLink.scss';

/**
 * Component rendering anchor element with mailto as a href
 * attribute
 *
 * @param {ComponentProps} props
 * @param {string} props.email
 * @return {StatelessComponent}
 */
const MailToLink = ({ children, email }) => (
  <a className="mail-to-link" href={`mailto:${email}`}>
    {children}
  </a>
);

MailToLink.propTypes = {
  children: PropTypes.node.isRequired,
  email: PropTypes.string.isRequired,
};

export default MailToLink;
