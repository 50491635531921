export enum TextSize {
  LEVEL_100 = 'extra-small', // 8px
  LEVEL_150 = 'smaller', // 10px
  LEVEL_200 = 'small', // 12px
  LEVEL_300 = 'normal', // 14px
  LEVEL_350 = 'normal-large', // 16px
  LEVEL_400 = 'large', // 18px
  LEVEL_500 = 'larger', // 24px
  LEVEL_600 = 'extra-large', // 32px
}

export enum TextWeight {
  LIGHT = 'light',
  REGULAR = 'regular',
  BOLD = 'bold',
  BLACK = 'extra-bold',
}

export enum TextLineHeight {
  NONE = 'none',
  NORMAL = 'normal',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum TextColor {
  BLACK = 'black',
  GREY = 'grey', // gray-1
  GREY_LIGHT = 'grey-light', // gray-2
  PRIMARY = 'primary',
  WHITE = 'white',
  ERROR = 'error',
}

export enum TextAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  JUSTIFY = 'justify',
}
