import { isNullOrUndefined, isString } from './utility';

/**
 * Returns capitalized string
 * @param {string} string
 * @returns {string}
 */
export const capitalize = (string) => {
  if (!isString(string)) {
    return '';
  }

  const [first, ...rest] = string;
  return first?.toUpperCase() + rest?.join('').toLowerCase();
};

/**
 * Returns quoted string
 * @param {String} string
 * @returns {String}
 */
export const getQuotedString = (string) => `“${string}“`;

/**
 * Returns Slovene representation of passed noun based on the number
 * which accompanies the noun
 * @param {string} noun
 * @param {number} number
 * @returns {string}
 */
export const getNounBasedOnNumber = (noun, number) => {
  if (isNullOrUndefined(number)) {
    return noun;
  }

  switch (number) {
    case 0: {
      // 0 znakov
      return `${number} ${noun}ov`;
    }

    case 1: {
      // 1 znak
      return `${number} ${noun}`;
    }

    case 2: {
      // 2 znaka
      return `${number} ${noun}a`;
    }

    case 3:
    case 4: {
      // 3 znake, 4 znake
      return `${number} ${noun}e`;
    }

    default: {
      // 5 znakov, 6 znakov, 7 znakov...
      return `${number} ${noun}ov`;
    }
  }
};

/**
 * Returns the initials of passed full name
 * @param {string} name
 * @returns {string}
 */
export const getInitialsFromFullName = (name) => {
  // If name is not passed, return empty string
  if (!name) {
    return '';
  }

  // Get the first and the last name
  const [first, last] = name.split(' ');

  // Return the first character of first and last name
  return `${first?.charAt(0)}${last?.charAt(0) || ''}`;
};
