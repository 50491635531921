import PropTypes from 'prop-types';
import React from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';

import { isEqual } from 'helpers/utility';

/**
 * Wrapper around TreeSelectInput to prevent unwanted re-renderings of the component
 * @link {https://dowjones.github.io/react-dropdown-tree-select/#/story/prevent-re-render-on-parent-render-hoc}
 *
 * @param {ComponentProps} props
 * @param {Object[]} props.data
 * @type {React.Component}
 */
class TreeSelectInputWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data };
  }

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
  };

  // As per this comment (https://github.com/dowjones/react-dropdown-tree-select/issues/292#issuecomment-535050126) we
  // will be able to omit this deprecated life-cycle method once the package is updated to v3. For now, we are fine
  // with it.
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = (nextProps) => {
    if (!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  };

  // We only want to re-render the component if the original data changes
  shouldComponentUpdate = (nextProps) => {
    return !isEqual(nextProps.data, this.state.data);
  };

  render() {
    const { data, ...rest } = this.props;
    return <DropdownTreeSelect data={this.state.data} {...rest} />;
  }
}

export default TreeSelectInputWrapper;
