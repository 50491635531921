import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { DEV_ENV } from 'utils/constants';

import type { ReduxState, Store } from './interfaces';
import createRootReducer from './reducers';
import { rootSaga } from './sagas';

/**
 * Browser history object
 * @constant
 */
const history = createBrowserHistory();

/**
 * Returns store middlewares as enhancers
 * @param middleware
 */
const getStoreMiddlewares = (middleware: any[] = []) => {
  const appliedMiddlewares = applyMiddleware(...middleware);

  if (DEV_ENV) {
    return composeWithDevTools(appliedMiddlewares);
  }

  return appliedMiddlewares;
};

/**
 * Returns StoreCreator with applied enhancers
 */
const configureStore = (): Store<ReduxState> => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = getStoreMiddlewares([sagaMiddleware, routerMiddleware(history)]);

  const reducer = createRootReducer(history);

  const configuredStore = createStore(reducer, middlewares);

  sagaMiddleware.run(rootSaga);

  return configuredStore;
};

const store = configureStore();

export { history };
export default store;
