import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { SelectInput } from 'components/elements';

/**
 * Component rendering Select Field as a Table Cell
 * @param {Object} props
 * @param {Object} props.cell
 * @returns {StatelessComponent}
 */
const SelectInputCell = ({ cell }) => {
  const { column, value } = cell;
  const { name, options, props } = column;

  return (
    <Field
      component={SelectInput}
      defaultValue={value}
      fullwidth={true}
      isCompact={true}
      name={name}
      options={options}
      {...props}
    />
  );
};

SelectInputCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    column: PropTypes.shape({
      name: PropTypes.string.isRequired,
      optionsMapper: PropTypes.func,
      props: PropTypes.shape(),
    }),
  }).isRequired,
  component: PropTypes.elementType,
};

export default SelectInputCell;
