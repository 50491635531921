import { IconNames } from 'enums/icons';
import { Intent } from 'enums/ui';

/**
 * Icon name for intent map
 * @constant
 */
export const iconNameForIntentMap = Object.freeze({
  [Intent.SUCCESS]: IconNames.CHECK_CIRCLE,
  [Intent.WARNING]: IconNames.EXCLAMATION_CIRCLE,
  [Intent.ERROR]: IconNames.EXCLAMATION_TRIANGLE,
  [Intent.INFO]: IconNames.INFO_CIRCLE,
});
