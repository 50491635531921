import React from 'react';

import { Button } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { ButtonSize, ButtonType, Intent } from 'enums/ui';

import { getCurrentLocationWithCompanyIdParam } from 'helpers/location';

import useClipboard from 'hooks/useClipboard';

/**
 * Component rendering Actionbar Share button with copy-to-clipboard
 * functionality
 * @return {StatelessComponent}
 */
const ActionbarShareButton = () => {
  const { ref } = useClipboard({
    copyValue: getCurrentLocationWithCompanyIdParam(),
    successText: 'Povezava uspešno kopirana v odložišče!',
  });

  return (
    <Button ref={ref} icon={IconNames.SHARE} intent={Intent.NEUTRAL} size={ButtonSize.NORMAL} type={ButtonType.GHOST} />
  );
};

export default ActionbarShareButton;
