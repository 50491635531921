import React from 'react';
import { Field } from 'react-final-form';

import { Column, Grid, Section } from 'components/atoms';
import { TextInput } from 'components/elements';

import { ProductFormFields, UiFormFields } from 'enums/formFields';
import { GridColumnCount } from 'enums/ui';

/**
 * Component rendering Product System Info section of the Product Edit
 * form
 * @see {ProductEditForm}
 * @returns {StatelessComponent}
 */
const ProductSystemInfo = () => (
  <Section title="Sistemski podatki">
    <Grid numOfCols={GridColumnCount.THREE}>
      <Column>
        <Field
          component={TextInput}
          disabled={true}
          fullwidth={true}
          hasCopyAction={true}
          label="Id izdelka"
          name={ProductFormFields.ID}
        />
      </Column>

      <Column>
        <Field
          component={TextInput}
          fullwidth={true}
          label="Zunanji id izdelka"
          name={ProductFormFields.EXTERNAL_ID}
          placeholder="Ni podatka"
          tooltipProps={{
            content:
              'Zunanji ID izdelka je identifikacijske številka izdelka, ki jo uporablja ponudnik izdelka za vodenje lastne evidence.',
          }}
        />
      </Column>

      <Column>
        <Field
          component={TextInput}
          disabled={true}
          fullwidth={true}
          hasCopyAction={true}
          label="Povezava do izdelka v aplikaciji"
          name={UiFormFields.PRODUCT_IN_APP_LINK}
        />
      </Column>
    </Grid>
  </Section>
);

export default ProductSystemInfo;
