import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchCategoriesRoutine } from 'store/routines/categories';
import {
  allCategoriesSelector,
  categoriesIsFetchingSelector,
  categoriesLastFetchedSelector,
} from 'store/selectors/categoriesSelectors';

import CategorySelectField from './CategorySelectField';

export const mapStateToProps = createStructuredSelector({
  categories: allCategoriesSelector,
  isFetching: categoriesIsFetchingSelector,
  lastFetched: categoriesLastFetchedSelector,
});

export const mapDispatchToProps = {
  onFetchCategories: fetchCategoriesRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelectField);
