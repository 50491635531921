import { createByIdReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import type { PickupDTO } from 'interfaces/pickups';

import { fetchSinglePickupRoutine } from 'store/routines/pickups';

/**
 * Success cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([fetchSinglePickupRoutine]);

/**
 * Pickups reducers: By id reducer
 */
const pickupsByIdReducer = createByIdReducer<PickupDTO>({ successCases });

export { successCases };
export default pickupsByIdReducer;
