import PropTypes from 'prop-types';
import React from 'react';

import { UserRole } from 'enums/roles';

import { isOneOfUserRolesValid } from 'helpers/roles';
import { allValues } from 'helpers/utility';

import { useAuth } from 'hooks';

/**
 * Provider component for checking if logged in user has a
 * valid app role
 * @param {ComponentProps} props
 * @param {User} [user]
 * @returns {FunctionComponent}
 */
const AuthCheckProvider = ({ children, userRoles }) => {
  const { authenticated, logout } = useAuth();

  React.useEffect(() => {
    if (userRoles && authenticated) {
      // If none of the user roles are valid, we want
      // to sign this user out
      if (!isOneOfUserRolesValid(userRoles)) {
        logout();
      }
    }
  }, [authenticated, logout, userRoles]);

  return <React.Fragment>{children}</React.Fragment>;
};

AuthCheckProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.oneOf(allValues(UserRole))),
};

export default AuthCheckProvider;
