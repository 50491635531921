import { isNullOrUndefined } from './utility';

/**
 * Returns pagination current page number (not index!). Should be used to display
 * the UI pagination value, NOT to make API requests.
 * @param {ApiPaginationState} pagination
 * @returns {Number}
 */
export const getPaginationCurrentPageUIValue = (pagination) => {
  if (isNullOrUndefined(pagination?.currentPage)) {
    // If pagination object is not passed or pagination.currentPage is not defined, we don't
    // want the app to crash, but we still want to clearly indicate that something is wrong.
    // Therefore, we return 0 which should never be used as a UI presentation of the current page.
    return 0;
  }

  // pagination.currentPage is a zero-based value. For the UI presentation of the current
  // page value, we want to start counting at 1, so we add 1 to whatever the current page
  // index is-
  return pagination.currentPage + 1;
};

/**
 * Returns the value of pagination.totalPages or zero if the value is not defined
 * @param {ApiPaginationState} pagination
 * @returns {Number}
 */
export const getPaginationTotalPages = (pagination) => {
  if (isNullOrUndefined(pagination?.totalPages)) {
    // If the pagination object is not passed or the value of pagination.totalPages is not defined
    // we return 0
    return 0;
  }

  // Otherwise, we just return the value of pagination.totalPages
  return pagination.totalPages;
};
