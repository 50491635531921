import PropTypes from 'prop-types';
import React from 'react';
import ScrollLock from 'react-scrolllock';

import { Icon, Text } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { TextColor, TextSize } from 'enums/typography';

import './SidesheetWrapper.scss';

/**
 * Component rendering base Sidesheet layout
 * @param {ComponentProps} props
 * @param {ReactNode} props.footerContent
 * @param {boolean} props.isOpen
 * @param {Function} props.onClose
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const SidesheetWrapper = ({ children, footerContent, isOpen, onClose, title }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="sidesheet-wrapper">
      <div className="sidesheet-wrapper__backdrop" onClick={onClose} />

      <ScrollLock>
        <div className="sidesheet-wrapper__content">
          <div className="sidesheet-wrapper__content__header">
            <Text.ExtraBold color={TextColor.BLACK} numOfLines={1} size={TextSize.LEVEL_350}>
              {title}
            </Text.ExtraBold>

            <Icon fixedWidth={true} icon={IconNames.CLOSE} onClick={onClose} />
          </div>

          <div className="sidesheet-wrapper__content__body">{children}</div>

          {Boolean(footerContent) && <div className="sidesheet-wrapper__content__footer">{footerContent}</div>}
        </div>
      </ScrollLock>
    </div>
  );
};

SidesheetWrapper.propTypes = {
  children: PropTypes.node,
  footerContent: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

SidesheetWrapper.defaultProps = {
  isOpen: false,
};

export default SidesheetWrapper;
