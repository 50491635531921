import { DeliveryAreaType } from 'enums/delivery';

import { isEqual, valueOrDefault } from './utility';

/**
 * Returns true if delivery day's deliveryAreaType is LOCAL_AREA
 * @param {DeliveryDay} deliveryDay
 * @returns {Boolean}
 */
export const isDeliveryDayDeliveryAreaTypeLocal = (deliveryDay) => {
  return isEqual(deliveryDay.deliveryAreaType, DeliveryAreaType.LOCAL_AREA);
};

/**
 * Returns true if delivery day's deliveryAreaType is WIDER_AREA
 * @param {DeliveryDay} deliveryDay
 * @returns {Boolean}
 */
export const isDeliveryDayDeliveryAreaTypeWider = (deliveryDay) => {
  return isEqual(deliveryDay.deliveryAreaType, DeliveryAreaType.WIDER_AREA);
};

/**
 * For given array of delivery days, returns those delivery days grouped by the day of week
 * @param {DeliveryDay[]} deliveryDays
 * @returns {Record<DayOfWeek, DeliveryDay>}
 */
export const getDeliveryDaysGroupedByDayOfWeek = (deliveryDays = []) => {
  return deliveryDays.reduce((acc, deliveryDay) => {
    const dayOfWeek = deliveryDay.dayOfWeek.toLowerCase();

    if (!dayOfWeek) {
      return acc;
    }

    const existingDayOfWeekEntries = valueOrDefault(acc[dayOfWeek], []);

    acc[dayOfWeek] = [...existingDayOfWeekEntries, deliveryDay];

    return acc;
  }, {});
};

/**
 * For given array of delivery days, returns post codes included in those delivery days
 * @param {DeliveryDay[]} deliveryDays
 * @returns {PostCodeEntity[]}
 */
export const getDeliveryDaysPostCodeEntities = (deliveryDays = []) => {
  return deliveryDays.map((deliveryDay) => deliveryDay.postCode);
};
