import { createIsFetchingReducer } from 'helpers/redux';

import { fetchMerchantsRoutine } from 'store/routines/merchants';

/**
 * Reducer containing info about merchant entity fetching state
 */
const isFetchingReducer = createIsFetchingReducer({
  endCases: [fetchMerchantsRoutine.FAILURE, fetchMerchantsRoutine.SUCCESS],
  requestCases: [fetchMerchantsRoutine.REQUEST],
});

export default isFetchingReducer;
