import React from 'react';

import { LoadingIndicator, Text } from 'components/atoms';

import './TableLoadingState.scss';
import { TextColor } from 'enums/typography';

/**
 * Component rendering generic table body loading state
 * @returns {StatelessComponent}
 */
const TableLoadingState = () => (
  <div className="table-loading-state">
    <LoadingIndicator contained type="primary" />
    <Text.Regular color={TextColor.GREY_LIGHT}>Nalagam podatke...</Text.Regular>
  </div>
);

export default TableLoadingState;
