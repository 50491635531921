import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms';
import TableApiStateDescription from 'components/elements/table/PageableTable/components/TableApiStateDescription';

import { paginationProp } from 'constants/props';

import './TableTitle.scss';

/**
 * Component rendering Table title
 * @param {Object} props
 * @param {Boolean} [props.hasError=false]
 * @param {Boolean} [props.isLoading=false]
 * @param {ApiPaginationState} props.pagination
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const TableTitle = ({ title, ...rest }) => (
  <div className="table-title">
    <Text.Bold>{title}</Text.Bold>
    <TableApiStateDescription {...rest} />
  </div>
);

TableTitle.propTypes = {
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  pagination: paginationProp.isRequired,
  title: PropTypes.string.isRequired,
};

TableTitle.defaultProps = {
  hasError: false,
  isLoading: false,
};

export default TableTitle;
