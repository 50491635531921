import PropTypes from 'prop-types';
import React from 'react';

import { DropdownButton } from 'components/elements';

import { IconNames } from 'enums/icons';
import { ButtonType, DropdownAlignment, DropdownPosition, Intent } from 'enums/ui';

import { allValues } from 'helpers/utility';

/**
 * Component rendering Actionbar Menu dropdown
 * @param {Object} props
 * @param {Object[]} props.options
 * @return {StatelessComponent}
 */
const ActionbarMenu = ({ options }) => (
  <DropdownButton
    align={DropdownAlignment.END}
    buttonProps={{ icon: IconNames.DOTS_HORIZONTAL, type: ButtonType.GHOST, intent: Intent.NEUTRAL }}
    options={options}
    positions={[DropdownPosition.BOTTOM]}
  />
);

ActionbarMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.oneOf(allValues(IconNames)).isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
};

export default ActionbarMenu;
