import PropTypes from 'prop-types';
import React from 'react';

import { SidesheetWrapper } from 'components/elements';

import { EntityAction } from 'enums/ui';
import { getAddDeliveryEntrySidesheetTitle } from 'helpers/ui';

import {
  AddDeliveryEntryDeliveryDays,
  AddDeliveryEntryDeliveryType,
  AddDeliveryEntryPostcodes,
  AddDeliveryEntrySidesheetFooter,
} from './components';

/**
 * Component rendering add/edit delivery entry sidesheer
 * @param {ComponentProps} props
 * @param {EntityActionType} props.action
 * @param {Function} props.handleSubmit
 * @param {boolean} [props.isOpen=false]
 * @param {Function} props.onClose
 * @param {object[]} [props.postcodes=[]]
 * @return {StatelessComponent}
 */
const AddDeliveryEntrySidesheet = ({ action, handleSubmit, isOpen, onClose, postcodes }) => {
  const sidesheetTitle = getAddDeliveryEntrySidesheetTitle(action);

  return (
    <SidesheetWrapper
      footerContent={<AddDeliveryEntrySidesheetFooter action={action} onClose={onClose} onSave={handleSubmit} />}
      isOpen={isOpen}
      onClose={onClose}
      title={sidesheetTitle}
    >
      <AddDeliveryEntryPostcodes postcodes={postcodes} />

      <AddDeliveryEntryDeliveryDays />

      <AddDeliveryEntryDeliveryType />
    </SidesheetWrapper>
  );
};

AddDeliveryEntrySidesheet.propTypes = {
  action: PropTypes.oneOf([EntityAction.ADD, EntityAction.UPDATE]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  postcodes: PropTypes.arrayOf(PropTypes.shape({})),
};

AddDeliveryEntrySidesheet.defaultProps = {
  postcodes: [],
};

export default AddDeliveryEntrySidesheet;
