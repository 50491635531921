import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms/Text';

import { TextSize } from 'enums/typography';
import { allValues } from 'helpers/utility';

import './Section.scss';

/**
 * Component rendering passed children in a section wrapper
 * and (if passed) with a section title
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {boolean} [props.isFirst=false]
 * @param {string} [props.title]
 * @param {StatelessComponent|FunctionComponent} [props.titleComponent=Text.Regular]
 * @param {object} [props.titleProps]
 * @return {StatelessComponent}
 */
const Section = ({ children, className, isFirst, title, titleComponent: TitleComponent, titleProps }) => (
  <section className={classNames('section', { [className]: Boolean(className), 'section--is-first': isFirst })}>
    {Boolean(title) && (
      <div className="section__title">
        <TitleComponent size={TextSize.LEVEL_400} {...titleProps}>
          {title}
        </TitleComponent>
      </div>
    )}

    {children}
  </section>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isFirst: PropTypes.bool,
  title: PropTypes.string,
  titleComponent: PropTypes.oneOf(allValues(Text)),
  titleProps: PropTypes.shape({}),
};

Section.defaultProps = {
  isFirst: false,
  titleComponent: Text.Regular,
  titleProps: {},
};

export default Section;
