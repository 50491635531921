import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { isStringEqual } from 'helpers/utility';

import { TimelineAddEntry } from '../TimelineAddEntry';
import { TimelineSortable } from '../TimelineSortable';

import './TimelineColumn.scss';

/**
 * Component rendering single Timeline Column element
 * @param {ComponentProps} props
 * @param {Id} props.id
 * @param {Boolean} [props.isDraggingDisabled=false]
 * @param {Object[]} props.items
 * @param {Function} [props.mapItemToComponentProps]
 * @param {Function} [props.onAddEntry]
 * @param {StatelessComponent|FunctionComponent} props.sortableComponent
 * @return {StatelessComponent}
 */
const TimelineColumn = ({ id, isDraggingDisabled, items, mapItemToComponentProps, onAddEntry, sortableComponent }) => {
  const handleComponentPropsMapping = React.useCallback(
    (item) => {
      if (!mapItemToComponentProps) {
        return item;
      }
      return mapItemToComponentProps(item);
    },
    [mapItemToComponentProps]
  );

  const filteredItems = React.useMemo(() => {
    return items.filter((item) => isStringEqual(item.dayOfWeek, id, { ignoreCase: true }));
  }, [id, items]);

  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={classNames('timeline-column', { 'timeline-column--dragging-over': snapshot.isDraggingOver })}
        >
          {filteredItems.map((item, index) => (
            <TimelineSortable
              key={item.id}
              component={sortableComponent}
              componentProps={handleComponentPropsMapping(item)}
              disabled={isDraggingDisabled}
              index={index}
              item={item}
            />
          ))}

          {provided.placeholder}

          {Boolean(onAddEntry) && <TimelineAddEntry onAddEntry={onAddEntry} />}
        </div>
      )}
    </Droppable>
  );
};

TimelineColumn.propTypes = {
  id: PropTypes.string.isRequired,
  isDraggingDisabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mapItemToComponentProps: PropTypes.func,
  onAddEntry: PropTypes.func,
  sortableComponent: PropTypes.elementType.isRequired,
};

TimelineColumn.defaultProps = {
  isDraggingDisabled: false,
};

export default TimelineColumn;
