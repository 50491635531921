import { combineReducers } from 'redux';

import type { ProductPricesState } from 'store/interfaces/productPricesState.types';

import errorReducer from './errorReducer';
import isSubmittingReducer from './isSubmittingReducer';
import productPricesAllIdsReducer from './productPricesAllIdsReducer';
import productPricesByIdReducer from './productPricesByIdReducer';
import productPricesIsFetchingReducer from './productPricesIsFetchingReducer';
import productPricesLastFetchedReducer from './productPricesLastFetchedReducer';

/**
 * Product prices reducers: Root reducer
 */
const productPricesReducer = combineReducers<ProductPricesState>({
  allIds: productPricesAllIdsReducer,
  byId: productPricesByIdReducer,
  error: errorReducer,
  isFetching: productPricesIsFetchingReducer,
  isSubmitting: isSubmittingReducer,
  lastFetched: productPricesLastFetchedReducer,
});

export default productPricesReducer;
