import PropTypes from 'prop-types';
import React from 'react';

import { Section, Text } from 'components/atoms';
import { PurchaseOrderItemsTable } from 'components/molecules/tables';

/**
 * Component rendering purchase order items table section
 * @param {ComponentProps} props
 * @param {PurchaseOrderItem[]} props.items
 * @param {Object} props.productsById
 * @returns {StatelessComponent}
 */
const OrderDetailsItems = ({ items, productsById }) => {
  return (
    <Section isFirst={true} title="Seznam artiklov" titleComponent={Text.Bold}>
      <PurchaseOrderItemsTable items={items} productsById={productsById} />
    </Section>
  );
};

OrderDetailsItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productsById: PropTypes.shape({}).isRequired,
};

export default OrderDetailsItems;
