import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { Icon, Text, Tooltip } from 'components/atoms';

import { IconNames } from 'enums/icons';

import { allValues, valueOrEmptyObject } from 'helpers/utility';

import './SidebarNavigationItem.scss';

/**
 * component rendering single Sidebar Navigation Item
 *
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {string} props.icon
 * @param {Boolean} [props.isLocked=false]
 * @param {Object} [props.lockedTooltipProps]
 * @param {string} props.path
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const SidebarNavigationItem = ({ className, icon, isLocked, lockedTooltipProps, path, title }) => (
  <NavLink
    activeClassName="sidebar-navigation-item--active"
    className={classNames('sidebar-navigation-item', {
      'sidebar-navigation-item--locked': isLocked,
      [className]: Boolean(className),
    })}
    to={path}
  >
    <div className="sidebar-navigation-item__left">
      <Icon className="sidebar-navigation-item__left__icon" fixedWidth icon={icon} />
      <Text.Regular className="sidebar-navigation-item__left__title">{title}</Text.Regular>
    </div>

    {isLocked && (
      <div className="sidebar-navigation-item__right">
        <Tooltip visible={Boolean(lockedTooltipProps)} {...valueOrEmptyObject(lockedTooltipProps)}>
          <Icon className="sidebar-navigation-item__right__lock-icon" fixedWidth icon={IconNames.LOCK} />
        </Tooltip>
      </div>
    )}
  </NavLink>
);

SidebarNavigationItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(allValues(IconNames)).isRequired,
  isLocked: PropTypes.bool,
  lockedTooltipProps: PropTypes.shape({ ...Tooltip.propTypes }),
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

SidebarNavigationItem.defaultProps = {
  isLocked: false,
};

export default SidebarNavigationItem;
