import {
  createStatusAwarePaginationReducer,
  gatherReducerCasesFromRoutines,
  isPurchaseOrdersActionRelatedToActiveOrders,
} from 'helpers/redux';
import { fetchPurchaseOrdersNextPageRoutine, fetchPurchaseOrdersRoutine } from 'store/routines/purchaseOrders';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Success cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([
  fetchPurchaseOrdersRoutine,
  fetchPurchaseOrdersNextPageRoutine,
]);

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Active Purchase Orders reducers: Pagination Reducer
 */
const activePurchaseOrdersPaginationReducer = createStatusAwarePaginationReducer({
  resetCases,
  statusCheckFunction: isPurchaseOrdersActionRelatedToActiveOrders,
  successCases,
});

export default activePurchaseOrdersPaginationReducer;
