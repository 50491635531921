import { createLastFetchedReducer } from 'helpers/redux';

import { fetchCategoriesRoutine } from 'store/routines/categories';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Request cases
 * @type {ReduxType[]}
 */
export const successCases = [fetchCategoriesRoutine.SUCCESS];

/**
 * Reset state cases
 * @type {ReduxType[]}
 */
export const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Categories last fetched reducer
 * @type {ReduxReducer}
 * @param {number|null} state
 * @param {ReduxAction} action
 * @returns {number|null}
 */
const lastFetchedReducer = createLastFetchedReducer({ successCases, resetCases });

export default lastFetchedReducer;
