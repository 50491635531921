import { createLastFetchedReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';
import { fetchProductPriceRoutine } from 'store/routines/productPrices';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Map of cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([fetchProductPriceRoutine]);

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Product prices reducers: Last fetched reducer
 */
const productPricesLastFetchedReducer = createLastFetchedReducer({ successCases, resetCases });

export { successCases, resetCases };
export default productPricesLastFetchedReducer;
