import PropTypes from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';

import { pageableTableComponentsProp, paginationProp } from 'constants/props';

import { PageableTableBody, TableActionbar, TableFooter } from './components';
import { hasEmptyResults, hasEmptySearchResults } from './helpers';

import './PageableTable.scss';

/**
 * Component rendering Pageable Table
 * @param {Object} props
 * @param {Object[]} props.columns
 * @param {Object} [props.components]
 * @param {Object[]} props.data
 * @param {ReactNode} [props.filters]
 * @param {Boolean} [props.hasError]
 * @param {Boolean} [props.isLoading]
 * @param {Function} props.onFetchData
 * @param {Function} [props.onRowClick]
 * @param {ApiPaginationState} props.pagination
 * @param {Object} [props.tableProps]
 * @param {String} props.title
 * @return {StatelessComponent}
 */
const PageableTable = ({
  columns,
  components,
  data,
  filters,
  hasError,
  isLoading,
  onFetchData,
  onRowClick,
  pagination,
  tableProps: tablePropsBase,
  title,
}) => {
  const { values: params } = useFormState();

  // Joined Table props
  const tableProps = { ...tablePropsBase, columns, data, onRowClick };

  const emptyResults = hasEmptyResults({ data, isLoading });
  const emptySearchResults = hasEmptySearchResults({ data, isLoading, params });

  // We are setting isSearching to true if we are in a loading state and the value
  // for the search Query exists
  const isSearching = isLoading && Boolean(params.searchKey);

  // Props shared between all of the PageableTable child components
  const commonProps = { isLoading, isSearching };

  const handleRefresh = () => {
    onFetchData({ page: pagination.currentPage, params });
  };

  const handleFetchPreviousPage = () => {
    if (pagination.hasPreviousPage) {
      onFetchData({ page: pagination.currentPage - 1, params });
    }
  };

  const handleFetchNextPage = () => {
    if (pagination.hasNextPage) {
      onFetchData({ page: pagination.currentPage + 1, params });
    }
  };

  return (
    <div className="pageable-table">
      <TableActionbar
        {...commonProps}
        filters={filters}
        hasError={hasError}
        onGoToNextPage={handleFetchNextPage}
        onGoToPreviousPage={handleFetchPreviousPage}
        onRefresh={handleRefresh}
        pagination={pagination}
        title={title}
      />
      <PageableTableBody
        {...commonProps}
        {...tableProps}
        components={components}
        hasEmptyResults={emptyResults}
        hasEmptySearchResults={emptySearchResults}
        hasError={hasError}
        onRefresh={handleRefresh}
        params={params}
      />
      <TableFooter
        {...commonProps}
        hasError={hasError}
        onGoToNextPage={handleFetchNextPage}
        onGoToPreviousPage={handleFetchPreviousPage}
        pagination={pagination}
      />
    </div>
  );
};

PageableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  components: pageableTableComponentsProp,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasError: PropTypes.bool,
  filters: PropTypes.node,
  isLoading: PropTypes.bool,
  onFetchData: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  pagination: paginationProp.isRequired,
  tableProps: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
};

PageableTable.defaultProps = {
  hasError: false,
  isLoading: false,
  tableProps: {},
};

export default PageableTable;
