import { LocalStorageKeys } from 'enums/localStorage';

/**
 * Tries to find SELECTED_MERCHANT_ID in the local storage and appends
 * company_id query param to the current location if the local storage
 * key was found
 */
export const getCurrentLocationWithCompanyIdParam = (): string => {
  let location = window.location.href;
  const companyId = localStorage.getItem(LocalStorageKeys.SELECTED_MERCHANT_ID);

  if (companyId) {
    location += `?company_id=${companyId}`;
  }

  return location;
};
