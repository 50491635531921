import PropTypes from 'prop-types';
import React from 'react';

import { Field, useField } from 'react-final-form';

import { Section, Text } from 'components/atoms';
import { Hint } from 'components/elements';
import { SwitchInput, TreeSelectInput } from 'components/elements/inputs';

import { DeliveryEntryFormFields } from 'enums/formFields';
import { TextSize } from 'enums/typography';
import { Intent } from 'enums/ui';
import { getPostcodeTreeSelectNodes } from 'helpers/postcodes';

/**
 * Component rendering Postcodes Input section in AddDeliveryEntrySidesheet
 * @param {ComponentProps} props
 * @param {PostCodeEntity[]} props.postcodes
 * @return {StatelessComponent}
 */
const AddDeliveryEntryPostcodes = ({ postcodes }) => {
  const {
    input: { value: isUsingExternalDeliveryProvider },
  } = useField(DeliveryEntryFormFields.IS_EXTERNAL_DELIVERY_PROVIDER);

  const postcodeNodes = getPostcodeTreeSelectNodes(postcodes);

  return (
    <Section
      isFirst={true}
      title="Izberite poštne številke, ki jih želite podpreti s tem vnosom"
      titleProps={{ size: TextSize.LEVEL_350 }}
    >
      <Field
        component={SwitchInput}
        label="Ta vnos podpira dostavo na vse poštne številke Sloveniji?"
        name={DeliveryEntryFormFields.IS_EXTERNAL_DELIVERY_PROVIDER}
      />

      {isUsingExternalDeliveryProvider && (
        <Hint
          className="margin-top--16"
          intent={Intent.INFO}
          title="Za ta vnos bodo dodane vse poštne številke v Sloveniji"
        >
          <Text.Regular>
            Dodanih bo vseh 941 poštnih številk. S tem vnosom boste na izbrane dni podprli dostavo po celotni Sloveniji.
            V kolikor želite določene poštne številke odstraniti, odznačite to možnost in izberite poštne številke iz
            spustnega seznama.
          </Text.Regular>
        </Hint>
      )}

      {!isUsingExternalDeliveryProvider && (
        <Field
          className="margin-top--16"
          component={TreeSelectInput}
          name={DeliveryEntryFormFields.POSTCODES}
          options={postcodeNodes}
        />
      )}
    </Section>
  );
};

AddDeliveryEntryPostcodes.propTypes = {
  postcodes: PropTypes.arrayOf(
    PropTypes.shape({
      postCode: PropTypes.number,
      postName: PropTypes.string,
    })
  ),
};

export default AddDeliveryEntryPostcodes;
