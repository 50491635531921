import { createSelector } from 'reselect';

import { getDeliveryDaysGroupedByDayOfWeek, getDeliveryDaysPostCodeEntities } from 'helpers/deliveryDays';
import { getMappedPostCodesForTimelineEntry } from 'helpers/postcodes';

import {
  deliveryDaysWithLocalDeliveryAreaTypeSelector,
  deliveryDaysWithWiderDeliveryAreaTypeSelector,
} from 'store/selectors/deliveryDaysSelectors';

/**
 * Creator function for creating Delivery Days Post Codes grouped by Region for Area Type selector
 * @param {StandardSelector} selector
 * @returns {Function}
 */
const createDeliveryDaysPostCodesGroupedByRegionQueryForDeliveryAreaType = (selector) => {
  return createSelector([selector], (deliveryDaysForDeliveryAreaType) => {
    const deliveryDaysGroupedByDayOfWeek = getDeliveryDaysGroupedByDayOfWeek(deliveryDaysForDeliveryAreaType);

    return Object.entries(deliveryDaysGroupedByDayOfWeek).reduce((acc, [dayOfWeek, deliveryDays]) => {
      const postCodes = getDeliveryDaysPostCodeEntities(deliveryDays);
      return [...acc, ...getMappedPostCodesForTimelineEntry({ dayOfWeek, postCodes })];
    }, []);
  });
};

/**
 * Selects local area delivery days post codes grouped by region
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object[]}
 */
export const localDeliveryDaysPostCodesGroupedByRegionQuery = createDeliveryDaysPostCodesGroupedByRegionQueryForDeliveryAreaType(
  deliveryDaysWithLocalDeliveryAreaTypeSelector
);

/**
 * Selects wider area delivery days post codes grouped by region
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object[]}
 */
export const widerDeliveryDaysPostCodesGroupedByRegionQuery = createDeliveryDaysPostCodesGroupedByRegionQueryForDeliveryAreaType(
  deliveryDaysWithWiderDeliveryAreaTypeSelector
);
