import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { LocalStorageKeys } from 'enums/localStorage';
import { AuthRoutes } from 'enums/routes';

import { isOneOfUserRolesValid } from 'helpers/roles';

import { LOGIN_REDIRECT_TYPES } from 'utils/constants';

/**
 * Helper Router component for protecting certain parts of the app
 * from being accessed without correct auth properties
 * @param {ComponentProps} props
 * @param {Node} props.component
 * @param {User} props.user
 * @returns {StatelessComponent}
 */
const PrivateRoute = ({ component: Component, user, ...rest }) => {
  const isUserRoleValid = isOneOfUserRolesValid(user?.roles);
  const isAuthenticated = Boolean(localStorage.getItem(LocalStorageKeys.AUTH_TOKEN)) && isUserRoleValid;
  const redirectPathname = isUserRoleValid ? AuthRoutes.LOGIN : AuthRoutes.UNAUTHORIZED;
  const redirectType = isUserRoleValid ? LOGIN_REDIRECT_TYPES.NO_TOKEN : LOGIN_REDIRECT_TYPES.INVALID_ROLE;

  const renderPrivateRoute = (props) => {
    if (isAuthenticated) {
      return <Component {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: redirectPathname,
          state: {
            from: props.location,
            redirectType,
          },
        }}
      />
    );
  };

  return <Route {...rest} render={renderPrivateRoute} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  user: PropTypes.object.isRequired,
};

export default PrivateRoute;
