import { createIsSubmittingReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { putMerchantCompanyInfoRoutine } from 'store/routines/merchants';

/**
 * Map of cases gathered from routines
 * @constant
 */
const { requestCases, successCases, failureCases } = gatherReducerCasesFromRoutines([putMerchantCompanyInfoRoutine]);

/**
 * merchants is submitting reducer
 * @type {ReduxReducer}
 * @param {ReduxState} state
 * @param {ReduxAction} action
 * @returns {boolean}
 */
const isSubmittingReducer = createIsSubmittingReducer({ endCases: [...successCases, ...failureCases], requestCases });

export { requestCases, successCases, failureCases };
export default isSubmittingReducer;
