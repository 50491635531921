import { createRoutine } from 'redux-saga-routines';

import type { Id } from 'interfaces/common';

import {
  FETCH_MERCHANTS,
  FETCH_MERCHANTS_NEXT_PAGE,
  FETCH_SINGLE_MERCHANT,
  PUT_MERCHANT_COMPANY_INFO,
} from 'store/types/merchants';

/**
 * Redux routine responsible for dispatching FETCH_MERCHANTS related actions
 */
export const fetchMerchantsRoutine = createRoutine(FETCH_MERCHANTS);

/**
 * Redux routine responsible for dispatching FETCH_MERCHANTS_NEXT_PAGE related actions
 */
export const fetchMerchantsNextPageRoutine = createRoutine(FETCH_MERCHANTS_NEXT_PAGE);

/**
 * Redux routine responsible for dispatching PUT_MERCHANT_COMPANY_INFO related actions
 */
export const putMerchantCompanyInfoRoutine = createRoutine(PUT_MERCHANT_COMPANY_INFO);

/**
 * Redux routine responsible for dispatching FETCH_SINGLE_MERCHANT related actions
 */
export const fetchSingleMerchantRoutine = createRoutine(FETCH_SINGLE_MERCHANT, {
  trigger: (payload: { id: Id }) => payload,
});
