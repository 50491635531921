import { merchantOrginazinationStatusDescriptionMap } from 'constants/merchants';

/**
 * Returns formatted merchant's orhanization status
 *
 * @param {MerchantOrganizationStatusType} value
 * @returns {merchantOrginazinationStatusDescriptionMap}
 */
export const formatMerchantOrganizationStatusType = (value) => {
  return merchantOrginazinationStatusDescriptionMap[value] || merchantOrginazinationStatusDescriptionMap.UNKNOWN;
};

/**
 * Returns formatter field value for Producer form field
 * @param {Producer} fieldValue
 * @returns {?String}
 */
export const producerFormFieldValueFormatter = (fieldValue) => fieldValue?.producerName;

/**
 * Returns formatted option value for Category Select form field
 * @param {Category} option
 * @returns {String}
 */
export const categoryFormFieldOptionFormatter = (option) => option.categoryName;
