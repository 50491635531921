import { createIsFetchingReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchSinglePickupRoutine } from 'store/routines/pickups';

/**
 * Map of cases gathered from routines
 */
const { successCases, failureCases, requestCases } = gatherReducerCasesFromRoutines([fetchSinglePickupRoutine]);

/**
 * Pickups reducers: Is fetching reducer
 */
const pickupsIsFetchingReducer = createIsFetchingReducer({
  requestCases,
  endCases: [...successCases, ...failureCases],
});

export { successCases, failureCases, requestCases };
export default pickupsIsFetchingReducer;
