import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms';

import { TextSize } from 'enums/typography';
import { valueOrEmptyObject } from 'helpers/utility';

/**
 * Component rendering Text with one line limit
 * in table cell
 * @param {ComponentProps} props
 * @param {object} props.cell
 * @return {StatelessComponent}
 */
const OneLineTextCell = ({ cell }) => {
  const TextComponent = cell.column?.textComponent || Text.Regular;

  return (
    <TextComponent size={TextSize.LEVEL_200} {...valueOrEmptyObject(cell.column?.props)} numOfLines={1}>
      {cell.value}
    </TextComponent>
  );
};

OneLineTextCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
};

export default OneLineTextCell;
