import { PickupsEndpoints } from 'constants/endpoints';

import type { Id } from 'interfaces/common';

import fetchApi from './common';

/**
 * Makes GET request to /pickups/{pickupId}
 * - returns PickupDTO entity on success response
 * @param pickupId
 */
export const fetchPickup = async (pickupId: Id) => {
  return fetchApi({ needsAuth: true, url: PickupsEndpoints.SINGLE(pickupId) });
};
