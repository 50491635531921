import React from 'react';

import { GenericApiErrorAlert } from 'components/molecules/alerts';

import { showAlert } from './alerts';
import { getErrorMessageFromResponse } from './errors';

/**
 * Returns parsed errors string and opens an error SWAL
 * @param {object} errorData
 * @returns {string}
 */
export const withHandleSagaErrors = (errorData) => {
  const parsedErrors = getErrorMessageFromResponse(errorData);

  showAlert({ Content: <GenericApiErrorAlert errorMessage={parsedErrors} /> });

  return parsedErrors;
};
