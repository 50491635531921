import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/atoms';
import { IconNames } from 'enums/icons';
import { ButtonType, IconPosition } from 'enums/ui';

/**
 * Component rendering Edit Product table cell button
 * @param {Object} props
 * @param {Object} props.cell
 * @returns {StatelessComponent}
 */
const EditProductCellButton = ({ cell }) => {
  const handleClick = () => {
    cell.column.onClick(cell.row);
  };

  return (
    <Button
      fullwidth={true}
      icon={IconNames.EDIT}
      iconPosition={IconPosition.RIGHT}
      onClick={handleClick}
      type={ButtonType.GHOST}
    >
      Uredi
    </Button>
  );
};

EditProductCellButton.propTypes = {
  cell: PropTypes.shape({
    column: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
    }),
  }).isRequired,
};

export default EditProductCellButton;
