import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/atoms/Icon';

import { IconNames } from 'enums/icons';

import './LoadingIndicator.scss';

/**
 * Component rendering Acitivity Indicator (loading indicator). Used where we
 * need to indicate that some action is taking place and we are waiting for
 * that action.
 * @param {ComponentProps} props
 * @param {boolean} props.fullscreen
 * @param {LoadingIndicatorType} props.type
 * @param {boolean} props.large
 * @param {boolean} props.contained
 * @param {boolean} props.fullcontent
 * @returns {StatelessComponent}
 */
const LoadingIndicator = ({ fullscreen, type, large, contained, fullcontent }) => (
  <div
    className={classNames({
      LoadingIndicator: true,
      fullscreen,
      fullcontent,
      contained,
      [type]: true,
      large,
    })}
  >
    <Icon icon={IconNames.CIRCLE_NOTCH} spinning />
  </div>
);

LoadingIndicator.propTypes = {
  fullscreen: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'primary']),
  large: PropTypes.bool,
  contained: PropTypes.bool,
  fullcontent: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  fullscreen: false,
  large: false,
  type: 'default',
  contained: false,
  fullcontent: false,
};

export default LoadingIndicator;
