import { isObject, isString } from './utility';

/**
 * Returns error message based on the available properties
 * from the error response
 * @param {Object|string} error
 * @returns {string}
 */
export const getErrorMessageFromResponse = (error) => {
  let errorMessage;

  if (isObject(error)) {
    if (isObject(error.data)) {
      const { message, error: dataError } = error.data;
      errorMessage = message || dataError;
    } else if (isString(error.data)) {
      // In this case, error.data is a string - we want to use that if it is not
      // an empty string, or resort to error.status otherwise
      errorMessage = error.data || error.status?.toString();
    } else {
      if (error?.message && Boolean(error.message.length)) {
        errorMessage = error.message;
      } else {
        errorMessage = error.error;
      }
    }
  }

  return errorMessage || error || 'Something went wrong';
};
