import { createAllIdsReducer } from 'helpers/redux';
import { fetchProductPriceRoutine, patchProductPriceRoutine } from 'store/routines/productPrices';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * List of success cases with replace state flag
 */
const successCases = [
  { type: fetchProductPriceRoutine.SUCCESS, shouldReplaceState: false },
  { type: patchProductPriceRoutine.SUCCESS, shouldReplaceState: false },
];

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Products prices reducers: All ids reducer
 */
const productPricesAllIdsReducer = createAllIdsReducer({ successCases, resetCases });

export { successCases, resetCases };
export default productPricesAllIdsReducer;
