import axios from 'axios';

import { API_BASE_URL } from 'constants/api';
import { ApiRequestType, ApiServiceName, ApiVersion } from 'enums/api';
import { LocalStorageKeys } from 'enums/localStorage';
import { getUrlForApiService } from 'helpers/api';

/**
 * @constant {AxiosInstance}
 */
const client = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
});

/**
 * Helper function for making API requests
 * @param {Object} options
 * @param {string} [options.apiVersion=ApiVersion.V1]
 * @param {string} options.url
 * @param {ApiRequestType} [options.method=ApiRequestType.GET]
 * @param {Object} [options.data]
 * @param {Object} [options.params]
 * @param {boolean} [options.needsAuth]
 * @param {string} [options.serviceName=ApiServiceName.CORE]
 * @returns {Promise<Object>}
 */
const fetchApi = async ({
  apiVersion = ApiVersion.V1,
  url: endpoint,
  method = ApiRequestType.GET,
  data: reqData,
  params,
  needsAuth = false,
  serviceName = ApiServiceName.CORE,
}) => {
  try {
    if (needsAuth) {
      if (!client.defaults.headers.common['Authorization']) {
        const accessToken = localStorage.getItem(LocalStorageKeys.AUTH_TOKEN);

        if (accessToken) {
          client.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        } else {
          return { errorData: { message: 'Not authenticated', status: 401 }, isSuccess: false };
        }
      }
    } else {
      delete client.defaults.headers.common['Authorization'];
    }

    const dataOrParams = [ApiRequestType.GET, ApiRequestType.DELETE].includes(method) ? 'params' : 'data';

    const options = { url: getUrlForApiService({ apiVersion, needsAuth, serviceName, endpoint }), method };

    if (reqData) {
      options[dataOrParams] = reqData;
    }

    if (params) {
      options.params = { ...options.params, ...params };
    }

    if (method === ApiRequestType.PATCH) {
      options.headers = { ...options.headers, 'content-type': 'application/json-patch+json' };
    }

    const { data } = await client.request(options);

    return { data, isSuccess: true };
  } catch (err) {
    return { errorData: err.response, isSuccess: false };
  }
};

export default fetchApi;
