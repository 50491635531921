import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { GridColumnCount, GridGapSize } from 'enums/ui';
import { allValues } from 'helpers/utility';

import './Grid.scss';

/**
 * Component rendering passed children in a CSS grid container
 * with passed properties.
 * @param {ComponentProps} props
 * @param {string} props.gap
 * @param {string} props.numOfCols
 * @return {StatelessComponent}
 */
const Grid = ({ children, gap, numOfCols }) => (
  <div className={`grid grid--${numOfCols} grid--gap-${gap}`}>
    {React.Children.map(children, (child) => {
      // Return nothing if child is null or undefined
      if (!child) {
        return null;
      }

      // Since we don't want to pass gridSpan prop to a child
      // (only used in Grid component), we extract this property
      // from the rest of the props.
      const { gridSpan, ...rest } = child?.props || {};
      const hasGridSpanProp = Boolean(gridSpan);

      // We clone the original child and pass all of the props
      // to it except gridSpan
      const childElement = React.cloneElement({ ...child, props: rest });

      return (
        <div
          className={classNames('grid__child', {
            [`grid__child--span-${gridSpan}`]: hasGridSpanProp,
          })}
        >
          {childElement}
        </div>
      );
    })}
  </div>
);

Grid.propTypes = {
  children: PropTypes.node,
  gap: PropTypes.oneOf(allValues(GridGapSize)),
  numOfCols: PropTypes.oneOf(allValues(GridColumnCount)),
};

Grid.defaultProps = {
  gap: GridGapSize.NORMAL,
  numOfCols: GridColumnCount.THREE,
};

export default Grid;
