import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

import { ENV } from 'constants/env';
import { sentryDSN, sentryProjectId, sentryProjectURL } from 'constants/sentry';

const history = createBrowserHistory();

/**
 * Initializes Sentry error logging service
 * @function
 * @returns {void} Void
 */
export const initializeSentry = () => {
  Sentry.init({
    dsn: `https://${sentryDSN}@${sentryProjectURL}/${sentryProjectId}`,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    release: `ofb2cw@${process.env.npm_package_version}`,
    environment: ENV,
    enabled: ENV !== 'development',
    tracesSampleRate: 1.0,
  });
};

/**
 * Captures and logs exception to Sentry
 * @function
 * @param {Error} exception
 * @returns {void} Void
 */
export const logExceptionToSentry = (exception) => {
  Sentry.captureException(exception);
};

/**
 * Captures and logs exception with extra data to Sentry
 * @function
 * @param {Error} exception
 * @param {?object} data
 * @returns {void} Void
 */
export const logExceptionWithDataToSentry = (exception, data) => {
  Sentry.withScope((scope) => {
    scope.setContext('Extra debug info', data || {});
    logExceptionToSentry(exception);
  });
};

/**
 * Captures and logs exception with addition scope to Sentry
 * @function
 * @param {ErrorInfo} errorInfo
 * @param {Error} error
 * @return {void} Void
 */
export const logSentryExceptionWithScope = (errorInfo, error) => {
  Sentry.withScope((scope) => {
    scope.setExtra('componentStack', errorInfo.componentStack);
    Sentry.captureException(error);
  });
};

/**
 * Captures and logs message string to Sentry
 * @function
 * @param {string} message
 * @returns {void} Void
 */
export const logMessageToSentry = (message) => {
  Sentry.captureMessage(message);
};

/**
 * Logs message with extra data to Sentry
 * @function
 * @param {message} exception
 * @param {?object} data
 * @returns {void} Void
 */
export const logMessageWithDataToSentry = (message, data) => {
  Sentry.withScope((scope) => {
    scope.setContext('Extra info', data || {});
    logMessageToSentry(message);
  });
};
