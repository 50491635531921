import React from 'react';

import { Grid, LoadingIndicator } from 'components/atoms';
import { PageLayout } from 'components/modules/global';
import { PurchaseOrderStatusTag } from 'components/molecules';

import { PurchaseOrdersRoutes } from 'enums/routes';
import { GridColumnCount, GridGapSize } from 'enums/ui';

import { getPurchaseOrderNumber } from 'helpers/purchaseOrders';
import { getPathWithDynamicSegmentReplaced } from 'helpers/routing';

import { OrderDetailsFooter, OrderDetailsInfo, OrderDetailsItems } from './components';

/**
 * Page component rendering single Purchase Order details
 * @param {ComponentProps} props
 * @param {Function} props.fetchSingleOrder
 * @param {object} props.match
 * @param {PurchaseOrder} props.order
 * @returns {StatelessComponent}
 */
const OrderDetails = ({ fetchSingleOrder, match, order }) => {
  const breadcrumbs = [
    { path: PurchaseOrdersRoutes.ROOT, name: 'Pregled naročil' },
    {
      path: getPathWithDynamicSegmentReplaced(PurchaseOrdersRoutes.DETAILS, order?.id),
      name: `Podrobnosti naročila ${getPurchaseOrderNumber(order)}`,
    },
  ];

  const fetchOrderIfNotLoaded = React.useCallback(() => {
    if (!order) {
      fetchSingleOrder(match.params.orderId);
    }
  }, [fetchSingleOrder, match.params.orderId, order]);

  React.useEffect(() => {
    fetchOrderIfNotLoaded();
    window.scrollTo(0, 0);
  }, [fetchOrderIfNotLoaded]);

  // Return loading indicator if state data is not loaded yet
  if (!order) {
    return <LoadingIndicator fullcontent />;
  }

  return (
    <PageLayout
      actionbarAddons={<PurchaseOrderStatusTag purchaseOrderStatus={order.status} />}
      breadcrumbs={breadcrumbs}
    >
      <Grid gap={GridGapSize.MEDIUM} numOfCols={GridColumnCount.FIVE}>
        <OrderDetailsItems gridSpan={3} items={order.purchaseOrderItems} />
        <OrderDetailsInfo gridSpan={2} purchaseOrder={order} />
      </Grid>

      <OrderDetailsFooter order={order} />
    </PageLayout>
  );
};

export default OrderDetails;
