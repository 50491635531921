import { createErrorReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';
import {
  fetchMerchantsNextPageRoutine,
  fetchMerchantsRoutine,
  fetchSingleMerchantRoutine,
  putMerchantCompanyInfoRoutine,
} from 'store/routines/merchants';

const { failureCases, successCases, requestCases } = gatherReducerCasesFromRoutines([
  fetchMerchantsRoutine,
  fetchMerchantsNextPageRoutine,
  fetchSingleMerchantRoutine,
  putMerchantCompanyInfoRoutine,
]);

/**
 * Merchants reducers: Error Reducer
 */
const errorReducer = createErrorReducer({ resetCases: [...successCases, ...requestCases], failureCases });

export { failureCases, successCases, requestCases };
export default errorReducer;
