import { createByIdReducer } from 'helpers/redux';

import type { DeliveryDayDTO } from 'interfaces/deliveries';

import { fetchDeliveryDaysRoutine } from 'store/routines/deliveryDays';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Delivery Days reducers: by Id reducer
 */
const deliveryDaysByIdReducer = createByIdReducer<DeliveryDayDTO>({
  successCases: [fetchDeliveryDaysRoutine.SUCCESS],
  resetCases: [REMOVE_SELECTED_MERCHANT],
});

export default deliveryDaysByIdReducer;
