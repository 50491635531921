import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { formInputPropType } from 'constants/props';

import './SwitchInput.scss';
import { LabelPosition } from 'enums/ui';
import { allValues } from 'helpers/utility';

/**
 * Component rendering form Switch input field
 * @param {ComponentProps} props
 * @param {String} [props.clasName]
 * @param {boolean} [props.disabled=false]
 * @param {FormInput} props.input
 * @param {string} [props.label]
 * @param {LabelPosition} [props.labelPosition]
 * @return {StatelessComponent}
 */
const SwitchInput = ({ className, disabled, input, label, labelPosition }) => {
  const isOn = Boolean(input.value);

  const handleSwitchClick = () => {
    if (disabled) {
      return;
    }

    input.onChange(!input.value);
  };

  return (
    <div
      className={classNames('switch-input', `switch-input--label-position--${labelPosition}`, {
        'switch-input--disabled': disabled,
        [className]: Boolean(className),
      })}
      onClick={handleSwitchClick}
    >
      {Boolean(label) && <label className="switch-input__label">{label}</label>}

      <div className={classNames('switch-input__input', { 'switch-input__input--on': isOn })}>
        <div className="switch-input__input__indicator" />
      </div>
    </div>
  );
};

SwitchInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: formInputPropType,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(allValues(LabelPosition)),
};

SwitchInput.defaultProps = {
  disabled: false,
  labelPosition: LabelPosition.LEFT,
};

export default SwitchInput;
