import { createPaginationReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchMerchantsNextPageRoutine, fetchMerchantsRoutine } from 'store/routines/merchants';

/**
 * Map of cases from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([fetchMerchantsRoutine, fetchMerchantsNextPageRoutine]);

/**
 * Reducer containing info about merchant entity pagination state
 */
const paginationReducer = createPaginationReducer({ successCases });

export default paginationReducer;
