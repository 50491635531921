import React from 'react';
import { Field } from 'react-final-form';

import { Grid, Section } from 'components/atoms';
import { NumberInput, TextInput } from 'components/elements/inputs';

import { CompanyGeneralFormFields } from 'enums/formFields';
import { GridColumnCount } from 'enums/ui';
import { formatMerchantOrganizationStatusType } from 'helpers/formatters';

/**
 * Component rendering system info fields of
 * MerchantGeneralInfoForm
 * @see MerchantGeneralInfoForm
 * @return {StatelessComponent}
 */
const MerchantGeneralInfoFormSystemInfo = () => (
  <Section title="Sistemski podatki">
    <Grid numOfCols={GridColumnCount.THREE}>
      <Field
        component={TextInput}
        disabled={true}
        fullwidth={true}
        label="Id ponudnika"
        name={CompanyGeneralFormFields.ID}
      />
      <Field
        component={TextInput}
        disabled={true}
        format={formatMerchantOrganizationStatusType}
        fullwidth={true}
        label="Status ponudnika"
        name={CompanyGeneralFormFields.ORGANIZATION_STATUS}
        tooltipProps={{
          content:
            'Vaš sistemski status. Status "potrjen" pomeni, da je ekipa Optifarm uspešno preverila vaše podatke.',
        }}
      />
      <Field
        component={NumberInput}
        label="Prioriteta prikaza"
        name={CompanyGeneralFormFields.IMPORTANCE}
        tooltipProps={{
          content:
            'Vnesite vrednost med 1 in 100. Višja vrednost prioritete prikaza  pomeni višjo pozicijo ponudnika v mobilni aplikaciji.',
        }}
      />
    </Grid>
  </Section>
);

export default MerchantGeneralInfoFormSystemInfo;
