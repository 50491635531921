import { isEqual } from 'helpers/utility';

/**
 * Returns true if node has children
 * @param {object} node
 * @returns {boolean}
 */
export const nodeHasChildren = (node) => {
  return Boolean(node?.children && Array.isArray(node.children));
};

/**
 * Returns true if node is a parent node
 * @param {object} node
 * @returns {boolean}
 */
export const isNodeParent = (node) => Boolean(node.isParent);

/**
 * Returns true if node is select all node
 * @param {object} node
 * @returns {boolean}
 */
export const isNodeSelectAllNode = (node) => {
  return isEqual(node.value, 'selectAll');
};

/**
 * Returns array of node children values
 * @param {object} node
 * @returns {any[]}
 */
export const getMappedChildrenValuesArray = (node) => {
  if (!nodeHasChildren(node)) {
    return [];
  }

  return node.children.map((child) => child.value);
};

/**
 * Returns true if the value of the node is included in the default values
 * array
 * @param {object} options
 * @param {object} options.node
 * @param {any[]} [options.defaultValue]
 * @returns {boolean}
 */
export const isNodeCheckedFromDefaultValues = ({ node, defaultValue }) => {
  return Boolean(defaultValue) && Array.isArray(defaultValue) && defaultValue.includes(node.value);
};

/**
 * Returns true if node is checked
 * @param {object} options
 * @param {object} options.node
 * @param {any[]} [options.defaultValue]
 * @returns {boolean}
 */
export const isNodeChecked = ({ node, defaultValue }) => {
  return node.checked ?? isNodeCheckedFromDefaultValues({ node, defaultValue });
};

/**
 * Returns mapped data, ready to be used by TreeSelect component
 * @param {object} options
 * @param {object[]} options.data
 * @param {any[]} [options.defaultValue]
 * @returns {object[]}
 */
export const getMappedTreeSelectDataFromOriginalData = ({ data, defaultValue }) => {
  return data.map((node) => {
    // If node has children, we want to attach isParent property and
    // map values if the children to an array of values
    if (nodeHasChildren(node)) {
      return {
        ...node,
        isParent: true,
        values: getMappedChildrenValuesArray(node),
        children: getMappedTreeSelectDataFromOriginalData({ data: node.children, defaultValue }),
      };
    }

    // If it does not have any children, we just return the node
    return {
      ...node,
      checked: isNodeChecked({ defaultValue, node }),
    };
  });
};

/**
 * Returns prepared data with Select All checkbox which is consumed by TreeSelect component
 * @param {object} options
 * @param {object[]} options.data
 * @param {any[]} [options.defaultValue]
 * @returns {object[]}
 */
export const getPreparedTreeSelectData = ({ data, defaultValue }) => {
  return [
    { label: 'Izberi vse', value: 'selectAll', className: 'select-all' },
    ...getMappedTreeSelectDataFromOriginalData({ data, defaultValue }),
  ];
};

/**
 * Returns nodes marked as the value of passed checked parameter
 * @param {object} options
 * @param {object[]} options.data
 * @param {boolean} options.checked
 * @returns {object[]}
 */
export const getAllSelectNodesMarkedAsSelected = ({ data, checked }) => {
  // Make a copy of existing data
  const updatedNodes = [...data];

  // Loop over all of the fields and set their checked value
  // to the checked value passed to this function
  for (const node of updatedNodes) {
    node.checked = checked;

    if (nodeHasChildren(node)) {
      node.children = node.children.map((childNode) => ({
        ...childNode,
        checked,
      }));
    }
  }

  return updatedNodes;
};

/**
 * Returns array of node value(s)
 * @param {object} node
 * @returns {any[]}
 */
export const getNodeValue = (node) => {
  if (isNodeParent(node) || nodeHasChildren(node)) {
    if (node.values) {
      return node.values;
    }

    return getMappedChildrenValuesArray(node);
  }

  return [node.value];
};

/**
 * Returns the current value of TreeSelect input without duplicates
 * @param {ReactRef} ref
 * @returns {any[]}
 */
export const getTreeSelectValueFromSelectedNodes = (selectedNodes) => {
  return [
    ...new Set(
      selectedNodes.reduce((acc, node) => {
        if (isNodeSelectAllNode(node)) {
          return acc;
        }

        return [...acc, ...getNodeValue(node)];
      }, [])
    ),
  ];
};
