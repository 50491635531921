import PropTypes from 'prop-types';
import React from 'react';

import { MailToSupportLink, Text } from 'components/atoms';

import { TextLineHeight } from 'enums/typography';

import { capitalize, getQuotedString } from 'helpers/textHelpers';

/**
 * Component rendering the content for the Field Not Editable Tooltip
 * @param {Object} props
 * @param {String} [props.label] - Input label. Can potentially be undefined (if the input field does not have one).
 * @param {String} props.name - Input name. Is always defined and should be used instead of the label if the label is not passed
 * @returns {StatelessComponent}
 */
const FieldNotEditableTooltip = ({ label, name }) => {
  const fieldLabel = getQuotedString(label || capitalize(name));

  return (
    <Text.Regular lineHeight={TextLineHeight.MEDIUM}>
      Urejanje polja {fieldLabel} trenutno ni podprto. V kolikor želite spremeniti vrednost polja, kontaktirajte našo
      podporno ekipo na e-poštni naslov <MailToSupportLink />.
    </Text.Regular>
  );
};

FieldNotEditableTooltip.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

export default FieldNotEditableTooltip;
