import PropTypes from 'prop-types';
import React from 'react';

import { GridGapSize } from 'enums/ui';
import { allValues } from 'helpers/utility';

import './Column.scss';

/**
 * Component rendering passed children in a column
 * @param {ComponentProps} props
 * @param {string} [props.gap=GridGapSize.NORMAL]
 * @return {StatelessComponent}
 */
const Column = ({ children, gap }) => <div className={`column column--gap-${gap}`}>{children}</div>;

Column.propTypes = {
  gap: PropTypes.oneOf(allValues(GridGapSize)),
};

Column.defaultProps = {
  gap: GridGapSize.NORMAL,
};

export default Column;
