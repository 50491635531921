import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import type { ReduxState } from 'store/interfaces';

import { merchantFromPurchaseOrderPropSelector } from 'store/queries/merchantsQueries';

import OrderDetailsInfoPickup from './OrderDetailsInfoPickup';
import type { StateProps } from './OrderDetailsInfoPickup.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  company: merchantFromPurchaseOrderPropSelector,
});

export default connect(mapStateToProps)(OrderDetailsInfoPickup);
