import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchPurchaseOrdersNextPageRoutine, fetchPurchaseOrdersRoutine } from 'store/routines/purchaseOrders';
import { merchantsByIdSelector } from 'store/selectors/merchantsSelectors';
import {
  isPurchaseOrdersTypeLoadingFromPropsSelector,
  allPurchaseOrdersForOrdersTypeFromPropsSelector,
  purchaseOrdersTypePaginationFromPropsSelector,
} from 'store/selectors/purchaseOrdersSelectors';
import { userStateSelector } from 'store/selectors/userSelectors';

import PurchaseOrdersList from './PurchaseOrdersList';

export const mapStateToProps = createStructuredSelector({
  loading: isPurchaseOrdersTypeLoadingFromPropsSelector,
  merchantsById: merchantsByIdSelector,
  pagination: purchaseOrdersTypePaginationFromPropsSelector,
  purchaseOrders: allPurchaseOrdersForOrdersTypeFromPropsSelector,
  user: userStateSelector,
});

export const mapDispatchToProps = {
  fetchOrders: fetchPurchaseOrdersRoutine,
  fetchOrdersNextPage: fetchPurchaseOrdersNextPageRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrdersList);
