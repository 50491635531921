import { combineReducers } from 'redux';

import type { PurchaseOrdersState } from 'store/interfaces/purchaseOrdersState.types';

import activePurchaseOrdersReducer from './activePurchaseOrdersReducer';
import expiredPurchaseOrdersReducer from './expiredPurchaseOrdersReducer';
import finalizedPurchaseOrdersReducer from './finalizedPurchaseOrdersReducer';
import isFetchingReducer from './isFetchingReducer';
import purchaseOrdersAllIdsReducer from './purchaseOrdersAllIdsReducer';
import purchaseOrdersByIdReducer from './purchaseOrdersByIdReducer';
import purchaseOrdersErrorReducer from './purchaseOrdersErrorReducer';
import purchaseOrdersLastFetchedReducer from './purchaseOrdersLastFetchedReducer';

/**
 * Purchase orders reducers: Root reducer
 */
const purchaseOrdersReducer = combineReducers<PurchaseOrdersState>({
  allIds: purchaseOrdersAllIdsReducer,
  active: activePurchaseOrdersReducer,
  byId: purchaseOrdersByIdReducer,
  error: purchaseOrdersErrorReducer,
  expired: expiredPurchaseOrdersReducer,
  finalized: finalizedPurchaseOrdersReducer,
  isFetching: isFetchingReducer,
  lastFetched: purchaseOrdersLastFetchedReducer,
});

export default purchaseOrdersReducer;
