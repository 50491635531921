import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { merchantGeneralInfoValidationSchema } from 'constants/formValidationSchemas';

import { getFormValidationFieldErrors } from 'helpers/forms';

import { merchantGeneralFormInitialValuesQuery } from 'store/queries/merchantsQueries';
import { putMerchantCompanyInfoRoutine } from 'store/routines/merchants';
import { isMerchantsStateSubmittingSelector } from 'store/selectors/merchantsSelectors';

import MerchantGeneralInfoForm from './MerchantGeneralInfoForm';

export const mapStateToProps = createStructuredSelector({
  initialValues: merchantGeneralFormInitialValuesQuery,
  isSubmitting: isMerchantsStateSubmittingSelector,
});

export const mapDispatchToProps = {
  onFormSubmit: putMerchantCompanyInfoRoutine,
};

/**
 * Container for MerchantGeneralInfoForm component. Handles form logic and connection to the Redux state.
 * @param {ComponentProps} props
 * @param {Object} initialValues
 * @param {Function} onFormSubmit
 * @param {RestOfProps} rest
 * @returns
 */
export const MerchantGeneralInfoFormContainer = ({ initialValues, onFormSubmit, ...rest }) => {
  /**
   * Form submit handler
   *
   * @param {Object} values
   * @param {FormAPI} form
   * @returns {Promise<void>}
   */
  const handleSubmit = async (values, form) => {
    const errors = await form.submit();

    if (!errors) {
      onFormSubmit(values);
    }
  };

  /**
   * Form validation handler
   *
   * @param {Object} values
   * @returns {Promise<void|Object>}
   */
  const validateFormValues = async (values) => {
    try {
      await merchantGeneralInfoValidationSchema.validate(values, { abortEarly: false });
    } catch (err) {
      return getFormValidationFieldErrors(err);
    }
  };

  return (
    <Form
      className="merchant-general-info-form"
      component={MerchantGeneralInfoForm}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateFormValues}
      {...rest}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantGeneralInfoFormContainer);
