import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms';
import { Breadcrumbs } from 'components/elements/Breadcrumbs';

import { TextColor, TextSize } from 'enums/typography';

import './DashboardContainer.scss';

/**
 * Component rendering generic dashboard container
 * @param {ComponentProps} props
 * @param {Object[]} [props.breadcrumbs]
 * @param {string} [props.className]
 * @param {boolean} [props.isContained=true]
 * @param {string} [props.title]
 * @return {StatelessComponent}
 */
const DashboardContainer = ({ breadcrumbs, children, isContained, className, title }) => (
  <div
    className={classNames('dashboard-container', {
      'dashboard-container--is-contained': isContained,
      [className]: Boolean(className),
    })}
  >
    {Boolean(breadcrumbs) && (
      <div className="dashboard-container__breadcrumbs">
        <Breadcrumbs paths={breadcrumbs} />
      </div>
    )}

    {Boolean(title) && (
      <div className="dashboard-container__title">
        <Text.ExtraBold color={TextColor.BLACK} size={TextSize.LEVEL_500}>
          {title}
        </Text.ExtraBold>
      </div>
    )}

    {children}
  </div>
);

DashboardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isContained: PropTypes.bool,
  title: PropTypes.string,
};

DashboardContainer.defaultProps = {
  isContained: true,
};

export default DashboardContainer;
