import { createErrorReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchProductsRoutine, fetchSingleProductRoutine, putProductRoutine } from 'store/routines/products';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * List of error cases
 */
const { successCases, failureCases, requestCases } = gatherReducerCasesFromRoutines([
  fetchProductsRoutine,
  fetchSingleProductRoutine,
  putProductRoutine,
]);

/**
 * Products reducers: Error reducer
 */
const errorReducer = createErrorReducer({
  failureCases,
  resetCases: [...successCases, ...requestCases, REMOVE_SELECTED_MERCHANT],
});

export { successCases, failureCases, requestCases };
export default errorReducer;
