export enum CompanyGeneralFormFields {
  ADDRESS = 'address',
  EMAIL = 'email',
  HAS_DELIVERY = 'validDelivery',
  ID = 'id',
  IMAGE = 'image',
  IMPORTANCE = 'importance',
  FREE_LOCAL_DELIVERY_THRESHOLD = 'freeLocalDeliveryThreshold',
  FREE_REGIONAL_DELIVERY_THRESHOLD = 'freeRegionalDeliveryThreshold',
  LEGAL_ENTITY = 'isLegalEntity',
  LOCAL_DELIVERY_PRICE = 'localDeliveryPrice',
  REGIONAL_DELIVERY_PRICE = 'regionalDeliveryPrice',
  NAME = 'companyName',
  MIN_HOME_DELIVERY = 'minHomeDeliveryThreshold',
  POST = 'post',
  POST_CODE = 'postCode',
  POST_NAME = 'postName',
  ORGANIZATION_STATUS = 'organizationStatusType',
}

export enum DeliveryEntryFormFields {
  DELIVERY_DAYS = 'deliveryDays',
  DELIVERY_TYPE = 'deliveryType',
  IS_EVERY_DAY_DELIVERY = 'isEveryDayDelivery',
  IS_EXTERNAL_DELIVERY_PROVIDER = 'isExternalDeliveryProvider',
  POSTCODES = 'postcodes',
}

export enum ProductFormFields {
  ACTIVE = 'product.active',
  ADULT_CONSENT = 'product.adultConsent',
  CATEGORY = 'product.category',
  COMPANY = 'product.company',
  DESCRIPTION = 'product.productDescription',
  EXTERNAL_ID = 'product.externalId',
  ID = 'product.id',
  IMAGES = 'product.images',
  NAME = 'product.productName',
  ORIGIN = 'product.origin',
  PRICE = 'product.productPrice',
  PRODUCER = 'product.producer',
}

export enum ProductPriceFormFields {
  ACTIVE = 'productPrice.activePrice',
  CURRENCY = 'productPrice.currency',
  TAX_INCLUDED_AMOUNT = 'productPrice.taxIncludedAmount',
  UNIT_OF_MEASUREMENT = 'productPrice.unitOfMeasure',
  VAT = 'productPrice.vat',
}

export enum UiFormFields {
  PRODUCT_IN_APP_LINK = 'ui.inAppProductLink',
}
