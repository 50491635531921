import PropTypes from 'prop-types';
import React from 'react';

import { Table } from 'components/elements';

import { getPurchaseOrderItemsTableColumns, getPurchaseOrderItemsTableData } from './data';

/**
 * Component rendering Purchase Order Items table
 * @param {ComponentProps} props
 * @param {PurchaseOrderItem[]} props.items
 * @param {Object} props.productsById
 * @return {StatelessComponent}
 */
const PurchaseOrderItemsTable = ({ items, productsById }) => {
  const data = getPurchaseOrderItemsTableData(items, { productsById });
  const columns = getPurchaseOrderItemsTableColumns();

  return <Table columns={columns} data={data} />;
};

PurchaseOrderItemsTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productsById: PropTypes.shape({}).isRequired,
};

export default PurchaseOrderItemsTable;
