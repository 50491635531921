import classNames from 'classnames';
import _noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';

import './Tabs.scss';

/**
 * Component rendering Tabs with Tabs content.
 * @param {Object} props
 * @param {string[]} props.tabs
 * @param {number} props.activeIndex
 * @param {ReactNode} props.children
 * @param {Function} [props.saveTabIndex]
 * @returns {FunctionComponent}
 */
const Tabs = ({ tabs, activeIndex, children, saveTabIndex }) => {
  const [active, setActive] = React.useState(activeIndex);
  const [changed, setChanged] = React.useState(false);

  const setActiveTab = (index) => {
    if (!changed) {
      setChanged(true);
    }

    saveTabIndex(index);
    setActive(index);
  };

  React.useEffect(() => {
    setActive(activeIndex);
  }, [activeIndex]);

  return (
    <div className="Tabs">
      <div className="tabs-container">
        {tabs.map((tab, i) => (
          <span
            key={tab}
            className={classNames({ tab: true, active: active === i + 1 })}
            onClick={() => setActiveTab(i + 1)}
          >
            {tab}
          </span>
        ))}
      </div>
      {children.map(
        (child, i) =>
          active === i + 1 && (
            <div
              key={i}
              className={classNames({
                'tab-content': true,
                active: active === i + 1,
              })}
            >
              {child}
            </div>
          )
      )}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  saveTabIndex: PropTypes.func,
};

Tabs.defaultProps = {
  activeIndex: 1,
  saveTabIndex: _noop,
};

export default Tabs;
