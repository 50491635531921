import PropTypes from 'prop-types';
import React from 'react';

import ProductStatusIndicator from 'components/elements/ProductStatusIndicator/ProductStatusIndicator';

/**
 * Component rendering Product Status Indicator as a Table Cell
 * @param {Object} props
 * @param {Object} props.cell
 * @returns {StatelessComponent}
 */
const ProductStatusCell = ({ cell }) => <ProductStatusIndicator isActive={cell.value} />;

ProductStatusCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.bool }).isRequired,
};

export default ProductStatusCell;
