import PropTypes from 'prop-types';

import React from 'react';

import { Button } from 'components/atoms';
import { CancelPurchaseOrderAlert } from 'components/modules/purchaseOrders';
import { PurchaseOrderStatus } from 'enums/purchaseOrders';
import { ButtonType, Intent } from 'enums/ui';
import { showAlert } from 'helpers/alerts';

/**
 * Component rendering Purchase Order Details cancellation button
 * @param {ComponentProps} props
 * @param {number} props.orderId
 * @param {boolean} props.visible
 * @param {Function} props.updatePurchaseOrderStatus
 * @return {StatelessComponent}
 */
const OrderCancellationButton = ({ orderId, visible, updatePurchaseOrderStatus }) => {
  const orderCancelation = () => {
    updatePurchaseOrderStatus({ id: orderId, status: PurchaseOrderStatus.CANCELLED });
  };

  const onOrderCancelation = () => {
    showAlert({ Content: <CancelPurchaseOrderAlert onCancel={orderCancelation} orderId={orderId} /> });
  };

  if (!visible) {
    return null;
  }

  return (
    <Button
      className="order-details-footer__item"
      intent={Intent.INFO}
      onClick={onOrderCancelation}
      type={ButtonType.LINK}
    >
      Preklic naročila
    </Button>
  );
};

OrderCancellationButton.propTypes = {
  orderId: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  updatePurchaseOrderStatus: PropTypes.func.isRequired,
};

export default OrderCancellationButton;
