import { createSelector } from 'reselect';

import { PurchaseOrderType } from 'enums/purchaseOrders';

import type { Id } from 'interfaces/common';
import type { RouteMatch } from 'interfaces/navigation';

import { PurchaseOrderDTO } from 'interfaces/purchaseOrders';
import type { ReduxState } from 'store/interfaces';

/**
 * Selects entire redux state
 * @param state
 */
export const allStateSelector = (state: ReduxState) => state;

/**
 * Selects the props argument
 * @param _ - Redux state (not used)
 * @param props
 */
export const propsSelector = (_: ReduxState, props: Record<string, unknown>) => props;

/**
 * Selects an id
 * @param _ - Redux state (not used)
 * @param id - id
 */
export const idSelector = (_: ReduxState, id: Id) => id;

/**
 * Selects an type
 * @param _ - Redux state (not used)
 * @param type - type
 */
export const typeSelector = (_: ReduxState, type: PurchaseOrderType) => type;

/**
 * Selects props.type
 * @param _ - Redux state (not used)
 * @param props - Component props
 */
export const purchaseOrderTypeFromPropsSelector = createSelector(
  [propsSelector],
  (props) => props.type as PurchaseOrderType
);

/**
 * Selects match property of passed props
 * @param state
 * @param props
 */
export const propsMatchSelector = createSelector([propsSelector], (props) => props.match as RouteMatch);

/**
 * Selects porams property of passed props.match
 * @param state
 * @param props
 */
export const propsParamsSelector = createSelector([propsMatchSelector], (match) => match?.params);

/**
 * Selects orderId property of passed props.match.params
 * @param state
 * @param props
 */
export const propsOrderIdFromParamsSelector = createSelector([propsParamsSelector], (params) => {
  const { orderId } = Object(params);
  const id = parseInt(orderId);

  // if undefined is passed to the parseInt(), NaN is returned. We only want to return
  // the id if we have an actual id (i.e. integer). We don't want to deal with NaNs across
  // the codebase.
  if (!Number.isNaN(id)) {
    return id;
  }
});

/**
 * Selects productId property of passed props.match.params
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {RouterMatchProp} props.match
 * @returns {Id}
 */
export const productIdFromParamsSelector = createSelector([propsParamsSelector], (params) => {
  const { productId } = Object(params);
  const id = parseInt(productId);

  // if undefined is passed to the parseInt(), NaN is returned. We only want to return
  // the id if we have an actual id (i.e. integer). We don't want to deal with NaNs across
  // the codebase.
  if (!Number.isNaN(id)) {
    return id;
  }
});

/**
 * Selects order id from passed props
 * @param state
 * @param props
 */
export const orderIdPropSelector = createSelector([propsSelector], (props) => props.orderId as Id);

/**
 * Selects product id from passed props
 * @param state
 * @param props
 */
export const productIdPropSelector = createSelector([propsSelector], (props) => props.productId as Id);

/**
 * Selects product price id from passed props
 * @param state
 * @param props
 */
export const productPriceIdPropSelector = createSelector([propsSelector], (props) => props.productPriceId as Id);

/**
 * Selects the purchaseOrder from the passed props
 * @param state
 * @param props
 */
export const purchaseOrderPropSelector = createSelector(
  [propsSelector],
  (props) => props.purchaseOrder as PurchaseOrderDTO | undefined
);

/**
 * Selects company id from the passed pruchaseOrder prop
 * @param state
 * @param props
 */
export const companyIdFromPurchaseOrderPropSelector = createSelector([purchaseOrderPropSelector], (purchaseOrder) => {
  return purchaseOrder?.companyRef?.id;
});
