import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';

import { OptifarmLogo, SidebarChangeMerchantCta, SidebarUserMenu } from 'components/molecules';

import { MerchantsRoutes } from 'enums/routes';

import { getSidebarNavigationLinks, getSidebarUserMenuActions } from 'helpers/navigation';
import { isUserRoleOperator } from 'helpers/roles';

import useAuth from 'hooks/useAuth';

import { SidebarGroup, SidebarNavigationItem } from './components';

import './Sidebar.scss';

/**
 * Global App Sidebar component.
 * @param {ComponentProps} props
 * @param {Function} props.onMerchantChange
 * @param {Merchant} [props.selectedMerchant]
 * @param {User} props.user
 * @return {StatelessComponent}
 */
const Sidebar = ({ onMerchantChange, selectedMerchant, user }) => {
  const history = useHistory();
  const { logout } = useAuth();

  const isMerchantSelected = Boolean(selectedMerchant);

  const isUserOperator = isUserRoleOperator(user.roles);
  const links = getSidebarNavigationLinks({ user, isMerchantSelected });
  const userActions = getSidebarUserMenuActions({
    history,
    onClearSelectedMerchant: onMerchantChange,
    onLogout: logout,
    user,
  });

  const handleMerchantChange = () => {
    if (isUserOperator) {
      onMerchantChange();
      history.push(MerchantsRoutes.ROOT);
    }
  };

  return (
    <aside className="sidebar">
      <div className="sidebar__top">
        <NavLink className="sidebar__logo" to="/">
          <OptifarmLogo />
        </NavLink>

        {Boolean(selectedMerchant) && (
          <SidebarGroup>
            <SidebarChangeMerchantCta
              isActionable={isUserOperator}
              merchant={selectedMerchant}
              onClick={handleMerchantChange}
            />
          </SidebarGroup>
        )}

        <SidebarGroup className="sidebar__navigation">
          {links.map((link) => (
            <SidebarNavigationItem key={link.path} {...link} />
          ))}
        </SidebarGroup>
      </div>

      <div className="sidebar__bottom">
        <SidebarUserMenu actions={userActions} user={user} />
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  onMerchantChange: PropTypes.func.isRequired,
  selectedMerchant: PropTypes.shape({}),
  user: PropTypes.shape({
    roles: PropTypes.array.isRequired,
  }).isRequired,
};

Sidebar.defaultProps = {
  selectedMerchant: undefined,
  user: undefined,
};

export default Sidebar;
