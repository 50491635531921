import PropTypes from 'prop-types';
import React from 'react';

import { Button, Image, Text } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { TextColor, TextSize } from 'enums/typography';
import { ButtonType, IconPosition } from 'enums/ui';
import { getMerchantFormattedAddress } from 'helpers/merchants';

import './MerchantCard.scss';

/**
 * @param {ComponentProps} props
 * @param {Merchant} props.merchant
 * @param {Function} props.onClick
 * @return {StatelessComponent}
 */
const MerchantCard = ({ merchant, onClick }) => {
  const address = getMerchantFormattedAddress(merchant);

  const handleClick = () => {
    onClick(merchant.id);
  };

  return (
    <div className="merchant-card">
      <div className="merchant-card__image">
        <Image alt={merchant.companyName} src={merchant.image} />
      </div>
      <div className="merchant-card__content">
        <Text.Bold color={TextColor.BLACK} numOfLines={1} size={TextSize.LEVEL_400}>
          {merchant.companyName}
        </Text.Bold>
        <Text.Regular numOfLines={1}>{address}</Text.Regular>
      </div>
      <div className="merchant-card__footer">
        <Button
          icon={IconNames.ARROW_RIGHT}
          iconPosition={IconPosition.RIGHT}
          onClick={handleClick}
          type={ButtonType.GHOST}
        >
          Izberi ponudnika
        </Button>
      </div>
    </div>
  );
};

MerchantCard.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.shape({}),
    companyName: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MerchantCard;
