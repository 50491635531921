/* eslint-disable react/jsx-key */
import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { useTable } from 'react-table';

import { Text } from 'components/atoms';

import { TableCellPaddingSize } from 'enums/ui';
import { getDefaultTableCellSize } from 'helpers/ui';
import { allValues } from 'helpers/utility';

import './Table.scss';

/**
 * Component rendering generic table using react-table
 * package
 * @param {ComponentProps} props
 * @param {string} [props.cellPaddingSize=TableCellPaddingSize.NORMAL]
 * @param {object[]} props.columns
 * @param {object[]} props.data
 * @param {boolean} props.hideHeader
 * @return {StatelessComponent}
 */
const Table = ({ cellPaddingSize, columns, data, hideHeader, onRowClick }) => {
  // Data passed to the useTable hook needs to be memoized
  // to avaid performance leaks
  const memoColumns = React.useMemo(() => columns, [columns]);
  const memoData = React.useMemo(() => data, [data]);

  const numOfColumns = React.useMemo(() => memoColumns.length, [memoColumns]);
  const defaultTableCellSize = getDefaultTableCellSize(numOfColumns);

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: memoColumns,
    data: memoData,
  });

  return (
    <div className="table">
      <table className="table__table" {...getTableProps()}>
        {!hideHeader && (
          <thead className="table__head">
            {headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr className="table__row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={classNames('table__cell', cellPaddingSize, column.size || defaultTableCellSize, {
                      [column.align]: Boolean(column.align),
                      [`${column.alignHeader}--important`]: Boolean(column.alignHeader),
                    })}
                    {...column.getHeaderProps()}
                  >
                    <Text.Regular numOfLines={1}>{column.render('Header')}</Text.Regular>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}

        <tbody className="table__body" {...getTableBodyProps()}>
          {rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr
                className={classNames('table__row table__row--body', { 'table__row--clickable': Boolean(onRowClick) })}
                onClick={() => handleRowClick(row)}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td
                    className={classNames('table__cell', cellPaddingSize, cell.column.size || defaultTableCellSize, {
                      [cell.column.align]: Boolean(cell.column.align),
                    })}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  cellPaddingSize: PropTypes.oneOf(allValues(TableCellPaddingSize)),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hideHeader: PropTypes.bool,
};

Table.defaultProps = {
  cellPaddingSize: TableCellPaddingSize.NORMAL,
  hideHeader: false,
};

export default Table;
