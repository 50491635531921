import { combineReducers } from 'redux';

import type { StatusAwarePurchaseOrdersState } from 'store/interfaces/purchaseOrdersState.types';

import activePurchaseOrdersAllIdsReducer from './activePurchaseOrdersAllIdsReducer';
import activePurchaseOrdersIsFetchingReducer from './activePurchaseOrdersIsFetchingReducer';
import activePurchaseOrdersPaginationReducer from './activePurchaseOrdersPaginationReducer';

/**
 * Active purchase orders reducers: Root reducer
 */
const activePurchaseOrdersReducer = combineReducers<StatusAwarePurchaseOrdersState>({
  allIds: activePurchaseOrdersAllIdsReducer,
  isFetching: activePurchaseOrdersIsFetchingReducer,
  pagination: activePurchaseOrdersPaginationReducer,
});

export default activePurchaseOrdersReducer;
