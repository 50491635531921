import { CategoriesEndpoints } from 'constants/endpoints';

import fetchApi from './common';

/**
 * GET categories api request
 * @param {Id} companyId
 * @param {number} [page=0]
 * @returns {Promise<Payload>}
 */
export const getCategories = async (companyId, page = 0) => {
  return fetchApi({ data: { page, perPage: 24 }, needsAuth: false, url: CategoriesEndpoints.FOR_COMPANY(companyId) });
};
