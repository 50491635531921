import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-final-form';

import { formInputPropType, formMetaPropType } from 'constants/props';

import { IconNames } from 'enums/icons';
import { InputSize } from 'enums/ui';

import { allValues } from 'helpers/utility';

import TextInput from '../TextInput';

/**
 * Component rendering Search input field
 * @param {Object} props
 * @param {FormInput} props.input
 * @param {Boolean} [props.isSearching=false]
 * @param {FormInputMeta} props.meta
 * @param {String} [props.placeholder]
 * @param {InputSize} [props.size=InputSize.NORMAL]
 * @param {Object} props.rest
 * @return {StatelessComponent}
 */
const SearchInput = ({ input, meta, placeholder, size, isSearching, ...rest }) => {
  const { submit } = useForm();
  const debouncedSubmit = _debounce(submit, 200);

  const inputIconName = input.value ? IconNames.CLOSE : IconNames.SEARCH;

  const handleResetSearchIconClick = React.useCallback(() => {
    // We fire input.onChange event with target.value set to empty string
    input.onChange({ target: { value: '' } });
    // We submit the form
    submit();
  }, [input, submit]);

  const iconClickHandler = React.useMemo(() => {
    if (input.value) {
      return handleResetSearchIconClick;
    }
  }, [handleResetSearchIconClick, input]);

  const handleOnChange = (evt) => {
    // Cancel any debounced handlers waiting to be executed
    debouncedSubmit.cancel();
    // fire input.onChange event
    input.onChange(evt);
    // submit the form with _debounce handler
    debouncedSubmit();
  };

  return (
    <TextInput
      icon={inputIconName}
      input={{ ...input, onChange: handleOnChange }}
      isLoading={isSearching}
      isTransparent={true}
      meta={meta}
      onIconClick={iconClickHandler}
      placeholder={placeholder}
      size={size}
      {...rest}
    />
  );
};

SearchInput.propTypes = {
  input: formInputPropType,
  isSearching: PropTypes.bool,
  meta: formMetaPropType,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(allValues(InputSize)),
};

SearchInput.defaultProps = {
  isSearching: false,
  placeholder: 'Vnesi iskalni niz...',
  size: InputSize.NORMAL,
};

export default SearchInput;
