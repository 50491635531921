import PropTypes from 'prop-types';
import React from 'react';
import { Popover } from 'react-tiny-popover';

import { Button } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { DropdownAlignment, DropdownPosition, IconPosition } from 'enums/ui';

import { allValues } from 'helpers/utility';

import { DropdownOptionGeneric, DropdownOptionWrapper } from './components';

import './DropdownButton.scss';

/**
 * Component rendering generic dropdown button
 * @param {Object} props
 * @param {DropdownAlignment} [props.align=DropdownAlignment.CENTER]
 * @param {Object} [props.buttonProps={}]
 * @param {ReactNode} props.children
 * @param {Boolean} [props.defaultIsOpen=false]
 * @param {Object[]} props.options
 * @param {DropdownPosition[]} [props.positions=[DropdownPosition.BOTTOM, DropdownPosition.LEFT, DropdownPosition.RIGHT, DropdownPosition.TOP]]
 * @param {StatelessComponent|FunctionComponent} [props.optionComponent]
 * @return {FunctionComponent}
 */
const DropdownButton = ({
  align,
  buttonProps,
  children,
  defaultIsOpen,
  options,
  optionComponent: OptionComponent,
  positions,
}) => {
  const [isOpen, setIsOpen] = React.useState(defaultIsOpen);

  const defaultDropdownButtonIcon = isOpen ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN;

  const toggleOpenState = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Popover
      align={align}
      containerClassName="dropdown-button"
      content={
        <div className="dropdown-button__menu">
          {options?.map(({ onClick, ...option }, i) => (
            <DropdownOptionWrapper key={option.title || i.toString()} onClick={onClick} onToggle={toggleOpenState}>
              <OptionComponent {...option} />
            </DropdownOptionWrapper>
          ))}
        </div>
      }
      isOpen={isOpen}
      onClickOutside={toggleOpenState}
      padding={8}
      positions={positions}
    >
      <Button
        icon={defaultDropdownButtonIcon}
        iconPosition={IconPosition.RIGHT}
        {...buttonProps}
        onClick={toggleOpenState}
      >
        {children}
      </Button>
    </Popover>
  );
};

DropdownButton.propTypes = {
  align: PropTypes.oneOf(allValues(DropdownAlignment)),
  buttonProps: PropTypes.shape({ ...Button.propTypes }),
  children: PropTypes.node,
  defaultIsOpen: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      title: PropTypes.string,
    })
  ).isRequired,
  optionComponent: PropTypes.elementType,
  positions: PropTypes.arrayOf(PropTypes.string),
};

DropdownButton.defaultProps = {
  align: DropdownAlignment.CENTER,
  buttonProps: {},
  defaultIsOpen: false,
  optionComponent: DropdownOptionGeneric,
  positions: [DropdownPosition.BOTTOM, DropdownPosition.LEFT, DropdownPosition.RIGHT, DropdownPosition.TOP],
};

export default DropdownButton;
