import { createSelector } from 'reselect';

import { productPriceForProductPriceIdPropSelector } from 'store/selectors/productPricesSelectors';

import { productIdFromProductPriceIdPropSelector } from './productsQueries';

/**
 * Returns updated Product Price Form initial values
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {Id} props.productPriceId
 * @returns {{ productPrice: ProductPrice, product: { id: Id } }}
 */
export const productPriceUpdateFormInitialValuesQuery = createSelector(
  [productPriceForProductPriceIdPropSelector, productIdFromProductPriceIdPropSelector],
  (productPrice, productId) => ({
    productPrice,
    productId,
  })
);
