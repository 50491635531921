import { createLastFetchedReducer } from 'helpers/redux';
import { fetchDeliveryDaysRoutine } from 'store/routines/deliveryDays';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * List of success cases
 */
const successCases = [fetchDeliveryDaysRoutine.SUCCESS];

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Delivery days reducer: Last fetched reducer
 */
const deliveryDaysLastFetchedReducer = createLastFetchedReducer({ successCases, resetCases });

export { successCases, resetCases };
export default deliveryDaysLastFetchedReducer;
