import * as Yup from 'yup';

import { isString } from './utility';

/**
 * Validates that passed value is a string & not empty string
 * @param {String} value
 * @returns {?String[]} Errors if not valid
 */
export const requiredStringValidator = (value) => {
  try {
    Yup.string().required().validateSync(value);
  } catch (err) {
    return err.errors;
  }
};

/**
 * Validates that passed value is a number
 * @param {Number} value
 * @returns {?String[]} Errors if not valid
 */
export const requiredNumberValidator = (value) => {
  try {
    Yup.number().required().validateSync(value);
  } catch (err) {
    return err.errors;
  }
};

/**
 * Validates that either string or number is passed
 * @param {String|Number} value
 * @returns {?String[]} Errors if not valid
 */
export const requiredValidator = (value) => {
  const validator = isString(value) ? requiredStringValidator : requiredNumberValidator;
  return validator(value);
};
