import { createLastFetchedReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';
import { fetchPurchaseOrdersNextPageRoutine, fetchPurchaseOrdersRoutine } from 'store/routines/purchaseOrders';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Map of cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([
  fetchPurchaseOrdersRoutine,
  fetchPurchaseOrdersNextPageRoutine,
]);

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Purchase orders reducers: Last fetched reducer
 */
const purchaseOrdersLastFetchedReducer = createLastFetchedReducer({ successCases, resetCases });

export { successCases, resetCases };
export default purchaseOrdersLastFetchedReducer;
