import { ApiServiceName, ApiVersion } from 'enums/api';

import { getJoinedPath } from './routing';

/**
 * Returns API url for given SVC service name and endpoint
 *
 * @param {Object} options
 * @param {string} [options.apiVersion=ApiVersion.V1]
 * @param {Boolean} [options.needsAuth]
 * @param {string} [options.serviceName=ApiServiceName.CORE]
 * @param {string} options.endpoint
 * @returns {string} Formatted API url
 */
export const getUrlForApiService = ({
  apiVersion = ApiVersion.V1,
  needsAuth,
  serviceName = ApiServiceName.CORE,
  endpoint,
}) => {
  const segments = [serviceName, apiVersion, endpoint];

  // If we don't need auth, we want to add /public/ segment between API version and the endpoint
  if (!needsAuth) {
    // We want to insert into zero-based position 2 (after apiVersion, before endpoint), delete zero elements, and add 'public' as the segment value
    segments.splice(2, 0, 'public');
  }

  // We join the path with all of the segments
  return getJoinedPath(...segments);
};
