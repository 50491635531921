import _noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, FixedFooter } from 'components/atoms';
import { TimelineLabels } from 'components/elements';

import { PageLayout } from 'components/modules/global';
import { DELIVERY_DAYS_OPTIONS } from 'constants/ui';

import { IconNames } from 'enums/icons';
import { SettingsRoutes } from 'enums/routes';
import { ButtonType, Intent } from 'enums/ui';

import { LocalDeliveryDaysTimeline, RegionalDeliveryDaysTimeline } from './components';

import './CompanyDeliveryDays.scss';

/**
 * Component rendering Company Delivery Days settings page
 * TODO: change _noop call in "Save" button to an actual action dispatch once SVC
 * supports patching delivery days
 *
 * @param {ComponentProps} props
 * @param {Function} props.onFetchDeliveryDays
 * @returns {FunctionComponent}
 */
const CompanyDeliveryDays = ({ onFetchDeliveryDays }) => {
  const history = useHistory();

  const onBackButtonClick = () => {
    history.push(SettingsRoutes.ROOT);
  };

  const breadcrumbs = [
    { path: SettingsRoutes.ROOT, name: 'Nastavitve ponudnika' },
    { path: SettingsRoutes.COMPANY_DELIVERY_DAYS, name: 'Datumi dostave in prevzema' },
  ];
  const labels = DELIVERY_DAYS_OPTIONS.map((deliveryDayOption) => deliveryDayOption.label);

  React.useEffect(() => {
    onFetchDeliveryDays();
  }, [onFetchDeliveryDays]);

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <TimelineLabels labels={labels} />

      <LocalDeliveryDaysTimeline />

      <RegionalDeliveryDaysTimeline />

      <FixedFooter contentClassName="company-delivery-days__footer">
        <Button icon={IconNames.ARROW_LEFT} intent={Intent.INFO} onClick={onBackButtonClick} type={ButtonType.LINK}>
          Nazaj na vse nastavitve
        </Button>

        <Button disabled={true} icon={IconNames.SAVE} onClick={_noop}>
          Shrani spremembe
        </Button>
      </FixedFooter>
    </PageLayout>
  );
};

CompanyDeliveryDays.propTypes = {
  onFetchDeliveryDays: PropTypes.func.isRequired,
};

export default CompanyDeliveryDays;
