import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Text, Tooltip } from 'components/atoms';

import { formInputPropType, formMetaPropType } from 'constants/props';

import { TextInputType } from 'enums/forms';
import { IconNames } from 'enums/icons';
import { TextColor, TextSize } from 'enums/typography';
import { IconPosition, InputSize } from 'enums/ui';

import { allValues } from 'helpers/utility';

import useClipboard from 'hooks/useClipboard';

import './TextInput.scss';

/**
 * Component rendering basic input field using React Final Form API
 * @param {Object} props
 * @param {String} [props.className]
 * @param {Boolean} [props.disabled=false]
 * @param {Boolean} [props.fullwidth=false]
 * @param {String} [props.htmlType=TextInputType.TEXT]
 * @param {FormInput} props.input
 * @param {IconNames} [props.icon]
 * @param {IconPosition} [props.iconPosition=IconPosittion.RIGHT]
 * @param {Boolean} [props.isCompact]
 * @param {Boolean} [props.isLoading=false]
 * @param {Boolean} [props.isTransparent=false]
 * @param {String} [props.label]
 * @param {String} props.name
 * @param {Function} [props.onBlur]
 * @param {Function} [props.onChange]
 * @param {Function} [props.onFocus]
 * @param {Function} [props.onIconClick]
 * @param {String} [props.placeholder]
 * @param {InputSize} [props.size=InputSize.NORMAL]
 * @param {Object} [props.tooltipProps]
 * @return {FunctionComponent}
 */
const TextInput = ({
  className,
  disabled,
  fullwidth,
  hasCopyAction,
  htmlType,
  icon,
  iconPosition,
  input: inputProp,
  isCompact,
  isLoading,
  isTransparent,
  label,
  meta,
  name,
  onBlur,
  onChange,
  onFocus,
  onIconClick,
  size,
  tooltipProps,
  ...rest
}) => {
  const input = inputProp ? inputProp : { name, onChange, onBlur, onFocus };
  const hasError = Boolean(meta.touched && meta.error);
  const hasIcon = Boolean(!!icon || disabled || isLoading);
  const successText = `Vrednost polja "${label || input.name}" je kopirana v odložišče`;

  const { ref } = useClipboard({ copyValue: input.value, successText });

  const iconName = React.useMemo(() => {
    if (disabled) {
      return IconNames.LOCK;
    }

    return isLoading ? IconNames.CIRCLE_NOTCH : icon;
  }, [disabled, icon, isLoading]);

  return (
    <div
      className={classNames('input', `input--size-${size}`, `input--type-${htmlType}`, {
        'input--has-error': hasError,
        'input--has-focus': meta.active,
        'input--has-icon': hasIcon,
        [`input--icon-position--${iconPosition}`]: hasIcon,
        'input--is-compact': isCompact,
        'input--is-disabled': disabled,
        'input--is-fullwidth': fullwidth,
        'input--is-transparent': isTransparent,
        [className]: Boolean(className),
      })}
    >
      {Boolean(label) && (
        <label className="input__label" htmlFor={input.name}>
          {Boolean(tooltipProps) && (
            <Tooltip {...tooltipProps}>
              <Icon icon={IconNames.INFO_CIRCLE} />
            </Tooltip>
          )}
          {label}
        </label>
      )}

      <div className="input--inner">
        <input {...input} {...rest} className="input__element" disabled={disabled} lang="sl" />

        {hasCopyAction && (
          <div className={classNames('input__icon', 'input__icon--copy', { 'input__icon--clickable': hasCopyAction })}>
            <Icon icon={IconNames.COPY} innerRef={ref} />
          </div>
        )}

        {hasIcon && (
          <div className={classNames('input__icon', { 'input__icon--clickable': Boolean(onIconClick) })}>
            <Icon icon={iconName} onClick={onIconClick} spinning={isLoading} />
          </div>
        )}
      </div>

      {hasError && (
        <div className="input__error">
          <Text.Regular color={TextColor.ERROR} size={TextSize.LEVEL_200}>
            {meta.error}
          </Text.Regular>
        </div>
      )}
    </div>
  );
};

TextInput.propTypes = {
  disabled: PropTypes.bool,
  fullwidth: PropTypes.bool,
  hasCopyAction: PropTypes.bool,
  htmlType: PropTypes.oneOf(allValues(TextInputType)),
  icon: PropTypes.oneOf(allValues(IconNames)),
  iconPosition: PropTypes.oneOf(allValues(IconPosition)),
  input: formInputPropType,
  name: PropTypes.string,
  value: PropTypes.string,
  isCompact: PropTypes.bool,
  isLoading: PropTypes.bool,
  isTransparent: PropTypes.bool,
  label: PropTypes.string,
  meta: formMetaPropType,
  onIconClick: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(allValues(InputSize)),
};

TextInput.defaultProps = {
  disabled: false,
  fullwidth: false,
  htmlType: TextInputType.TEXT,
  icon: undefined,
  iconPosition: IconPosition.RIGHT,
  isCompact: undefined,
  isLoading: false,
  isTransparent: false,
  label: undefined,
  meta: {},
  placeholder: undefined,
  size: InputSize.NORMAL,
};

export default TextInput;
