import React from 'react';

import {
  OrderDetailsInfoCustomer,
  OrderDetailsInfoOrderInfo,
  OrderDetailsInfoOrderValue,
  OrderDetailsInfoPayment,
  OrderDetailsInfoPickup,
} from './components';
import type { OrderDetailsInfoProps } from './OrderDetailsInfo.types';

/**
 * Component rendering the info section of the Purchase Order details page
 * @param props - Component props
 */
const OrderDetailsInfo: React.VFC<OrderDetailsInfoProps> = ({ purchaseOrder }) => (
  <div className="order-info">
    <OrderDetailsInfoOrderInfo purchaseOrder={purchaseOrder} />
    <OrderDetailsInfoCustomer purchaseOrder={purchaseOrder} />
    <OrderDetailsInfoPickup purchaseOrder={purchaseOrder} />
    <OrderDetailsInfoPayment purchaseOrder={purchaseOrder} />
    <OrderDetailsInfoOrderValue purchaseOrder={purchaseOrder} />
  </div>
);

export default OrderDetailsInfo;
