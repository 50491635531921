import PropTypes from 'prop-types';
import React from 'react';

import { Grid } from 'components/atoms';
import { CollapsibleCard, DataEntry } from 'components/elements';

import { IconNames } from 'enums/icons';
import { GridColumnCount, GridGapSize } from 'enums/ui';

import { getFormattedDateString } from 'helpers/datetime';

import { ORDER_STATUS, ORDER_STATUS_LABELS } from 'utils/constants';

/**
 * Component rendering Purchase Order order info details
 * @param {Object} props
 * @param {PurchaseOrderDTO} props.purchaseOrder
 * @returns {StatelessComponent}
 */
const OrderDetailsInfoOrderInfo = ({ purchaseOrder }) => (
  <CollapsibleCard className="margin-bottom--12" title="Podatki naročila">
    <Grid gap={GridGapSize.MEDIUM} numOfCols={GridColumnCount.TWO}>
      <DataEntry
        icon={IconNames.CALENDAR}
        label="Datum naročila"
        value={getFormattedDateString(purchaseOrder.createdDateTime)}
      />
      <DataEntry label="Stanje naročila" value={ORDER_STATUS_LABELS[purchaseOrder.status]} />
    </Grid>
  </CollapsibleCard>
);

OrderDetailsInfoOrderInfo.propTypes = {
  purchaseOrder: PropTypes.shape({
    created: PropTypes.string,
    status: PropTypes.oneOf(Object.values(ORDER_STATUS)),
    comment: PropTypes.string,
  }).isRequired,
};

export default OrderDetailsInfoOrderInfo;
