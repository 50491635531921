import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './SidebarGroup.scss';

/**
 * Layout container component for single Sidebar Group
 * @param {ComponentProps} props
 * @param {ReactNode} props.children
 * @param {string} [props.className]
 * @return {StatelessComponent}
 */
const SidebarGroup = ({ children, className }) => (
  <div className={classNames('sidebar-group', { [className]: Boolean(className) })}>{children}</div>
);

SidebarGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default SidebarGroup;
