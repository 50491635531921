import { PostNameRegions } from 'enums/postcodes';

/**
 * Post regions regex matchers
 * @constant
 * @type {object.<string, regex>}
 */
export const POSTCODES_MATCHERS = {
  [PostNameRegions.OSREDNJESLOVENSKA]: '^1',
  [PostNameRegions.PRIMORSKO_NOTRANJSKA]: '^(13|62[0-3])',
  [PostNameRegions.PODRAVSKA]: '^(2[0-2]|2[4-9])',
  [PostNameRegions.KOROSKA]: '^23',
  [PostNameRegions.SAVINJSKA]: '^3',
  [PostNameRegions.GORENJSKA]: '^4',
  [PostNameRegions.GORISKA]: '^5',
  [PostNameRegions.OBALNO_KRASKA]: '^(62[4-9])|60',
  [PostNameRegions.DOLENJSKA]: '^8',
  [PostNameRegions.POMURSKA]: '^9',
};
