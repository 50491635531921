import React from 'react';

import { PageLayout } from 'components/modules/global';
import { SettingsPagesGrid } from 'components/modules/settings';
import { SettingsRoutes } from 'enums/routes';

/**
 * Component rendering Settings root page
 * @return {StatelessComponent}
 */
const SettingsRoot = () => (
  <PageLayout breadcrumbs={[{ path: SettingsRoutes.ROOT, name: 'Nastavitve ponudnika' }]}>
    <SettingsPagesGrid />
  </PageLayout>
);

export default SettingsRoot;
