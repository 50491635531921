import PropTypes from 'prop-types';
import React from 'react';

import { StatusTag } from 'components/elements';

import { getProductStatusIndicatorIntent, getProductStatusIndicatorTitleText } from 'helpers/ui';

/**
 * Component rendering Product Status tag
 * @param {Object} props
 * @param {Boolean} props.isActive
 * @param {Boolean} props.isLoading
 * @returns {StatelessComponent}
 */
const ProductStatusTag = ({ isActive, isLoading }) => {
  const intent = getProductStatusIndicatorIntent(isActive, isLoading);
  const text = getProductStatusIndicatorTitleText(isActive, isLoading);

  return <StatusTag intent={intent} text={text} />;
};

ProductStatusTag.propTypes = {
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default ProductStatusTag;
