import PropTypes from 'prop-types';
import React from 'react';

import { FixedFooter } from 'components/atoms';

import { PurchaseOrderStatus } from 'enums/purchaseOrders';
import { allValues } from 'helpers/utility';
import { FINALIZED_ORDER_STATUSES } from 'utils/constants';

import { OrderCancellationButton, OrderConfirmationButtons, OrderReclamationButton } from './components';
import './OrderDetailsFooter.scss';

/**
 * Component rendering the content of Purchase Order Details
 * footer element
 * @param {ComponentProps} props
 * @param {PurchaseOrder} props.order
 * @returns {StatelessComponent}
 */
const OrderDetailsFooter = ({ order }) => {
  const shouldShowFooterButtons = !FINALIZED_ORDER_STATUSES.includes(order.status);

  return (
    <FixedFooter contentClassName="order-details-footer">
      <OrderCancellationButton orderId={order.id} visible={shouldShowFooterButtons} />

      <OrderReclamationButton />

      <div className="spacer" />

      <OrderConfirmationButtons orderId={order.id} visible={shouldShowFooterButtons} />
    </FixedFooter>
  );
};

OrderDetailsFooter.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.oneOf(allValues(PurchaseOrderStatus)),
  }).isRequired,
};

export default OrderDetailsFooter;
