import PropTypes from 'prop-types';
import React from 'react';

import './TableBodyEmptyStateBase.scss';

/**
 * Base component, wrapping different empty states of the pageable table body
 * @param {Object} props
 * @param {ReactNode} props.children
 * @returns {StatelessComponent}
 */
const TableBodyEmptyStateBase = ({ children }) => <div className="table-body-empty-state-base">{children}</div>;

TableBodyEmptyStateBase.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableBodyEmptyStateBase;
