/**
 * Redux action for fetching merchants
 * @constant
 */
export const FETCH_MERCHANTS = '@merchants/FETCH_MERCHANTS';

/**
 * Redux action for fetching merchants' next page
 * @constant
 */
export const FETCH_MERCHANTS_NEXT_PAGE = '@merchants/FETCH_MERCHANTS_NEXT_PAGE';

/**
 * Redux action for fetching a single merchant
 * @constant
 */
export const FETCH_SINGLE_MERCHANT = '@merchants/FETCH_SINGLE_MERCHANT';

/**
 * Redux action for update merchant's company info
 * @constant
 */
export const PUT_MERCHANT_COMPANY_INFO = '@merchants/PUT_MERCHANT_COMPANY_INFO';
