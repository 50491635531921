import { createByIdReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import type { CompanyDTO } from 'interfaces/companies';

import {
  fetchMerchantsNextPageRoutine,
  fetchMerchantsRoutine,
  fetchSingleMerchantRoutine,
  putMerchantCompanyInfoRoutine,
} from 'store/routines/merchants';

/**
 * Map of cases from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([
  fetchMerchantsRoutine,
  fetchMerchantsNextPageRoutine,
  fetchSingleMerchantRoutine,
  putMerchantCompanyInfoRoutine,
]);

/**
 * Merchants reducers: by Id reducer
 */
const merchantsByIdReducer = createByIdReducer<CompanyDTO>({ successCases });

export default merchantsByIdReducer;
