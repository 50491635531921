import { createByIdReducer } from 'helpers/redux';

import type { CategoryDTO } from 'interfaces/categories';

import { fetchCategoriesRoutine } from 'store/routines/categories';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Categories by Id reducer
 */
const categoriesByIdReducer = createByIdReducer<CategoryDTO>({
  successCases: [fetchCategoriesRoutine.SUCCESS],
  resetCases: [REMOVE_SELECTED_MERCHANT],
});

export default categoriesByIdReducer;
