import PropTypes from 'prop-types';
import React from 'react';

import { Table } from 'components/elements';

import { productPriceDtoProp } from 'constants/props';

import { getProductPriceTableColumns } from './data';

/**
 * Component rendering Product Price Table
 * @param {Object} props
 * @param {Function} props.handleSubmit
 * @param {Boolean} props.isSubmitting
 * @param {Boolean} props.pristine
 * @param {ProductPrice} props.productPrice
 * @param {UnitOfMeasure[]} props.unitOfMeasures
 * @param {Boolean} props.valid
 * @param {Vat[]} props.vats
 * @returns {StatelessComponent}
 */
const ProductPriceTable = ({ handleSubmit, isSubmitting, pristine, productPrice, unitOfMeasures, valid, vats }) => {
  const columns = getProductPriceTableColumns({
    isSubmitting,
    isUpdateDisabled: pristine || !valid,
    onUpdatePrice: handleSubmit,
    unitOfMeasures,
    vats,
  });

  return <Table columns={columns} data={[productPrice]} />;
};

ProductPriceTable.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  pristine: PropTypes.bool,
  productPrice: productPriceDtoProp,
  valid: PropTypes.bool,
};

export default ProductPriceTable;
