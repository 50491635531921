import { createAllIdsReducer } from 'helpers/redux';

import { fetchProductsRoutine } from 'store/routines/products';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Success cases
 */
const successCases = [{ type: fetchProductsRoutine.SUCCESS, shouldReplaceState: true }];

/**
 * Reset state cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Products reducers: All Ids reducer
 */
const productsAllIdsReducer = createAllIdsReducer({ successCases, resetCases });

export default productsAllIdsReducer;
