import React from 'react';

import { Button, Tooltip } from 'components/atoms';
import { TooltipContentInDevelopment } from 'components/elements/tooltipContents';

import { ButtonType, Intent } from 'enums/ui';

/**
 * Component rendering Purchase Order Details reclamation button
 * @return {StatelessComponent}
 */
const OrderReclamationButton = () => (
  <Tooltip content={<TooltipContentInDevelopment />}>
    <Button className="order-details-footer__item" disabled={true} intent={Intent.INFO} type={ButtonType.LINK}>
      Reklamacija naročila
    </Button>
  </Tooltip>
);

export default OrderReclamationButton;
