import { createByIdReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';
import { PurchaseOrderDTO } from 'interfaces/purchaseOrders';
import {
  fetchPurchaseOrdersNextPageRoutine,
  fetchPurchaseOrdersRoutine,
  fetchSinglePurchaseOrderRoutine,
  patchPurchaseOrderStatusRoutine,
} from 'store/routines/purchaseOrders';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Map of cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([
  fetchPurchaseOrdersRoutine,
  fetchPurchaseOrdersNextPageRoutine,
  fetchSinglePurchaseOrderRoutine,
  patchPurchaseOrderStatusRoutine,
]);

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Purchase orders reducers: By id reducer
 */
const purchaseOrdersByIdReducer = createByIdReducer<PurchaseOrderDTO>({ successCases, resetCases });

export { successCases, resetCases };
export default purchaseOrdersByIdReducer;
