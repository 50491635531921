import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { patchPurchaseOrderStatusRoutine } from 'store/routines/purchaseOrders';
import {
  purchaseOrderForOrderIdPropSelector,
  purchaseOrdersIsFetchingSelector,
} from 'store/selectors/purchaseOrdersSelectors';

import OrderConfirmationButtons from './OrderConfirmationButtons';

export const mapStateToProps = createStructuredSelector({
  purchaseOrder: purchaseOrderForOrderIdPropSelector,
  isLoading: purchaseOrdersIsFetchingSelector,
});

export const mapDispatchToProps = {
  updatePurchaseOrderStatus: patchPurchaseOrderStatusRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationButtons);
