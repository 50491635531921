import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { Text } from 'components/atoms';

import { CheckboxInput } from '../CheckboxInput';

import './CheckboxGroupInput.scss';

/**
 * Component rendering a group of CheckboxInputs
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {boolean} [props.disabled=false]
 * @param {string} [props.label]
 * @param {object[]} props.options
 * @return {StatelessComponent}
 */
const CheckboxGroupInput = ({ className, disabled, label, name, options }) => (
  <div className={classNames('checkbox-group-input', { [className]: Boolean(className) })}>
    {Boolean(label) && <Text.Regular className="checkbox-group-input__label">{label}</Text.Regular>}

    <div className="checkbox-group-input__inputs">
      {options.map((option) => (
        <Field
          key={option.value}
          component={CheckboxInput}
          disabled={disabled}
          isMultiple={true}
          name={name}
          type="checkbox"
          {...option}
        />
      ))}
    </div>
  </div>
);

CheckboxGroupInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ).isRequired,
};

CheckboxGroupInput.defaultProps = {
  disabled: false,
};

export default CheckboxGroupInput;
