import { hasZeroLength } from 'helpers/utility';

/**
 * Returns true if the passed data length is 0 and the current loading state
 * is inactive
 * @param {Object} options
 * @param {*[]} options.data
 * @param {Boolean} options.isLoading
 * @returns {Boolean}
 */
export const hasEmptyResults = ({ data, isLoading }) => {
  return !isLoading && hasZeroLength(data);
};

/**
 * Returns true if the passed data length is 0, is currently not loading and has
 * active search query
 * @param {Object} options
 * @param {*[]} options.data
 * @param {Boolean} options.isLoading
 * @param {Object} options.porams
 * @param {String} [options.params.searchKey]
 * @returns {Boolean}
 */
export const hasEmptySearchResults = ({ params, ...rest }) => {
  return Boolean(params.searchKey) && hasEmptyResults(rest);
};
