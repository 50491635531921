import {
  createStatusAwareIsFetchingReducer,
  gatherReducerCasesFromRoutines,
  isPurchaseOrdersActionRelatedToActiveOrders,
} from 'helpers/redux';

import { fetchPurchaseOrdersRoutine } from 'store/routines/purchaseOrders';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Map of ccases gathered from routines
 */
const { requestCases, successCases, failureCases } = gatherReducerCasesFromRoutines([fetchPurchaseOrdersRoutine]);

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Active Purchase Orders reducers: Is Fetching Reducer
 */
const activePurchaseOrdersIsFetchingReducer = createStatusAwareIsFetchingReducer({
  endCases: [...successCases, ...failureCases],
  requestCases,
  resetCases,
  statusCheckFunction: isPurchaseOrdersActionRelatedToActiveOrders,
});

export default activePurchaseOrdersIsFetchingReducer;
