import { PurchaseOrderStatus, PurchaseOrderType } from 'enums/purchaseOrders';

/**
 * @constant
 */
export const purchaseOrderTypeForPurchaseOrderStatusMap = Object.freeze({
  [PurchaseOrderStatus.CANCELLED]: PurchaseOrderType.FINALIZED,
  [PurchaseOrderStatus.COMPLETED]: PurchaseOrderType.FINALIZED,
  [PurchaseOrderStatus.ACKNOWLEDGED]: PurchaseOrderType.ACTIVE,
  [PurchaseOrderStatus.IN_PROGRESS]: PurchaseOrderType.ACTIVE,
  [PurchaseOrderStatus.PACKED]: PurchaseOrderType.ACTIVE,
});

/**
 * List of dependecies for each purchase order status
 * @constant
 */
export const purchaseOrderStatusDependenciesMap = Object.freeze({
  [PurchaseOrderStatus.CANCELLED]: [],
  [PurchaseOrderStatus.IN_PROGRESS]: [],
  [PurchaseOrderStatus.PACKED]: [PurchaseOrderStatus.IN_PROGRESS],
  [PurchaseOrderStatus.COMPLETED]: [PurchaseOrderStatus.IN_PROGRESS, PurchaseOrderStatus.PACKED],
});
