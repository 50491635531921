import { deliveryTypeToLabelMap } from 'constants/delivery';

import { DeliveryType } from 'enums/delivery';

import { allValues } from 'helpers/utility';

/**
 * Returns prepared Delivery Type input options
 * @returns {object[]}
 */
export const getDeliveryTypeInputOptions = () => {
  return allValues(DeliveryType).map((deliveryType) => ({
    value: deliveryType,
    label: deliveryTypeToLabelMap[deliveryType],
  }));
};
