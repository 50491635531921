import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { ButtonType, Intent } from 'enums/ui';
import { allValues } from 'helpers/utility';

/**
 * Component rendering actions items as tooltip content
 * @param {ComponentProps} props
 * @param {object[]} props.actions
 * @param {Function} props.onCloseMenu
 * @return {StatelessComponent}
 */
const TooltipWithActions = ({ actions, onCloseMenu }) => (
  <React.Fragment>
    {actions.map((action) => {
      const handleActionClick = () => {
        if (!action.disabled) {
          action.callback(action.params);
          onCloseMenu();
        }
      };

      return (
        <Button
          key={action.name}
          className="user-menu-dropdown-content"
          disabled={action.disabled}
          icon={action.icon}
          intent={action.intent || Intent.INFO}
          onClick={handleActionClick}
          type={ButtonType.LINK}
        >
          {action.name}
        </Button>
      );
    })}
  </React.Fragment>
);

TooltipWithActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func.isRequired,
      icon: PropTypes.oneOf(allValues(IconNames)),
      name: PropTypes.string.isRequired,
      intent: PropTypes.oneOf(allValues(Intent)),
    })
  ).isRequired,
  onCloseMenu: PropTypes.func.isRequired,
};

export default TooltipWithActions;
