import { DeliveryType } from 'enums/delivery';

/**
 * Map of delivery types with corresponding labels
 * @constant
 */
export const deliveryTypeToLabelMap = Object.freeze({
  [DeliveryType.LOCAL]: 'Lokalna dostava',
  [DeliveryType.REGIONAL]: 'Regionalna dostava',
});
