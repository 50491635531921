export enum PurchaseOrderType {
  ALL = 'all',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  FINALIZED = 'finalized',
  LATEST_FINALIZED = 'latest-finalized',
}

export enum PurchaseOrderStatus {
  CANCELLED = 'CANCELLED',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  PENDING = 'PENDING',
  HELD = 'HELD',
  IN_PROGRESS = 'IN_PROGRESS',
  PACKED = 'PACKED',
  ASSESSING_CANCELLATION = 'ASSESSING_CANCELLATION',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PARTIAL = 'PARTIAL',
  REJECTED = 'REJECTED',
}

export enum PurchaseOrderPickupStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  PREPARED = 'PREPARED',
  PICKED_UP = 'PICKED_UP',
}
