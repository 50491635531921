import { createByIdReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';
import { UnitOfMeasureDTO } from 'interfaces/unitOfMeasures';

import { fetchUnitOfMeasurementsRoutine } from 'store/routines/unitOfMeasurements';

/**
 * Success cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([fetchUnitOfMeasurementsRoutine]);

/**
 * Unit of measurements by id reducer
 */
const unitOfMeasurementsByIdReducer = createByIdReducer<UnitOfMeasureDTO>({ successCases });

export { successCases };
export default unitOfMeasurementsByIdReducer;
