import { createLastFetchedReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchProductsRoutine } from 'store/routines/products';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Success cases
 */
const { successCases } = gatherReducerCasesFromRoutines([fetchProductsRoutine]);

/**
 * Reset state cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Products reducers: Last fetched reducer
 */
const lastFetchedReducer = createLastFetchedReducer({ successCases, resetCases });

export { successCases, resetCases };
export default lastFetchedReducer;
