import { createIsSubmittingReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { patchProductPriceRoutine } from 'store/routines/productPrices';

/**
 * Map of cases gathered from routines
 */
const { successCases, failureCases, requestCases } = gatherReducerCasesFromRoutines([patchProductPriceRoutine]);

/**
 * Product prices reducers: isSubmitting reducer
 */
const isSubmittingReducer = createIsSubmittingReducer({ endCases: [...successCases, ...failureCases], requestCases });

export { successCases, failureCases, requestCases };
export default isSubmittingReducer;
