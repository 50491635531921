import React from 'react';
import { Field, useField } from 'react-final-form';

import { Section, Text } from 'components/atoms';
import { Hint } from 'components/elements';
import { CheckboxGroupInput, SwitchInput } from 'components/elements/inputs';

import { DELIVERY_DAYS_OPTIONS } from 'constants/ui';
import { DeliveryEntryFormFields } from 'enums/formFields';
import { TextSize } from 'enums/typography';
import { Intent } from 'enums/ui';

/**
 * Component rendering Delivery Days inputs in
 * AddDeliveryEntrySidesheet component
 *
 * @return {StatelessComponent}
 */
const AddDeliveryEntryDeliveryDays = () => {
  const {
    input: { value: shouldApplyToEveryDay },
  } = useField(DeliveryEntryFormFields.IS_EVERY_DAY_DELIVERY);

  return (
    <Section
      className="add-delivery-entry__delivery-days"
      title="Izberite poštne številke, ki jih želite podpreti s tem vnosom"
      titleProps={{ size: TextSize.LEVEL_350 }}
    >
      <Field
        component={SwitchInput}
        label="Vnos velja za vse dni v tednu?"
        name={DeliveryEntryFormFields.IS_EVERY_DAY_DELIVERY}
      />

      {shouldApplyToEveryDay && (
        <Hint className="margin-top--16" intent={Intent.INFO} title="Ta vnos bo veljal za vse dni v tednu">
          <Text.Regular>
            Izbrane poštne številke bodo na časovnico dodane za vse dni v tednu (vključno s soboto in nedeljo). V
            kolikor tega ne želite, odznačite to možnost in izberite posamezne dni v tednu.
          </Text.Regular>
        </Hint>
      )}

      {!shouldApplyToEveryDay && (
        <CheckboxGroupInput
          className="margin-top--16"
          name={DeliveryEntryFormFields.DELIVERY_DAYS}
          options={DELIVERY_DAYS_OPTIONS}
        />
      )}
    </Section>
  );
};

export default AddDeliveryEntryDeliveryDays;
