import { createIsFetchingReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchProductsRoutine, fetchSingleProductRoutine } from 'store/routines/products';

/**
 * Map of cases gathered from routines
 */
const { successCases, failureCases, requestCases } = gatherReducerCasesFromRoutines([
  fetchProductsRoutine,
  fetchSingleProductRoutine,
]);

/**
 * Reducer containing info about product entity fetching state
 */
const isFetchingReducer = createIsFetchingReducer({
  endCases: [...successCases, ...failureCases],
  requestCases,
});

export { successCases, failureCases, requestCases };
export default isFetchingReducer;
