import PropTypes from 'prop-types';
import React from 'react';

import { ActionbarBreadcrumbs, ActionbarMenu, ActionbarShareButton } from 'components/molecules/actionbar';

import { IconNames } from 'enums/icons';

import { allValues, hasLength } from 'helpers/utility';

import './Actionbar.scss';

/**
 * Component rendering main page actionbar
 * @param {Object} props
 * @param {ReactNode} [props.addons]
 * @param {Object[]} props.breadcrumbs
 * @param {Boolean} [props.hideShareButton=false]
 * @param {Object[]} props.menuActions
 * @return {StatelessComponent}
 */
const Actionbar = ({ addons, breadcrumbs, hideShareButton, menuActions }) => (
  <div className="actionbar">
    <div className="actionbar__left">
      <ActionbarBreadcrumbs paths={breadcrumbs} />
    </div>

    <div className="actionbar__right">
      <div className="actionbar__addons">{addons}</div>
      {!hideShareButton && <ActionbarShareButton />}
      {hasLength(menuActions) && <ActionbarMenu options={menuActions} />}
    </div>
  </div>
);

Actionbar.propTypes = {
  addons: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({ path: PropTypes.string.isRequired, name: PropTypes.string.isRequired })
  ).isRequired,
  hideShareButton: PropTypes.bool,
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.oneOf(allValues(IconNames)),
      onClick: PropTypes.func.isRequired,
    })
  ),
};

Actionbar.defaultProps = {
  hideShareButton: false,
};

export default Actionbar;
