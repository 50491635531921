import swal from '@sweetalert/with-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Icon, Text } from 'components/atoms';

import { iconNameForIntentMap } from 'constants/icons';

import { IconNames } from 'enums/icons';
import { TextColor, TextSize } from 'enums/typography';
import { ButtonSize, ButtonType, Intent } from 'enums/ui';

import { allValues } from 'helpers/utility';

import './AlertWrapper.scss';

/**
 * Component rendering generic wrapper for SweetAlert component
 * @param {ComponentProps} props
 * @param {object} props.confirmButtonProps
 * @param {boolean} [props.hasCancelButton=true]
 * @param {Intent} [props.intent=Intent.INFO]
 * @param {boolean} [props.showTitleIcon=false]
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const AlertWrapper = ({ children, className, confirmButtonProps, hasCancelButton, intent, showTitleIcon, title }) => {
  const { text, onClick, ...buttonProps } = confirmButtonProps;

  const closeAlert = () => {
    swal.close();
  };

  const handleConfirm = () => {
    if (onClick) {
      onClick();
    }

    swal.close();
  };

  return (
    <div className={classNames(`alert-wrapper alert-wrapper--${intent}`, { [className]: Boolean(className) })}>
      <div className="alert-wrapper__close-button" onClick={closeAlert}>
        <Icon icon={IconNames.CLOSE} />
      </div>
      <div className="alert-wrapper__title">
        {showTitleIcon && <Icon icon={iconNameForIntentMap[intent]} />}

        <Text.Bold color={TextColor.BLACK} size={TextSize.LEVEL_300}>
          {title}
        </Text.Bold>
      </div>

      <div className="alert-wrapper__content">{children}</div>

      <div className="alert-wrapper__footer">
        {hasCancelButton && (
          <Button intent={Intent.INFO} onClick={closeAlert} size={ButtonSize.LARGE} type={ButtonType.LINK}>
            Prekliči
          </Button>
        )}

        <Button
          intent={intent}
          onClick={handleConfirm}
          size={ButtonSize.LARGE}
          type={ButtonType.GHOST}
          {...buttonProps}
        >
          {text}
        </Button>
      </div>
    </div>
  );
};

AlertWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  confirmButtonProps: PropTypes.shape({
    onClick: PropTypes.func,
    children: PropTypes.node,
  }).isRequired,
  hasCancelButton: PropTypes.bool,
  intent: PropTypes.oneOf(allValues(Intent)),
  showTitleIcon: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

AlertWrapper.defaultProps = {
  hasCancelButton: true,
  intent: Intent.INFO,
  showTitleIcon: false,
};

export default AlertWrapper;
