import { all, call, put, select } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import _flattenDeep from 'lodash/flattenDeep';
import _uniq from 'lodash/uniq';

import { CommonErrorMessages } from 'constants/errors';

import { MerchantsRoutes } from 'enums/routes';

import { getEntitiesFromApiResponse } from 'helpers/redux';
import { hasZeroLength } from 'helpers/utility';

import { uiSelectedMerchantIdSelector } from 'store/selectors/uiSelectors';

import { entityRefToRoutineMap, entityRefToSelectorMap } from './constants';

/**
 * Returns the selected merchant ID if exists or dispatches failure action and
 * redirect to select merchant page
 * @param {ReduxActionCreator} failureAction
 * @returns {IterableIterator<Id>}
 */
export function* getSelectedMerchantIdOrRedirect(failureAction) {
  const companyId = yield select(uiSelectedMerchantIdSelector);

  // If companyId is not set for some reason, trigger failure, redirect to select
  // merchant screen and exit the saga early
  if (!companyId) {
    yield all([put(failureAction(CommonErrorMessages.NO_COMPANY_ID)), put(push(MerchantsRoutes.ROOT))]);
    return;
  }

  return companyId;
}

/**
 * Given an API response, entity name and a routine it triggers that routine
 * if the included entity is not already stored in the state
 * @param {Object} response
 * @param {EntityRef[]} entities
 * @param {Object} [mappers]
 */
export function* fetchIncludedEntitiesIfMissing(response, entities, mappers = {}) {
  const parentEntities = yield call(getEntitiesFromApiResponse, response?.data);

  for (const entity of entities) {
    const defaultMapper = (e) => e[entity]?.extId;
    const mapper = mappers[entity] || defaultMapper;
    const ids = _flattenDeep(parentEntities.map(mapper)).filter(Boolean);
    const routine = entityRefToRoutineMap[entity];
    const selector = entityRefToSelectorMap[entity];

    // If we don't have a routine and a selector defined for this
    // entity ref type, we want to skip any further logic in the
    // current loop
    if (!routine || !selector || hasZeroLength(ids)) {
      continue;
    }

    // We grab the existing entities stored in the state
    const existingEntitiesById = yield select(selector);

    // We loop over all of the (unique) ids
    for (const id of _uniq(ids)) {
      // For each entity id, we check if the entity already exist. If it does, we don't want
      // to trigger another API request. If it doesn't, we want to dispatch an action to trigger
      // API fetch request
      if (!existingEntitiesById?.[id]) {
        yield put(routine.trigger({ id }));
      }
    }
  }
}
