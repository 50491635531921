import React from 'react';

import { PageLayout } from 'components/modules/global';
import { PurchaseOrdersTabs } from 'components/modules/purchaseOrders';

import { PurchaseOrdersRoutes } from 'enums/routes';

/**
 * Component rendering PurchaseOrders tabs. Used as a root
 * entry point for purchase orders flow.
 * @returns {StatelessComponent}
 */
const PurchaseOrdersRoot = () => {
  const breadcrumbs = [{ path: PurchaseOrdersRoutes.ROOT, name: 'Pregled naročil' }];

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <PurchaseOrdersTabs />
    </PageLayout>
  );
};

export default PurchaseOrdersRoot;
