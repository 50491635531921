import PropTypes from 'prop-types';
import React from 'react';

import { LoadingIndicator, Text } from 'components/atoms';

import { getPaginationCurrentPageUIValue } from 'helpers/pagination';

/**
 * Component rendering the content part of the TablePaginationActions component
 * @see {TablePaginationActions}
 *
 * @param {Object} props
 * @param {Boolean} [props.hasError=false]
 * @param {Boolean} [props.isLoading=false]
 * @param {ApiPaginationState} props.pagination
 * @return {StatelessComponent}
 */
const TablePaginationActionsContent = ({ hasError, isLoading, pagination }) => {
  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (hasError) {
    return <Text.Bold className="table-pagination-actions__content__error">Napaka</Text.Bold>;
  }

  return (
    <>
      <Text.Bold>{getPaginationCurrentPageUIValue(pagination)}</Text.Bold>
      <Text.Regular className="table-pagination-actions__content__page-divider"> / </Text.Regular>
      <Text.Regular>{pagination.totalPages}</Text.Regular>
    </>
  );
};

TablePaginationActionsContent.propTypes = {
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
};

TablePaginationActionsContent.defaultProps = {
  hasError: false,
  isLoading: false,
};

export default TablePaginationActionsContent;
