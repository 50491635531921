import { createSelector } from 'reselect';

import { getMappedStateIds } from 'helpers/redux';

import type { ReduxState } from 'store/interfaces';

/**
 * Selects root categories state
 * @param state
 */
const getState = (state: ReduxState) => state.categories;

/**
 * Selects allIds property of the categories state
 * @param state
 */
export const categoriesAllIdsSelector = createSelector([getState], (state) => state.allIds);

/**
 * Selects the byId property of the categories state
 * @param state
 */
export const categoriesByIdSelector = createSelector([getState], (state) => state.byId);

/**
 * Selects all categories stored in the state
 * @param state
 */
export const allCategoriesSelector = createSelector(
  [categoriesAllIdsSelector, categoriesByIdSelector],
  getMappedStateIds
);

/**
 * Selects the isFetching property of the categories state
 * @param state
 */
export const categoriesIsFetchingSelector = createSelector([getState], (state) => state.isFetching);

/**
 * Selects the error property of the categories state
 * @param state
 */
export const categoriesErrorSelector = createSelector([getState], (state) => state.error);

/**
 * Selects the lastFetched property of the categories state
 * @param state
 */
export const categoriesLastFetchedSelector = createSelector([getState], (state) => state.lastFetched);
