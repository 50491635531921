import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useField } from 'react-final-form';

import { Icon, Text } from 'components/atoms';

import { formInputPropType } from 'constants/props';
import { IconNames } from 'enums/icons';
import { isEqual, isNotEqual } from 'helpers/utility';

import './CheckboxInput.scss';

/**
 * Component rendering Checkbox input field using Unform API
 * @param {ComponentProps} props
 * @param {Boolean} [props.disabled=false]
 * @param {FormInput} props.input
 * @param {Boolean} [props.isMultiple=false]
 * @param {string} [props.label]
 * @return {StatelessComponent}
 */
const CheckboxInput = ({ disabled, input, isMultiple, label }) => {
  const {
    input: { value: currentFieldValue },
  } = useField(input.name);

  const inputValue = isEqual(input.type, 'checkbox') ? input.checked : input.value;
  const isChecked = Boolean(inputValue);

  const handleCheckboxClick = () => {
    if (disabled) {
      return;
    }

    if (isMultiple) {
      if (input.checked) {
        input.onChange(currentFieldValue.filter((value) => isNotEqual(value, input.value)));
      } else {
        input.onChange([...currentFieldValue, input.value]);
      }
    } else {
      input.onChange(!input.value);
    }
  };

  return (
    <div
      className={classNames('checkbox-input', {
        'checkbox-input--is-checked': isChecked,
        'checkbox-input--is-disabled': disabled,
      })}
    >
      <div className="checkbox-input__icon" onClick={handleCheckboxClick}>
        {isChecked && <Icon icon={IconNames.CHECK} />}
      </div>
      <label className="checkbox-input__label" htmlFor={input.name} onClick={handleCheckboxClick}>
        <Text.Regular>{label || input.name}</Text.Regular>
      </label>
      <input {...input} className="checkbox-input__element" readOnly type="checkbox" />
    </div>
  );
};

CheckboxInput.propTypes = {
  disabled: PropTypes.bool,
  input: formInputPropType,
  isMultiple: PropTypes.bool,
  label: PropTypes.string,
};

CheckboxInput.defaultProps = {
  disabled: false,
  isMultiple: false,
};

export default CheckboxInput;
