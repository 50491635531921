/**
 * Select option shape
 * @typedef {Object} CategorySelectOption
 * @property {String} label
 * @property {Id} value
 */

/**
 * Returns formatted select menu options from passed Category DTO list
 * @param {Category[]} categories
 * @returns {CategorySelectOption[]}
 */
export const getSelectOptionsFromCategories = (categories) => {
  return categories.map((category) => ({ label: category.categoryName, value: category.id }));
};

/**
 * Returns formatted field value for currently selected option
 * @param {CategorySelectOption} [selected]
 * @returns {?String}
 */
export const formatFieldValue = (selected) => selected?.label;

/**
 * Returns parsed field value for currently selected option
 * @param {CategorySelectOption} [selected]
 * @returns {?Id}
 */
export const parseFieldValue = (selected) => selected?.value;
