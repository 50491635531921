import { createAllIdsReducer } from 'helpers/redux';

import { fetchUnitOfMeasurementsRoutine } from 'store/routines/unitOfMeasurements';

/**
 * List of success cases with replace state flag
 */
const successCases = [{ type: fetchUnitOfMeasurementsRoutine.SUCCESS, shouldReplaceState: true }];

/**
 * Unit of measurements all ids reducer
 */
const unitOfMeasurementsAllIdsReducer = createAllIdsReducer({ successCases });

export { successCases };
export default unitOfMeasurementsAllIdsReducer;
