import React from 'react';

import { MailToSupportLink, Text } from 'components/atoms';

/**
 * Component rendering content of a tooltip for notifying users
 * that some feature is currently in development.
 * @see SettingsCard
 *
 * @return {StatelessComponent}
 */
const TooltipContentInDevelopment = () => (
  <Text.Regular>
    Ta funkcionalnost je trenutno v izdelavi. Kontaktirajte našo ekipo na <MailToSupportLink /> če želite biti
    obveščeni, ko bo na voljo.
  </Text.Regular>
);

export default TooltipContentInDevelopment;
