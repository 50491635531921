export enum LoadingIndicatorType {
  PRIMARY = 'primary',
  DEFAULT = 'default',
}

export enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum LabelPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum DropdownPosition {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum DropdownAlignment {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export enum Intent {
  ERROR = 'error',
  INFO = 'info',
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum ButtonType {
  GHOST = 'ghost',
  LINK = 'link',
  OUTLINED = 'outlined',
  PRIMARY = 'primary',
}

export enum ButtonHtmlType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}

export enum ButtonSize {
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum InputSize {
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  NORMAL = 'normal',
}

export enum TooltipPosition {
  TOP_LEFT = 'top-start',
  TOP_CENTER = 'top',
  TOP_RIGHT = 'top-end',
  LEFT_TOP = 'left-start',
  LEFT_CENTER = 'left',
  LEFT_BOTTOM = 'left-end',
  BOTTOM_LEFT = 'bottom-start',
  BOTTOM_CENTER = 'bottom',
  BOTTOM_RIGHT = 'bottom-end',
  RIGHT_TOP = 'right-start',
  RIGHT_CENTER = 'right',
  RIGHT_BOTTOM = 'right-end',
}

export enum AvatarSize {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum GridGapSize {
  NONE = 'none',
  SMALL = 'small',
  NORMAL = 'normal',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum GridColumnCount {
  ONE = 'one',
  TWO = 'two',
  THREE = 'three',
  FOUR = 'four',
  FIVE = 'five',
  SIX = 'six',
}

export enum TableCellSize {
  ONE = 'table__cell--size-1',
  TWO = 'table__cell--size-2',
  THREE = 'table__cell--size-3',
  FOUR = 'table__cell--size-4',
  FIVE = 'table__cell--size-5',
  SIX = 'table__cell--size-6',
  SEVEN = 'table__cell--size-7',
  EIGHT = 'table__cell--size-8',
  NINE = 'table__cell--size-9',
  TEN = 'table__cell--size-10',
  ELEVEN = 'table__cell--size-11',
  TWELVE = 'table__cell--size-12',
  THIRTEEN = 'table__cell--size-13',
  FOURTEEN = 'table__cell--size-14',
  FIFTEEN = 'table__cell--size-15',
  SIXTEN = 'table__cell--size-16',
  SEVENTEEN = 'table__cell--size-17',
  EIGHTEN = 'table__cell--size-18',
  NINETEEN = 'table__cell--size-19',
  TWENTY = 'table__cell--size-20',
}

export enum TableCellTextAlign {
  LEFT = 'table__cell--align-left',
  RIGHT = 'table__cell--align-right',
  CENTER = 'table__cell--align-center',
}

export enum TableCellPaddingSize {
  NORMAL = 'table__cell--padding-normal',
  MEDIUM = 'table__cell--padding-medium',
  LARGE = 'table__cell--padding-large',
}

export enum DaysOfWeek {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum EntityAction {
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum FlowDirection {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum FlexDirection {
  ROW = 'row',
  ROW_REVERSE = 'row-reverse',
  COLUMN = 'column',
  COLUMN_REVERS = 'column-reverse',
}

export enum FlexAlign {
  CENTER = 'center',
  START = 'flex-start',
  END = 'flex-end',
  STRETCH = 'stretch',
}

export enum FlexJustify {
  CENTER = 'center',
  START = 'flex-start',
  END = 'flex-end',
  BETWEEN = 'space-between',
  AROUND = 'space-around',
}
