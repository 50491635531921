import { createSelector } from 'reselect';

import { getMappedStateIds } from 'helpers/redux';

const getState = (state) => state.products;

/**
 * Selects the allIds property of the products state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Id[]}
 */
export const productsAllIdsSelector = createSelector([getState], (state) => state.allIds);

/**
 * Selects the byId property of the products state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object.<Id, Product>}
 */
export const productsByIdSelector = createSelector([getState], (state) => state.byId);

/**
 * Selects all products in the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Product[]}
 */
export const allProductsSelector = createSelector([productsAllIdsSelector, productsByIdSelector], getMappedStateIds);

/**
 * Selects the isFetching property of the products state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const productsIsFetchingSelector = createSelector([getState], (state) => state.isFetching);

/**
 * Selects the isSubmitting property of the products state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const productsIsSubmittingSelector = createSelector([getState], (state) => state.isSubmitting);

/**
 * Selects the pagination property of the products state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {ApiPaginationState}
 */
export const productsPaginationSelector = createSelector([getState], (state) => state.pagination);

/**
 * Selects the lastFetched property of the products state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Timestamp|null}
 */
export const productsLastFetchedSelector = createSelector([getState], (state) => state.lastFetched);
