import { createSelector } from 'reselect';

import { allStateSelector } from './globalSelectors';

/**
 * Selects root UI state property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const uiStateSelector = createSelector([allStateSelector], (state) => state.ui);

/**
 * Selects selectedMerchantId from UI state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Id|null}
 */
export const uiSelectedMerchantIdSelector = createSelector([uiStateSelector], (uiState) => uiState.selectedMerchantId);
