import ClipboardJS from 'clipboard';
import React from 'react';
import { v4 } from 'uuid';

import { showSuccessNotification } from 'helpers/alerts';

/**
 * Hook for making an arbitrary HTML Element dispatch a copy to clipboard action.
 * @see {withCopyAction}
 *
 * @example
 * const ref = useClipboard({ copyValue: 'Copy me', successText: 'Copy successful! });
 *
 * <div ref={ref}>
 *   Some content
 * </div>
 *
 * @param {Object} options
 * @param {string} options.copyValue
 * @param {string} [options.successText]
 * @returns {{ref: import('react').MutableRefObject<any>, id: string}}
 */
const useClipboard = ({ copyValue, successText }) => {
  // We create a ref. Any HTML element is a valid ref holder.
  const ref = React.useRef();
  // Alternativelly, we create a random UUID that can be set as an element ID. To create a valid
  // dom selector, we need to remove the numbers from uuid
  const elementId = v4().replace(/[0-9]+/gi, '');

  React.useEffect(() => {
    // If ref is set, we want to use that as a reference to the copy-to-clipboard element. If not,
    // we use generated elementId
    const element = ref.current || `#${elementId}`;

    // We create clipboard instance
    const clipboard = new ClipboardJS(element, {
      text: () => copyValue,
    });

    // We listen for copy success action and dispatch a toaster event with provided successText (or default success
    // value if success text is not passed).
    clipboard.on('success', () => {
      const text = successText || 'Vrednost uspešno kopirana!';
      showSuccessNotification({ Content: text, config: { id: copyValue } });
    });

    return () => {
      // On hook un-mount, if we have an active clipboard instance, we want to destroy it.
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, [copyValue, elementId, ref, successText]);

  // The output of the hook is the ref element which needs to be set on an element that we want to hold the copy to
  // clipboard action.
  return { ref, id: elementId };
};

export default useClipboard;
