import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { EntitiesSelect, SelectInput } from 'components/elements';

import { categoryDtoProp } from 'constants/props';

import { InputSize } from 'enums/ui';

import { categoryFormFieldOptionFormatter } from 'helpers/formatters';
import { allValues } from 'helpers/utility';

/**
 * Component rendering Category Select field
 * @param {Object} props
 * @param {Category[]} props.categories
 * @param {Boolean} props.isFetching
 * @param {Timestamp} [props.lastFetched]
 * @param {String} props.name
 * @param {Function} props.onFetchCategories
 * @param {InputSize} props.size
 * @returns {StatelessComponent}
 */
const CategorySelectField = ({ categories, isFetching, lastFetched, name, onFetchCategories, size, ...rest }) => {
  React.useEffect(() => {
    if (!isFetching && !lastFetched) {
      onFetchCategories();
    }
  }, [isFetching, lastFetched, onFetchCategories]);

  return (
    <Field
      {...rest}
      component={SelectInput}
      formatOptionLabel={categoryFormFieldOptionFormatter}
      fullwidth={true}
      isClearable={false}
      label="Kategorija izdelka"
      name={name}
      options={categories}
      selectComponent={EntitiesSelect}
      size={size}
    />
  );
};

CategorySelectField.propTypes = {
  categories: PropTypes.arrayOf(categoryDtoProp),
  isFetching: PropTypes.bool,
  lastFetched: PropTypes.number,
  name: PropTypes.string.isRequired,
  onFetchCategories: PropTypes.func.isRequired,
  size: PropTypes.oneOf(allValues(InputSize)),
};

CategorySelectField.defaultProps = {
  size: InputSize.NORMAL,
};

export default CategorySelectField;
