import React from 'react';

import { Text } from 'components/atoms';

/**
 * Component rendering Select menu No Options Available Message
 * @see {Select}
 * @return {StatelessComponent}
 */
const NoOptionsMessage = () => <Text.Regular>Ni razpoložljivih možnosti za izbiro</Text.Regular>;

export default NoOptionsMessage;
