import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { SwitchInput } from 'components/elements';

/**
 * Component rendering Switch Input table cell
 * @param {Object} props
 * @param {Object} props.cell
 * @returns {StatelessComponent}
 */
const SwitchInputCell = ({ cell }) => (
  <Field {...cell.column.props} component={SwitchInput} defaultValue={cell.value} name={cell.column.name} />
);

SwitchInputCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.bool,
    column: PropTypes.shape({
      name: PropTypes.string.isRequired,
      props: PropTypes.shape(),
    }),
  }),
};

export default SwitchInputCell;
