import { createAction } from '@reduxjs/toolkit';

import { AuthUser } from 'interfaces/users';

/**
 * Redux action creator for setting the auth user info
 */
export const setUser = createAction('@users/SET_USER', (payload: AuthUser) => ({ payload }));

/**
 * Redux action creator for removing the auth user info
 */
export const removeUser = createAction('@users/REMOVE_USER');
