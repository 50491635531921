import Intl from 'intl';
import 'intl/locale-data/jsonp/sl-SI';

import { IntlLocales } from 'enums/locales';

/**
 * Returns formatted price value
 * @param {number} vat
 * @returns {string}
 */
export const getFormattedVatString = (vat) => {
  const formattedFloatValue = Intl.NumberFormat(IntlLocales.SL).format(vat * 100);
  return `${formattedFloatValue} %`;
};

/**
 * Returns formated price value from PriceDto
 * @param {Vat} vatDto
 * @returns {string}
 */
export const getFormattedVatFromVatDto = (vatDto) => {
  return getFormattedVatString(vatDto.tax);
};
