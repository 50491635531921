import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { AppLayout } from 'components/modules/global';
import { PrivateRoute } from 'components/modules/navigation';

import { AuthRoutes, MerchantsRoutes, ProductsRoutes, PurchaseOrdersRoutes, SettingsRoutes } from 'enums/routes';

import { history } from 'store/root';

import { Login } from './Auth';
import { Dashboard } from './Dashboard';
import { SelectMerchant } from './Merchants';
import { OrderDetails } from './OrderDetails';
import { ProductEdit, ProductsOverview } from './Products';
import { PurchaseOrdersRoot } from './PurchaseOrders';
import { CompanyDeliveryDays, CompanyGeneralSettings, SettingsRoot } from './Settings';
import { DashboardSidesheets } from './Sidesheets';

/**
 * Main router component
 * @param {ComponentProps} props
 * @param {User} [props.user]
 * @returns {StatelessComponent}
 */
const Router = ({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <ConnectedRouter history={history}>
      <DashboardSidesheets />

      <AppLayout>
        <Switch>
          {/**
           * Auth routes
           */}
          <Route component={Login} path={AuthRoutes.LOGIN} />
          <Route component={Login} path={AuthRoutes.UNAUTHORIZED} />

          {/**
           * Dashboard
           */}
          <PrivateRoute component={Dashboard} exact path="/" />

          {/**
           * Merchants Routes
           */}
          <PrivateRoute component={SelectMerchant} exact path={MerchantsRoutes.ROOT} />

          {/**
           * Purchse Orders Routes
           */}
          <PrivateRoute component={PurchaseOrdersRoot} exact path={PurchaseOrdersRoutes.ROOT} />
          <PrivateRoute component={OrderDetails} path={PurchaseOrdersRoutes.DETAILS} />

          {/**
           * Products Routes
           */}
          <PrivateRoute component={ProductsOverview} exact path={ProductsRoutes.ROOT} />
          <PrivateRoute component={ProductEdit} path={ProductsRoutes.DETAILS} />

          {/**
           * Settings Routes
           */}
          <PrivateRoute component={SettingsRoot} exact path={SettingsRoutes.ROOT} />
          <PrivateRoute component={CompanyGeneralSettings} exact path={SettingsRoutes.COMPANY_GENERAL} />
          <PrivateRoute component={CompanyDeliveryDays} exact path={SettingsRoutes.COMPANY_DELIVERY_DAYS} />
        </Switch>
      </AppLayout>
    </ConnectedRouter>
  );
};

export default Router;
