export enum ApiRequestType {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export enum ApiVersion {
  V1 = 'v1',
}

export enum ApiServiceName {
  CORE = 'coreManagement',
  PARTY = 'partyManagement',
  ORDER = 'orderManagement',
}

export enum EntityRef {
  COMPANY = 'companyRef',
  PICKUP = 'pickupRef',
  PRODUCT = 'productRef',
}
