import { createSelector } from 'reselect';

import { getMappedStateIds } from 'helpers/redux';

/**
 * Returns Unit of Measurements slice of the state
 * @param {ReduxState} state
 * @returns {ReduxStateSlice<UnitOfMeasure>}
 */
const getState = (state) => state.unitOfMeasurements;

/**
 * Selects the allIds part of the unit of measurements state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Id[]}
 */
export const unitOfMeasurementsAllIdsSelector = createSelector([getState], (state) => state.allIds);

/**
 * Selects the byId part of the unit of measurements state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object.<Id, UnitOfMeasure>}
 */
export const unitOfMeasurementsByIdSelector = createSelector([getState], (state) => state.byId);

/**
 * Returns list of all unit of measurements entities stored in the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {UnitOfMeasure[]}
 */
export const unitOfMeasurementsAllValuesSelector = createSelector(
  [unitOfMeasurementsAllIdsSelector, unitOfMeasurementsByIdSelector],
  getMappedStateIds
);

/**
 * Selects the error part of the unit of measurements state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {?String}
 */
export const unitOfMeasurementsErrorSelector = createSelector([getState], (state) => state.error);

/**
 * Selects the isFetching part of the unit of measurements state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const unitOfMeasurementsIsFetchingSelector = createSelector([getState], (state) => state.isFetching);

/**
 * Selects the lastFetched part of the unit of measurements state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {?Timestamp}
 */
export const unitOfMeasurementsLastFetchedSelector = createSelector([getState], (state) => state.lastFetched);

/**
 * Returns true if not currently fetching & lastFetched is not set
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const unitOfMeasurementsShouldFetchSelector = createSelector(
  [unitOfMeasurementsIsFetchingSelector, unitOfMeasurementsLastFetchedSelector],
  (isFetching, lastFetched) => !isFetching && !lastFetched
);
