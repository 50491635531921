import { createSelector } from 'reselect';

import { getMappedStateIds } from 'helpers/redux';

const getState = (state) => state.merchants;

/**
 * Selects the byId property from the merchants state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Id[]}
 */
export const merchantsAllIdsSelector = createSelector([getState], (state) => state.allIds);

/**
 * Selects "byId" part of the Merchants state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Record<Merchant.id, Merchant>}
 */
export const merchantsByIdSelector = createSelector([getState], (state) => state.byId);

/**
 * Selects "byId" part of the Merchants state and returns
 * the values of the object in an array
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Merchant[]}
 */
export const allMerchantsSelector = createSelector([merchantsAllIdsSelector, merchantsByIdSelector], getMappedStateIds);

/**
 * Selects the "isFetching" part of the Merchants state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isMerchantsStateFetchingSelector = createSelector(
  [getState],
  (merchantsState) => merchantsState.isFetching
);

/**
 * Selects the "isFetching" part of the Merchants state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isMerchantsStateSubmittingSelector = createSelector(
  [getState],
  (merchantsState) => merchantsState.isSubmitting
);

/**
 * Selects the "isInitialized" part of the Merchants state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isMerchantsStateInitializedSelector = createSelector(
  [getState],
  (merchantsState) => merchantsState.isInitialized
);

/**
 * Selects the "pagination" part of the Merchants state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {ApiPaginationState}
 */
export const merchantsPaginationSelector = createSelector([getState], (merchantsState) => merchantsState.pagination);
