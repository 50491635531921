import { createAllIdsReducer } from 'helpers/redux';
import { fetchMerchantsNextPageRoutine, fetchMerchantsRoutine } from 'store/routines/merchants';

/**
 * Success cases
 */
const successCases = [
  { type: fetchMerchantsRoutine.SUCCESS, shouldReplaceState: true },
  { type: fetchMerchantsNextPageRoutine.SUCCESS, shouldReplaceState: false },
];

/**
 * Merchants reducers: All Ids reducer
 */
const merchantsAllIdsReducer = createAllIdsReducer({ successCases });

export default merchantsAllIdsReducer;
