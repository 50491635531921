import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'enums/icons';

import { allValues } from 'helpers/utility';

import './Icon.scss';

/**
 * Component rendering simple FontAwesome icon
 * @param {Object} props
 * @param {string} [props.className]
 * @param {boolean} [props.fixedWidth=false]
 * @param {string} props.icon
 * @param {boolean} [props.noTransition=false]
 * @param {Function} [props.onClick]
 * @param {boolean} [props.outlined=false]
 * @param {boolean} [props.spinning=false]
 * @returns {StatelessComponent}
 */
const Icon = ({ className, icon, innerRef, spinning, fixedWidth, reversed, onClick, noTransition, outlined }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <i
      ref={innerRef}
      className={classNames('icon', `fa-${icon}`, {
        [className]: Boolean(className),
        'fa-solid': !outlined,
        'fa-regular': outlined,
        'fa-spin': spinning,
        'fa-fw': fixedWidth,
        'icon--reversed': reversed,
        'icon--no-transition': noTransition,
      })}
      onClick={handleClick}
    />
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(allValues(IconNames)),
  spinning: PropTypes.bool.isRequired,
  fixedWidth: PropTypes.bool.isRequired,
  reversed: PropTypes.bool.isRequired,
  noTransition: PropTypes.bool.isRequired,
  outlined: PropTypes.bool.isRequired,
};

Icon.defaultProps = {
  spinning: false,
  fixedWidth: false,
  reversed: false,
  noTransition: false,
  outlined: false,
};

export default Icon;
