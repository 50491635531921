import { all, call, cancel, cancelled, put, select } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

import * as api from 'api/purchaseOrders';

import { CommonErrorMessages } from 'constants/errors';

import { EntityRef } from 'enums/api';
import { MerchantsRoutes } from 'enums/routes';

import { showSuccessNotification } from 'helpers/alerts';
import { getErrorMessageFromResponse } from 'helpers/errors';
import { getMissingPurchaseOrderStatusesForStatusUpdate } from 'helpers/purchaseOrders';
import { sagaWatcher } from 'helpers/redux';
import { withHandleSagaErrors } from 'helpers/sagas';
import { isEqual } from 'helpers/utility';

import {
  fetchPurchaseOrdersNextPageRoutine,
  fetchPurchaseOrdersRoutine,
  fetchSinglePurchaseOrderRoutine,
  patchPurchaseOrderStatusRoutine,
} from 'store/routines/purchaseOrders';
import { purchaseOrderForOrderIdSelector } from 'store/selectors/purchaseOrdersSelectors';
import { uiSelectedMerchantIdSelector } from 'store/selectors/uiSelectors';

import { ORDER_STATUS_LABELS } from 'utils/constants';

import { fetchIncludedEntitiesIfMissing } from '../shared';
import { purchaseOrderItemsProductRefMapper } from '../shared/helpers';

/**
 * Fetch Purchase Orders Saga
 * @param {ReduxAction} action
 * @param {Payload} action.payload
 * @param {string} action.payload.purchaseOrdersStatus
 * @returns {Generator}
 */
export function* fetchPurchaseOrdersSaga({ payload }) {
  let errorData = {};

  yield put(fetchPurchaseOrdersRoutine.request({ purchaseOrdersStatus: payload }));

  // Select currently selected company id from the state
  const companyId = yield select(uiSelectedMerchantIdSelector);

  // If companyId is not set for some reason, trigger failure, redirect to select
  // merchant screen and exit the saga early
  if (!companyId) {
    yield all([
      put(fetchPurchaseOrdersRoutine.failure(CommonErrorMessages.NO_COMPANY_ID)),
      put(push(MerchantsRoutes.ROOT)),
    ]);
    return;
  }

  try {
    // Call the API
    const response = yield call(api.getPurchaseOrders, { companyId, status: payload });

    // If response is a success, dispatch success action with
    // received data as a payload and exit the saga
    if (response.isSuccess) {
      yield put(fetchPurchaseOrdersRoutine.success({ ...response.data, purchaseOrdersStatus: payload }));
      yield call(fetchIncludedEntitiesIfMissing, response, [EntityRef.COMPANY, EntityRef.PRODUCT], {
        productRef: purchaseOrderItemsProductRefMapper,
      });

      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  // Dispatch failure action with error data as a payload
  yield put(fetchPurchaseOrdersRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Fetch Purchase Orders Saga
 * @param {ReduxAction} action
 * @param {Payload} action.payload
 * @param {string} action.payload.purchaseOrdersStatus
 * @returns {Generator}
 */
export function* fetchPurchaseOrdersNextPageSaga({ payload }) {
  const { status, page } = payload;
  let errorData = {};

  yield put(fetchPurchaseOrdersNextPageRoutine.request());

  // Select currently selected company id from the state
  const companyId = yield select(uiSelectedMerchantIdSelector);

  // If companyId is not set for some reason, trigger failure, redirect to select
  // merchant screen and exit the saga early
  if (!companyId) {
    yield all([
      put(fetchPurchaseOrdersNextPageRoutine.failure(CommonErrorMessages.NO_COMPANY_ID)),
      put(push(MerchantsRoutes.ROOT)),
    ]);
    return;
  }

  try {
    // Call the API
    const response = yield call(api.getPurchaseOrders, { companyId, status, page });

    // If response is a success, dispatch success action with
    // received data as a payload and exit the saga
    if (response.isSuccess) {
      yield put(fetchPurchaseOrdersNextPageRoutine.success({ ...response.data, purchaseOrdersStatus: status }));
      yield call(fetchIncludedEntitiesIfMissing, response, [EntityRef.COMPANY, EntityRef.PRODUCT], {
        productRef: purchaseOrderItemsProductRefMapper,
      });
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  // Dispatch failure action with error data as a payload
  yield put(fetchPurchaseOrdersNextPageRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Fetch Purchase Orders Saga
 * @param {ReduxAction} action
 * @param {Payload} action.payload
 * @param {string} action.payload.purchaseOrdersStatus
 * @returns {Generator}
 */
export function* fetchSinglePurchaseOrderSaga({ payload }) {
  let errorData = {};

  yield put(fetchSinglePurchaseOrderRoutine.request());

  // Select currently selected company id from the state
  const companyId = yield select(uiSelectedMerchantIdSelector);

  // If companyId is not set for some reason, trigger failure, redirect to select
  // merchant screen and exit the saga early
  if (!companyId) {
    yield all([
      put(fetchSinglePurchaseOrderRoutine.failure(CommonErrorMessages.NO_COMPANY_ID)),
      put(push(MerchantsRoutes.ROOT)),
    ]);
    return;
  }

  try {
    // Call the API
    const response = yield call(api.getSinglePurchaseOrder, { id: payload });

    // If response is a success, dispatch success action with
    // received data as a payload and exit the saga
    if (response.isSuccess) {
      yield put(fetchSinglePurchaseOrderRoutine.success(response.data));
      yield call(fetchIncludedEntitiesIfMissing, response, [EntityRef.COMPANY, EntityRef.PRODUCT], {
        productRef: purchaseOrderItemsProductRefMapper,
      });
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  // Dispatch failure action with error data as a payload
  yield put(fetchSinglePurchaseOrderRoutine.failure(getErrorMessageFromResponse(errorData)));
}

export function* patchPurchseOrderStatusSaga({ payload }) {
  let errorData = {};
  const { status: desiredStatus, id } = payload;

  try {
    if (!desiredStatus || !id) {
      errorData = CommonErrorMessages.NO_STATUS_AND_ID_PARAMS;
      yield cancel();
    }

    // We select the purchase order that we want to update
    const purchaseOrder = yield select(purchaseOrderForOrderIdSelector, id);

    // We grab missing purchase order statuses - the function will return a list of all missing
    // purchase order status that we need to transition to, prior to updating the purchase order
    // to the desired status. If no status is missing, an empty list is returned.
    const missingPurchaseOrderStatuses = getMissingPurchaseOrderStatusesForStatusUpdate({
      currentStatus: purchaseOrder.status,
      status: desiredStatus,
    });

    // We loop over each of the missing statuses, and perform API request for each
    for (const status of [...missingPurchaseOrderStatuses, desiredStatus]) {
      yield put(patchPurchaseOrderStatusRoutine.request());

      const response = yield call(api.patchPurchaseOrderStatus, { id, status });

      if (response.isSuccess) {
        // We dispatch the success action with updated purchase order status
        yield put(patchPurchaseOrderStatusRoutine.success({ ...purchaseOrder, status }));

        if (isEqual(status, desiredStatus)) {
          // We want to display toast with info about status update if the current status is the
          // desired status (meaning we reached the final request)
          yield call(showSuccessNotification, {
            Content: `Naročilo uspešno posodobljeno! Nov status naročila: ${ORDER_STATUS_LABELS[desiredStatus]}.`,
          });
        }
        continue;
      }

      // We still want to dispatch failure action
      errorData = response.errorData;

      // We cancel the Saga, since there is no point of going forward with other requests if
      // this one has failed
      yield cancel();
      break;
    }
  } catch (err) {
    errorData = err;
    yield cancel();
  } finally {
    if (yield cancelled()) {
      yield put(patchPurchaseOrderStatusRoutine.failure(withHandleSagaErrors(errorData)));
    }
  }
}

/**
 * Root Purchase Orders Saga
 * @returns {IterableIterator}
 */
export default function* purchaseOrdersSagas() {
  yield sagaWatcher([
    {
      saga: fetchPurchaseOrdersSaga,
      type: fetchPurchaseOrdersRoutine.TRIGGER,
    },
    {
      saga: fetchPurchaseOrdersNextPageSaga,
      type: fetchPurchaseOrdersNextPageRoutine.TRIGGER,
    },
    {
      saga: fetchSinglePurchaseOrderSaga,
      type: fetchSinglePurchaseOrderRoutine.TRIGGER,
    },
    {
      saga: patchPurchseOrderStatusSaga,
      type: patchPurchaseOrderStatusRoutine.TRIGGER,
    },
  ]);
}
