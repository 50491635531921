export enum IconNames {
  ANGLE_LEFT = 'angle-left',
  ANGLE_RIGHT = 'angle-right',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  CALENDAR = 'calendar-alt',
  CALENDAR_CHECK = 'calendar-check',
  CARET_DOWN = 'caret-down',
  CARET_UP = 'caret-up',
  CHANGE_ARROWS = 'exchange-alt',
  CHECK = 'check',
  CHECK_CIRCLE = 'check-circle',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_UP = 'chevron-up',
  CIRCLE_NOTCH = 'circle-notch',
  CLOCK = 'clock',
  CLOSE = 'times',
  COG = 'cog',
  COINS = 'coins',
  COPY = 'copy',
  DELIVERY = 'truck',
  DOTS_HORIZONTAL = 'ellipsis',
  DOTS_VERTICAL = 'ellipsis-v',
  EDIT = 'edit',
  EMAIL = 'envelope',
  EXCLAMATION_CIRCLE = 'exclamation-circle',
  EXCLAMATION_TRIANGLE = 'exclamation-triangle',
  EURO = 'euro-sign',
  FILTER_LIST = 'stream',
  INFO_CIRCLE = 'info-circle',
  LIST = 'list',
  LOCK = 'lock',
  MONEY = 'money-check-alt',
  MONEY_BILL = 'money-bill-alt',
  MONEY_IN_HAND = 'hand-holding-usd',
  MOVE = 'arrows-alt',
  PERCENTAGE = 'percentage',
  PHONE = 'phone',
  PLUS = 'plus',
  REFRESH = 'sync-alt',
  SAVE = 'save',
  SEARCH = 'search',
  SHARE = 'share-nodes',
  STORE = 'store',
  SIGN_OUT = 'sign-out-alt',
  TRASH = 'trash',
  UPDATE = 'file-import',
  USER = 'user',
  USERS = 'users',
}
