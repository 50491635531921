import COMPANY_DELIVERY_SETTINGS from 'assets/icons/IconSettings.svg';
import COMPANY_GENERAL_SETTINGS from 'assets/icons/IconStorage.svg';

import { SettingsRoutes } from 'enums/routes';

import { isUserRoleOperator } from './roles';

/**
 * Returns array of object with config for displaying settings
 * card in a grid
 * @param {Object} options
 * @param {UserRoleType[]} options.roles
 * @returns {Object[]}
 */
export const getSettingsPagesGridData = ({ roles }) => {
  // Get settings config for user type OPERATOR
  if (isUserRoleOperator(roles)) {
    return [
      {
        slug: SettingsRoutes.COMPANY_GENERAL,
        disabled: false,
        title: 'Podatki u ponudniku',
        image: COMPANY_GENERAL_SETTINGS,
        details: 'Splošni podatki o trgovini, sedež, kontaktni podatki in odgovorne osebe',
        buttonText: 'Urejanje podatkov',
      },
      {
        slug: SettingsRoutes.COMPANY_DELIVERY_DAYS,
        disabled: false,
        title: 'Datumi dostave in prevzema',
        image: COMPANY_DELIVERY_SETTINGS,
        details: 'Urejanje podatkov o podprtih poštnih številkah dostave, datum dostave in datum prevzema',
        buttonText: 'Urejanje dostave in prevzema',
      },
    ];
  }
};
