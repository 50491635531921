import * as Yup from 'yup';

import {
  getDefaultFieldErrorMessage,
  getFormFieldExpectedMatch,
  getFormFieldLabelOrPath,
  getRequiredFieldErrorMessage,
} from 'helpers/forms';
import { getNounBasedOnNumber } from 'helpers/textHelpers';
import { isEqual } from 'helpers/utility';

Yup.setLocale({
  mixed: {
    required: getRequiredFieldErrorMessage,
    default: getDefaultFieldErrorMessage,
  },
  number: {
    max: (field) => {
      return `Največja dovoljena vrednost polja ${getFormFieldLabelOrPath(field)} je ${field.max}`;
    },
    min: (field) => {
      return `Najmanjša dovoljena vrednost polja ${getFormFieldLabelOrPath(field)} je ${field.min}`;
    },
  },
  string: {
    matches: (field) => {
      const expectedMatch = getFormFieldExpectedMatch(field);

      if (isEqual(expectedMatch, 'email')) {
        return `Polje ${getFormFieldLabelOrPath(field)} mora biti veljaven e-poštni naslov.`;
      }

      return `Polje ${getFormFieldLabelOrPath(field)} lahko vsebuje samo ${getFormFieldExpectedMatch(field)}.`;
    },
    length: (field) => {
      return `Polje ${getFormFieldLabelOrPath(field)} mora vsebovati ${getNounBasedOnNumber('znak', field.length)}.`;
    },
    min: (field) => {
      return `Polje ${getFormFieldLabelOrPath(field)} mora vsebovati vsaj ${getNounBasedOnNumber('znak', field.min)}.`;
    },
  },
});

export default Yup;
