import { DeliveryDaysEndpoints } from 'constants/endpoints';
import { ApiServiceName } from 'enums/api';

import fetchApi from './common';

/**
 * GET delivery days for comapny api request.
 * @param {Id} companyId
 * @param {number} [page=0]
 * @param {number} [perPage]
 * @returns {Promise<Payload>}
 */
export const getDeliveryDaysForCompany = async ({ companyId, page = 0, perPage }) => {
  return fetchApi({
    data: { page, perPage },
    needsAuth: true,
    url: DeliveryDaysEndpoints.BY_COMPANY(companyId),
    serviceName: ApiServiceName.CORE,
  });
};
