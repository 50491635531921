import { createByIdReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import type { VatDTO } from 'interfaces/vats';

import { fetchVatsRoutine } from 'store/routines/vats';

/**
 * List of success cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([fetchVatsRoutine]);

/**
 * Vats by id reducer
 */
const vatsByIdReducer = createByIdReducer<VatDTO>({ successCases });

export { successCases };
export default vatsByIdReducer;
