import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { productDtoProp } from 'constants/props';

import { productPriceUpdateFormInitialValuesQuery } from 'store/queries/productPricesQueries';
import { patchProductPriceRoutine } from 'store/routines/productPrices';
import {
  productPriceForProductPriceIdPropSelector,
  productPricesIsSubmittingSelector,
} from 'store/selectors/productPricesSelectors';
import { unitOfMeasurementsAllValuesSelector } from 'store/selectors/unitOfMeasurementsSelectors';
import { vatsAllValuesSelector } from 'store/selectors/vatsSelectors';

import ProductPriceTable from './ProductPriceTable';

/**
 * Container component rendering Product Price Table form
 * @param {Object} props
 * @param {ProductPrice} props.productPrice
 * @returns {StatelessComponent}
 */
export const ProductPriceTableContainer = ({ initialValues, onUpdateProductPrice, ...rest }) => {
  const handleSubmit = async (values, form) => {
    const errors = await form.submit();

    if (!errors) {
      onUpdateProductPrice(values);
    }
  };

  return <Form component={ProductPriceTable} initialValues={initialValues} onSubmit={handleSubmit} {...rest} />;
};

ProductPriceTableContainer.propTypes = {
  productPrice: productDtoProp.isRequired,
};

export const mapStateToProps = createStructuredSelector({
  isSubmitting: productPricesIsSubmittingSelector,
  initialValues: productPriceUpdateFormInitialValuesQuery,
  productPrice: productPriceForProductPriceIdPropSelector,
  unitOfMeasures: unitOfMeasurementsAllValuesSelector,
  vats: vatsAllValuesSelector,
});

export const mapDispatchToProps = {
  onUpdateProductPrice: patchProductPriceRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceTableContainer);
