import { combineReducers } from 'redux';

import type { PickupsState } from 'store/interfaces/pickupsState.types';

import pickupsAllIdsReducer from './pickupsAllIdsReducer';
import pickupsByIdReducer from './pickupsByIdReducer';
import pickupsErrorReducer from './pickupsErrorReducer';
import pickupsIsFetchingReducer from './pickupsIsFetchingReducer';
import pickupsLastFetchedReducer from './pickupsLastFetchedReducer';

/**
 * Pickups reducers: Root reducer
 */
const pickupsReducer = combineReducers<PickupsState>({
  allIds: pickupsAllIdsReducer,
  byId: pickupsByIdReducer,
  error: pickupsErrorReducer,
  isFetching: pickupsIsFetchingReducer,
  lastFetched: pickupsLastFetchedReducer,
});

export default pickupsReducer;
