import swal from '@sweetalert/with-react';
import React from 'react';

import { MailToLink, Text } from 'components/atoms';
import { Hint } from 'components/elements';
import AlertWrapper from 'components/elements/alerts/AlertWrapper/AlertWrapper';

import { Intent } from 'enums/ui';

import './GenericApiErrorAlert.scss';

/**
 * Component rendering generic API error alert
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const GenericApiErrorAlert = ({ errorMessage }) => (
  <AlertWrapper
    className="generic-api-error-alert"
    confirmButtonProps={{ onClick: () => swal.close(), text: 'Zapri obvestilo' }}
    hasCancelButton={false}
    intent={Intent.ERROR}
    showTitleIcon={true}
    title="Pri zahtevi je prišlo do napake"
  >
    <Text.Regular>
      Pri zahtevi se je nekaj zalomilo... 😞 Po pregledu napake zaprite to obvestilo in poizkusite ponovno.
    </Text.Regular>

    <Hint intent={Intent.ERROR} title="Status napake">
      <Text.Regular>{errorMessage}</Text.Regular>
    </Hint>

    <Text.Regular>
      V kolikor potrebujete pomoč pri odpravi napake, kontaktirajte našo podporno ekipo na e-poštnem naslovu{' '}
      <MailToLink email="support@optifarm.net">support@optifarm.net</MailToLink>.
    </Text.Regular>
  </AlertWrapper>
);

export default GenericApiErrorAlert;
