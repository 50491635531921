import { createSelector } from 'reselect';

import { DEFAULT_PAGINATION_STATE } from 'constants/api';

import { PurchaseOrderType } from 'enums/purchaseOrders';

import { getMappedStateIds } from 'helpers/redux';

import type { ReduxState } from 'store/interfaces';

import {
  allStateSelector,
  idSelector,
  orderIdPropSelector,
  propsOrderIdFromParamsSelector,
  purchaseOrderTypeFromPropsSelector,
  typeSelector,
} from './globalSelectors';

/**
 * Selects root purchase orders state
 * @param state
 */
const getState = (state: ReduxState) => state.purchaseOrders;

/**
 * Selects All Ids property from the purchase orders state
 * @param state
 */
export const purchaseOrdersAllIdsSelector = createSelector(
  [getState],
  (purchaseOrdersState) => purchaseOrdersState.allIds
);

/**
 * Selects By Id property from the purchase orders state
 * @param state
 */
export const purchaseOrdersByIdSelector = createSelector([getState], (purchaseOrdersState) => purchaseOrdersState.byId);

/**
 * Selects all purchase orders by id and returns them in a list
 * @param state
 */
export const allPurchaseOrdersSelector = createSelector(
  [purchaseOrdersAllIdsSelector, purchaseOrdersByIdSelector],
  getMappedStateIds
);

/**
 * Selects isFetching property from purchase orders state
 * @param state
 */
export const purchaseOrdersIsFetchingSelector = createSelector(
  [getState],
  (purchaseOrdersState) => purchaseOrdersState.isFetching
);

/**
 * Selects the single StatusAware Purchase Orders state
 * @param state
 * @param ordersType
 */
export const purchaseOrdersByOrdersTypeStateSelector = createSelector(
  [getState, typeSelector],
  (ordersState, ordersType) => {
    if (!ordersType) {
      return null;
    }

    const ordersByRequestedType = ordersState[ordersType];

    if (!ordersByRequestedType) {
      return null;
    }

    return ordersByRequestedType;
  }
);

/**
 * Selects Purchase Orders state for given purchase order type
 * passed through props
 * @param state
 * @param props
 */
export const purchaseOrdersForOrdersTypeFromPropsStateSelector = createSelector(
  [allStateSelector, purchaseOrderTypeFromPropsSelector],
  (globalState, type) => purchaseOrdersByOrdersTypeStateSelector(globalState, type)
);

/**
 * Selects allIds property of the purchaseOrder[ordersType] state
 * @param state
 * @param ordersType
 */
export const purchaseOrdersByOrdersTypeAllIdsSelector = createSelector(
  [purchaseOrdersByOrdersTypeStateSelector],
  (ordersByTypeState) => ordersByTypeState?.allIds || []
);

/**
 * Selects all purchase orders for given purchase orders type
 * @param state
 * @param ordersType
 */
export const allPurchaseOrdersByOrdersTypeSelector = createSelector(
  [purchaseOrdersByOrdersTypeAllIdsSelector, purchaseOrdersByIdSelector],
  getMappedStateIds
);

/**
 * Selects all purchase orders by given purchase orders type
 * @param state
 * @param props
 */
export const allPurchaseOrdersForOrdersTypeFromPropsSelector = createSelector(
  [purchaseOrdersForOrdersTypeFromPropsStateSelector, purchaseOrdersByIdSelector],
  (purchaseOrdersTypeState, purchaseOrdersById) => {
    return getMappedStateIds(purchaseOrdersTypeState?.allIds, purchaseOrdersById);
  }
);

/**
 * Selects isFetching property for given purchase orders type state
 * @param state
 * @param props
 */
export const isPurchaseOrdersTypeLoadingFromPropsSelector = createSelector(
  [purchaseOrdersForOrdersTypeFromPropsStateSelector],
  (purchaseOrdersTypeState) => purchaseOrdersTypeState?.isFetching || false
);

/**
 * Selects pagination property for given purchase orders type state
 * @param state
 * @param props
 */
export const purchaseOrdersTypePaginationFromPropsSelector = createSelector(
  [purchaseOrdersForOrdersTypeFromPropsStateSelector],
  (purchaseOrdersTypeState) => purchaseOrdersTypeState?.pagination || DEFAULT_PAGINATION_STATE
);

/**
 * Returns active purchase orders array
 * @param state
 */
export const activePurchaseOrdersSelector = createSelector([allStateSelector], (globalState) =>
  allPurchaseOrdersByOrdersTypeSelector(globalState, PurchaseOrderType.ACTIVE)
);

/**
 * Selects single purchase order for given ID
 * @param state
 * @param id
 */
export const purchaseOrderForOrderIdSelector = createSelector(
  [purchaseOrdersByIdSelector, idSelector],
  (purchaseOrdersById, id) => purchaseOrdersById[id]
);

/**
 * Selects single purchase order for given ID
 * @param state
 * @param id
 */
export const purchaseOrderForOrderIdPropSelector = createSelector(
  [purchaseOrdersByIdSelector, orderIdPropSelector],
  (purchaseOrdersById, purchaseOrderId) => {
    if (!purchaseOrderId) {
      return;
    }

    return purchaseOrdersById[purchaseOrderId];
  }
);

/**
 * Selects pagination property for given purchase orders type state
 * @param state
 */
export const purchaseOrderForOrderIdFromParamsSelector = createSelector(
  [purchaseOrdersByIdSelector, propsOrderIdFromParamsSelector],
  (purchaseOrdersById, purchaseOrderId) => {
    if (!purchaseOrderId) {
      return;
    }

    return purchaseOrdersById[purchaseOrderId];
  }
);

/**
 * Selects the purchase order status from order id prop
 * @param state
 * @param props
 */
export const purchaseOrderStatusFromOrderIdPropSelector = createSelector(
  [purchaseOrderForOrderIdFromParamsSelector],
  (purchaseOrder) => purchaseOrder?.status
);
