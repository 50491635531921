import { combineReducers } from 'redux';

import type { UnitOfMeasurementsState } from 'store/interfaces/unitOfMeasurementsState.types';

import errorReducer from './errorReducer';
import isFetchingReducer from './isFetchingReducer';
import lastFetchedReducer from './lastFetchedReducer';
import unitOfMeasurementsAllIdsReducer from './unitOfMeasurementsAllIdsReducer';
import unitOfMeasurementsByIdReducer from './unitOfMeasurementsByIdReducer';

/**
 * Unit of measurements root reducer
 */
const unitOfMeasurementsReducer = combineReducers<UnitOfMeasurementsState>({
  allIds: unitOfMeasurementsAllIdsReducer,
  byId: unitOfMeasurementsByIdReducer,
  error: errorReducer,
  isFetching: isFetchingReducer,
  lastFetched: lastFetchedReducer,
});

export default unitOfMeasurementsReducer;
