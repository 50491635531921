import React from 'react';

import { formInputPropType, formMetaPropType } from 'constants/props';

import { IconNames } from 'enums/icons';
import { IconPosition } from 'enums/ui';

import FloatInput from '../FloatInput';

/**
 * Component rendering Price Input Field. Convience wrapper around FloatInput.
 * @param {Object} props
 * @returns {StatelessComponent}
 */
const PriceInput = (props) => (
  <FloatInput fullwidth={true} icon={IconNames.EURO} iconPosition={IconPosition.LEFT} {...props} />
);

PriceInput.propTypes = {
  input: formInputPropType.isRequired,
  meta: formMetaPropType.isRequired,
};

export default PriceInput;
