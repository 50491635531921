import React from 'react';
import { Field, useField } from 'react-final-form';

import { Column, Grid, Section } from 'components/atoms';
import { CheckboxInput, PriceInput } from 'components/elements';

import { CompanyGeneralFormFields } from 'enums/formFields';
import { GridColumnCount, GridGapSize } from 'enums/ui';

/**
 * Component rendering payment info fields of
 * MerchantGeneralInfoForm
 * @see MerchantGeneralInfoForm
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const MerchantGeneralInfoFormPayment = () => {
  const {
    input: { value: isDeliverySupported },
  } = useField(CompanyGeneralFormFields.HAS_DELIVERY, { parse: (value) => Boolean(value) });

  return (
    <Section title="Podatki o cenah dostave">
      <Grid numOfCols={GridColumnCount.ONE}>
        <Field
          component={CheckboxInput}
          disabled={true}
          label="Ponudnik podpira dostavo na dom?"
          name={CompanyGeneralFormFields.HAS_DELIVERY}
        />
      </Grid>

      {isDeliverySupported && (
        <Grid numOfCols={GridColumnCount.THREE}>
          <Field
            component={PriceInput}
            fullwidth={true}
            label="Minimalna vrednost naročila za prevoz"
            name={CompanyGeneralFormFields.MIN_HOME_DELIVERY}
            tooltipProps={{
              content: 'Minimalna vrednost nakupa pri kateri podpirate prevoz na dom.',
            }}
          />

          <Column gap={GridGapSize.MEDIUM}>
            <Field
              component={PriceInput}
              fullwidth={true}
              label="Cena prevoza - bližnja okolica"
              name={CompanyGeneralFormFields.LOCAL_DELIVERY_PRICE}
              tooltipProps={{
                content: 'Vrednost cene prevoza za kraje v bližnji okolici.',
              }}
            />

            <Field
              component={PriceInput}
              fullwidth={true}
              label="vrednost za brezplačen prevoz - Bližnja okolica"
              name={CompanyGeneralFormFields.FREE_LOCAL_DELIVERY_THRESHOLD}
              tooltipProps={{
                content: 'Minimalna vrednost nakupa pri kateri podpirate brezplačen prevoz na dom za bližnjo okolico.',
              }}
            />
          </Column>

          <Column gap={GridGapSize.MEDIUM}>
            <Field
              component={PriceInput}
              fullwidth={true}
              label="Cena prevoza - ostala območja"
              name={CompanyGeneralFormFields.REGIONAL_DELIVERY_PRICE}
              tooltipProps={{
                content: 'Vrednost cene prevoza za kraje, ki niso v vaši bližnji okolici.',
              }}
            />

            <Field
              component={PriceInput}
              fullwidth={true}
              label="vrednost za brezplačen prevoz - ostala območja"
              name={CompanyGeneralFormFields.FREE_REGIONAL_DELIVERY_THRESHOLD}
              tooltipProps={{
                content:
                  'Minimalna vrednost nakupa pri kateri podpirate brezplačen prevoz na dom za kraje, ki niso v vaši bližnji okolici.',
              }}
            />
          </Column>
        </Grid>
      )}
    </Section>
  );
};

export default MerchantGeneralInfoFormPayment;
