import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Text } from 'components/atoms';

import { iconNameForIntentMap } from 'constants/icons';

import { Intent } from 'enums/ui';
import { allValues } from 'helpers/utility';

import './Hint.scss';

/**
 * Component rendering Hint instance
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {boolean} [props.hasIcon=true]
 * @param {Intent} [props.intent=Intent.SUCCESS]
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const Hint = ({ children, className, hasIcon, intent, title }) => (
  <div className={classNames(`hint hint--${intent}`, { [className]: Boolean(className) })}>
    <Text.Bold className="hint__title">
      {hasIcon && <Icon icon={iconNameForIntentMap[intent]} />}
      {title}
    </Text.Bold>

    {children}
  </div>
);

Hint.propTypes = {
  children: PropTypes.node.isRequired,
  hasIcon: PropTypes.bool,
  intent: PropTypes.oneOf(allValues(Intent)),
  title: PropTypes.string.isRequired,
};

Hint.defaultProps = {
  hasIcon: true,
  intent: Intent.SUCCESS,
};

export default Hint;
