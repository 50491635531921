export const TOKEN_KEY = 'of-token';
export const REFRESH_TOKEN_KEY = 'of-refresh-token';

export const DEV_ENV = process.env.NODE_ENV === 'development';

export const MERCHANT = 'ofb2c-merchant';
export const CUSTOMER = 'ofb2c-customer';
export const ADMIN = 'ofb2c-admin';
export const MANAGER = 'ofb2c-manager';
export const ACCOUNTANT = 'ofb2c-accountant';
export const CHIEF_MERCHANT = 'ofb2c-chief-merchant';

export const ALLOWED_ROLES = [CHIEF_MERCHANT, MERCHANT, ACCOUNTANT, MANAGER, ADMIN];

export const containerTypeMapper = {
  [CHIEF_MERCHANT]: 'merchant',
  [MERCHANT]: 'merchant',
  [MANAGER]: 'manager',
  [ADMIN]: 'admin',
  [ACCOUNTANT]: 'accountant',
  default: 'default',
};

export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const IMAGES_BASE_URL = process.env.REACT_APP_IMAGES_URL;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT = process.env.REACT_APP_KEYCLOAK_CLIENT;

export const REQUEST_METHODS = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  PUT: 'PUT',
});

export const ORDER_TYPES = Object.freeze({
  ALL: 'all',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  FINALIZED: 'finalized',
  LATEST_FINALIZED: 'latest-finalized',
});

export const ORDER_STATUS = Object.freeze({
  NEW: 'NEW',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  PENDING: 'PENDING',
  HELD: 'HELD',
  IN_PROGRESS: 'IN_PROGRESS',
  PAYED: 'PAYED',
  PACKED: 'PACKED',
  ASSESSING_CANCELLATION: 'ASSESSING_CANCELLATION',
  PENDING_CANCELLATION: 'PENDING_CANCELLATION',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  PARTIAL: 'PARTIAL',
  REJECTED: 'REJECTED',
});

export const FINALIZED_ORDER_STATUSES = [ORDER_STATUS.COMPLETED, ORDER_STATUS.CANCELLED];

export const ORDER_STATUS_LABELS = Object.freeze({
  [ORDER_STATUS.ASSESSING_CANCELLATION]: 'Priprava naročila za preklic',
  [ORDER_STATUS.PENDING_CANCELLATION]: 'Naročilo v čakanju za preklic',
  [ORDER_STATUS.COMPLETED]: 'Naročilo zaključeno',
  [ORDER_STATUS.IN_PROGRESS]: 'Naročilo v obdelavi',
  [ORDER_STATUS.CANCELLED]: 'Naročilo preklicano',
  [ORDER_STATUS.PACKED]: 'Naročilo spakirano',
  [ORDER_STATUS.PARTIAL]: 'Delno naročilo',
  [ORDER_STATUS.PENDING]: 'Naročilo v čakanju',
  [ORDER_STATUS.FAILED]: 'Naročilo neuspešno',
  [ORDER_STATUS.REJECTED]: 'Naročilo zavrnjeno',
  [ORDER_STATUS.ACKNOWLEDGED]: 'Naročilo v čakanju',
  [ORDER_STATUS.HELD]: 'Naročilo zadržano',
});

export const NOTIFICATION_TYPES = Object.freeze({
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
});

export const ALERTS_TYPES = Object.freeze({
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
});

export const LOGIN_REDIRECT_TYPES = Object.freeze({
  INVALID_ROLE: 'invalid_role',
  NO_TOKEN: 'no_token',
});

export const PAYMENT_METHODS = Object.freeze({
  ON_SITE: { id: 'ON_SITE', name: 'Plačilo po povzetju' },
  CC: { id: 'CC', name: 'Plačilo s kreditno kartico' },
  PAY_PAL: { id: 'PAY_PAL', name: 'PayPal plačilo' },
  PREPAID: { id: 'PREPAID', name: 'Plačilo po predračunu' },
});

export const MERCHANT_TABS = [
  { label: 'Vsa naročila', type: 'all', role: [CHIEF_MERCHANT] },
  {
    label: 'Aktivna naročila',
    type: 'active',
    role: [MERCHANT, CHIEF_MERCHANT],
  },
  {
    label: 'Zapadla naročila',
    type: 'expired',
    role: [MERCHANT, CHIEF_MERCHANT],
  },
  { label: 'Izvedena naročila', type: 'finalized', role: [CHIEF_MERCHANT] },
  { label: 'Izvedena naročila', type: 'latest-finalized', role: [MERCHANT] },
];

export const PICKUP_TYPES = Object.freeze({
  PICKUP_POINT: 'PICKUP_POINT',
  HOME_DELIVERY: 'HOME_DELIVERY',
});

export const PICKUP_TYPES_LABELS = Object.freeze({
  [PICKUP_TYPES.PICKUP_POINT]: 'Prevzem na lokaciji',
  [PICKUP_TYPES.HOME_DELIVERY]: 'Dostava na dom',
});
