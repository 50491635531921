import PropTypes from 'prop-types';
import React from 'react';

import { Tabs } from 'components/elements';
import { PurchaseOrdersList } from 'components/modules/purchaseOrders/PurchaseOrdersList';

import { UserRolesProp } from 'constants/props';
import { PURCHASE_ORDERS_TABS } from 'constants/ui';
import { getProminentUserRole } from 'helpers/roles';

/**
 * Component rendering tabs with purchase orders
 * list
 * @param {ComponentProps} props
 * @param {User} props.user
 * @return {StatelessComponent}
 */
const PurchaseOrdersTabs = ({ user, ...rest }) => {
  const [activeTab, setActiveTab] = React.useState(1);

  const saveActiveTabIndex = (index) => {
    localStorage.setItem('orders-tab-index', index);
  };

  const tabs = React.useMemo(() => {
    const userRole = getProminentUserRole(user?.roles);
    return PURCHASE_ORDERS_TABS.filter((tab) => tab.role.includes(userRole));
  }, [user]);

  const tabsLabels = React.useMemo(() => {
    return tabs.map((tab) => tab.label);
  }, [tabs]);

  React.useEffect(() => {
    const savedTab = localStorage.getItem('orders-tab-index');
    if (savedTab) {
      setActiveTab(parseInt(savedTab));
    }
  }, []);

  return (
    <div className="purchase-orders-tabs">
      <Tabs activeIndex={activeTab} saveTabIndex={saveActiveTabIndex} tabs={tabsLabels}>
        {tabs.map(({ type }) => (
          <PurchaseOrdersList key={type} type={type} {...rest} />
        ))}
      </Tabs>
    </div>
  );
};

PurchaseOrdersTabs.propTypes = {
  user: PropTypes.shape({ roles: UserRolesProp }).isRequired,
};

export default PurchaseOrdersTabs;
