import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { onRemoveSelectedMerchant } from 'store/actions/uiActions';
import { merchantFromUiSelectedMerchantIdQuery } from 'store/queries/merchantsQueries';
import { userStateSelector } from 'store/selectors/userSelectors';

import Sidebar from './Sidebar';

export const mapStateToProps = createStructuredSelector({
  user: userStateSelector,
  selectedMerchant: merchantFromUiSelectedMerchantIdQuery,
});

export const mapDispatchToProps = {
  onMerchantChange: onRemoveSelectedMerchant,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
