import PropTypes from 'prop-types';
import React from 'react';

import { vatDtoProp } from 'constants/props';

import { IconNames } from 'enums/icons';
import { TextAlign } from 'enums/typography';

import { valueOrEmptyObject } from 'helpers/utility';
import { getFormattedVatFromVatDto } from 'helpers/vat';

import SelectInputCell from '../SelectInputCell';

/**
 * Component renderint Table cell for Tax value select
 * @param {Object} props
 * @param {Object} props.cell
 * @param {Vat[]} props.cell.value
 * @returns {StatelessComponent}
 */
const TaxInputCell = ({ cell }) => (
  <SelectInputCell
    cell={{
      ...cell,
      column: {
        ...cell.column,
        props: {
          ...valueOrEmptyObject(cell.column.props),
          formatOptionLabel: getFormattedVatFromVatDto,
          icon: IconNames.MONEY_IN_HAND,
          isClearable: false,
          textAlign: TextAlign.RIGHT,
        },
      },
    }}
  />
);

TaxInputCell.propTypes = {
  cell: PropTypes.shape({
    value: vatDtoProp,
    column: PropTypes.shape({
      name: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(vatDtoProp),
      props: PropTypes.shape(),
    }),
  }).isRequired,
};

export default TaxInputCell;
