import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { TextColor, TextLineHeight, TextSize, TextWeight } from 'enums/typography';

import './Text.scss';

/**
 * Component (primitive) rendering any given text string.
 *
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {string} [props.color=TextColor.GREY]
 * @param {string} [props.lineHeight=TextColor.GREY]
 * @param {string} [props.size=TextSize.LEVEL_300]
 * @param {number} [props.numOfLines]
 * @param {string} [props.weight=TextWeight.REGULAR]
 * @return {StatelessComponent}
 */
const BaseText = ({ children, className, color, lineHeight, size, numOfLines, weight }) => (
  <span
    className={classNames(
      'text',
      `text--color-${color}`,
      `text--size-${size}`,
      `text--weight-${weight}`,
      `text--line-height-${lineHeight}`,
      {
        [className]: Boolean(className),
        'text--truncate': Boolean(numOfLines),
        [`text--truncate--${numOfLines}`]: Boolean(numOfLines),
      }
    )}
  >
    {children}
  </span>
);

const commonTextProps = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(TextColor)),
  lineHeight: PropTypes.oneOf(Object.values(TextLineHeight)),
  numOfLines: PropTypes.oneOf([1, 2, 3, 4, 5]),
  size: PropTypes.oneOf(Object.values(TextSize)),
};

const commonDefaultTextProps = {
  color: TextColor.GREY,
  lineHeight: TextLineHeight.NORMAL,
  numOfLines: undefined,
  size: TextSize.LEVEL_300,
};

BaseText.propTypes = {
  ...commonTextProps,
  weight: PropTypes.oneOf(Object.values(TextWeight)),
};

BaseText.defaultProps = {
  ...commonDefaultTextProps,
  weight: TextWeight.REGULAR,
};

/**
 * Component rendering BaseText with font-weight set to regular
 * @see BaseText
 *
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {string} [props.color=TextColor.GREY]
 * @param {number} [props.numOfLines]
 * @param {string} [props.size=TextSize.LEVEL_300]
 * @return {StatelessComponent}
 */
const Regular = (props) => <BaseText {...props} weight={TextWeight.REGULAR} />;
Regular.propTypes = commonTextProps;
Regular.defaultProps = commonDefaultTextProps;

/**
 * Component rendering BaseText with font-weight set to regular
 * @see BaseText
 *
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {string} [props.color=TextColor.GREY]
 * @param {number} [props.numOfLines]
 * @param {string} [props.size=TextSize.LEVEL_300]
 * @return {StatelessComponent}
 */
const Light = (props) => <BaseText {...props} weight={TextWeight.LIGHT} />;
Light.propTypes = commonTextProps;
Light.defaultProps = commonDefaultTextProps;

/**
 * Component rendering BaseText with font-weight set to regular
 * @see BaseText
 *
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {string} [props.color=TextColor.GREY]
 * @param {number} [props.numOfLines]
 * @param {string} [props.size=TextSize.LEVEL_300]
 * @return {StatelessComponent}
 */
const Bold = (props) => <BaseText {...props} weight={TextWeight.BOLD} />;
Bold.propTypes = commonTextProps;
Bold.defaultProps = commonDefaultTextProps;

/**
 * Component rendering BaseText with font-weight set to regular
 * @see BaseText
 *
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {string} [props.color=TextColor.GREY]
 * @param {number} [props.numOfLines]
 * @param {string} [props.size=TextSize.LEVEL_300]
 * @return {StatelessComponent}
 */
const ExtraBold = (props) => <BaseText {...props} weight={TextWeight.BLACK} />;
ExtraBold.propTypes = commonTextProps;
ExtraBold.defaultProps = commonDefaultTextProps;

/**
 * @enum {StatelessComponent} Namespaced Text Components
 */
const Text = { Bold, ExtraBold, Light, Regular };

export { BaseText };
export default Text;
