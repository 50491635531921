import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Text } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { TextSize } from 'enums/typography';

import { allValues } from 'helpers/utility';

import './ProductPriceTooltipRow.scss';

/**
 * Component rendering single Product Price Tooltip data row
 * @see {ProductPriceTooltip}
 * @param {Object} props
 * @param {IconNames} props.icon
 * @param {String} props.label
 * @param {String} props.value
 * @returns {StatelessComponent}
 */
const ProductPriceTooltipRow = ({ icon, label, value }) => (
  <div className="product-price-tooltip-row">
    <div className="product-price-tooltip-row__label">
      <Icon fixedWidth={true} icon={icon} />
      <Text.Regular size={TextSize.LEVEL_200}>{label}</Text.Regular>
    </div>

    <div className="product-price-tooltip-row__value">
      <Text.Bold size={TextSize.LEVEL_200}>{value}</Text.Bold>
    </div>
  </div>
);

ProductPriceTooltipRow.propTypes = {
  icon: PropTypes.oneOf(allValues(IconNames)).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default ProductPriceTooltipRow;
