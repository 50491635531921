import { createAllIdsReducer } from 'helpers/redux';

import { fetchSinglePickupRoutine } from 'store/routines/pickups';

/**
 * Success cases with replace state flag
 */
const successCases = [{ type: fetchSinglePickupRoutine.SUCCESS, shouldReplace: false }];

/**
 * Pickups reducers: All ids reducer
 */
const pickupsAllIdsReducer = createAllIdsReducer({ successCases });

export { successCases };
export default pickupsAllIdsReducer;
