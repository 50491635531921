import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { removeClassNameFromDomNode, setClassNameOnDomNode } from 'helpers/dom';

import './FixedFooter.scss';

export const APP_LAYOUT_QUERY = '.app-layout';
export const APP_LAYOUT_CLASSNAME = 'app-layout--has-fixed-footer';

/**
 * Component rendering footer which is fixed to the bottom
 * of the page
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {string} [props.contentClassName]
 * @return {StatelessComponent}
 */
const FixedFooter = ({ children, className, contentClassName }) => {
  React.useEffect(() => {
    setClassNameOnDomNode(APP_LAYOUT_QUERY, APP_LAYOUT_CLASSNAME);

    return () => removeClassNameFromDomNode(APP_LAYOUT_QUERY, APP_LAYOUT_CLASSNAME);
  });

  return (
    <footer
      className={classNames('fixed-footer', {
        [className]: Boolean(className),
      })}
    >
      <div className={classNames('fixed-footer__content', { [contentClassName]: Boolean(contentClassName) })}>
        {children}
      </div>
    </footer>
  );
};

FixedFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

export default FixedFooter;
