/**
 * Redux action for fetching Purchase Orders
 * @constant
 */
export const FETCH_PURCHASE_ORDERS = '@purchaseOrders/FETCH_PURCHASE_ORDERS';

/**
 * Redux action for fetching Purchase Orders' next page
 * @constant
 */
export const FETCH_PURCHASE_ORDERS_NEXT_PAGE = '@purchaseOrders/FETCH_PURCHASE_ORDERS_NEXT_PAGE';

/**
 * Redux action for fetching a single Purchase Order
 * @constant
 */
export const FETCH_SINGLE_PURCHASE_ORDER = '@purchaseOrders/FETCH_SINGLE_PURCHASE_ORDER';

/**
 * Redux action for patching Purchase Order status
 * @constant
 */
export const PATCH_PURCHASE_ORDER_STATUS = '@purchaseOrders/PATCH_PURCHASE_ORDER_STATUS';
