import React from 'react';

import { OF_SUPPORT_EMAIL } from 'constants/system';

import MailToLink from '../MailToLink';

/**
 * Component rendering mail-to link with Optifarm Support email address
 * as the recipient value
 * @returns {StatelessComponent}
 */
const MailToSupportLink = () => <MailToLink email={OF_SUPPORT_EMAIL}>{OF_SUPPORT_EMAIL}</MailToLink>;

export default MailToSupportLink;
