import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import { Icon, Text } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { TextColor, TextSize } from 'enums/typography';
import { InputSize } from 'enums/ui';

import { allValues } from 'helpers/utility';

import './SelectControl.scss';

/**
 * Component rendering custom Select Control component
 * @see {Select}
 * @param {Object} props
 * @param {ReactNode} props.children
 * @param {Boolean} props.hasValue
 * @param {Object} props.selectProps
 * @return {StatelessComponent}
 */
const SelectControl = ({ children, hasValue, selectProps, ...rest }) => {
  const { error, hasError, icon, size } = selectProps;

  const iconName = hasError ? IconNames.EXCLAMATION_CIRCLE : icon;

  return (
    <div className={`select-control select-control--size-${size}`}>
      <div className="select-control__control">
        <components.Control selectProps={selectProps} {...rest}>
          {Boolean(iconName) && (
            <Icon
              className={classNames('select-control__control__icon', {
                'select-control__control__icon--has-error': hasError,
                'select-control__control__icon--has-value': hasValue,
              })}
              icon={iconName}
            />
          )}
          {children}
        </components.Control>
      </div>

      {hasError && (
        <div className="select-control__error-container">
          <Text.Regular color={TextColor.ERROR} size={TextSize.LEVEL_200}>
            {error}
          </Text.Regular>
        </div>
      )}
    </div>
  );
};

SelectControl.propTypes = {
  selectProps: PropTypes.shape({
    error: PropTypes.string,
    hasError: PropTypes.bool,
    icon: PropTypes.oneOf(allValues(IconNames)),
    size: PropTypes.oneOf(allValues(InputSize)),
  }).isRequired,
};

export default SelectControl;
