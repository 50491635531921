import placeholderImage from 'assets/placeholder.jpeg';

import { IMAGES_BASE_URL } from 'constants/api';

import { isObject } from './utility';

/**
 * Returns image full URL from given relative download uri
 * @param {(string|Image)} image
 * @returns {string}
 */
export const getImageFullUrl = (image) => {
  // Return nothing if image URL not provided
  if (!image) {
    return placeholderImage;
  }

  // If we pass Image object, return image.download uri with Optifarm Base Image Url prefix
  if (isObject(image)) {
    if (!image.downloadUri) {
      return placeholderImage;
    }

    return `${IMAGES_BASE_URL}${image.downloadUri}`;
  }

  // Return unchanged URL if we are loading local image asset
  if (image.startsWith('static/media') || image.startsWith('/static/media')) {
    return image;
  }

  // Return unchanged URL if we are loading image which was
  // just uploaded using ImageInput input
  if (image.startsWith('blob:') || image.startsWith('data:image')) {
    return image;
  }

  // Return unchanged URL if it start with either https or http
  if (image.startsWith('https') || image.startsWith('http')) {
    return image;
  }

  // Return image URL with Optifarm Base Image Url if it's a relative URL
  return `${IMAGES_BASE_URL}${image}`;
};
