import { TABLE_EMPTY_STATE_MESSAGES } from 'constants/ui';

import { PurchaseOrderStatus } from 'enums/purchaseOrders';
import { ButtonHtmlType, EntityAction, Intent, TableCellSize } from 'enums/ui';

import { ORDER_STATUS_LABELS } from 'utils/constants';

import { getProminentUserRole, isOneOfUserRolesValid } from './roles';
import { allValues, isEqual } from './utility';

/**
 * Return app container type based on the user role
 * @param {UserRoleType[]} userRoles
 * @returns {string}
 */
export const getAppContainerType = (userRoles) => {
  if (!isOneOfUserRolesValid(userRoles)) {
    return 'default';
  }

  return getProminentUserRole(userRoles);
};

/**
 * Returns true if button html type is submit
 * @param {ButtonHtmlType} buttonHtmlType
 * @returns {boolean}
 */
export const isButtonHtmlTypeSubmit = (buttonHtmlType) => {
  return isEqual(buttonHtmlType, ButtonHtmlType.SUBMIT);
};

/**
 * Returns the default cell size to be used in a table
 * component
 * @param {number} numOfColumns
 * @returns {string}
 */
export const getDefaultTableCellSize = (numOfColumns) => {
  if (!numOfColumns) {
    return null;
  }

  // Table size goes up to 20 columns (min column width is 5%)
  // For default value, we want to divide all possible columns
  // with the number of columns, and we subtract one to get the
  // correct index
  const tableSizeIndex = 20 / numOfColumns - 1;
  const tableCellSizeValues = allValues(TableCellSize);

  return tableCellSizeValues[tableSizeIndex];
};

/**
 * Returns true if passed entity action is add action
 * @param {EntityActionType} action
 * @returns {boolean}
 */
export const isEntityActionAdd = (action) => {
  return isEqual(action, EntityAction.ADD);
};

/**
 * Returns true if passed entity action is edit action
 * @param {EntityActionType} action
 * @returns {boolean}
 */
export const isEntityActionUpdate = (action) => {
  return isEqual(action, EntityAction.UPDATE);
};

/**
 * Returns true if passed entity action is delete action
 * @param {EntityActionType} action
 * @returns {boolean}
 */
export const isEntityActionDelete = (action) => {
  return isEqual(action, EntityAction.DELETE);
};

/**
 * Returns formatted Delivery Entry Sidesheet title
 * @param {EntityActionType} action
 * @returns {string}
 */
export const getAddDeliveryEntrySidesheetTitle = (action) => {
  const isAddAction = isEntityActionAdd(action);
  const prefix = isAddAction ? 'Dodaj' : 'Uredi';
  const suffix = isAddAction ? 'časovnico' : 'časovnici';

  return `${prefix} vnos na ${suffix}`;
};

/**
 * Returns formatted Delivery Entry Sidesheet confirm button text
 * @param {EntityActionType} action
 * @returns {string}
 */
export const getDeliveryEntrySidesheetConfirmButtonText = (action) => {
  const isAddAction = isEntityActionAdd(action);
  const prefix = isAddAction ? 'Dodaj' : 'Posodobi';
  const suffix = isAddAction ? 'časovnico' : 'časovnici';

  return `${prefix} vnos na ${suffix}`;
};

/**
 * Returns correct color className based on the given purchase order status
 * @param {PurchaseOrder} purchaseOrder
 * @returns {Intent} Status based intent
 */
export const getPurchaseOrderStatusIntent = (purchaseOrder) => {
  switch (purchaseOrder.status) {
    case PurchaseOrderStatus.COMPLETED: {
      return Intent.SUCCESS;
    }

    case PurchaseOrderStatus.CANCELLED:
    case PurchaseOrderStatus.FAILED:
    case PurchaseOrderStatus.REJECTED: {
      return Intent.ERROR;
    }

    case PurchaseOrderStatus.ACKNOWLEDGED:
    case PurchaseOrderStatus.PENDING:
    case PurchaseOrderStatus.PACKED: {
      return Intent.WARNING;
    }

    case PurchaseOrderStatus.IN_PROGRESS: {
      return Intent.INFO;
    }

    default: {
      return Intent.NEUTRAL;
    }
  }
};

/**
 * Returns purchase order status UI text
 * @param {PurchaseOrder} purchaseOrder
 * @returns {string} - Purchase Order status text
 */
export const getPurchaseOrderStatusText = (purchaseOrder) => {
  return ORDER_STATUS_LABELS[purchaseOrder.status] || 'Status naročila ni znan';
};

/**
 * Returns correct title string based on the passed isActive param
 * @param {Boolean} isActive
 * @returns {String}
 */
export const getProductStatusIndicatorTitleText = (isActive, isLoading) => {
  if (isLoading) {
    return 'Nalagam podatke...';
  }

  let base = 'Izdelek ';

  if (isActive) {
    base += 'aktiven';
  } else {
    base += 'deaktiviran';
  }

  return base;
};

/**
 * Returns correct description string based on the passed isActive param
 * @param {Boolean} isActive
 * @returns {String}
 */
export const getProductStatusIndicatorDescriptionText = (isActive) => {
  const adverb = isActive ? 'je' : 'ni';

  return `Izdelek ${adverb} viden v aplikaciji`;
};

/**
 * Returns correct Intent value for a given isActive param
 * @param {Boolean} isActive
 * @param {Boolean} isLoading
 * @returns {Intent}
 */
export const getProductStatusIndicatorIntent = (isActive, isLoading) => {
  if (isLoading) {
    return Intent.NEUTRAL;
  }

  return isActive ? Intent.SUCCESS : Intent.ERROR;
};

/**
 * Returns formatted Pageable Table empty state title
 * @param {String} [searchKey]
 * @param {Object} [options = {}]
 * @returns {String}
 */
export const getTableEmptyResultsTitle = (searchKey, options = {}) => {
  if (searchKey) {
    const prefixKey = 'emptySearchResultsTitlePrefix';
    const suffixKey = 'emptySearchResultsTitleSuffix';
    const titlePrefix = options[prefixKey] || TABLE_EMPTY_STATE_MESSAGES[prefixKey];
    const titleSuffix = options[suffixKey] || TABLE_EMPTY_STATE_MESSAGES[suffixKey];

    return `${titlePrefix} "${searchKey}" ${titleSuffix}`;
  }

  return options.emptyResultsTitleText || TABLE_EMPTY_STATE_MESSAGES.emptyResultsTitleText;
};

/**
 * Returns formatted Pageable Table empty state text
 * @param {String} [searchKey]
 * @param {Object} [options = {}]
 * @returns {String}
 */
export const getTableEmptyResultsText = (searchKey, options = {}) => {
  if (searchKey) {
    return options.emptySearchResultsBodyText || TABLE_EMPTY_STATE_MESSAGES.emptySearchResultsBodyText;
  }

  return options.emptyResultsBodyText || TABLE_EMPTY_STATE_MESSAGES.emptyResultsBodyText;
};

/**
 * Returns formatted Pegeable Table empty state title and text
 * @param {Object} options
 * @param {String} [options.searchKey]
 * @param {Object} [options.options]
 * @param {Object} [options.options.titleTexts]
 * @param {Object} [options.options.bodyTexts]
 * @returns {{title: String, text: String}}
 */
export const getTableEmptyResultsTitleAndText = ({ searchKey, options } = {}) => ({
  title: getTableEmptyResultsTitle(searchKey, options?.titleTexts),
  text: getTableEmptyResultsText(searchKey, options?.bodyTexts),
});
