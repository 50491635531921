export enum PostNameRegions {
  DOLENJSKA = 'dolenjska',
  GORENJSKA = 'gorenjska',
  GORISKA = 'goriška',
  KOROSKA = 'koroška',
  OSREDNJESLOVENSKA = 'osrednjeslovenska',
  OBALNO_KRASKA = 'obalno-kraška',
  PODRAVSKA = 'podravska',
  POMURSKA = 'pomurska',
  PRIMORSKO_NOTRANJSKA = 'primorsko_notranjska',
  SAVINJSKA = 'savinjska',
  UNKNOWN = 'ostalo',
}
