import { isNumber } from './utility';

/**
 * Returns postCode and postName from post string
 * @param {string} postString
 * @returns {PostCodeAndName}
 */
export const getPostCodeAndNameFromPostString = (postString) => {
  // "postString" should always be in the following format:
  // postCode, postName
  const [postCode, postName] = postString?.split(',') || [];

  return {
    postCode: isNumber(postCode) ? parseInt(postCode, 10) : undefined,
    postName: postName?.trim(),
  };
};
