/**
 * Text displayed in the empty state body when there are no results
 * @constant
 */
export const EMPTY_BODY =
  'V vaš seznam izdelkov še niste dodali nobenega izdelka. S klikom na spodnji gumb “Dodaj nov izdelek” lahko dodate svoj prvi izdelek.';

/**
 * Text displayed in the empty state body when there are no search results
 * @constant
 */
export const EMPTY_SEARCH_BODY =
  'Za vnešen iskalni niz nismo našli rezultatov. Ponovite iskanje z drugim iskalnim nizom oziroma kliknite na “Prikaži vse izdelke” za pregled vseh izdelkov.';

/**
 * Text displayed in the empty state title when there are no results
 * @constant
 */
export const EMPTY_TITLE = 'Vaš seznam izdelkov je prazen';

/**
 * Text displayed in the "Show all results" button
 * @constant
 */
export const BUTTON_SHOW_ALL = 'Prikaži vse izdelke';

/**
 * Text displayed in the refresh button
 * @constant
 */
export const BUTTON_REFRESH = 'Osveži seznam';

/**
 * Text displayed in the add new product button
 */
export const BUTTON_ADD_NEW = 'Dodaj nov izdelek';
