import { createRoutine } from 'redux-saga-routines';

import { FETCH_PRODUCT_PRICE, PATCH_PRODUCT_PRICE } from 'store/types/productPrices';

/**
 * Redux routine responsible for dispatching FETCH_PRODUCT_PRICE related action
 */
export const fetchProductPriceRoutine = createRoutine(FETCH_PRODUCT_PRICE);

/**
 * Redux routine responsible for dispatching PATCH_PRODUCT_PRICE related actions
 */
export const patchProductPriceRoutine = createRoutine(PATCH_PRODUCT_PRICE, null, {
  success: (_, meta) => ({ ...meta.meta }),
});
