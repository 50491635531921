import { connect } from 'react-redux';

import { patchPurchaseOrderStatusRoutine } from 'store/routines/purchaseOrders';

import OrderCancellationButton from './OrderCancellationButton';

export const mapDispatchToProps = {
  updatePurchaseOrderStatus: patchPurchaseOrderStatusRoutine,
};

export default connect(null, mapDispatchToProps)(OrderCancellationButton);
