import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { ButtonType, IconPosition, Intent } from 'enums/ui';

import { valueOrEmptyObject } from 'helpers/utility';

/**
 * Component rendering table cell with Update Product Price button
 * @param {Object} props
 * @param {Object} props.cell
 * @returns {StatelessComponent}
 */
const UpdateProductPriceButtonCell = ({ cell: { column } }) => (
  <Button
    {...valueOrEmptyObject(column.props)}
    fullwidth={true}
    icon={IconNames.SAVE}
    iconPosition={IconPosition.RIGHT}
    intent={Intent.SUCCESS}
    onClick={column.onClick}
    type={ButtonType.GHOST}
  >
    Posodobi ceno
  </Button>
);

UpdateProductPriceButtonCell.propTypes = {
  cell: PropTypes.shape({
    column: PropTypes.shape({
      props: PropTypes.shape(),
      onClick: PropTypes.func.isRequired,
    }),
  }).isRequired,
};

export default UpdateProductPriceButtonCell;
