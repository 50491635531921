import { MerchantsEndpoints } from 'constants/endpoints';

import { ApiRequestType } from 'enums/api';

import fetchApi from './common';

/**
 * GET merchants api request.
 * @param {number} [page=0]
 * @returns {Promise<Payload>}
 */
export const getMerchants = async (page = 0) => {
  return fetchApi({ data: { page }, needsAuth: true, url: MerchantsEndpoints.ALL });
};

/**
 * GET single merchant api request
 * @param {Id} merchantId
 * @returns {Promise<Payload>}
 */
export const getMerchant = async (merchantId) => {
  return fetchApi({ needsAuth: false, url: MerchantsEndpoints.SINGLE(merchantId) });
};

/**
 * PUT merchant company info API request
 * @param {object} options
 * @param {Id} options.merchantId
 * @param {Payload} options.payload
 * @returns {Promise<Payload>}
 */
export const putMerchantCompanyInfo = async ({ merchantId, payload }) => {
  return fetchApi({
    data: payload,
    method: ApiRequestType.PUT,
    needsAuth: true,
    url: MerchantsEndpoints.SINGLE(merchantId),
  });
};
