import _isEqual from 'lodash/isEqual';

import { CommonRegex } from 'constants/regex';

/**
 * Returns true is provided values are strict equal
 * @function
 * @param {*} a
 * @param {*} b
 * @return {boolean} boolean
 */
export const isEqual = (a, b) => _isEqual(a, b);

/**
 * Returns true is provided values are not strict equal
 * @function
 * @param {*} a
 * @param {*} b
 * @return {boolean} boolean
 */
export const isNotEqual = (a, b) => !isEqual(a, b);

/**
 * Returns true if passed param is a string
 * @param {*} value
 * @returns {boolean}
 */
export const isString = (value) => {
  return isEqual(typeof value, 'string');
};

/**
 * Returns true if two strings are equal. Accepts ignoreCase as an option parameter.
 * @param {string} a
 * @param {string} b
 * @param {Object} options
 * @param {Boolean} options.ignoreCase
 * @returns {Boolean}
 */
export const isStringEqual = (a, b, options = {}) => {
  // if either a or b is undefined and/or null, we return false
  if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
    return false;
  }

  // We first want to convert the passed values to strings in order to make sure
  // all string operations are available to us
  let aStr = a?.toString();
  let bStr = b?.toString();

  if (options.ignoreCase) {
    // if ignoreCase is set to true, we want to normalize string casing to be the
    // same in both strings
    aStr = aStr?.toLowerCase();
    bStr = bStr?.toLowerCase();
  }

  // Finally, we compare equality between the two strings
  return isEqual(aStr, bStr);
};

/**
 * Returns true if passed param is a number
 * @param {*} value
 * @returns {boolean}
 */
export const isNumber = (value) => {
  if (!value) {
    return false;
  }

  let parsedValue = value;

  // if string, first parse the string
  if (isString(value)) {
    const regex = new RegExp(CommonRegex.LETTERS);

    if (value.match(regex)) {
      // String has characters, return false
      return false;
    }

    // Set the value to parsed value of the string with numbers
    parsedValue = parseInt(value, 10);
  }

  // If NaN, return false
  if (Number.isNaN(parsedValue)) {
    return false;
  }

  return Number.isFinite(parsedValue);
};

/**
 * Returns true if passed param is object
 * @function
 * @param {*} obj
 * @returns {boolean} boolean
 */
export const isObject = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return false;
  }

  if (Array.isArray(obj)) {
    return false;
  }

  return true;
};

/**
 * Returns true if given value is null
 * @param {*} value
 * @returns {boolean} boolean
 */
export const isNull = (value) => {
  return value === null;
};

/**
 * Returns true if given value is undefined
 * @param {*} value
 * @returns {boolean} boolean
 */
export const isUndefined = (value) => {
  return value === undefined;
};

/**
 * Returns true if given value is null or undefined
 * @param {*} value
 * @returns {boolean} boolean
 */
export const isNullOrUndefined = (value) => {
  return isNull(value) || isUndefined(value);
};

/**
 * Returns true if passed value is instance of passed instance
 * @param {*} value
 * @param {*} instance
 * @returns {boolean}
 */
export const isInstanceOf = (value, instance) => {
  if (isNullOrUndefined(value) || isNullOrUndefined(instance)) {
    return false;
  }

  return value instanceof instance;
};

/**
 * Returns all object keys, or empty array if object is undefined/null.
 * @param {ObjectMaybe} obj
 * @return {Array<*>}
 */
export const allKeys = (obj) => {
  if (!obj || !isObject(obj)) {
    return [];
  }

  return Object.keys(obj);
};

/**
 * Returns all object values, or empty array if object is undefined/null.
 * @param {ObjectMaybe} obj
 * @return {Array<*>}
 */
export const allValues = (obj) => {
  if (!obj || !isObject(obj)) {
    return [];
  }

  return Object.values(obj);
};

/**
 * Returns all object entries, or empty array if object is undefined/null
 * @param {ObjectMaybe} obj
 * @returns {any[]}
 */
export const allEntries = (obj) => {
  if (!obj || !isObject(obj)) {
    return [];
  }

  return Object.entries(obj);
};

/**
 * Returns value if provided or default value otherwise
 * @param {*} val
 * @param {*} defaultVal
 * @returns {*}
 */
export const valueOrDefault = (val, defaultVal) => {
  return val ?? defaultVal;
};

/**
 * Returns value if provided or empty object otherwise
 * @param {*} val
 * @returns {*}
 */
export const valueOrEmptyObject = (val) => {
  return valueOrDefault(val, {});
};

/**
 * Returns the length of given array
 * @param {any[]} arr
 * @returns {number}
 */
export const lengthOf = (arr) => {
  return Number(arr?.length || 0);
};

/**
 * Returns true if passed array is not empty
 * @param {any[]} arr
 * @returns {Boolean}
 */
export const hasLength = (arr) => {
  return lengthOf(arr) > 0;
};

/**
 * Returns true if given array has a length of zero
 * @param {any[]} arr
 * @returns {boolean}
 */
export const hasZeroLength = (arr) => {
  return isEqual(lengthOf(arr), 0);
};
