import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchProductsRoutine } from 'store/routines/products';
import {
  allProductsSelector,
  productsIsFetchingSelector,
  productsLastFetchedSelector,
  productsPaginationSelector,
} from 'store/selectors/productsSelectors';

import ProductsOverview from './ProductsOverview';

export const mapStateToProps = createStructuredSelector({
  isLoading: productsIsFetchingSelector,
  lastFetched: productsLastFetchedSelector,
  products: allProductsSelector,
  productsPagination: productsPaginationSelector,
});

export const mapDispatchToProps = {
  onFetchProducts: fetchProductsRoutine.trigger,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsOverview);
