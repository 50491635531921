import { connectRouter } from 'connected-react-router';
import type { BrowserHistory } from 'history';
import { combineReducers } from 'redux';

import type { ReduxState } from 'store/interfaces';

import categoriesReducer from './categoriesReducer';
import { deliveryDaysReducer } from './deliveryDaysReducer';
import { merchantsReducer } from './merchantsReducer';
import pickupsReducer from './pickupsReducer/root';
import productPricesReducer from './productPricesReducer';
import productsReducer from './productsReducer';
import purchaseOrdersReducer from './purchaseOrdersReducer';
import { sidesheetsReducer } from './sidesheetsReducer';
import uiReducer from './uiReducer';
import unitOfMeasurementsReducer from './unitOfMeasurementsReducer';
import userReducer from './userReducer';
import vatsReducer from './vatsReducer';

const createRootReducer = (history: BrowserHistory) => {
  const routerReducer = connectRouter(history);

  return combineReducers<ReduxState>({
    categories: categoriesReducer,
    deliveryDays: deliveryDaysReducer,
    merchants: merchantsReducer,
    pickups: pickupsReducer,
    productPrices: productPricesReducer,
    products: productsReducer,
    purchaseOrders: purchaseOrdersReducer,
    router: routerReducer,
    sidesheets: sidesheetsReducer,
    ui: uiReducer,
    unitOfMeasurements: unitOfMeasurementsReducer,
    user: userReducer,
    vats: vatsReducer,
  });
};

export default createRootReducer;
