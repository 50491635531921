import {
  createStatusAwarePaginationReducer,
  gatherReducerCasesFromRoutines,
  isPurchaseOrdersActionRelatedToFinalizedOrders,
} from 'helpers/redux';

import { fetchPurchaseOrdersNextPageRoutine, fetchPurchaseOrdersRoutine } from 'store/routines/purchaseOrders';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Success cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([
  fetchPurchaseOrdersRoutine,
  fetchPurchaseOrdersNextPageRoutine,
]);

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Finalized Purchase Orders Pagination Reducer
 */
const activePurchaseOrdersPaginationReducer = createStatusAwarePaginationReducer({
  resetCases,
  statusCheckFunction: isPurchaseOrdersActionRelatedToFinalizedOrders,
  successCases,
});

export default activePurchaseOrdersPaginationReducer;
