import { call, put } from 'redux-saga/effects';

import * as api from 'api/vats';

import { getErrorMessageFromResponse } from 'helpers/errors';
import { sagaWatcher } from 'helpers/redux';

import { fetchVatsRoutine } from 'store/routines/vats';

/**
 * Fetch vats saga
 * @returns {Generator}
 */
export function* fetchVatsSaga() {
  let errorData = {};

  yield put(fetchVatsRoutine.request());

  try {
    const response = yield call(api.getVats);

    if (response.isSuccess) {
      yield put(fetchVatsRoutine.success(response.data));
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield put(fetchVatsRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Root vats saga
 * @returns {IterableIterator}
 */
export default function* vatsSagas() {
  yield sagaWatcher([
    {
      type: fetchVatsRoutine.TRIGGER,
      saga: fetchVatsSaga,
    },
  ]);
}
