import PropTypes from 'prop-types';

import { CurrencyCode } from 'enums/currencies';
import { MerchantStatus } from 'enums/merchants';
import { UserRole } from 'enums/roles';

import { allValues } from 'helpers/utility';

/**
 * @constant {PropTypes.Requireable} CommonImageProps
 */
export const imageDtoProp = PropTypes.shape({
  downloadUri: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  imageName: PropTypes.string,
});

/**
 * @constant {PropTypes.Requireable} UserRolesProp
 */
export const UserRolesProp = PropTypes.arrayOf(PropTypes.oneOf(allValues(UserRole)));

/**
 * @constant {PropTypes.Requireable} refProp
 */
export const refProp = PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]);

/**
 * @constant {PropTypes.Requireable} paginationProp
 */
export const paginationProp = PropTypes.shape({
  currentPage: PropTypes.number,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  totalPages: PropTypes.number,
});

/**
 * @constant {PropTypes.Requireable} FormInputProps
 */
export const formInputPropType = PropTypes.shape({
  checked: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.any,
});

/**
 * @constant {PropTypes.Requireable} FormMetaProps
 */
export const formMetaPropType = PropTypes.shape({
  active: PropTypes.bool,
  autofilled: PropTypes.bool,
  asyncValidating: PropTypes.bool,
  dirty: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  form: PropTypes.string,
  initial: PropTypes.any,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
  visited: PropTypes.bool,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
});

/**
 * Pageable Table components prop
 * @type {import('prop-types').Requireable}
 * @constant
 */
export const pageableTableComponentsProp = PropTypes.shape({
  LoadingState: PropTypes.elementType,
  ErrorState: PropTypes.elementType,
  EmptyResults: PropTypes.elementType,
});

/**
 * Vat DTO prop
 * @type {import('prop-types').Requireable}
 * @constant
 */
export const vatDtoProp = PropTypes.shape({
  id: PropTypes.number,
  tax: PropTypes.number,
});

/**
 * Unit of Measure DTO prop
 * @type {import('prop-types').Requireable}
 * @constant
 */
export const unitOfMeasureDtoProp = PropTypes.shape({
  id: PropTypes.number,
  unitName: PropTypes.string,
  unitShortName: PropTypes.string,
});

/**
 * Price DTO prop
 * @type {import('prop-types').Requireable}
 * @constant
 */
export const priceDtoProp = PropTypes.shape({
  currency: PropTypes.oneOf(allValues(CurrencyCode)),
  dutyFreeAmount: PropTypes.number,
  id: PropTypes.number,
  taxIncludedAmount: PropTypes.number,
  unitOfMeasure: unitOfMeasureDtoProp,
  vat: vatDtoProp,
});

/**
 * Propduct Price DTO prop
 * @type {import('prop-types').Requireable}
 * @constant
 */
export const productPriceDtoProp = PropTypes.shape({
  id: PropTypes.number,
  currency: PropTypes.oneOf(allValues(CurrencyCode)),
  dutyFreeAmount: PropTypes.number,
  taxIncludedAmount: PropTypes.number,
  unitOfMeasure: unitOfMeasureDtoProp,
  vat: vatDtoProp,
  activePrice: PropTypes.bool,
});

/**
 * Company DTO prop
 * @type {import('prop-types').Requireable}
 * @constant
 */
export const companyDtoProp = PropTypes.shape({
  id: PropTypes.number,
  address: PropTypes.string,
  companyName: PropTypes.string,
  email: PropTypes.string,
  freeLocalDeliveryThreshold: PropTypes.number,
  freeRegionalDeliveryThreshold: PropTypes.number,
  image: imageDtoProp,
  importance: PropTypes.number,
  isHeadOffice: PropTypes.bool,
  isLegalEntity: PropTypes.bool,
  localDeliveryPrice: PropTypes.number,
  minHomeDeliveryThreshold: PropTypes.number,
  postCode: PropTypes.string,
  postName: PropTypes.string,
  regionalDeliveryPrice: PropTypes.number,
  status: PropTypes.oneOf(allValues(MerchantStatus)),
  statusDateTime: PropTypes.number,
  validDelivery: PropTypes.bool,
  validPickupPoint: PropTypes.bool,
});

/**
 * Producer DTO prop
 * @type {import('prop-types').Requireable}
 * @constant
 */
export const producerDtoProp = PropTypes.shape({
  id: PropTypes.number,
  producerCountry: PropTypes.string,
  producerName: PropTypes.string,
});

/**
 * Category DTO prop
 * @type {import('prop-types').Requireable}
 * @constant
 */
export const categoryDtoProp = PropTypes.shape({
  id: PropTypes.number,
  categoryName: PropTypes.string,
  code: PropTypes.string,
  importance: PropTypes.number,
  productCount: PropTypes.number,
});

/**
 * Product DTO prop
 * @type {import('prop-types').Requireable}
 * @constant
 */
export const productDtoProp = PropTypes.shape({
  id: PropTypes.number,
  active: PropTypes.bool,
  adultConsent: PropTypes.bool,
  category: categoryDtoProp,
  company: companyDtoProp,
  externalId: PropTypes.string,
  images: PropTypes.arrayOf(imageDtoProp),
  origin: PropTypes.string,
  producer: producerDtoProp,
  productDescription: PropTypes.string,
  productName: PropTypes.string,
  productPrice: productPriceDtoProp,
});
