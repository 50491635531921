import PropTypes from 'prop-types';
import React from 'react';

import './DropdownOptionWrapper.scss';

/**
 * Wrapper component for single dropdown option
 * @param {Object} props
 * @param {ReactNode} props.children
 * @param {Function} props.onClick
 * @param {Function} props.onToggle
 * @return {StatelessComponent}
 */
const DropdownOptionWrapper = ({ children, onClick, onToggle }) => {
  const handleClick = (evt) => {
    onClick(evt);
    onToggle(evt);
  };

  return (
    <div className="dropdown-option-wrapper" onClick={handleClick}>
      {children}
    </div>
  );
};

DropdownOptionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default DropdownOptionWrapper;
