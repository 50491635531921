/**
 * Redux action for fetching products
 * @constant
 */
export const FETCH_PRODUCTS = '@products/FETCH_PRODUCTS';

/**
 * Redux action for fetching a single product
 * @constant
 */
export const FETCH_SINGLE_PRODUCT = '@products/FETCH_SINGLE_PRODUCT';

/**
 * Redux action for updateing a product
 * @constant
 */
export const UPDATE_PRODUCT = '@products/UPDATE_PRODUCT';
