import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms';

import { TextSize } from 'enums/typography';
import { Intent } from 'enums/ui';

import { allValues } from 'helpers/utility';

import './StatusTag.scss';

/**
 * Component rendering generic status tag
 * @param {Object} props
 * @param {String} [props.className]
 * @param {Boolean} [props.fullwidth]
 * @param {Intent} props.intent
 * @param {String} props.text
 * @returns {StatelessComponent}
 */
const StatusTag = ({ className, fullwidth, intent, text }) => (
  <div className={classNames('status-tag', { [className]: Boolean(className), 'status-tag--fullwidth': fullwidth })}>
    <div className={`status-tag__indicator status-tag__indicator--${intent}`} />
    <div className="status-tag__label">
      <Text.Bold size={TextSize.LEVEL_200}>{text}</Text.Bold>
    </div>
  </div>
);

StatusTag.propTypes = {
  className: PropTypes.string,
  fullwidth: PropTypes.bool,
  intent: PropTypes.oneOf(allValues(Intent)).isRequired,
  text: PropTypes.string.isRequired,
};

export default StatusTag;
