import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { Text } from 'components/atoms';

import { FlowDirection } from 'enums/ui';
import { allValues } from 'helpers/utility';

import './RadioSelect.scss';

/**
 * Component rendering single radio input field. Must be used
 * in the context of Unform Form.
 * @param {ComponentProps} props
 * @param {FlowDirection} [props.direction=FlowDirection.VERTICAL]
 * @param {boolean} [props.disabled=false]
 * @param {FormInput} props.input
 * @param {string} [props.label]
 * @param {string} props.name
 * @param {object[]} props.options
 * @return {StatelessComponent}
 */
const RadioInput = ({ direction, disabled, label, name, options }) => (
  <div className="radio-select">
    {Boolean(label) && <Text.Regular className="radio-select__label">{label}</Text.Regular>}

    <div className={`radio-select__inputs radio-select__inputs--direction-${direction}`}>
      {options.map((option) => (
        <div key={option.value} className="radio-select__inner">
          <Field
            className="radio-select__inner__input"
            component="input"
            disabled={disabled}
            id={option.value}
            name={name}
            type="radio"
            value={option.value}
          />
          <label className="radio-select__inner__indicator" htmlFor={option.value} />
          <label className="radio-select__inner__label" htmlFor={option.value}>
            {option.label || option.value}
          </label>
        </div>
      ))}
    </div>
  </div>
);

RadioInput.propTypes = {
  direction: PropTypes.oneOf(allValues(FlowDirection)),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
};

RadioInput.defaultProps = {
  direction: FlowDirection.VERTICAL,
  disabled: false,
};

export default RadioInput;
