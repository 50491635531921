import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, LoadingIndicator, Tooltip } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { PurchaseOrderStatus } from 'enums/purchaseOrders';
import { ButtonType, IconPosition, Intent } from 'enums/ui';
import {
  isTransitionToCompletedPurchaseOrderStatusDisabled,
  isPurchaseOrderStatusPacked,
} from 'helpers/purchaseOrders';

import './OrderConfirmationButtons.scss';

/**
 * Component rendering Purcase Order Details confirmation
 * buttons (mark as ready and mark as completed)
 * @param {ComponentProps} props
 * @param {boolean} props.isLoading
 * @param {PurchaseOrder} props.purchaseOrder
 * @param {boolean} props.visible
 * @param {Function} props.updatePurchaseOrder
 * @return {StatelessComponent}
 */
const OrderConfirmationButtons = ({ isLoading, purchaseOrder, visible, updatePurchaseOrderStatus }) => {
  const markAsCompletedDisabled = isTransitionToCompletedPurchaseOrderStatusDisabled(purchaseOrder);
  const isStatusPacked = isPurchaseOrderStatusPacked(purchaseOrder);

  const markAsCompleted = () => {
    updatePurchaseOrderStatus({ id: purchaseOrder.id, status: PurchaseOrderStatus.COMPLETED });
  };

  const markAsReady = () => {
    updatePurchaseOrderStatus({ id: purchaseOrder.id, status: PurchaseOrderStatus.PACKED });
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={classNames('order-details-footer-right', { 'order-details-footer-right--is-loading': isLoading })}>
      {isLoading && (
        <div className="order-details-footer-right__loading-indicator">
          <LoadingIndicator type="primary" />
        </div>
      )}
      <Tooltip
        className="order-details-footer__item"
        content="Zaključek naročila ni mogoč, ker naročilo s tipom plačila Plačilo po predračunu še ni bilo plačano. Preden lahko označite naročilo kot zaključeno, posodobite status plačila."
        visible={markAsCompletedDisabled}
      >
        <Button
          disabled={markAsCompletedDisabled}
          icon={IconNames.CHECK}
          iconPosition={IconPosition.RIGHT}
          intent={Intent.SUCCESS}
          onClick={markAsCompleted}
          type={isStatusPacked ? ButtonType.PRIMARY : ButtonType.GHOST}
        >
          Zaključek nakupa
        </Button>
      </Tooltip>

      {!isStatusPacked && (
        <Button
          className="order-details-footer__item"
          icon={IconNames.DELIVERY}
          iconPosition={IconPosition.RIGHT}
          intent={Intent.SUCCESS}
          onClick={markAsReady}
          type={ButtonType.PRIMARY}
        >
          Pripravljeno za prevzem
        </Button>
      )}
    </div>
  );
};

OrderConfirmationButtons.propTypes = {
  isLoading: PropTypes.bool,
  orderId: PropTypes.number.isRequired,
  purchaseOrder: PropTypes.shape({}),
  visible: PropTypes.bool,
  updatePurchaseOrderStatus: PropTypes.func.isRequired,
};

export default OrderConfirmationButtons;
