import { createErrorReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchVatsRoutine } from 'store/routines/vats';

/**
 * Map of cases gathered from routines
 */
const { successCases, requestCases, failureCases } = gatherReducerCasesFromRoutines([fetchVatsRoutine]);

/**
 * Vats error reducer
 */
const errorReducer = createErrorReducer({ failureCases, resetCases: [...successCases, ...requestCases] });

export { failureCases, successCases, requestCases };
export default errorReducer;
