import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { PageableTable } from 'components/elements';

import { paginationProp, productDtoProp } from 'constants/props';

import { getJoinedPath } from 'helpers/routing';

import { ProductsTableEmptyState, ProductsTableErrorState } from './components';
import { getProductsTableColumns } from './data';

/**
 * Component rendering (pageable) Products Table
 * @param {Object} props
 * @param {Function} props.fetchProducts
 * @param {Product[]} props.products
 * @param {Object} props.rest
 * @returns {StatelessComponent}
 */
const ProductsTable = ({ fetchProducts, products, ...rest }) => {
  const history = useHistory();

  const handleEditProductClick = (row) => {
    history.push(getJoinedPath('products', row.original.id));
  };

  const columns = getProductsTableColumns({ onEditProduct: handleEditProductClick });

  return (
    <PageableTable
      columns={columns}
      components={{ ErrorState: ProductsTableErrorState, EmptyResults: ProductsTableEmptyState }}
      data={products}
      onFetchData={fetchProducts}
      onRowClick={handleEditProductClick}
      title="Seznam izdelkov"
      {...rest}
    />
  );
};

ProductsTable.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  pagination: paginationProp.isRequired,
  products: PropTypes.arrayOf(productDtoProp).isRequired,
};

export default ProductsTable;
