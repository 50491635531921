import PropTypes from 'prop-types';
import React from 'react';

import { getImageFullUrl } from 'helpers/images';

/**
 * Component rendering image. Supports passing ImageDTO object
 * as a src prop.
 *
 * @param {ComponentProps} props
 * @param {string|Image} props.src
 * @param {string} props.alt
 * @return {StatelessComponent}
 */
const Image = ({ src, alt, ...rest }) => {
  const imageUrl = React.useMemo(() => {
    return getImageFullUrl(src);
  }, [src]);

  return <img alt={alt} src={imageUrl} {...rest} decoding="async" loading="lazy" />;
};

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ downloadUri: PropTypes.string })]).isRequired,
  alt: PropTypes.string,
};

export default Image;
