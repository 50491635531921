import { createIsFetchingReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchVatsRoutine } from 'store/routines/vats';

/**
 * Map of cases gathered from rouines
 */
const { requestCases, successCases, failureCases } = gatherReducerCasesFromRoutines([fetchVatsRoutine]);

/**
 * Vats is fetching reducer
 */
const isFetchingReducer = createIsFetchingReducer({ endCases: [...successCases, ...failureCases], requestCases });

export { successCases, failureCases, requestCases };
export default isFetchingReducer;
