import dayjs from 'dayjs';

/**
 * Returns formatted date string from provided timestamp
 * @function
 * @param {number} timestamp
 * @return {string} Formatted date string
 */
export const getFormattedDateString = (timestamp) => {
  if (!timestamp) {
    return null;
  }

  return dayjs(timestamp).format('DD. MM. YYYY');
};

/**
 * Returns formatted time string from provided local time string
 * @function
 * @param {string} localtime
 * @returns {string} Formatted time string
 */
export const getFormattedTimeStringFromLocalTime = (localtime) => {
  if (!localtime) {
    return null;
  }

  // The localtime from the server comes in format
  // "00:00:00" which is not how UI wants to display it.
  // The expected format for UI is: "00:00", hence the last
  // 3 characters are sliced of from a string
  return localtime.slice(0, -3);
};
