import { IconNames } from 'enums/icons';
import { Intent } from 'enums/ui';
import { capitalize } from 'helpers/textHelpers';
import { isEqual } from 'helpers/utility';

/**
 * Returns formatted delivery timeline entry title
 * @param {object} options
 * @param {string} options.title
 * @param {string[]} options.postcodes
 * @returns {string}
 */
export const getDeliveryTimelineEntryTitle = ({ title, postcodes = [] }) => {
  return `${capitalize(title)} (${postcodes.length})`;
};

/**
 * Returns true if postcodes string is truncated (i.e. number of postcodes is
 * greater than 2)
 * @param {string[]} postcodes
 * @returns {boolean}
 */
export const isPostcodesStringTruncated = (postcodes = []) => {
  return postcodes.length > 2;
};

/**
 * Returns formatted postcodes snippet text
 * @param {PostCodeEntity[]} postcodes
 * @returns {string}
 */
export const getDeliveryTimelineEntryPostcodesSnippet = (postcodes = []) => {
  const shouldTruncate = isPostcodesStringTruncated(postcodes);
  const codes = postcodes.map((postCodeEntity) => postCodeEntity.postCode);

  if (!shouldTruncate) {
    return codes.join(', ');
  }

  const [first, second, ...rest] = codes;

  const numOfHiddenPostcodes = rest.length;
  let suffix = 'drugih';

  if (isEqual(numOfHiddenPostcodes, 1)) {
    // in ena druga poštna ševilka
    suffix = 'druga';
  } else if (isEqual(numOfHiddenPostcodes, 2)) {
    // in dve drugi poštni ševilki
    suffix = 'drugi';
  } else if (isEqual(numOfHiddenPostcodes, 3) || isEqual(numOfHiddenPostcodes, 4)) {
    // in tri/štiri druge poštne ševilke
    suffix = 'druge';
  }

  return `${first}, ${second} in ${numOfHiddenPostcodes} ${suffix}`;
};

/**
 * Returns formatted string for Post Codes tooltip content based on given array of post codes
 * entities
 *
 * @param {PostCodeEntity[]} postcodes
 * @returns {string}
 */
export const getDeliveryTimelineEntryPostCodesTooltipContent = (postcodes = []) => {
  return postcodes.map((postCodeEntity) => `${postCodeEntity.postCode} ${postCodeEntity.postName}`).join(', ');
};

/**
 * Returns array of actions used in the tooltip content
 * @param {object} options
 * @param {Function} options.onDelete
 * @param {Function} options.onDuplicate
 * @param {Function} options.onEdit
 * @param {Function} options.onMove
 * @param {string[]} options.postcodes
 * @returns {object[]}
 */
export const getDeliveryTimelineEntryTooltipActions = ({ onDelete, onDuplicate, onEdit, onMove, postcodes }) => {
  return [
    { params: postcodes, name: 'Uredi vnos', callback: onEdit, icon: IconNames.EDIT },
    { params: postcodes, name: 'Premakni vnos', callback: onMove, icon: IconNames.MOVE, disabled: true },
    { params: postcodes, name: 'Podvoji vnos', callback: onDuplicate, icon: IconNames.COPY, disabled: true },
    {
      params: postcodes,
      name: 'Izbriši vnos',
      callback: onDelete,
      icon: IconNames.TRASH,
      intent: Intent.ERROR,
      disabled: true,
    },
  ];
};
