/**
 * Redux action for openning Add Delivery Day side sheet
 * @constant
 */
export const ADD_DELIVERY_DAY_SIDESHEET_OPEN = '@sidesheets/ADD_DELIVERY_DAY_SIDESHEET_OPEN';

/**
 * Redux action for closing Add Delivery Day side sheet
 * @constant
 */
export const ADD_DELIVERY_DAY_SIDESHEET_CLOSE = '@sidesheets/ADD_DELIVERY_DAY_SIDESHEET_CLOSE';
