import { createReducer } from '@reduxjs/toolkit';

import { UI_REDUCER_INITIAL_STATE } from 'constants/ui';

import { LocalStorageKeys } from 'enums/localStorage';

import { onRemoveSelectedMerchant, onSetSelectedMerchant } from 'store/actions/uiActions';
import { UiState } from 'store/interfaces/uiState.types';

/**
 * Reducers: UI reducer
 */
const uiReducer = createReducer<UiState>(UI_REDUCER_INITIAL_STATE, (builder) => {
  return builder
    .addCase(onSetSelectedMerchant, (state, action) => {
      localStorage.setItem(LocalStorageKeys.SELECTED_MERCHANT_ID, action.payload.selectedMerchantId.toString());
      state.selectedMerchantId = action.payload.selectedMerchantId;
    })
    .addCase(onRemoveSelectedMerchant, (state) => {
      localStorage.removeItem(LocalStorageKeys.SELECTED_MERCHANT_ID);
      state.selectedMerchantId = null;
    });
});

export default uiReducer;
