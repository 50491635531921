import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/atoms';

import { paginationProp } from 'constants/props';

import { IconNames } from 'enums/icons';
import { ButtonSize, ButtonType, Intent } from 'enums/ui';

import { TablePaginationActionsContent } from './components';

import './TablePaginationActions.scss';

/**
 * Component rendering Table Pagination Actions. Meant to be used as a part
 * of TableHeader or TableFooter component.
 * @param {Object} props
 * @param {Boolean} [props.hasError=false]
 * @param {Boolean} [props.isLoading=false]
 * @param {Function} props.onGoToPreviousPage
 * @param {Function} props.onGoToPreviousPage
 * @param {ApiPaginationState} props.pagination
 * @return {StatelessComponent}
 */
const TablePaginationActions = ({ hasError, isLoading, onGoToPreviousPage, onGoToNextPage, pagination }) => (
  <div className="table-pagination-actions">
    <Button
      disabled={isLoading || hasError || !pagination.hasPreviousPage}
      icon={IconNames.CHEVRON_LEFT}
      intent={Intent.NEUTRAL}
      onClick={onGoToPreviousPage}
      size={ButtonSize.SMALL}
      type={ButtonType.OUTLINED}
    />

    <div className="table-pagination-actions__content">
      <TablePaginationActionsContent hasError={hasError} isLoading={isLoading} pagination={pagination} />
    </div>

    <Button
      disabled={isLoading || hasError || !pagination.hasNextPage}
      icon={IconNames.CHEVRON_RIGHT}
      intent={Intent.NEUTRAL}
      onClick={onGoToNextPage}
      size={ButtonSize.SMALL}
      type={ButtonType.OUTLINED}
    />
  </div>
);

TablePaginationActions.propTypes = {
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  onGoToNextPage: PropTypes.func.isRequired,
  onGoToPreviousPage: PropTypes.func.isRequired,
  pagination: paginationProp.isRequired,
};

export default TablePaginationActions;
