import { combineReducers } from 'redux';

import type { VatsState } from 'store/interfaces/vatsState.types';

import errorReducer from './errorReducer';
import isFetchingReducer from './isFetchingReducer';
import lastFetchedReducer from './lastFetchedReducer';
import vatsAllIdsReducer from './vatsAllIdsReducer';
import vatsByIdReducer from './vatsByIdReducer';

/**
 * Vats root reducer
 */
const vatsReducer = combineReducers<VatsState>({
  allIds: vatsAllIdsReducer,
  byId: vatsByIdReducer,
  error: errorReducer,
  isFetching: isFetchingReducer,
  lastFetched: lastFetchedReducer,
});

export default vatsReducer;
