import { createSelector } from 'reselect';

import { productPriceIdPropSelector } from './globalSelectors';

/**
 * Returns Product Prices slice of the state
 * @param {ReduxState} state
 * @returns {ReduxStateSlice<ProductPrice>}
 */
const getState = (state) => state.productPrices;

/**
 * Selects byId part of the product prices state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object.<Id, ProductPrice>}
 */
export const productPricesByIdSelector = createSelector([getState], (state) => state.byId);

/**
 * Selects isSubmitting part of the product prices state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const productPricesIsSubmittingSelector = createSelector([getState], (state) => state.isSubmitting);

/**
 * Selects error part of the product prices state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {?String}
 */
export const productPricesErrorSelector = createSelector([getState], (state) => state.error);

/**
 * Selects product price entity for given productPriceId prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {Id} props.productPriceId
 * @returns {?ProductPrice}
 */
export const productPriceForProductPriceIdPropSelector = createSelector(
  [productPricesByIdSelector, productPriceIdPropSelector],
  (productPricesById, productPriceId) => productPricesById[productPriceId]
);
