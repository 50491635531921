import Keycloak from 'keycloak-js';

import { KEYCLOAK_REALM, KEYCLOAK_CLIENT } from 'constants/env';
import { LocalStorageKeys } from 'enums/localStorage';

export const keycloakConfig = {
  url: process.env.REACT_APP_SSO_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT,
  sslRequired: 'external',
};

export const keycloak = new Keycloak(keycloakConfig);

export const handleKeycloakEvent = (kc, event, error) => {
  if (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return;
  }

  switch (event) {
    case 'onAuthSuccess': {
      break;
    }
    case 'onAuthRefreshSuccess': {
      break;
    }
    case 'onReady': {
      if (!kc.authenticated) {
        kc.login();
      }
      break;
    }
    case 'onAuthLogout': {
      localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
      localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
      window.location.reload();
      break;
    }
    case 'onAuthError': {
      // window.location.replace('/login-error');
      break;
    }
    default: {
      break;
    }
  }
};
