import { PurchaseOrdersEndpoints } from 'constants/endpoints';

import { ApiRequestType, ApiServiceName } from 'enums/api';
import { PurchaseOrderStatus, PurchaseOrderType } from 'enums/purchaseOrders';

import type { Id } from 'interfaces/common';

import fetchApi from './common';

/**
 * GET purchase orders api request.
 * @param options - API request options
 */
export const getPurchaseOrders = async ({
  companyId,
  status,
  page = 0,
}: {
  companyId: Id;
  status: PurchaseOrderType;
  page?: number;
}) => {
  return fetchApi({
    data: { page },
    needsAuth: true,
    serviceName: ApiServiceName.ORDER,
    url: PurchaseOrdersEndpoints.BY_STATUS_AND_COMPANY(companyId, status),
  });
};

/**
 * GET single purchase order api request.
 * @param options - API request options
 */
export const getSinglePurchaseOrder = async ({ id }: { id: Id }) => {
  return fetchApi({
    needsAuth: true,
    serviceName: ApiServiceName.ORDER,
    url: PurchaseOrdersEndpoints.SINGLE(id),
  });
};

/**
 * PATCH purchase order status api request.
 * @param options - API request options
 */
export const patchPurchaseOrderStatus = async ({ status, id }: { id: Id; status: PurchaseOrderStatus }) => {
  return fetchApi({
    method: ApiRequestType.PATCH,
    needsAuth: true,
    serviceName: ApiServiceName.ORDER,
    url: PurchaseOrdersEndpoints.SINGLE(id),
    params: { status },
  });
};
