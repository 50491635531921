import { call, put } from 'redux-saga/effects';

import * as api from 'api/unitOfMeasurement';

import { getErrorMessageFromResponse } from 'helpers/errors';
import { sagaWatcher } from 'helpers/redux';

import { fetchUnitOfMeasurementsRoutine } from 'store/routines/unitOfMeasurements';

/**
 * Fetch units of measurements saga
 * @returns {Generator}
 */
export function* fetchUnitOfMeasurementsSaga() {
  let errorData = {};

  yield put(fetchUnitOfMeasurementsRoutine.request());

  try {
    const response = yield call(api.getUnitOfMeasurements);

    if (response.isSuccess) {
      yield put(fetchUnitOfMeasurementsRoutine.success(response.data));
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield put(fetchUnitOfMeasurementsRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Root unit of measurements saga
 * @returns {IterableIterator}
 */
export default function* unitOfMeasurementsSagas() {
  yield sagaWatcher([
    {
      type: fetchUnitOfMeasurementsRoutine.TRIGGER,
      saga: fetchUnitOfMeasurementsSaga,
    },
  ]);
}
