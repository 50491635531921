import PropTypes from 'prop-types';

import React from 'react';

import { Text } from 'components/atoms';

import { paginationProp } from 'constants/props';

import { TextColor, TextSize } from 'enums/typography';

import { getTableApiStateDescription } from './helpers';

/**
 * Component rendering Pageable Table API state description
 * @param {Object} props
 * @param {Boolean} [props.hasError=false]
 * @param {Boolean} [props.isLoading=false]
 * @param {ApiPaginationState} props.pagination
 * @return {StatelessComponent}
 */
const TableApiStateDescription = (props) => {
  const text = getTableApiStateDescription(props);

  return (
    <Text.Regular className="margin-top--2" color={TextColor.GREY_LIGHT} size={TextSize.LEVEL_200}>
      {text}
    </Text.Regular>
  );
};

TableApiStateDescription.propTypes = {
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  pagination: paginationProp.isRequired,
};

TableApiStateDescription.defaultProps = {
  hasError: false,
  isLoading: false,
};

export default TableApiStateDescription;
