import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { createMergeProps } from 'helpers/redux';

import { productFromProductIdParamPropQuery } from 'store/queries/productsQueries';
import { fetchSingleProductRoutine } from 'store/routines/products';
import { productsIsFetchingSelector } from 'store/selectors/productsSelectors';

import ProductEdit from './ProductEdit';

export const mapStateToProps = createStructuredSelector({
  isFetching: productsIsFetchingSelector,
  product: productFromProductIdParamPropQuery,
});

export const mapDispatchToProps = {
  onFetchProductRequest: fetchSingleProductRoutine,
};

export const mergeProps = createMergeProps((_, dispatchProps, componentProps) => {
  const { productId } = Object(componentProps.match.params);

  return {
    onFetchProduct: () => dispatchProps.onFetchProductRequest({ id: productId }),
  };
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProductEdit);
