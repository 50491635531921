import { createSelector } from 'reselect';

import { SIDESHEET_NAMES } from 'constants/ui';

/**
 * Selects sidesheets part of the Redux state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Record<string, Object>}
 */
export const sidesheetsSelector = (state) => state.sidesheets;

/**
 * Selector creator for selecting specific sidesheet state values
 * @param {string} sidesheetName
 * @returns {Function}
 */
export const createSidesheetValuesSelector = (sidesheetName) => {
  return createSelector([sidesheetsSelector], (sidesheets) => sidesheets[sidesheetName]);
};

/**
 * Add Delivery Day Sidesheet values selector
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const addDeliveryDaySidesheetValuesSelector = createSidesheetValuesSelector(SIDESHEET_NAMES.addEditDeliveryDays);
