import { createErrorReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchSinglePickupRoutine } from 'store/routines/pickups';

/**
 * Map of cases gathered from routines
 */
const { successCases, failureCases, requestCases } = gatherReducerCasesFromRoutines([fetchSinglePickupRoutine]);

/**
 * Pickups reducers: Error reducer
 */
const pickupsErrorReducer = createErrorReducer({ failureCases, resetCases: [...requestCases, ...successCases] });

export { successCases, failureCases, requestCases };
export default pickupsErrorReducer;
