import { createAllIdsReducer } from 'helpers/redux';

import { fetchVatsRoutine } from 'store/routines/vats';

/**
 * List of success cases with replace state flag
 */
const successCases = [{ type: fetchVatsRoutine.SUCCESS, shouldReplaceState: true }];

/**
 * Vats all ids reducer
 */
const vatsAllIdsReducer = createAllIdsReducer({ successCases });

export default vatsAllIdsReducer;
