import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FlexAlign, FlexDirection, FlexJustify } from 'enums/ui';

import { allValues } from 'helpers/utility';

import './Flex.scss';

/**
 * Atomic component rendering generic flexbox container
 * @param {Object} props
 * @param {FlexAlign} [props.align=FlexAlign.START]
 * @param {ReactNode} props.children
 * @param {FlexDirection} [props.dir=FlexDirection.ROW]
 * @param {FlexJustify} [props.justify=FlexJustify.START]
 * @returns {StatelessComponent}
 */
const Flex = ({ align, children, className, dir, justify }) => (
  <div
    className={classNames(`flex flex--dir-${dir} flex--align-${align} flex--justify-${justify}`, {
      [className]: Boolean(className),
    })}
  >
    {children}
  </div>
);

Flex.propTypes = {
  align: PropTypes.oneOf(allValues(FlexAlign)),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dir: PropTypes.oneOf(allValues(FlexDirection)),
  justify: PropTypes.oneOf(allValues(FlexJustify)),
};

Flex.defaultProps = {
  align: FlexAlign.START,
  dir: FlexDirection.ROW,
  justify: FlexJustify.START,
};

export default Flex;
