import { CommonRegex } from 'constants/regex';

import { CompanyGeneralFormFields } from 'enums/formFields';

import validator from 'services/validator';

/**
 * Key/value pairs of common validation patterns
 * @constant {Object}
 */
const commonValidationFields = Object.freeze({
  postCode: validator.string().matches(CommonRegex.DIGITS_ONLY).length(4).required().label('poštna številka'),
  postName: validator.string().min(2).required().label('pošta'),
});

/**
 * Validation schema for Find near by Merchants Form
 * @constant {Yup.ObjectSchema}
 */
export const merchantGeneralInfoValidationSchema = validator.object().shape({
  [CompanyGeneralFormFields.NAME]: validator.string().required().label('ime podjetja'),
  [CompanyGeneralFormFields.EMAIL]: validator.string().matches(CommonRegex.BASIC_EMAIL).label('e-poštni naslov'),
  [CompanyGeneralFormFields.ADDRESS]: validator.string().required().label('naslov'),
  [CompanyGeneralFormFields.POST_CODE]: commonValidationFields.postCode,
  [CompanyGeneralFormFields.POST_NAME]: commonValidationFields.postName,
  [CompanyGeneralFormFields.ID]: validator.number().required(),
  [CompanyGeneralFormFields.IMPORTANCE]: validator.number().min(1).max(100).required().label('prioriteta prikaza'),
});
