import {
  EditProductCellButton,
  ImageCell,
  OneLineTextCell,
  PriceWithDetailsCell,
  ProductStatusCell,
} from 'components/elements';

import { IconNames } from 'enums/icons';
import { ButtonType, IconPosition, TableCellSize, TableCellTextAlign } from 'enums/ui';

/**
 * Returns Products Table columns config
 * @param {Object} options
 * @param {Function} options.onEditProduct
 * @returns {Object[]}
 */
export const getProductsTableColumns = ({ onEditProduct }) => {
  return [
    {
      Header: '',
      accessor: 'images[0]',
      align: TableCellTextAlign.LEFT,
      size: TableCellSize.TWO,
      Cell: ImageCell,
    },
    {
      Header: 'Ime izdelka',
      accessor: 'productName',
      align: TableCellTextAlign.LEFT,
      size: TableCellSize.FIVE,
      Cell: OneLineTextCell,
    },
    {
      Header: 'Cena z DDV',
      accessor: 'productPrice',
      align: TableCellTextAlign.LEFT,
      size: TableCellSize.THREE,
      Cell: PriceWithDetailsCell,
    },
    {
      Header: 'Enota mere',
      accessor: 'productPrice.unitOfMeasure.unitName',
      align: TableCellTextAlign.CENTER,
      alignHeader: TableCellTextAlign.LEFT,
      size: TableCellSize.TWO,
      Cell: OneLineTextCell,
    },
    {
      Header: 'Status izdelka',
      accessor: 'active',
      align: TableCellTextAlign.LEFT,
      size: TableCellSize.THREE,
      Cell: ProductStatusCell,
    },
    {
      Header: 'Pridelovalec',
      accessor: 'producer.producerName',
      align: TableCellTextAlign.LEFT,
      size: TableCellSize.THREE,
      Cell: OneLineTextCell,
    },
    {
      Header: '',
      accessor: 'id',
      align: TableCellTextAlign.CENTER,
      size: TableCellSize.TWO,
      props: {
        text: 'Uredi',
        type: ButtonType.GHOST,
        icon: IconNames.ARROW_RIGHT,
        iconPosition: IconPosition.RIGHT,
      },
      onClick: onEditProduct,
      Cell: EditProductCellButton,
    },
  ];
};
