import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import * as Sentry from '@sentry/react';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { LoadingIndicator } from 'components/atoms';
import { AuthCheckProvider } from 'components/Providers';

import 'styles/index.sass';

import { LocalStorageKeys } from 'enums/localStorage';
import { initializeSentry } from 'helpers/sentry';
import { keycloak, handleKeycloakEvent } from 'utils';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

initializeSentry();

render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <ReactKeycloakProvider
        LoadingComponent={<LoadingIndicator fullscreen large type="primary" />}
        authClient={keycloak}
        initOptions={{
          onLoad: 'login-required',
          promiseType: 'native',
          checkLoginIframe: false,
        }}
        isLoadingCheck={(keycloakState) => !keycloakState.authenticated}
        onEvent={(event, error) => handleKeycloakEvent(keycloak, event, error)}
        onTokens={({ token, refreshToken }) => {
          localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, token);
          localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, refreshToken);
        }}
      >
        <Provider store={store}>
          <AuthCheckProvider>
            <App />
          </AuthCheckProvider>
        </Provider>
      </ReactKeycloakProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
