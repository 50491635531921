import React from 'react';

import { Text } from 'components/atoms';

import { priceDtoProp } from 'constants/props';

import { IconNames } from 'enums/icons';
import { PriceKey } from 'enums/price';

import { getFormattedPriceFromPriceDto } from 'helpers/price';
import { getFormattedVatFromVatDto } from 'helpers/vat';

import { ProductPriceTooltipRow } from './components';

import './ProductPriceTooltip.scss';

/**
 * Component rendering the content of Product Price Tooltip
 * @param {Object} props
 * @param {ProductPrice} props.price
 * @returns {StatelessComponent}
 */
const ProductPriceTooltip = ({ price }) => {
  const rows = [
    {
      icon: IconNames.COINS,
      label: 'Cena brez DDV',
      value: getFormattedPriceFromPriceDto({ price, priceKey: PriceKey.DUTY_FREE }),
    },
    {
      icon: IconNames.PERCENTAGE,
      label: 'Davčna stopnja',
      value: getFormattedVatFromVatDto(price.vat),
    },
    {
      icon: IconNames.MONEY_BILL,
      label: 'Cena z DDV',
      value: getFormattedPriceFromPriceDto({ price, priceKey: PriceKey.TAX_INCLUDED }),
    },
  ];

  return (
    <div className="product-price-tooltip">
      <Text.Bold>Podrobnosti cene izdelka</Text.Bold>

      <div className="product-price-tooltip__details">
        {rows.map((row) => (
          <ProductPriceTooltipRow key={row.label} {...row} />
        ))}
      </div>
    </div>
  );
};

ProductPriceTooltip.propTypes = {
  price: priceDtoProp,
};

export default ProductPriceTooltip;
