import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { onSetSelectedMerchant } from 'store/actions/uiActions';
import { fetchMerchantsNextPageRoutine, fetchMerchantsRoutine } from 'store/routines/merchants';
import {
  allMerchantsSelector,
  isMerchantsStateFetchingSelector,
  isMerchantsStateInitializedSelector,
  merchantsPaginationSelector,
} from 'store/selectors/merchantsSelectors';

import MerchantsGrid from './MerchantsGrid';

export const mapStateToProps = createStructuredSelector({
  isFetching: isMerchantsStateFetchingSelector,
  isInitialized: isMerchantsStateInitializedSelector,
  merchants: allMerchantsSelector,
  pagination: merchantsPaginationSelector,
});

export const mapDispatchToProps = {
  onFetchMerchants: fetchMerchantsRoutine,
  onFetchMerchantsNextPage: fetchMerchantsNextPageRoutine,
  onSelectMerchant: onSetSelectedMerchant,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantsGrid);
