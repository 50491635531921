import { createSelector } from 'reselect';

import { getMappedStateIds } from 'helpers/redux';

/**
 * Returns Vats slice of the state
 * @param {ReduxState} state
 * @returns {ReduxStateSlice<Vat>}
 */
const getState = (state) => state.vats;

/**
 * Selects the allIds part of the vats state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Id[]}
 */
export const vatsAllIdsSelector = createSelector([getState], (state) => state.allIds);

/**
 * Selects the byId part of the vats state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object.<Id, Vat>}
 */
export const vatsByIdSelector = createSelector([getState], (state) => state.byId);

/**
 * Returns list of all vats entities stored in the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Vat[]}
 */
export const vatsAllValuesSelector = createSelector([vatsAllIdsSelector, vatsByIdSelector], getMappedStateIds);

/**
 * Selects the error part of the vats state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {?String}
 */
export const vatsErrorSelector = createSelector([getState], (state) => state.error);

/**
 * Selects the isFetching part of the vats state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const vatsIsFetchingSelector = createSelector([getState], (state) => state.isFetching);

/**
 * Selects the lastFetched part of the vats state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {?Timestamp}
 */
export const vatsLastFetchedSelector = createSelector([getState], (state) => state.lastFetched);

/**
 * Returns true if not currently fetching & lastFetched is not set
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const vatsShouldFetchSelector = createSelector(
  [vatsIsFetchingSelector, vatsLastFetchedSelector],
  (isFetching, lastFetched) => !isFetching && !lastFetched
);
