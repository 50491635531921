import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms';

import { TextColor, TextSize } from 'enums/typography';

import './FullScreenModal.scss';

/**
 * Component rendering full-screen app modal.
 * @param {Object} props
 * @param {ReactNode} [props.addons]
 * @param {ReactNode} props.children
 * @param {string} props.title
 * @param {string} [props.subtitle]
 * @return {StatelessComponent}
 */
const FullScreenModal = React.forwardRef(({ addons, children, title, subtitle }, ref) => (
  <div ref={ref} className="full-screen-modal">
    <header className="full-screen-modal__header">
      <div className="full-screen-modal__header__title">
        <Text.Bold color={TextColor.BLACK} size={TextSize.LEVEL_300}>
          {title}
        </Text.Bold>
        {Boolean(subtitle) && <Text.Regular size={TextSize.LEVEL_300}>{subtitle}</Text.Regular>}
      </div>

      {Boolean(addons) && <div className="full-screen-modal__header__addons">{addons}</div>}
    </header>

    <div className="full-screen-modal__content">{children}</div>
  </div>
));

FullScreenModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default FullScreenModal;
