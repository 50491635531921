import { combineReducers } from 'redux';

import type { StatusAwarePurchaseOrdersState } from 'store/interfaces/purchaseOrdersState.types';

import expiredPurchaseOrdersAllIdsReducer from './expiredPurchaseOrdersAllIdsReducer';
import expiredPurchaseOrdersIsFetchingReducer from './expiredPurchaseOrdersIsFetchingReducer';
import expiredPurchaseOrdersPaginationReducer from './expiredPurchaseOrdersPaginationReducer';

/**
 * Expired purchase orders reducers: Root reducer
 */
const expiredPurchaseOrdersReducer = combineReducers<StatusAwarePurchaseOrdersState>({
  allIds: expiredPurchaseOrdersAllIdsReducer,
  isFetching: expiredPurchaseOrdersIsFetchingReducer,
  pagination: expiredPurchaseOrdersPaginationReducer,
});

export default expiredPurchaseOrdersReducer;
