import React from 'react';

/**
 * Hook for making request to paginated API endpoints
 * @function
 * @param {ApiPaginationState} pagination
 * @param {Function} onRequestNewPage
 * @return {Function} Ready to be used onGetNewPage function
 */
const usePaginationRequest = ({ hasNextPage, currentPage }, onRequestNewPage) => {
  const [loadingNextPage, setLoadingNextPage] = React.useState(false);
  const [loadingPageNum, setLoadingPageNum] = React.useState(0);

  const onGetNewPage = React.useCallback(
    (...args) => {
      if (!hasNextPage || loadingNextPage) {
        return;
      }
      setLoadingPageNum(currentPage);
      setLoadingNextPage(true);
      return onRequestNewPage(...args);
    },
    [currentPage, hasNextPage, loadingNextPage, onRequestNewPage, setLoadingNextPage, setLoadingPageNum]
  );

  React.useEffect(() => {
    if (loadingPageNum < currentPage) {
      setLoadingNextPage(false);
    }
  }, [loadingPageNum, currentPage]);

  return onGetNewPage;
};

export default usePaginationRequest;
