// Merchants Endpoints
/**
 * @constant {Object} MerchantsEndpoints
 */
export const MerchantsEndpoints = Object.freeze({
  ALL: '/companies',
  SINGLE: (id) => `companies/${id}`,
});

// Categories Endpoints
/**
 * @constant {Object} CategoriesEndpoints
 */
export const CategoriesEndpoints = Object.freeze({
  ALL: '/categories',
  SINGLE: (id) => `/categories/${id}`,
  FOR_COMPANY: (companyId) => `/categories/company/${companyId}`,
});

// Products Endpoints
/**
 * @constant {Object} ProductsEndpoints
 */
export const ProductsEndpoints = Object.freeze({
  ALL: '/products',
  SINGLE: (id) => `/products/${id}`,
  FOR_COMPANY: (companyId) => `/products/company/${companyId}`,
  FOR_COMPANY_BY_CATEGORY: (companyId, categoryId) => `/products/company/${companyId}/category/${categoryId}`,
});

/**
 * Product Prices Endpoints
 * @constant
 */
export const ProductPricesEndpoints = Object.freeze({
  SINGLE: (id) => `/product-prices/${id}`,
});

// Merchants Endpoints
/**
 * @constant {Object} PurchaseOrdersEndpoints
 */
export const PurchaseOrdersEndpoints = Object.freeze({
  ALL: '/purchase-orders',
  BY_STATUS_AND_COMPANY: (companyId, status) => `/purchase-orders/company/${companyId}/statuses/${status}`,
  SINGLE: (id) => `/purchase-orders/${id}/`,
});

// Delivery Days Endpoints
/**
 * @constant {Object} DeliveryDaysEndpoints
 */
export const DeliveryDaysEndpoints = Object.freeze({
  BY_COMPANY: (companyId) => `/delivery-days/company/${companyId}`,
});

/**
 * Unit of Measurements Endpoints
 * @constant
 */
export const UnitOfMeasurementsEndpoints = Object.freeze({
  ALL: '/units',
});

/**
 * Vats Endpoints
 * @constant
 */
export const VatsEndpoints = Object.freeze({
  ALL: '/vats',
});

/**
 * Vats Endpoints
 * @constant
 */
export const PickupsEndpoints = Object.freeze({
  ALL: '/pickups',
  SINGLE: (id) => `/pickups/${id}`,
});
