import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { userStateSelector } from 'store/selectors/userSelectors';

import OrderDetailsFooter from './OrderDetailsFooter';

export const mapStateToProps = createStructuredSelector({
  user: userStateSelector,
});

export default connect(mapStateToProps)(OrderDetailsFooter);
