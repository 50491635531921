import { combineReducers } from 'redux';

import type { CategoriesState } from 'store/interfaces/categoriesState.types';

import categoriesAllIdsReducer from './categoriesAllIdsReducer';
import categoriesByIdReducer from './categoriesByIdReducer';
import errorReducer from './errorReducer';
import isFetchingReducer from './isFetchingReducer';
import lastFetchedReducer from './lastFetchedReducer';

/**
 * Categories reducers: Root reducer
 */
const categoriesReducer = combineReducers<CategoriesState>({
  allIds: categoriesAllIdsReducer,
  byId: categoriesByIdReducer,
  error: errorReducer,
  isFetching: isFetchingReducer,
  lastFetched: lastFetchedReducer,
});

export default categoriesReducer;
