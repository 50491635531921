import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/atoms';
import Avatar from 'components/elements/Avatar/Avatar';

import { imageDtoProp } from 'constants/props';
import { IconNames } from 'enums/icons';
import { ButtonType, IconPosition } from 'enums/ui';

import './SidebarChangeMerchantCta.scss';

/**
 * Component rendering Avatar with passed merchant image and name along
 * with a Button if isActionable. Used in the Sidebar.
 *
 * @param {ComponentProps} props
 * @param {Boolean} [props.isActionable=false]
 * @param {Merchant} props.merchant
 * @param {Function} props.onClick
 * @return {StatelessComponent}
 */
const SidebarChangeMerchantCta = ({ isActionable, onClick, merchant }) => (
  <div className="sidebar-change-merchant-cta">
    <Avatar image={merchant.image} name={merchant.companyName} />

    {isActionable && (
      <Button
        icon={IconNames.CHANGE_ARROWS}
        iconPosition={IconPosition.RIGHT}
        onClick={onClick}
        type={ButtonType.GHOST}
      >
        Spremeni ponudnika
      </Button>
    )}
  </div>
);

SidebarChangeMerchantCta.propTypes = {
  isActionable: PropTypes.bool,
  merchant: PropTypes.shape({
    image: imageDtoProp,
    companyName: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

SidebarChangeMerchantCta.defaultProps = {
  isActionable: false,
};

export default SidebarChangeMerchantCta;
