import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { Icon, Tooltip } from 'components/atoms';
import { NumberInput, TextInput } from 'components/elements/inputs';

import { IconNames } from 'enums/icons';

import './PostCodeAndNameInput.scss';

/**
 * Component rendering post code and post name input fields
 * @param {ComponentProps} props
 * @param {string} [props.label]
 * @param {string} props.postCodeFieldName
 * @param {string} props.postNameFieldName
 * @return {StatelessComponent}
 */
const PostCodeAndNameInput = ({ postCodeFieldName, postNameFieldName, label, tooltipProps, ...rest }) => (
  <div className="post-code-and-name-input">
    {Boolean(label) && (
      <label className="post-code-and-name-input__label">
        {Boolean(tooltipProps) && (
          <Tooltip {...tooltipProps}>
            <Icon icon={IconNames.INFO_CIRCLE} />
          </Tooltip>
        )}
        {label}
      </label>
    )}

    <div className="post-code-and-name-input--inner">
      <Field
        className="post-code-and-name-input__post-code"
        component={NumberInput}
        name={postCodeFieldName}
        {...rest}
      />
      <Field className="post-code-and-name-input__post-name" component={TextInput} name={postNameFieldName} {...rest} />
    </div>
  </div>
);

PostCodeAndNameInput.propTypes = {
  postCodeFieldName: PropTypes.string.isRequired,
  postNameFieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default PostCodeAndNameInput;
