import PropTypes from 'prop-types';
import React from 'react';

import emptyStateIllustration from 'assets/illustrations/empty.svg';

import { Button, Image, Text } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { TextColor, TextLineHeight, TextSize } from 'enums/typography';
import { ButtonSize } from 'enums/ui';

import { getTableEmptyResultsTitleAndText } from 'helpers/ui';

import './TableEmptyResults.scss';

/**
 * Component rendering generic empty results table state
 * @param {Object} props
 * @param {Function} props.onRefresh
 * @param {String} [props.searchKey]
 * @returns {StatelessComponent}
 */
const TableEmptyResults = ({ onRefresh, searchKey }) => {
  const { text, title } = getTableEmptyResultsTitleAndText({ searchKey });

  return (
    <div className="table-empty-results">
      <Image alt="Ni rezultatov" src={emptyStateIllustration} />

      <Text.Bold className="margin-top--24" color={TextColor.BLACK} size={TextSize.LEVEL_400}>
        {title}
      </Text.Bold>

      <Text.Regular className="margin-top--16" lineHeight={TextLineHeight.MEDIUM}>
        {text}
      </Text.Regular>

      <Button className="margin-top--24" icon={IconNames.REFRESH} onClick={onRefresh} size={ButtonSize.LARGE}>
        Osveži rezultate
      </Button>
    </div>
  );
};

TableEmptyResults.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  searchKey: PropTypes.string,
};

export default TableEmptyResults;
