import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField, useForm, useFormState } from 'react-final-form';

import { SelectInput } from 'components/elements';

import { categoryDtoProp } from 'constants/props';

import { InputSize } from 'enums/ui';

import { formatFieldValue, getSelectOptionsFromCategories, parseFieldValue } from './helpers';

/**
 * Name of the form field. Is used when accesing the filter value via
 * the form API
 * @type {String}
 */
const FIELD_NAME = 'category';

/**
 * Component rendering Category Select Form Field. MUST be used inside of a
 * React Final Form context.
 * @param {Object} props
 * @param {Category[]} props.categories
 * @param {Boolean} props.isLoading
 * @param {Timestamp} [props.lastFetched]
 * @param {Function} props.onFetchCategories
 * @returns {StatelessComponent}
 */
const CategorySelectFormField = ({ categories, isLoading, lastFetched, onFetchCategories }) => {
  const { submit } = useForm();
  const { values } = useFormState();
  const { input } = useField(FIELD_NAME);
  const ref = React.useRef();

  const hasSearchInput = Boolean(values.searchKey);

  React.useEffect(() => {
    if (!lastFetched && !isLoading) {
      onFetchCategories();
    }
  }, [isLoading, lastFetched, onFetchCategories]);

  React.useEffect(() => {
    // If search input has value, and current input has value (meaning a category is selected),
    // we want to dispatch onChange event with null as a value and clear the current selection
    // in the select menu
    if (hasSearchInput && Boolean(input.value)) {
      input.onChange(null);
      ref.current?.clearValue();
    }
  }, [hasSearchInput, input]);

  return (
    <Field
      component={SelectInput}
      format={formatFieldValue}
      innerRef={ref}
      isDisabled={hasSearchInput}
      isTransparent={true}
      name={FIELD_NAME}
      onSelect={submit}
      options={getSelectOptionsFromCategories(categories)}
      parse={parseFieldValue}
      placeholder="Vse kategorije"
      size={InputSize.EXTRA_SMALL}
    />
  );
};

CategorySelectFormField.propTypes = {
  categories: PropTypes.arrayOf(categoryDtoProp).isRequired,
  isLoading: PropTypes.bool,
  lastFetched: PropTypes.number,
  onFetchCategories: PropTypes.func.isRequired,
};

export default CategorySelectFormField;
