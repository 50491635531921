import { createRoutine } from 'redux-saga-routines';

import {
  FETCH_PURCHASE_ORDERS,
  FETCH_PURCHASE_ORDERS_NEXT_PAGE,
  FETCH_SINGLE_PURCHASE_ORDER,
  PATCH_PURCHASE_ORDER_STATUS,
} from 'store/types/purchaseOrders';

/**
 * Redux routine responsible for dispatching FETCH_PURCHASE_ORDERS related action
 */
export const fetchPurchaseOrdersRoutine = createRoutine(FETCH_PURCHASE_ORDERS);

/**
 * Redux routine responsible for dispatching FETCH_PURCHASE_ORDERS_NEXT_PAGE related action
 */
export const fetchPurchaseOrdersNextPageRoutine = createRoutine(FETCH_PURCHASE_ORDERS_NEXT_PAGE);

/**
 * Redux routine responsible for dispatching FETCH_SINGLE_PURCHASE_ORDER related action
 */
export const fetchSinglePurchaseOrderRoutine = createRoutine(FETCH_SINGLE_PURCHASE_ORDER);

/**
 * Redux routine responsible for dispatching PATCH_PURCHASE_ORDER_STATUS related action
 */
export const patchPurchaseOrderStatusRoutine = createRoutine(PATCH_PURCHASE_ORDER_STATUS);
