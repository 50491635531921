import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { PriceInput } from 'components/elements';

/**
 * Component rendering table cell price input field
 * @param {Object} props
 * @param {Object} props.cell
 * @returns {StatelessComponent}
 */
const PriceInputCell = ({ cell }) => (
  <Field
    component={PriceInput}
    defaultValue={cell.value}
    isCompact={true}
    isTransparent={true}
    name={cell.column.name}
    validate={cell.column.validate}
  />
);

PriceInputCell.propTypes = {
  cell: PropTypes.shape({
    column: PropTypes.shape({
      name: PropTypes.string,
      validate: PropTypes.func,
    }),
    value: PropTypes.number,
  }).isRequired,
};

export default PriceInputCell;
