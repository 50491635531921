import { push } from 'connected-react-router';
import { all, call, put, select } from 'redux-saga/effects';

import * as api from 'api/merchants';
import { CommonErrorMessages } from 'constants/errors';

import { MerchantsRoutes } from 'enums/routes';
import { showSuccessNotification } from 'helpers/alerts';
import { getErrorMessageFromResponse } from 'helpers/errors';
import { sagaWatcher } from 'helpers/redux';

import {
  fetchMerchantsNextPageRoutine,
  fetchMerchantsRoutine,
  fetchSingleMerchantRoutine,
  putMerchantCompanyInfoRoutine,
} from 'store/routines/merchants';
import { uiSelectedMerchantIdSelector } from 'store/selectors/uiSelectors';

/**
 * Fetch merchants saga
 * @returns {Generator}
 */
export function* fetchMerchantsSaga() {
  let errorData = {};

  yield put(fetchMerchantsRoutine.request());

  try {
    const response = yield call(api.getMerchants);

    if (response.isSuccess) {
      yield put(fetchMerchantsRoutine.success(response.data));
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield put(fetchMerchantsRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Fetch merchants next page saga
 * @param {ReduxAction}
 * @returns {Generator}
 */
export function* fetchMerchantsNextPageSaga({ payload }) {
  let errorData = {};

  yield put(fetchMerchantsNextPageRoutine.request());

  try {
    const { page } = payload;
    const response = yield call(api.getMerchants, page);

    if (response.isSuccess) {
      yield put(fetchMerchantsNextPageRoutine.success(response.data));
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield put(fetchMerchantsNextPageRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Fetch single merchant saga
 * @param {ReduxAction}
 * @returns {Generator}
 */
export function* fetchSingleMerchantSaga({ payload }) {
  let errorData = {};

  yield put(fetchSingleMerchantRoutine.request());

  try {
    const { id } = payload;
    const response = yield call(api.getMerchant, id);

    if (response.isSuccess) {
      yield put(fetchSingleMerchantRoutine.success(response.data));
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield put(fetchSingleMerchantRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Put merchant company info saga
 * @param {ReduxAction} action
 * @param {Payload} action.payload
 * @returns {Generator}
 */
export function* putMerchantCompanyInfoSaga({ payload }) {
  let errorData = {};

  // Dispatch initial request action
  yield put(putMerchantCompanyInfoRoutine.request());

  try {
    // Select currently selected merchant Id (this is the company that we
    // want to update).
    const merchantId = yield select(uiSelectedMerchantIdSelector);

    if (!merchantId) {
      // Set the error and redirect to select merchant screen
      yield all([
        put(putMerchantCompanyInfoRoutine.failure(CommonErrorMessages.NO_COMPANY_ID)),
        put(push(MerchantsRoutes.ROOT)),
      ]);
      return;
    }

    // Call the API
    const response = yield call(api.putMerchantCompanyInfo, { merchantId, payload });

    if (response.isSuccess) {
      // Dispatch success action with the response received from the API as
      // the action payload
      yield all([
        put(putMerchantCompanyInfoRoutine.success(response.data)),
        call(showSuccessNotification, { Content: 'Podatki uspešno posodobljeni!' }),
      ]);
      return;
    }

    // Set errors otherwise
    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  // Dispatch error action with error data as a payload
  yield put(putMerchantCompanyInfoRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Root merchants saga
 * @returns {IterableIterator}
 */
export default function* merchantsSagas() {
  yield sagaWatcher([
    {
      type: fetchMerchantsRoutine.TRIGGER,
      saga: fetchMerchantsSaga,
    },
    {
      type: fetchMerchantsNextPageRoutine.TRIGGER,
      saga: fetchMerchantsNextPageSaga,
    },
    {
      type: fetchSingleMerchantRoutine.TRIGGER,
      saga: fetchSingleMerchantSaga,
    },
    {
      type: putMerchantCompanyInfoRoutine.TRIGGER,
      saga: putMerchantCompanyInfoSaga,
    },
  ]);
}
