import { UserRole } from 'enums/roles';

/**
 * List of valid user roles
 * @constant {UserRoleType[]}
 */
export const VALID_USER_ROLES = [
  UserRole.ACCOUNTANT,
  UserRole.ADMIN,
  UserRole.CHIEF_MERCHANT,
  UserRole.MANAGER,
  UserRole.MERCHANT,
  UserRole.OPERATOR,
];
