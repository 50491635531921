/**
 * Common regex patterns
 * @constant
 */
export const CommonRegex = Object.freeze({
  BASIC_EMAIL: /^\S+@\S+\.\S+$/,
  DIGITS_ONLY: /^\d+$/,
  DYNAMIC_PATH_SEGMENT: /:.[^/]*/,
  LETTERS: /[a-zA-Z]+/,
  POST_STRING: /^[0-9]+,(( ?)([a-zA-ZŠšČčŽž]+))+$/,
});
