import _noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';

import { DeliveryDaysTimeline } from 'components/molecules';

import { DeliveryType } from 'enums/delivery';
import { EntityAction } from 'enums/ui';

/**
 * Delivery Days Timeline displaying Regional Delivery entries
 * TODO: Replace _noop calls with an actual action dispatches once SVC supports
 * patching delivery days
 *
 * @param {ComponentProps} props
 * @param {DeliveryDay[]} props.entries
 * @param {Function} props.onOpenAddDeliveryDaySidesheet
 * @returns {StatelessComponent}
 */
const RegionalDeliveryDaysTimeline = ({ entries, onOpenAddDeliveryDaySidesheet }) => {
  const handleOnEditEntryClick = ({ postcodes }) => {
    onOpenAddDeliveryDaySidesheet({ action: EntityAction.UPDATE, deliveryType: DeliveryType.REGIONAL, postcodes });
  };

  return (
    <DeliveryDaysTimeline
      className="margin-top--24"
      deliveryType={DeliveryType.REGIONAL}
      entries={entries}
      isDraggingDisabled={true}
      onAddNewEntry={_noop}
      onEditEntry={handleOnEditEntryClick}
      onItemDrop={_noop}
    />
  );
};

RegionalDeliveryDaysTimeline.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  onOpenAddDeliveryDaySidesheet: PropTypes.func.isRequired,
};

export default RegionalDeliveryDaysTimeline;
