/**
 * Redux action for fetching a product price
 * @constant
 */
export const FETCH_PRODUCT_PRICE = '@productPrices/FETCH_PRODUCT_PRICE';

/**
 * Redux action for patching a product price
 * @constant
 */
export const PATCH_PRODUCT_PRICE = '@productPrices/PATCH_PRODUCT_PRICE';
