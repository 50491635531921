import { getPaginationCurrentPageUIValue, getPaginationTotalPages } from 'helpers/pagination';

/**
 * Returns formatted table API state description string
 * @param {Object} options
 * @param {Boolean} options.hasError
 * @param {Boolean} options.isLoading
 * @param {ApiPaginationState} options.pagination
 * @returns {string}
 */
export const getTableApiStateDescription = ({ hasError, isLoading, pagination }) => {
  // If the table is in a loading state, we want to return a text string
  // describing that state
  if (isLoading) {
    return 'Nalagam podatke...';
  }

  // If the table has an error, we want to return a text string
  // describing that state
  if (hasError) {
    return 'Pri nalaganju podatkov je prišlo do napake.';
  }

  // Otherwise, we return formatted string describing current pagination page in relation
  // to total pagination pages
  return `Prikazana stran ${getPaginationCurrentPageUIValue(pagination)} od ${getPaginationTotalPages(pagination)}`;
};
