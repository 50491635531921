import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Image, Tag, Text } from 'components/atoms';
import { TooltipContentInDevelopment } from 'components/elements/tooltipContents';

import { IconNames } from 'enums/icons';
import { TextSize } from 'enums/typography';
import { ButtonSize, ButtonType, TooltipPosition } from 'enums/ui';

import './SettingsCard.scss';

/**
 * Component rendering Settings subpage card
 * @param {ComponentProps} props
 * @param {string} [props.buttonText="Izberi"]
 * @param {string} props.details
 * @param {boolean} [props.disabled]
 * @param {string} props.image
 * @param {Function} props.onClick
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const SettingsCard = ({ buttonText, details, disabled, image, onClick, title }) => (
  <div className={classNames('settings-card', { 'settings-card--disabled': disabled })}>
    {disabled && (
      <div className="settings-card__disabled-overlay">
        <Tag
          icon={IconNames.INFO_CIRCLE}
          text="v izdelavi"
          tooltipProps={{
            position: TooltipPosition.BOTTOM_RIGHT,
            content: <TooltipContentInDevelopment />,
          }}
        />
      </div>
    )}

    <div className="settings-card__image">
      <Image alt={title} src={image} />
    </div>

    <Text.Bold className="settings-card__title" size={TextSize.LEVEL_400}>
      {title}
    </Text.Bold>

    <Text.Light className="settings-card__details" numOfLines={2} size={TextSize.LEVEL_200}>
      {details}
    </Text.Light>

    <Button onClick={onClick} size={ButtonSize.LARGE} type={ButtonType.GHOST}>
      {buttonText}
    </Button>
  </div>
);

SettingsCard.propTypes = {
  buttonText: PropTypes.string,
  details: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

SettingsCard.defaultProps = {
  buttonText: 'Izberi',
  disabled: false,
};

export default SettingsCard;
