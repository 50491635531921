import { VatsEndpoints } from 'constants/endpoints';

import fetchApi from './common';

/**
 * GET request for fetching all vats
 * @returns {Promise<Payload>}
 */
export const getVats = async () => {
  return fetchApi({
    url: VatsEndpoints.ALL,
    needsAuth: true,
  });
};
