import { UnitOfMeasurementsEndpoints } from 'constants/endpoints';

import fetchApi from './common';

/**
 * GET request for fetching all unit of measures
 * @returns {Promise<Payload>}
 */
export const getUnitOfMeasurements = async () => {
  return fetchApi({
    url: UnitOfMeasurementsEndpoints.ALL,
    needsAuth: true,
  });
};
