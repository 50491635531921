import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { userRolesSelector } from 'store/selectors/userSelectors';

import AuthCheckProvider from './AuthCheckProvider';

export const mapStateToProps = createStructuredSelector({
  userRoles: userRolesSelector,
});

export default connect(mapStateToProps)(AuthCheckProvider);
