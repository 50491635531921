import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LoadingIndicator } from 'components/atoms';

import { DEFAULT_PAGINATION_STATE } from 'constants/api';

import { hasZeroLength, lengthOf } from 'helpers/utility';

import { usePaginationRequest } from 'hooks';

import { getFetchNextRequestPageNumber, shouldFetchNextPage } from './helpers';

/**
 * Component rendering Scrollable List inside of
 * an infinite scroll wrapper
 * @param {ComponentProps} props
 * @param {any[]} props.data
 * @param {ReactNode} [props.emptyContent]
 * @param {Function} props.fetchNext
 * @param {ApiPaginationState} props.pagination
 * @param {ReactRef} [props.parrent]
 * @returns {FunctionComponent}
 */
const ScrollableList = ({ children, data, emptyContent, fetchNext, pagination, parrent }) => {
  const container = React.useRef();
  const fetchNextRequest = usePaginationRequest(pagination, fetchNext);

  const parrentItem = React.useMemo(() => {
    return parrent ? parrent.current : window;
  }, [parrent]);

  const isEmpty = React.useMemo(() => {
    return hasZeroLength(data);
  }, [data]);

  const onFetchNext = React.useCallback(() => {
    return fetchNextRequest({ page: getFetchNextRequestPageNumber(pagination) });
  }, [fetchNextRequest, pagination]);

  React.useEffect(() => {
    const wrapper = container.current.getBoundingClientRect();

    if (!parrentItem) {
      return;
    }

    if (shouldFetchNextPage({ wrapper, pagination, parrentItem })) {
      onFetchNext();
    }
  }, [container, parrentItem, pagination, onFetchNext]);

  return (
    <div ref={container} className="scrollable-list">
      {isEmpty ? (
        emptyContent
      ) : (
        <InfiniteScroll
          dataLength={lengthOf(data)}
          hasMore={pagination.hasNextPage}
          loader={<LoadingIndicator contained />}
          next={onFetchNext}
          scrollableTarget={parrentItem}
          style={{ overflow: 'unset' }}
        >
          {children}
        </InfiniteScroll>
      )}
    </div>
  );
};

ScrollableList.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.array,
  emptyContent: PropTypes.node,
  fetchNext: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}),
  parrent: PropTypes.any,
  hasNextPage: PropTypes.bool.isRequired,
};

ScrollableList.defaultProps = {
  hasNextPage: true,
  pagination: DEFAULT_PAGINATION_STATE,
};

export default ScrollableList;
