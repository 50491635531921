import PropTypes from 'prop-types';
import React from 'react';

import { Image } from 'components/atoms';
import { imageDtoProp } from 'constants/props';

/**
 * Component rendering image cell
 * @param {ComponentProps} props
 * @param {object} props.cell
 * @return {StatelessComponent}
 */
const ImageCell = ({ cell }) => {
  return <Image alt="Cell Image" src={cell.value} />;
};

ImageCell.propTypes = {
  cell: PropTypes.shape({ value: imageDtoProp }).isRequired,
};

export default ImageCell;
