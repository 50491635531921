import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Text, Tooltip } from 'components/atoms';
import { ProductPriceTooltip } from 'components/elements';

import { productPriceDtoProp } from 'constants/props';

import { IconNames } from 'enums/icons';

import { getFormattedPriceFromPriceDto } from 'helpers/price';

import './PriceWithDetailsCell.scss';

/**
 * Component rendering Price Table Cell with Price Details Tooltip
 * @param {Object} props
 * @param {Object} props.cell
 * @returns {StatelessComponent}
 */
const PriceWithDetailsCell = ({ cell }) => (
  <Tooltip className="price-with-details-cell" content={<ProductPriceTooltip price={cell.value} />}>
    <Icon icon={IconNames.INFO_CIRCLE} />
    <Text.Regular>{getFormattedPriceFromPriceDto({ price: cell.value })}</Text.Regular>
  </Tooltip>
);

PriceWithDetailsCell.propTypes = {
  cell: PropTypes.shape({
    value: productPriceDtoProp,
  }),
};

export default PriceWithDetailsCell;
