import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as api from 'api/products';

import { showErrorNotification, showSuccessNotification } from 'helpers/alerts';
import { getErrorMessageFromResponse } from 'helpers/errors';
import { sagaWatcher } from 'helpers/redux';

import { fetchProductsRoutine, fetchSingleProductRoutine, putProductRoutine } from 'store/routines/products';
import { getSelectedMerchantIdOrRedirect } from 'store/sagas/shared';

/**
 * Fetch products saga
 * @returns {Generator}
 */
export function* fetchProductsSaga({ payload }) {
  let errorData = {};

  yield put(fetchProductsRoutine.request());

  try {
    const { page, params } = Object(payload);

    const companyId = yield getSelectedMerchantIdOrRedirect(fetchProductsRoutine.failure);

    if (!companyId) {
      return;
    }

    const response = yield call(api.getProducts, companyId, page, params);

    if (response.isSuccess) {
      yield put(fetchProductsRoutine.success(response.data));
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield put(fetchProductsRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Fetch single product saga
 * @returns {Generator}
 */
export function* fetchSingleProductSaga({ payload }) {
  let errorData = {};

  yield put(fetchSingleProductRoutine.request());

  try {
    const { id } = Object(payload);

    const response = yield call(api.getSingleProduct, id);

    if (response.isSuccess) {
      yield put(fetchSingleProductRoutine.success(response.data));
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield put(fetchSingleProductRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Put product saga
 * @returns {Generator}
 */
export function* putProductSaga({ payload }) {
  let errorData = {};

  yield put(putProductRoutine.request());

  try {
    const { product } = Object(payload);

    const response = yield call(api.putProduct, product);

    if (response.isSuccess) {
      yield all([
        put(putProductRoutine.success(response.data)),
        call(showSuccessNotification, { Content: 'Izdelek uspešno posodobljen!' }),
      ]);
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield all([
    put(putProductRoutine.failure(getErrorMessageFromResponse(errorData))),
    call(showErrorNotification, { Content: 'Pri posodabljanju izdelka je prišlo do napake...' }),
  ]);
}

/**
 * Root products saga
 * @returns {IterableIterator}
 */
export default function* productsSagas() {
  yield takeLatest(fetchProductsRoutine.TRIGGER, fetchProductsSaga);

  yield sagaWatcher([
    {
      type: fetchSingleProductRoutine.TRIGGER,
      saga: fetchSingleProductSaga,
    },
    {
      type: putProductRoutine.TRIGGER,
      saga: putProductSaga,
    },
  ]);
}
