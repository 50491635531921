import PropTypes from 'prop-types';
import React from 'react';

import errorIllustration from 'assets/illustrations/error.svg';

import { Button, Image, MailToLink, Text } from 'components/atoms';

import { OF_SUPPORT_EMAIL } from 'constants/system';

import { IconNames } from 'enums/icons';
import { TextColor, TextLineHeight, TextSize } from 'enums/typography';
import { ButtonSize } from 'enums/ui';

import './ProductsTableErrorState.scss';

/**
 * Component rendering Products Table error state
 * @param {Object} props
 * @param {Function} props.onRefresh
 * @returns {StatelessComponent}
 */
const ProductsTableErrorState = ({ onRefresh }) => (
  <div className="products-table-error-state">
    <Image alt="Napaka pri nalaganju rezultatov" src={errorIllustration} />

    <Text.Bold className="margin-top--24" color={TextColor.BLACK} size={TextSize.LEVEL_400}>
      Pri pridobivanju podatkov je prišlo do napake
    </Text.Bold>

    <Text.Regular className="margin-top--16" lineHeight={TextLineHeight.MEDIUM}>
      Pri pridobivanju podatkov o vaših izdelkih je prišlo do nepričakovane napake. Prosimo, poskusite ponovno oziroma
      kontaktirajte našo podporno ekipo na e-poštni naslov{' '}
      <MailToLink email={OF_SUPPORT_EMAIL}>{OF_SUPPORT_EMAIL}</MailToLink>.
    </Text.Regular>

    <Button className="margin-top--24" icon={IconNames.REFRESH} onClick={onRefresh} size={ButtonSize.LARGE}>
      Poskusi ponovno
    </Button>
  </div>
);

ProductsTableErrorState.propTypes = {
  onRefresh: PropTypes.func.isRequired,
};

export default ProductsTableErrorState;
