import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { productEditFormInitialValuesQuery, productForProductIdPropQuery } from 'store/queries/productsQueries';
import { putProductRoutine } from 'store/routines/products';
import { productsIsSubmittingSelector } from 'store/selectors/productsSelectors';

import ProductEditForm from './ProductEditForm';

/**
 * Container component for Product Edit form
 * @param {Object} props
 * @param {Function} props.onUpdateProduct
 * @param {Object} props.rest
 * @returns {StatelessComponent}
 */
export const ProductEditFormContainer = ({ onUpdateProduct, ...rest }) => {
  /**
   * Form submit handler
   * @param {Object} values
   * @param {FormAPI} form
   * @returns {Promise<void>}
   */
  const handleSubmit = async (values, form) => {
    const errors = await form.submit();

    if (!errors) {
      onUpdateProduct(values);
    }
  };

  return <Form component={ProductEditForm} onSubmit={handleSubmit} {...rest} />;
};

export const mapStateToProps = createStructuredSelector({
  initialValues: productEditFormInitialValuesQuery,
  isSubmitting: productsIsSubmittingSelector,
  product: productForProductIdPropQuery,
});

export const mapDispatchToProps = {
  onUpdateProduct: putProductRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductEditFormContainer);
