import { createIsInitializedReducer } from 'helpers/redux';

import { fetchMerchantsRoutine } from 'store/routines/merchants';

/**
 * Reducer containing info about merchant entity being initialized
 */
const isInitializedReducer = createIsInitializedReducer({ endCases: [fetchMerchantsRoutine.SUCCESS] });

export default isInitializedReducer;
