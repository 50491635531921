import React from 'react';

import { Section } from 'components/atoms';
import { RadioSelect } from 'components/elements/inputs';

import { DeliveryEntryFormFields } from 'enums/formFields';
import { TextSize } from 'enums/typography';
import { FlowDirection } from 'enums/ui';

import { getDeliveryTypeInputOptions } from './helpers';

/**
 * Component rendering Delivery Type section in AddDeliveryEntrySidesheet
 * component
 * @return {StatelessComponent}
 */
const AddDeliveryEntryDeliveryType = () => (
  <Section title="Izberite območje dostave" titleProps={{ size: TextSize.LEVEL_350 }}>
    <RadioSelect
      direction={FlowDirection.HORIZONTAL}
      name={DeliveryEntryFormFields.DELIVERY_TYPE}
      options={getDeliveryTypeInputOptions()}
    />
  </Section>
);

export default AddDeliveryEntryDeliveryType;
