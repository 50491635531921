import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms';

import { getFormattedPriceString } from 'helpers/price';

/**
 * Component rendering formatted price cell in Table
 * component
 * @param {ComponentProps} props
 * @param {object} props.cell
 * @return {StatelessComponent}
 */
const PriceCell = ({ cell }) => {
  const formattedPrice = getFormattedPriceString({ price: cell.value });

  return <Text.Regular>{formattedPrice}</Text.Regular>;
};

PriceCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.number.isRequired }).isRequired,
};

export default PriceCell;
