import {
  createByIdReducer,
  gatherReducerCasesFromRoutines,
  getEntitiesByIdFromApiResponse,
  mergeWithCurrentState,
} from 'helpers/redux';

import type { ProductDTO } from 'interfaces/products';
import type { PayloadActionWithMeta } from 'interfaces/redux';

import { patchProductPriceRoutine } from 'store/routines/productPrices';
import { fetchProductsRoutine, fetchSingleProductRoutine, putProductRoutine } from 'store/routines/products';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Map of cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([
  fetchProductsRoutine,
  putProductRoutine,
  fetchSingleProductRoutine,
]);

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Products reducers: by Id reducer
 */
const productsByIdReducer = createByIdReducer<ProductDTO>({
  successCases,
  resetCases,
  extraCases: (builder) => {
    return builder.addCase(patchProductPriceRoutine.SUCCESS, (state, action: PayloadActionWithMeta) => {
      const { productId } = action.meta || {};

      if (!productId) {
        return state;
      }

      return mergeWithCurrentState(
        state,
        getEntitiesByIdFromApiResponse({ ...state[productId], productPrice: action.payload })
      );
    });
  },
});

export { successCases, resetCases };
export default productsByIdReducer;
