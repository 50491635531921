import { useKeycloak } from '@react-keycloak/web';

/**
 * Custom hook for retrieving auth related logic and helpers
 * from Keycloak API. Enabled easier testing.
 * @function
 * @returns {useAuthReturnValue}
 */
const useAuth = () => {
  const {
    initialized,
    keycloak: { logout, login, authenticated },
  } = useKeycloak();

  if (!initialized) {
    return { authenticated, logout: () => {}, login: () => {} };
  }

  return { authenticated, logout, login };
};

export default useAuth;
