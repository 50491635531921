import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { sidesheetsSelector } from 'store/selectors/sidesheetSelectors';

import DashboardSidesheets from './DashboardSidesheets';

export const mapStateToProps = createStructuredSelector({
  sidesheets: sidesheetsSelector,
});

export default connect(mapStateToProps)(DashboardSidesheets);
