import { compare } from 'fast-json-patch';

/**
 * Returns transformed productPrice data ready to be used in a json-patch request
 * @param {Partial<ProductPrice>} newProductPriceValues
 * @param {ProductPrice} currentProductPrice
 * @returns {import('fast-json-patch').Operation[]}
 */
export const transformProductPriceFormValuesForJsonPatchRequest = (newProductPriceValues, currentProductPrice) => {
  return compare(currentProductPrice, newProductPriceValues);
};
