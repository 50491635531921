import { ProductsEndpoints } from 'constants/endpoints';

import { ApiRequestType } from 'enums/api';

import fetchApi from './common';
import * as transformers from './transformers';

/**
 * GET products api request
 * @param {Id} companyId
 * @param {number} [page=0]
 * @param {Object} options
 * @returns {Promise<Payload>}
 */
export const getProducts = async (companyId, page = 0, options = {}) => {
  const { category, searchKey } = options;

  const url = category
    ? ProductsEndpoints.FOR_COMPANY_BY_CATEGORY(companyId, category)
    : ProductsEndpoints.FOR_COMPANY(companyId);

  return fetchApi({ data: { page, perPage: 12, searchKey }, needsAuth: true, url });
};

/**
 * GET single product api request
 * @param {Id} productId
 * @returns {Promise<Payload>}
 */
export const getSingleProduct = async (productId) => {
  return fetchApi({ url: ProductsEndpoints.SINGLE(productId), needsAuth: true });
};

/**
 * PUT product api request
 * @param {Product} product
 * @returns {Promise<Payload>}
 */
export const putProduct = async (product) => {
  return fetchApi({
    data: transformers.transformProductFormValuesForPutRequest(product),
    needsAuth: true,
    method: ApiRequestType.PUT,
    url: ProductsEndpoints.SINGLE(product.id),
  });
};
