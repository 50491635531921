import React from 'react';

import { Grid } from 'components/atoms';
import { CollapsibleCard, DataEntry } from 'components/elements';

import { IconNames } from 'enums/icons';
import { GridColumnCount, GridGapSize } from 'enums/ui';

import { getFormattedDateString } from 'helpers/datetime';
import {
  getFormattedFromToTimeFromPickupDto,
  getPickupAddressBasedOnPickupType,
  getPickupDeliveryDateLabel,
  getPickupDeliveryTimeLabel,
  getPickupDeliveryTypeLabel,
  isPickupTypeHomeDelivery,
} from 'helpers/pickups';

import { PICKUP_TYPES_LABELS } from 'utils/constants';

import type { OrderDetailsInfoPickupProps } from './OrderDetailsInfoPickup.types';

/**
 * Component rendering PurchaseOrder pickup info details
 * @param props - Component props
 */
const OrderDetailsInfoPickup: React.VFC<OrderDetailsInfoPickupProps> = ({ company, purchaseOrder }) => {
  const { pickup } = purchaseOrder || {};

  // If we don't have the pickup data available, we don't want to render anything here
  if (!pickup) {
    return null;
  }

  const isHomeDelivery = isPickupTypeHomeDelivery(pickup);
  const pickupTypeLabel = getPickupDeliveryTypeLabel(isHomeDelivery);
  const pickupDateLabel = getPickupDeliveryDateLabel(isHomeDelivery);
  const pickupTimeLabel = getPickupDeliveryTimeLabel(isHomeDelivery);
  const pickupAddress = getPickupAddressBasedOnPickupType({ isHomeDelivery, pickup, company });
  const pickupFromTo = getFormattedFromToTimeFromPickupDto(pickup);

  return (
    <CollapsibleCard className="margin-bottom--12" title="Podatki o prevzemu">
      <Grid gap={GridGapSize.MEDIUM} numOfCols={GridColumnCount.TWO}>
        <DataEntry gridSpan={2} label="Tip prevzema" value={PICKUP_TYPES_LABELS[pickup.pickupType]} />

        <DataEntry gridSpan={2} label={pickupTypeLabel} value={pickupAddress} />

        <DataEntry
          icon={IconNames.CALENDAR_CHECK}
          label={pickupDateLabel}
          value={getFormattedDateString(pickup.pickupDate)}
        />
        <DataEntry icon={IconNames.CLOCK} label={pickupTimeLabel} value={pickupFromTo} />
      </Grid>
    </CollapsibleCard>
  );
};

export default OrderDetailsInfoPickup;
