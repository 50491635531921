import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';

import { paginationProp } from 'constants/props';

import PageableTable from './PageableTable';

/**
 * Component wrapping PageableTable presentational component into React Final
 * Form container
 * @param {Object} props
 * @param {Function} props.onFetchData
 * @param {ApiPaginationState} props.pagination
 * @param {Object} props.rest
 * @returns {StatelessComponent}
 */
const PageableTableContainer = ({ onFetchData, pagination, ...rest }) => {
  const handleSubmit = (params) => {
    onFetchData({ page: pagination.currentPage, params });
  };

  return (
    /**
     * This is a special case for form, where we want the form to only store the current
     * fields values (search input, filters select dropdowns...), and not submit anything
     * ("submitting" is handled via custom action dispatches). That's why we pass _noop to
     * the onSubmit handler.
     */
    <Form
      onSubmit={handleSubmit}
      render={() => <PageableTable onFetchData={onFetchData} pagination={pagination} {...rest} />}
    />
  );
};

PageableTableContainer.propTypes = {
  onFetchData: PropTypes.func.isRequired,
  pagination: paginationProp.isRequired,
};

export default PageableTableContainer;
