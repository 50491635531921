import PropTypes from 'prop-types';
import React from 'react';

import { StatusDot, Text } from 'components/atoms';

import { TextColor, TextSize } from 'enums/typography';

import {
  getProductStatusIndicatorDescriptionText,
  getProductStatusIndicatorIntent,
  getProductStatusIndicatorTitleText,
} from 'helpers/ui';

import './ProductStatusIndicator.scss';

/**
 * Component rendering Product active/inactive status indicator
 * @param {Object} props
 * @param {Boolean} props.isActive
 * @return {StatelessComponent}
 */
const ProductStatusIndicator = ({ isActive }) => (
  <div className="product-status-indicator">
    <div className="product-status-indicator__title">
      <StatusDot intent={getProductStatusIndicatorIntent(isActive)} />
      <Text.Regular size={TextSize.LEVEL_200}>{getProductStatusIndicatorTitleText(isActive)}</Text.Regular>
    </div>

    <div className="product-status-indicator__description">
      <Text.Regular color={TextColor.GREY_LIGHT} size={TextSize.LEVEL_150}>
        {getProductStatusIndicatorDescriptionText(isActive)}
      </Text.Regular>
    </div>
  </div>
);

ProductStatusIndicator.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default ProductStatusIndicator;
