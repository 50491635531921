import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/atoms';

import { valueOrEmptyObject } from 'helpers/utility';

import './PurchaseOrderDetailsCellButton.scss';

/**
 * Component rendering button inside of a Table cell
 * @param {ComponentProps} props
 * @param {object} props.cell
 * @return {StatelessComponent}
 */
const PurchaseOrderDetailsCellButton = ({ cell }) => {
  const { text, ...rest } = valueOrEmptyObject(cell.column?.props);

  const onClick = () => {
    cell.column?.handleClick(cell.value);
  };

  return (
    <Button {...rest} className="purchase-order-details-cell-button" onClick={onClick}>
      {text}
    </Button>
  );
};

PurchaseOrderDetailsCellButton.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.number,
    column: PropTypes.shape({
      props: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default PurchaseOrderDetailsCellButton;
