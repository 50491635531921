/**
 * Base API URL
 * @constant {string}
 */
export const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * Base Public API URL
 * @constant {string}
 */
export const API_PUBLIC_BASE_URL = `${API_BASE_URL}/public`;

/**
 * Base Images Download URL
 * @constant {string}
 */
export const IMAGES_BASE_URL = process.env.REACT_APP_IMAGES_URL;

/**
 * Default pagination state
 * @constant {ApiPaginationState}
 */
export const DEFAULT_PAGINATION_STATE = Object.freeze({
  currentPage: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  totalPages: 1,
});
