import PropTypes from 'prop-types';
import React from 'react';

import Select from 'components/elements/Select';

import { formInputPropType, formMetaPropType } from 'constants/props';

/**
 * Component rendering dropdown Select as a form field input
 * @param {Object} props
 * @param {FormInput} props.input
 * @param {FormInputMeta} props.meta
 * @param {Function} [props.onSelect]
 * @param {Object[]} props.options
 * @return {StatelessComponent}
 */
const SelectInput = ({ input, meta, onSelect, selectComponent: Component, ...rest }) => {
  const { value, ...restOfInput } = input;
  const hasError = Boolean(meta.touched && meta.error);

  const handleChange = (selected) => {
    input.onChange(selected);

    if (onSelect) {
      onSelect(selected);
    }
  };

  return (
    <Component
      {...restOfInput}
      {...rest}
      defaultValue={value}
      error={meta.error}
      hasError={hasError}
      onChange={handleChange}
    />
  );
};

SelectInput.propTypes = {
  input: formInputPropType.isRequired,
  meta: formMetaPropType.isRequired,
  onSelect: PropTypes.func,
  selectComponent: PropTypes.elementType,
};

SelectInput.defaultProps = {
  selectComponent: Select,
};

export default SelectInput;
