import { ImageCell, OneLineTextCell, PriceCell } from 'components/elements';

import { TableCellSize, TableCellTextAlign } from 'enums/ui';

/**
 * Returns columns config array for Purchase Order Items
 * table
 * @returns {object[]}
 */
export const getPurchaseOrderItemsTableColumns = () => {
  return [
    {
      Header: '',
      accessor: 'image',
      align: TableCellTextAlign.LEFT,
      size: TableCellSize.TWO,
      Cell: ImageCell,
    },
    {
      Header: 'Artikel',
      accessor: 'itemName',
      align: TableCellTextAlign.LEFT,
      size: TableCellSize.EIGHT,
      Cell: OneLineTextCell,
    },
    {
      Header: 'Količina',
      accessor: 'quantity',
      align: TableCellTextAlign.CENTER,
      size: TableCellSize.TWO,
    },
    {
      Header: 'Enota mere',
      accessor: 'unitOfMeassurement',
      align: TableCellTextAlign.CENTER,
      size: TableCellSize.TWO,
    },
    {
      Header: 'Cena',
      accessor: 'price',
      align: TableCellTextAlign.RIGHT,
      alignHeader: TableCellTextAlign.CENTER,
      size: TableCellSize.THREE,
      Cell: PriceCell,
    },
    {
      Header: 'Znesek',
      accessor: 'totalPrice',
      align: TableCellTextAlign.RIGHT,
      alignHeader: TableCellTextAlign.CENTER,
      size: TableCellSize.THREE,
      Cell: PriceCell,
    },
  ];
};

/**
 * Returns mepped purchase order item data to be used
 * in Table component
 * @param {PurchaseOrderItem[]} items
 * @param {Object} options
 * @param {Object} options.productsById
 * @returns {object[]}
 */
export const getPurchaseOrderItemsTableData = (items, options) => {
  const { productsById } = options;

  return items.map((item) => {
    const product = productsById[item.productRef.extId];

    return {
      image: product?.images?.[0],
      itemName: product?.productName,
      quantity: item.quantity,
      unitOfMeassurement: item.itemPrice.unitOfMeasure?.unitShortName,
      price: item.itemPrice.dutyFreeAmount,
      totalPrice: item.itemTotalPrice.dutyFreeAmount,
    };
  });
};
