import PropTypes from 'prop-types';
import React from 'react';

import { Table } from 'components/elements';

import { TableCellPaddingSize } from 'enums/ui';

import { getPurchaseOrdersTableColumns, getPurchaseOrdersTableData } from './data';

/**
 * Component rendering Purchase Orders table
 * @param {ComponentProps} props
 * @param {Object} props.merchantsById
 * @param {PurchaseOrder[]} props.purchaseOrders
 * @return {StatelessComponent}
 */
const PurchaseOrdersTable = ({ merchantsById, onOrderClick, purchaseOrders }) => {
  const data = getPurchaseOrdersTableData(purchaseOrders, { merchantsById });
  const columns = getPurchaseOrdersTableColumns({ onOrderClick });

  const handleRowClick = (row) => {
    onOrderClick(row.values.id);
  };

  return (
    <Table cellPaddingSize={TableCellPaddingSize.LARGE} columns={columns} data={data} onRowClick={handleRowClick} />
  );
};

PurchaseOrdersTable.propTypes = {
  merchantsById: PropTypes.shape({}).isRequired,
  purchaseOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PurchaseOrdersTable;
