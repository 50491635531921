import { createRoutine } from 'redux-saga-routines';

import { FETCH_CATEGORIES } from 'store/types/categories';

/**
 * Redux routine responsible for dispatching FETCH_CATEGORIES related actions
 */
export const fetchCategoriesRoutine = createRoutine(FETCH_CATEGORIES, {
  trigger: (payload?: { page?: number }) => payload,
});
