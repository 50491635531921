import React from 'react';
import { Field } from 'react-final-form';

import { TextAreaInput } from 'components/elements';

import { ProductFormFields } from 'enums/formFields';

/**
 * Component rendering Product Description form field. Must be used in scope of
 * React Final Form.
 */
const ProductDescriptionFormField = () => (
  <Field
    name={ProductFormFields.DESCRIPTION}
    render={(props) => <TextAreaInput {...props} fullwidth label="Opis izdelka" rows={4} />}
  />
);

export default ProductDescriptionFormField;
