import { createAction } from '@reduxjs/toolkit';

import type { Id } from 'interfaces/common';

import { REMOVE_SELECTED_MERCHANT, SET_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Redux action for setting selected merchant id
 */
export const onSetSelectedMerchant = createAction(SET_SELECTED_MERCHANT, (selectedMerchantId: Id) => ({
  payload: { selectedMerchantId },
}));

/**
 * Redux action for removing selected merchant id
 */
export const onRemoveSelectedMerchant = createAction(REMOVE_SELECTED_MERCHANT);
