import React from 'react';
import { Field } from 'react-final-form';

import { Column, Grid, Section } from 'components/atoms';
import { CheckboxInput, ImageInput, TextInput } from 'components/elements/inputs';
import { PostCodeAndNameInput } from 'components/molecules';

import { CompanyGeneralFormFields } from 'enums/formFields';
import { GridColumnCount, GridGapSize } from 'enums/ui';

/**
 * Component rendering Basic Info section of Merchant General Info form
 *
 * @returns {StatelessComponent}
 */
const MerchantGeneralInfoFormBasicInfo = () => (
  <Section isFirst={true} title="Osnovni podatki">
    <Grid numOfCols={GridColumnCount.THREE}>
      <Field component={ImageInput} disabled={true} label="Slika podjetja" name={CompanyGeneralFormFields.IMAGE} />

      <Column gap={GridGapSize.MEDIUM}>
        <Field component={TextInput} fullwidth={true} label="Naziv podjetja" name={CompanyGeneralFormFields.NAME} />
        <Field component={TextInput} fullwidth={true} label="E-poštni naslov" name={CompanyGeneralFormFields.EMAIL} />
        <Field component={CheckboxInput} label="Pravna entiteta?" name={CompanyGeneralFormFields.LEGAL_ENTITY} />
      </Column>

      <Column gap={GridGapSize.MEDIUM}>
        <Field component={TextInput} fullwidth={true} label="Naslov" name={CompanyGeneralFormFields.ADDRESS} />
        <PostCodeAndNameInput
          label="Poštna številka in pošta"
          postCodeFieldName={CompanyGeneralFormFields.POST_CODE}
          postNameFieldName={CompanyGeneralFormFields.POST_NAME}
        />
      </Column>
    </Grid>
  </Section>
);

export default MerchantGeneralInfoFormBasicInfo;
