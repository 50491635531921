import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchCategoriesRoutine } from 'store/routines/categories';
import {
  allCategoriesSelector,
  categoriesIsFetchingSelector,
  categoriesLastFetchedSelector,
} from 'store/selectors/categoriesSelectors';

import CategorySelectFormField from './CategorySelectFormField';

export const mapStateToProps = createStructuredSelector({
  categories: allCategoriesSelector,
  isLoading: categoriesIsFetchingSelector,
  lastFetched: categoriesLastFetchedSelector,
});

export const mapDispatchToProps = {
  onFetchCategories: fetchCategoriesRoutine.trigger,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelectFormField);
