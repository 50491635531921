import PropTypes from 'prop-types';
import React from 'react';
import { NumericFormat } from 'react-number-format';

import { formInputPropType, formMetaPropType } from 'constants/props';

import { TextInputType } from 'enums/forms';
import { IconNames } from 'enums/icons';
import { DecimalSeparators } from 'enums/numeral';

import { allValues } from 'helpers/utility';

import TextInput from '../TextInput';

/**
 * Wrapper component around TextInput component with htmlType set to number.
 *
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const NumberInput = ({ input, isFloatField, ...rest }) => {
  const handleValueChange = ({ floatValue }) => {
    // We always want to dispatch the actual float value (and not
    // the formatted one) to the input's onChange handler
    input.onChange(floatValue);
  };

  return (
    <NumericFormat
      allowLeadingZeros={false}
      allowNegative={false}
      customInput={TextInput}
      decimalScale={isFloatField ? 2 : 0}
      decimalSeparator={DecimalSeparators.COMMA}
      fixedDecimalScale={false}
      htmlType={TextInputType.NUMBER}
      name={input.name}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      onValueChange={handleValueChange}
      thousandSeparator=""
      value={input.value}
      {...rest}
    />
  );
};

NumberInput.propTypes = {
  decimalScale: PropTypes.number,
  disabled: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  fullwidth: PropTypes.bool,
  icon: PropTypes.oneOf(allValues(IconNames)),
  isFloatField: PropTypes.bool,
  input: formInputPropType.isRequired,
  label: PropTypes.string,
  meta: formMetaPropType,
  placeholder: PropTypes.string,
};

NumberInput.defaultProps = {
  isFloatField: false,
};

export default NumberInput;
