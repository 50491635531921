import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useCollapse from 'react-collapsed';

import { Icon, Text } from 'components/atoms';

import { IconNames } from 'enums/icons';

import './CollapsibleCard.scss';

/**
 * Component rendering Collapsible Card container
 * @param {Object} props
 * @param {ReactNode} props.children
 * @param {string} [props.className]
 * @param {Boolean} [props.defaultOpen=true]
 * @param {string} props.title
 * @return {FunctionComponent}
 */
const CollapsibleCard = ({ children, className, defaultOpen, title }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: defaultOpen });

  const toggleIcon = isExpanded ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN;

  return (
    <div className={classNames('collapsible-card', { [className]: Boolean(className) })}>
      <div className="collapsible-card__header" {...getToggleProps()}>
        <div className="collapsible-card__header--inner">
          <Text.Bold>{title}</Text.Bold>
          <Icon icon={toggleIcon} />
        </div>
      </div>

      <div className="collapsible-card__content" {...getCollapseProps()}>
        <div className="collapsible-card__content--inner">{children}</div>
      </div>
    </div>
  );
};

CollapsibleCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

CollapsibleCard.defaultProps = {
  defaultOpen: true,
};

export default CollapsibleCard;
