import { all, call, put, select } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

import * as api from 'api/deliveryDays';
import { CommonErrorMessages } from 'constants/errors';

import { MerchantsRoutes } from 'enums/routes';
import { getErrorMessageFromResponse } from 'helpers/errors';

import { sagaWatcher } from 'helpers/redux';

import { fetchDeliveryDaysRoutine } from 'store/routines/deliveryDays';
import { uiSelectedMerchantIdSelector } from 'store/selectors/uiSelectors';

/**
 * Fetch delivery days saga
 * @returns {Generator}
 */
export function* fetchDeliveryDaysSaga() {
  let errorData = {};

  // Dispatch request action
  yield put(fetchDeliveryDaysRoutine.request());

  try {
    // Get the currently selected Merchant's company id
    const companyId = yield select(uiSelectedMerchantIdSelector);

    // If companyId is not set for some reason, trigger failure, redirect to select
    // merchant screen and exit the saga early
    if (!companyId) {
      return yield all([
        put(fetchDeliveryDaysRoutine.failure(CommonErrorMessages.NO_COMPANY_ID)),
        put(push(MerchantsRoutes.ROOT)),
      ]);
    }

    // Call the API
    const response = yield call(api.getDeliveryDaysForCompany, { companyId });

    if (response.isSuccess) {
      // Dispatch success action
      yield put(fetchDeliveryDaysRoutine.success(response.data));
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  // Dispatch failure action
  yield put(fetchDeliveryDaysRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Root delivery days saga
 * @returns {IterableIterator}
 */
export default function* deliveryDaysSagas() {
  yield sagaWatcher([
    {
      type: fetchDeliveryDaysRoutine.TRIGGER,
      saga: fetchDeliveryDaysSaga,
    },
  ]);
}
