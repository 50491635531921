import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms';

import { formInputPropType } from 'constants/props';

import {
  getAllSelectNodesMarkedAsSelected,
  getPreparedTreeSelectData,
  getTreeSelectValueFromSelectedNodes,
  isNodeSelectAllNode,
} from './helpers';

import TreeSelectInputWrapper from './TreeSelectInputWrapper';

import 'react-dropdown-tree-select/dist/styles.css';
import './TreeSelectInput.scss';

/**
 * Component rendering TreeSelect form input
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {string} [props.label]
 * @param {FormInput} props.input
 * @param {object[]} props.options
 * @param {string} [props.placeholder="Izberi iz seznama..."]
 * @param {string} [props.searchPlaceholder="Poišči..."]
 * @return {StatelessComponent}
 */
const TreeSelectInput = ({ className, options, label, input, placeholder, searchPlaceholder }) => {
  const [data, setData] = React.useState(options);

  const preparedInputData = React.useMemo(() => {
    return getPreparedTreeSelectData({ data, defaultValue: input.value });
  }, [data, input.value]);

  const handleChange = ({ value, checked }, nodes) => {
    let selectedNodes = nodes;

    if (isNodeSelectAllNode({ value })) {
      const updatedNodes = getAllSelectNodesMarkedAsSelected({ data, checked });
      setData(updatedNodes);
      selectedNodes = updatedNodes;
    }

    input.onChange(getTreeSelectValueFromSelectedNodes(selectedNodes));
  };

  return (
    <div className={classNames('tree-select-input', { [className]: Boolean(className) })}>
      {Boolean(label) && <Text.Regular className="tree-select-input__label">{label}</Text.Regular>}

      <TreeSelectInputWrapper
        data={preparedInputData}
        inlineSearchInput={true}
        keepTreeOnSearch={true}
        onBlur={input.onBlur}
        onChange={handleChange}
        onFocus={input.onFocus}
        texts={{ placeholder, inlineSearchPlaceholder: searchPlaceholder }}
      />
    </div>
  );
};

TreeSelectInput.propTypes = {
  label: PropTypes.string,
  input: formInputPropType.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
};

TreeSelectInput.defaultProps = {
  options: [],
  placeholder: 'Izberi iz seznama...',
  searchPlaceholder: 'Poišči...',
};

export default TreeSelectInput;
