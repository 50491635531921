import PropTypes from 'prop-types';
import React from 'react';

import { AddDeliveryEntrySidesheet } from 'components/modules/settings';

import { SIDESHEET_NAMES } from 'constants/ui';

/**
 * Map of sidesheet names with corresponding sidesheet component
 * @constant
 */
const sidesheetMap = Object.freeze({
  [SIDESHEET_NAMES.addEditDeliveryDays]: AddDeliveryEntrySidesheet,
});

/**
 * Wrapper component responsible for displaying correct dashboard sidesheet
 * @param {ComponentProps} props
 * @param {Object} [props.sidesheets={}]
 * @returns {StatelessComponent}
 */
const DashboardSidesheets = ({ sidesheets }) => (
  <React.Fragment>
    {Object.keys(sidesheets).map((sidesheetName) => {
      const sidesheet = sidesheets[sidesheetName];

      if (!sidesheet.isOpen) {
        return null;
      }

      const Component = sidesheetMap[sidesheetName];

      if (!Component) {
        return null;
      }

      return <Component key={sidesheetName} {...sidesheet.componentProps} />;
    })}
  </React.Fragment>
);

DashboardSidesheets.propTypes = {
  sidesheets: PropTypes.shape({}),
};

DashboardSidesheets.defaultProps = {
  sidesheets: {},
};

export default DashboardSidesheets;
