import PropTypes from 'prop-types';
import React from 'react';

import emptyStateIllustration from 'assets/illustrations/empty.svg';

import { Button, Image, Text } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { TextColor, TextLineHeight, TextSize } from 'enums/typography';
import { ButtonSize, ButtonType, Intent } from 'enums/ui';

import { getTableEmptyResultsTitleAndText } from 'helpers/ui';

import { getButtonsTexts } from './helpers';
import * as texts from './texts';

import './ProductsTableEmptyState.scss';

/**
 * Component rendering Products Table empty state
 * @param {Object} props
 * @param {Function} props.onRefresh
 * @param {String} [props.searchKey]
 * @returns {StatelessComponent}
 */
const ProductsTableEmptyState = ({ onAddProduct, onRefresh, searchKey }) => {
  const options = {
    bodyTexts: {
      emptyResultsBodyText: texts.EMPTY_BODY,
      emptySearchResultsBodyText: texts.EMPTY_SEARCH_BODY,
    },
    titleTexts: {
      emptyResultsTitleText: texts.EMPTY_TITLE,
    },
  };
  const { text, title } = getTableEmptyResultsTitleAndText({ searchKey, options });
  const { addButtonText, refreshButtonText } = getButtonsTexts(searchKey);

  const handleAddProduct = () => {
    onAddProduct(searchKey);
  };

  return (
    <div className="products-table-empty-state">
      <Image alt="Ni rezultatov" src={emptyStateIllustration} />

      <Text.Bold className="margin-top--24" color={TextColor.BLACK} size={TextSize.LEVEL_400}>
        {title}
      </Text.Bold>

      <Text.Regular className="margin-top--16" lineHeight={TextLineHeight.MEDIUM}>
        {text}
      </Text.Regular>

      <Button className="margin-top--24" icon={IconNames.PLUS} onClick={handleAddProduct} size={ButtonSize.LARGE}>
        {addButtonText}
      </Button>
      <Button className="margin-top--4" intent={Intent.NEUTRAL} onClick={onRefresh} type={ButtonType.LINK}>
        {refreshButtonText}
      </Button>
    </div>
  );
};

ProductsTableEmptyState.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  searchKey: PropTypes.string,
};

export default ProductsTableEmptyState;
