import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { openAddDeliveryDaySidesheet } from 'store/actions/sidesheets';
import { widerDeliveryDaysPostCodesGroupedByRegionQuery } from 'store/queries/deliveryDaysQueries';

import RegionalDeliveryDaysTimeline from './RegionalDeliveryDaysTimeline';

const mapStateToProps = createStructuredSelector({
  entries: widerDeliveryDaysPostCodesGroupedByRegionQuery,
});

const mapDispatchToProps = {
  onOpenAddDeliveryDaySidesheet: openAddDeliveryDaySidesheet,
};

export { mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(RegionalDeliveryDaysTimeline);
