import { createSelector } from 'reselect';

import { getInAppProductLink } from 'helpers/routing';
import { allValues } from 'helpers/utility';

import { productIdFromParamsSelector, productIdPropSelector } from 'store/selectors/globalSelectors';
import { productPriceForProductPriceIdPropSelector } from 'store/selectors/productPricesSelectors';
import { productsByIdSelector } from 'store/selectors/productsSelectors';

/**
 * Selects product for given productid prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {id} props.productId
 * @returns {?Product}
 */
export const productForProductIdPropQuery = createSelector(
  [productsByIdSelector, productIdPropSelector],
  (productsById, productId) => productsById[productId]
);

/**
 * Selects product for given product id retrieved from the match.params prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {RouterMatchProp} props.match
 * @returns {?Product}
 */
export const productFromProductIdParamPropQuery = createSelector(
  [productsByIdSelector, productIdFromParamsSelector],
  (productsById, productId) => productsById[productId]
);

/**
 * Selects product from the state for given productId prop and returns initial values for Product Edit from
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {Id} props.productId
 * @returns {?{ product: Product, ui: { inAppProductLink: String } }}
 */
export const productEditFormInitialValuesQuery = createSelector([productForProductIdPropQuery], (product) => {
  if (!product) {
    return {};
  }

  const { images, ...rest } = product;

  return { product: { ...rest, images: images[0] }, ui: { inAppProductLink: getInAppProductLink(product.id) } };
});

/**
 * Selects product from the state by looking at the product price and finding a product with a matching
 * product price
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {Id} props.productPriceId
 * @returns {?Product}
 */
export const productFromProductPriceIdPropSelector = createSelector(
  [productsByIdSelector, productPriceForProductPriceIdPropSelector],
  (productsById, productPrice) => {
    return allValues(productsById).find((product) => product.productPrice.id === productPrice.id);
  }
);

/**
 * Selects product id from the state by looking at the product price and finding a product with a matching
 * product price
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {Id} props.productPriceId
 * @returns {?Id}
 */
export const productIdFromProductPriceIdPropSelector = createSelector(
  [productFromProductPriceIdPropSelector],
  (product) => product?.id
);
