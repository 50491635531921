import PropTypes from 'prop-types';
import React from 'react';

import { Sidebar } from 'components/modules/navigation';

import './AppLayout.scss';

/**
 * Container component rendering global app layout
 * @param {Object} props
 * @param {ReactNode} props.children
 * @returns {StatelessComponent}
 */
const AppLayout = ({ children }) => (
  <div className="app-layout">
    <div className="app-layout__sidebar">
      <Sidebar />
    </div>

    <div className="app-layout__main-content">{children}</div>
  </div>
);

AppLayout.propTypes = {
  children: PropTypes.node,
};

export default AppLayout;
