import PropTypes from 'prop-types';
import React from 'react';

import { Button, Icon, Text, Tooltip } from 'components/atoms';
import { TooltipWithActions } from 'components/elements/tooltipContents';

import { IconNames } from 'enums/icons';
import { TextSize } from 'enums/typography';
import { ButtonSize, ButtonType, TooltipPosition } from 'enums/ui';

import {
  getDeliveryTimelineEntryPostcodesSnippet,
  getDeliveryTimelineEntryPostCodesTooltipContent,
  getDeliveryTimelineEntryTitle,
  getDeliveryTimelineEntryTooltipActions,
  isPostcodesStringTruncated,
} from './helpers';

import './DeliveryTimelineEntry.scss';

/**
 * Component rendering single delivery entry. Meant to be used in
 * Delivery Timelines.
 * @param {ComponentProps} props
 * @param {Function} props.onDelete
 * @param {Function} props.onDuplicate
 * @param {Function} props.onEdit
 * @param {Function} props.onMove
 * @param {string[]} props.postcodes
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const DeliveryTimelineEntry = ({ onDelete, onDuplicate, onEdit, onMove, postcodes, title }) => {
  const [optionsOpen, setOptionsOpen] = React.useState(false);

  const handleEditClick = () => {
    onEdit({ postcodes });
  };

  const handleOptionsOpen = () => {
    setOptionsOpen(true);
  };

  const handleOptionsClose = () => {
    setOptionsOpen(false);
  };

  const formattedTitle = getDeliveryTimelineEntryTitle({ postcodes, title });
  const shouldShowPostcodesTooltip = isPostcodesStringTruncated(postcodes);
  const formattedPostcodesSnippet = getDeliveryTimelineEntryPostcodesSnippet(postcodes);
  const tooltipActions = getDeliveryTimelineEntryTooltipActions({
    postcodes,
    onDelete,
    onDuplicate,
    onEdit: handleEditClick,
    onMove,
  });

  return (
    <div className="delivery-timeline-entry">
      <Tooltip
        className="delivery-timeline-entry__options"
        content={<TooltipWithActions actions={tooltipActions} onCloseMenu={handleOptionsClose} />}
        onHide={handleOptionsClose}
        onShow={handleOptionsOpen}
        open={optionsOpen}
        trigger="manual"
      >
        <Icon fixedWidth={true} icon={IconNames.DOTS_VERTICAL} onClick={handleOptionsOpen} />
      </Tooltip>

      <Text.Regular className="delivery-timeline-entry__title" size={TextSize.LEVEL_300}>
        {formattedTitle}
      </Text.Regular>

      <Tooltip
        content={getDeliveryTimelineEntryPostCodesTooltipContent(postcodes)}
        position={TooltipPosition.BOTTOM_CENTER}
        visible={shouldShowPostcodesTooltip}
      >
        <Text.Regular className="delivery-timeline-entry__postcodes" numOfLines={1} size={TextSize.LEVEL_200}>
          {formattedPostcodesSnippet}
        </Text.Regular>
      </Tooltip>

      <Button onClick={handleEditClick} size={ButtonSize.SMALL} type={ButtonType.GHOST}>
        Uredi vnos
      </Button>
    </div>
  );
};

DeliveryTimelineEntry.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  postcodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
};

export default DeliveryTimelineEntry;
