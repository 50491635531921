import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms';
import AlertWrapper from 'components/elements/alerts/AlertWrapper/AlertWrapper';

import { Intent } from 'enums/ui';
import { getPurchaseOrderNumber } from 'helpers/purchaseOrders';

/**
 * Component rendering Cancel Purchase Order SWAL content
 * @param {ComponentProps} props
 * @param {Function} props.onCancel
 * @return {StatelessComponent}
 */
const CancelPurchaseOrderAlert = ({ onCancel, orderId }) => (
  <AlertWrapper
    confirmButtonProps={{ onClick: onCancel, text: 'Potrdi preklic naročila' }}
    intent={Intent.ERROR}
    showTitleIcon={true}
    title={`Ste prepričani, da želite preklicati naročilo #${getPurchaseOrderNumber({ id: orderId })}?`}
  >
    <Text.Regular>Tega dejanja ni mogoče povrniti...</Text.Regular>
  </AlertWrapper>
);

CancelPurchaseOrderAlert.propTypes = {
  orderId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CancelPurchaseOrderAlert;
