import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { Button } from 'components/atoms';
import { SearchInput } from 'components/elements/inputs';
import TablePaginationActions from 'components/elements/table/PageableTable/components/TablePaginationActions';

import { paginationProp } from 'constants/props';

import { IconNames } from 'enums/icons';
import { ButtonSize, ButtonType, InputSize, Intent } from 'enums/ui';

import './TableActions.scss';

/**
 * Component rendering Table Header Actions
 * @param {Object} props
 * @param {ReactNode} [props.filters]
 * @param {Boolean} [props.hasError=false]
 * @param {Boolean} [props.hasSearch=true]
 * @param {Boolean} [props.isLoading=false]
 * @param {Boolean} [props.isSearching=false]
 * @param {Function} props.onGoToNextPage
 * @param {Function} props.onGoToPreviousPage
 * @param {Function} props.onRefresh
 * @param {ApiPaginationState} props.pagination
 * @return {StatelessComponent}
 */
const TableActions = ({
  filters,
  hasError,
  hasSearch,
  isLoading,
  isSearching,
  onGoToPreviousPage,
  onGoToNextPage,
  onRefresh,
  pagination,
  searchPlaceholder,
}) => (
  <div className="table-actions">
    {hasSearch && (
      <div className="table-actions__segment">
        <Field
          component={SearchInput}
          isSearching={isSearching}
          name="searchKey"
          placeholder={searchPlaceholder}
          size={InputSize.EXTRA_SMALL}
        />
        {filters}
      </div>
    )}

    <div className="table-actions__segment">
      <Button
        disabled={isLoading || isSearching}
        icon={IconNames.REFRESH}
        intent={Intent.NEUTRAL}
        onClick={onRefresh}
        size={ButtonSize.SMALL}
        type={ButtonType.OUTLINED}
      />
    </div>

    <div className="table-actions__segment">
      <TablePaginationActions
        hasError={hasError}
        isLoading={isLoading || isSearching}
        onGoToNextPage={onGoToNextPage}
        onGoToPreviousPage={onGoToPreviousPage}
        pagination={pagination}
      />
    </div>
  </div>
);

TableActions.propTypes = {
  filters: PropTypes.node,
  hasError: PropTypes.bool,
  hasSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSearching: PropTypes.bool,
  onGoToNextPage: PropTypes.func.isRequired,
  onGoToPreviousPage: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  pagination: paginationProp.isRequired,
  searchPlaceholder: PropTypes.string,
};

TableActions.defaultProps = {
  hasError: false,
  hasSearch: true,
  isLoading: false,
  isSearching: false,
};

export default TableActions;
