import { all } from 'redux-saga/effects';

import categoriesSagas from './categories';
import deliveryDaysSagas from './deliveryDays';
import merchantsSagas from './merchants';
import pickupsSagas from './pickups';
import productPricesSagas from './productPrices';
import productsSagas from './products';
import purchaseOrdersSagas from './purchaseOrders';
import unitOfMeasurementsSagas from './unitOfMeasurements';
import vatsSagas from './vats';

function* rootSaga() {
  yield all([
    categoriesSagas(),
    deliveryDaysSagas(),
    merchantsSagas(),
    pickupsSagas(),
    productPricesSagas(),
    productsSagas(),
    purchaseOrdersSagas(),
    unitOfMeasurementsSagas(),
    vatsSagas(),
  ]);
}

export default rootSaga;
