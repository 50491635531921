import type { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { fetchPickup } from 'api/pickups';

import { sagaWatcher } from 'helpers/redux';
import { withHandleSagaErrors } from 'helpers/sagas';

import type { Id } from 'interfaces/common';

import { fetchSinglePickupRoutine } from 'store/routines/pickups';

/**
 * Fetch single pickup saga
 */
export function* fetchSinglePickupSaga({ payload }: PayloadAction<{ id: Id }>) {
  let errorData;

  yield put(fetchSinglePickupRoutine.request());

  try {
    const { id } = payload;
    const response = yield call(fetchPickup, id);

    if (response.isSuccess) {
      yield put(fetchSinglePickupRoutine.success(response.data));
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield put(fetchSinglePickupRoutine.failure(withHandleSagaErrors(errorData)));
}

/**
 * Root pickups saga
 */
export default function* pickupsSagas() {
  yield sagaWatcher([{ type: fetchSinglePickupRoutine.TRIGGER, saga: fetchSinglePickupSaga }]);
}
