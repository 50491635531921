import type { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import * as api from 'api/categories';

import { getErrorMessageFromResponse } from 'helpers/errors';
import { sagaWatcher } from 'helpers/redux';

import { fetchCategoriesRoutine } from 'store/routines/categories';
import { getSelectedMerchantIdOrRedirect } from 'store/sagas/shared';

/**
 * Fetch categories saga
 */
export function* fetchCategoriesSaga(action: PayloadAction<{ page?: number } | undefined>) {
  let errorData;

  yield put(fetchCategoriesRoutine.request());

  try {
    const { page } = action.payload || {};

    const companyId = yield getSelectedMerchantIdOrRedirect(fetchCategoriesRoutine.failure);

    if (!companyId) {
      return;
    }

    const response = yield call(api.getCategories, companyId, page);

    if (response.isSuccess) {
      yield put(fetchCategoriesRoutine.success(response.data));
      return;
    }

    errorData = response.errorData;
  } catch (err) {
    errorData = err;
  }

  yield put(fetchCategoriesRoutine.failure(getErrorMessageFromResponse(errorData)));
}

/**
 * Root categories saga
 * @returns {IterableIterator}
 */
export default function* categoriesSagas() {
  yield sagaWatcher([
    {
      type: fetchCategoriesRoutine.TRIGGER,
      saga: fetchCategoriesSaga,
    },
  ]);
}
