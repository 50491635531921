import { createErrorReducer } from 'helpers/redux';

import { fetchDeliveryDaysRoutine } from 'store/routines/deliveryDays';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * List of failure cases
 */
const failureCases = [fetchDeliveryDaysRoutine.FAILURE];

/**
 * List of reset cases
 */
const resetCases = [fetchDeliveryDaysRoutine.REQUEST, fetchDeliveryDaysRoutine.SUCCESS, REMOVE_SELECTED_MERCHANT];

/**
 * Delivery days reducers: Error reducer
 */
const deliveryDaysErrorReducer = createErrorReducer({ failureCases, resetCases });

export { failureCases, resetCases };
export default deliveryDaysErrorReducer;
