import { UserRole } from 'enums/roles';

/**
 * UI Reducer Initial State
 */
export const UI_REDUCER_INITIAL_STATE = Object.freeze({
  selectedMerchantId: null,
});

/**
 * Purchase Orders Tabs config
 * @constant {Object[]}
 */
export const PURCHASE_ORDERS_TABS = [
  { label: 'Vsa naročila', type: 'all', role: [UserRole.CHIEF_MERCHANT] },
  {
    label: 'Aktivna naročila',
    type: 'active',
    role: [UserRole.MERCHANT, UserRole.CHIEF_MERCHANT, UserRole.OPERATOR],
  },
  {
    label: 'Zapadla naročila',
    type: 'expired',
    role: [UserRole.MERCHANT, UserRole.CHIEF_MERCHANT, UserRole.OPERATOR],
  },
  { label: 'Izvedena naročila', type: 'finalized', role: [UserRole.CHIEF_MERCHANT, UserRole.OPERATOR] },
  { label: 'Izvedena naročila', type: 'latest-finalized', role: [UserRole.MERCHANT] },
];

/**
 * Delivery days options array
 * @constant {object[]}
 */
export const DELIVERY_DAYS_OPTIONS = [
  { value: 'monday', label: 'Ponedeljek' },
  { value: 'tuesday', label: 'Torek' },
  { value: 'wednesday', label: 'Sreda' },
  { value: 'thursday', label: 'Četrtek' },
  { value: 'friday', label: 'Petek' },
  { value: 'saturday', label: 'Sobota' },
  { value: 'sunday', label: 'Nedelja' },
];

/**
 * Names of sidesheets used throught the app
 * @constant {Record<string, string>}
 */
export const SIDESHEET_NAMES = {
  addEditDeliveryDays: 'addEditDeliveryDays',
};

/**
 * Generic messages used in table empty states
 * @constant
 * @type {Object.<string, string>}
 */
export const TABLE_EMPTY_STATE_MESSAGES = {
  emptyResultsBodyText:
    'Za vašo poizvedbo nismo našli nobenih rezultatov. S klikom na spodnji gumb lahko ponovite poizvedbo in osvežite rezultate.',
  emptyResultsTitleText: 'Ni rezultatov',
  emptySearchResultsBodyText:
    'Za vnešen iskalni niz nismo našli rezultatov. Ponovite iskanje z drugim iskalnim nizom ali pa osvežite seznam.',
  emptySearchResultsTitlePrefix: 'Za iskalni niz',
  emptySearchResultsTitleSuffix: 'nismo našli rezultatov',
};
