import { MerchantOrganizationStatusType } from 'enums/merchants';

/**
 * Merchants orginazation statues mapped to a corresponding UI description
 * @constant
 */
export const merchantOrginazinationStatusDescriptionMap = Object.freeze({
  [MerchantOrganizationStatusType.CLOSED]: 'Ponudnik preklican',
  [MerchantOrganizationStatusType.INITIALIZED]: 'Ponudnik dodan',
  [MerchantOrganizationStatusType.VALIDATED]: 'Ponudnik potrjen',
  UNKNOWN: 'Status neznan',
});
