import { createSelector } from 'reselect';

import { isDeliveryDayDeliveryAreaTypeLocal, isDeliveryDayDeliveryAreaTypeWider } from 'helpers/deliveryDays';

import { getMappedStateIds } from 'helpers/redux';

const getState = (state) => state.deliveryDays;

/**
 * Selects the allIds property of the delivery days state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Id[]}
 */
export const deliveryDaysAllIdsSelector = createSelector([getState], (state) => state.allIds);

/**
 * Selects "byId" part of the Delivery Days state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object.<DeliveryDay.id, DeliveryDay>}
 */
export const deliveryDaysByIdSelector = createSelector([getState], (deliveryDaysState) => deliveryDaysState.byId);

/**
 * Selects "byId" part of the Delivery Days state and returns
 * the values of the object in an array
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {DeliveryDay[]}
 */
export const allDeliveryDaysSelector = createSelector(
  [deliveryDaysAllIdsSelector, deliveryDaysByIdSelector],
  getMappedStateIds
);

/**
 * Selects Delivery Days with LOCAL_DELIVERY deliveryAreaType property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {DeliveryDay[]}
 */
export const deliveryDaysWithLocalDeliveryAreaTypeSelector = createSelector(
  [allDeliveryDaysSelector],
  (deliveryDays) => {
    return deliveryDays.filter((deliveryDay) => isDeliveryDayDeliveryAreaTypeLocal(deliveryDay));
  }
);

/**
 * Selects Delivery Days with WIDER_DELIVERY deliveryAreaType property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {DeliveryDay[]}
 */
export const deliveryDaysWithWiderDeliveryAreaTypeSelector = createSelector(
  [allDeliveryDaysSelector],
  (deliveryDays) => {
    return deliveryDays.filter((deliveryDay) => isDeliveryDayDeliveryAreaTypeWider(deliveryDay));
  }
);
