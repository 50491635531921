/**
 * React App Environment
 * @constant {string}
 */
export const ENV = process.env.REACT_APP_ENV || 'development';

/**
 * Keycloak Realm ID
 * @constant {string}
 */
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;

/**
 * Keycloak Client
 * @constant {string}
 */
export const KEYCLOAK_CLIENT = process.env.REACT_APP_KEYCLOAK_CLIENT;
