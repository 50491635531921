/**
 * Returns default value for simple select field (simple = array of string is passed
 * as Select component options prop)
 * @param {SelectOption[]} options
 * @param {String} value
 * @returns {SelectOption}
 */
export const simpleSelectDefaultValueGetter = (options, value) => {
  return options.find((option) => {
    return option.value === value;
  });
};

/**
 * Returns formatted array of options, ready to be used in ReactSelect component
 * @param {String[]} options
 * @returns {SelectOption[]}
 */
export const simpleSelectOptionsFormatter = (options) => options.map((option) => ({ label: option, value: option }));

/**
 * Return option.id as an option value.
 * By default, ReactSelect expects option.value to be present, and uses that as an option value. When dealing with
 * DTOs, though, we want to point to id instead of a value property.
 * @param {{id: Id}} option
 * @returns {Id}
 */
export const getEntitiesOptionValue = (option) => option.id;
