import { createSelector } from 'reselect';

const getState = (state) => state.user;

/**
 * Selects the user state object from the Redux state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {User}
 */
export const userStateSelector = createSelector([getState], (userState) => userState);

/**
 * Selects roles property from the user state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {UserRoleType[]}
 */
export const userRolesSelector = createSelector([userStateSelector], (userState) => userState?.roles);
