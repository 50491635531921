import React from 'react';

import { PageLayout } from 'components/modules/global';
import { MerchantGeneralInfoForm } from 'components/modules/merchants';

import { SettingsRoutes } from 'enums/routes';

/**
 * Component rendering CompanyGeneralSettings page
 * @return {StatelessComponent}
 */
const CompanyGeneralSettings = () => {
  const breadcrumbs = [
    { path: SettingsRoutes.ROOT, name: 'Nastavitve ponudnika' },
    { path: SettingsRoutes.COMPANY_GENERAL, name: 'Podatki o ponudniku' },
  ];

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <MerchantGeneralInfoForm />
    </PageLayout>
  );
};

export default CompanyGeneralSettings;
