import {
  createStatusAwareIsFetchingReducer,
  gatherReducerCasesFromRoutines,
  isPurchaseOrdersActionRelatedToExpiredOrders,
} from 'helpers/redux';

import { fetchPurchaseOrdersRoutine } from 'store/routines/purchaseOrders';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Map of cases gathered from routines
 */
const { successCases, failureCases, requestCases } = gatherReducerCasesFromRoutines([fetchPurchaseOrdersRoutine]);

/**
 * List of reset cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Expired Purchase Orders reducers: Is Fetching Reducer
 */
const expiredPurchaseOrdersIsFetchingReducer = createStatusAwareIsFetchingReducer({
  endCases: [...successCases, ...failureCases],
  requestCases,
  resetCases,
  statusCheckFunction: isPurchaseOrdersActionRelatedToExpiredOrders,
});

export default expiredPurchaseOrdersIsFetchingReducer;
