export enum MerchantStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum MerchantOfferingType {
  SINGLE = 'SINGLE',
  GROUP = 'GROUP',
}

export enum MerchantOrganizationStatusType {
  CLOSED = 'CLOSED',
  INITIALIZED = 'INITIALIZED',
  VALIDATED = 'VALIDATED',
}
