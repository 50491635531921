import { createReducer } from '@reduxjs/toolkit';

import type { AuthUser } from 'interfaces/users';

import { setUser, removeUser } from 'store/actions';

/**
 * Reducers: user reducer
 */
const userReducer = createReducer<AuthUser | null>(null, (builder) => {
  return builder.addCase(setUser, (_, action) => action.payload).addCase(removeUser, () => null);
});

export default userReducer;
