/**
 * Sets the passed className to the document.body
 * DOM element classList
 * @param {string} className
 */
export const setBodyClassName = (className) => {
  document.body.classList.add(className);
};

/**
 * Remvoes the passed className to the document.body
 * DOM element classList
 * @param {string} className
 */
export const removeBodyClassName = (className) => {
  document.body.classList.remove(className);
};

export const setClassNameOnDomNode = (querySelector, className) => {
  document.querySelector(querySelector).classList.add(className);
};

export const removeClassNameFromDomNode = (querySelector, className) => {
  document.querySelector(querySelector).classList.remove(className);
};
