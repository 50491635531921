import PropTypes from 'prop-types';
import React from 'react';

import { Button, Tooltip } from 'components/atoms';

import { TooltipContentInDevelopment } from 'components/elements';
import { IconNames } from 'enums/icons';
import { ButtonSize, ButtonType } from 'enums/ui';

/**
 * Component rendering Add New Product button
 * @param {Object} props
 * @param {Function} props.onClick
 * @returns {StatelessComponent}
 */
const AddNewProductButton = ({ onClick }) => (
  <Tooltip content={<TooltipContentInDevelopment />}>
    <Button disabled={true} icon={IconNames.PLUS} onClick={onClick} size={ButtonSize.NORMAL} type={ButtonType.GHOST}>
      Dodaj nov izdelek
    </Button>
  </Tooltip>
);

AddNewProductButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddNewProductButton;
