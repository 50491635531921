import PropTypes from 'prop-types';
import React from 'react';

import { StatusTag } from 'components/elements';

import { PurchaseOrderStatus } from 'enums/purchaseOrders';

import { getPurchaseOrderStatusIntent, getPurchaseOrderStatusText } from 'helpers/ui';
import { allValues } from 'helpers/utility';

/**
 * Component rendering Purchase Order status tag
 * @param {Object} props
 * @param {Boolean} [props.fullwidth=false]
 * @param {PurchaseOrderState} props.purchaseOrderStatus
 * @return {StatelessComponent}
 */
const PurchaseOrderStatusTag = ({ fullwidth, purchaseOrderStatus }) => {
  const intent = getPurchaseOrderStatusIntent({ status: purchaseOrderStatus });
  const text = getPurchaseOrderStatusText({ status: purchaseOrderStatus });

  return <StatusTag fullwidth={fullwidth} intent={intent} text={text} />;
};

PurchaseOrderStatusTag.propTypes = {
  fullwidth: PropTypes.bool,
  purchaseOrderStatus: PropTypes.oneOf(allValues(PurchaseOrderStatus)).isRequired,
};

PurchaseOrderStatusTag.defaultProps = {
  fullwidth: false,
};

export default PurchaseOrderStatusTag;
