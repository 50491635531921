import React from 'react';

import { Grid } from 'components/atoms';
import { CollapsibleCard, DataEntry } from 'components/elements';

import { PriceKey } from 'enums/price';
import { GridColumnCount, GridGapSize } from 'enums/ui';

import { getFormattedPriceFromPriceDto } from 'helpers/price';

import type { OrderDetailsInfoOrderValueProps } from './OrderDetailsInfoOrderValue.types';

/**
 * Component rendering Purchase Order Details order value info card
 * @param props - Component props
 */
const OrderDetailsInfoOrderValue = ({ purchaseOrder }: OrderDetailsInfoOrderValueProps): JSX.Element => (
  <CollapsibleCard title="Vrednost naročila">
    <Grid gap={GridGapSize.MEDIUM} numOfCols={GridColumnCount.TWO}>
      <DataEntry
        label="Vrednost brez DDV"
        value={getFormattedPriceFromPriceDto({ price: purchaseOrder.totalOrderPrice, priceKey: PriceKey.DUTY_FREE })}
      />
      <DataEntry
        label="Vrednost z DDV"
        value={getFormattedPriceFromPriceDto({ price: purchaseOrder.totalOrderPrice })}
      />
    </Grid>
  </CollapsibleCard>
);

export default OrderDetailsInfoOrderValue;
