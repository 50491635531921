import PropTypes from 'prop-types';
import React from 'react';

import { Breadcrumb } from './components';

import './Breadcrumbs.scss';

/**
 * Component rendering navigation breadcrumbs
 * @param {Object} props
 * @param {{ path: String; name: String; }[]} props.poths
 * @return {StatelessComponent}
 */
const Breadcrumbs = ({ paths }) => (
  <div className="breadcrumbs">
    {paths.map((path, i) => (
      <Breadcrumb key={`${path.path}-${path.name}`} index={i} numOfAllPaths={paths.length} path={path} />
    ))}
  </div>
);

Breadcrumbs.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.shape({ path: PropTypes.string, name: PropTypes.string })).isRequired,
};

export default Breadcrumbs;
