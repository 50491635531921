import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { UserRole } from 'enums/roles';
import { AuthRoutes, PurchaseOrdersRoutes } from 'enums/routes';

import { isOneOfUserRoleMatch } from 'helpers/roles';

/**
 * Component resposible for redirecting to the correct part of the app on root
 * URL hit. Based on the role, user is redirected to a different part of the app
 * @param {Object} props
 * @param {User} [props.user]
 * @returns {StatelessComponent}
 */
const Dashboard = ({ user }) => {
  // If we don't have user data, we render nothing
  if (!user) {
    return null;
  }

  // For the roles MERCHANT, CHIEF_MERCHANT and OPERATOR, we want to redirect the user to the
  // purchase orders page
  if (isOneOfUserRoleMatch(user.roles, [UserRole.MERCHANT, UserRole.CHIEF_MERCHANT, UserRole.OPERATOR])) {
    return <Redirect to={PurchaseOrdersRoutes.ROOT} />;
  }

  // As a fallback, if user has a role that has not already been handles in above checks,
  // redirect the user back to the Login page since his/her role is not a valid dashboard role
  return <Redirect to={AuthRoutes.LOGIN} />;
};

Dashboard.propTypes = {
  user: PropTypes.shape({ roles: PropTypes.array }),
};

export default Dashboard;
