import PropTypes from 'prop-types';
import React from 'react';

import { CategorySelectFormField } from 'components/modules/categories';
import { PageLayout } from 'components/modules/global';
import { AddNewProductButton, ProductsTable } from 'components/molecules';

import { paginationProp, productDtoProp } from 'constants/props';

import { IconNames } from 'enums/icons';
import { ProductsRoutes } from 'enums/routes';

/**
 * Page component rendering ProductsOverview page
 * @param {Object} props
 * @param {Boolean} [props.hasError]
 * @param {Boolean} [props.isLoading]
 * @param {Timestamp} [props.lastFetched]
 * @param {Function} props.onAddNewProduct
 * @param {Function} props.onFetchProducts
 * @param {Product[]} props.products
 * @param {ApiPaginationState} props.productsPagination
 * @returns {StatelessComponent}
 */
const ProductsOverview = ({
  hasError,
  isLoading,
  lastFetched,
  onAddNewProduct,
  onFetchProducts,
  products,
  productsPagination,
}) => {
  const menuActions = [{ icon: IconNames.REFRESH, title: 'Osveži seznam izdelkov', onClick: onFetchProducts }];
  const breadcrumbs = [{ path: ProductsRoutes.ROOT, name: 'Spletna trgovina' }];

  React.useEffect(() => {
    if (!lastFetched && !isLoading) {
      onFetchProducts();
    }
  }, [isLoading, lastFetched, onFetchProducts]);

  return (
    <PageLayout
      actionbarAddons={<AddNewProductButton onClick={onAddNewProduct} />}
      actionbarMenuActions={menuActions}
      breadcrumbs={breadcrumbs}
    >
      <ProductsTable
        fetchProducts={onFetchProducts}
        filters={<CategorySelectFormField />}
        hasError={hasError}
        isLoading={isLoading}
        pagination={productsPagination}
        products={products}
      />
    </PageLayout>
  );
};

ProductsOverview.propTypes = {
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  lastFetched: PropTypes.number,
  onAddNewProduct: PropTypes.func.isRequired,
  onFetchProducts: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(productDtoProp).isRequired,
  productsPagination: paginationProp.isRequired,
};

export default ProductsOverview;
