import PropTypes from 'prop-types';
import React from 'react';

import { Image, Text } from 'components/atoms';

import { AvatarSize } from 'enums/ui';
import { getInitialsFromFullName } from 'helpers/textHelpers';
import { allValues } from 'helpers/utility';

import './Avatar.scss';

/**
 * Component rendering user image and (optionally) user full name
 * as an avatar
 *
 * @param {ComponentProps} props
 * @param {string|Image} props.image
 * @param {string} [props.name]
 * @param {string} [props.size=AvatarSize.NORMAL]
 * @return {StatelessComponent}
 */
const Avatar = ({ image, name, size }) => {
  const hasImage = Boolean(image);
  const shouldShowInitials = !hasImage && Boolean(name);

  const avatarInitials = getInitialsFromFullName(name);

  return (
    <div className={`avatar avatar--${size}`}>
      {hasImage && (
        <div className="avatar__image">
          <Image alt={name} src={image} />
        </div>
      )}

      {shouldShowInitials && <div className="avatar__initials">{avatarInitials}</div>}

      {Boolean(name) && <Text.Regular className="avatar__name">{name}</Text.Regular>}
    </div>
  );
};

Avatar.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ downloadUri: PropTypes.string })]),
  name: PropTypes.string,
  size: PropTypes.oneOf(allValues(AvatarSize)),
};

Avatar.defaultProps = {
  size: AvatarSize.NORMAL,
};

export default Avatar;
