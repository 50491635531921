import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Grid } from 'components/atoms';
import { SettingsCard } from 'components/elements/SettingsCard';

import { UserRole } from 'enums/roles';
import { GridColumnCount, GridGapSize } from 'enums/ui';
import { getSettingsPagesGridData } from 'helpers/settings';
import { allValues } from 'helpers/utility';

/**
 * Component rendering Settings pages cards in a
 * grid
 * @param {ComponentProps} props
 * @param {UserRoleType[]} props.userRoles
 * @return {StatelessComponent}
 */
const SettingsPagesGrid = ({ userRoles }) => {
  const history = useHistory();
  const settingsPages = getSettingsPagesGridData({ roles: userRoles });

  const handleSettingCardClick = (setting) => {
    if (!setting.disabled) {
      history.push(setting.slug);
    }
  };

  return (
    <Grid gap={GridGapSize.MEDIUM} numOfCols={GridColumnCount.FOUR}>
      {settingsPages?.map((setting) => (
        <SettingsCard key={setting.slug} onClick={() => handleSettingCardClick(setting)} {...setting} />
      ))}
    </Grid>
  );
};

SettingsPagesGrid.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.oneOf(allValues(UserRole))),
};

export default SettingsPagesGrid;
