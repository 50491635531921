import swal from '@sweetalert/with-react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

/**
 * Dispatch action for opening SweetAlert with custom
 * content
 * @param {object} options
 * @param {JSX.Element} options.Content
 * @param {{intent?: Intent}} [options.config={}]
 */
export const showAlert = ({ Content, config = {} }) => {
  const { intent, ...restOfConfig } = config;

  swal({
    className: classNames('swal', { [`swal--${intent}`]: Boolean(intent) }),
    content: Content,
    buttons: false,
    ...restOfConfig,
  });
};

/**
 * Dispatch action for displaying generic toast notification
 * @param {object} options
 * @param {string|JSX.Element} options.Content
 * @param {object} [options.config={}]
 */
export const showNotification = ({ Content, config = {} }) => {
  toast(Content, {
    position: 'top-center',
    draggable: false,
    ...config,
  });
};

/**
 * Dispatch action for displaying success toast notification
 * @param {object} options
 * @param {string|JSX.Element} options.Content
 * @param {object} [options.config={}]
 */
export const showSuccessNotification = ({ Content, config }) => {
  showNotification({ Content, config: { ...config, type: 'success' } });
};

/**
 * Dispatch action for displaying error toast notification
 * @param {object} options
 * @param {string|JSX.Element} options.Content
 * @param {object} [options.config={}]
 */
export const showErrorNotification = ({ Content, config }) => {
  showNotification({ Content, config: { ...config, type: 'error' } });
};

/**
 * Dispatch action for displaying warning toast notification
 * @param {object} options
 * @param {string|JSX.Element} options.Content
 * @param {object} [options.config={}]
 */
export const showWarningNotification = ({ Content, config }) => {
  showNotification({ Content, config: { ...config, type: 'warning' } });
};
