import { createLastFetchedReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchMerchantsNextPageRoutine, fetchMerchantsRoutine } from 'store/routines/merchants';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

const { successCases } = gatherReducerCasesFromRoutines([fetchMerchantsRoutine, fetchMerchantsNextPageRoutine]);

const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Merchants reducers: Last fetched reducer
 */
const merchantsLastFetchedReducer = createLastFetchedReducer({ successCases, resetCases });

export { successCases, resetCases };
export default merchantsLastFetchedReducer;
