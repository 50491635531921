import {
  EntitiesSelect,
  PriceInputCell,
  SelectInputCell,
  SimpleSelect,
  SwitchInputCell,
  TaxInputCell,
  UpdateProductPriceButtonCell,
} from 'components/elements';

import { CurrencyCode } from 'enums/currencies';
import { ProductPriceFormFields } from 'enums/formFields';
import { PriceKey } from 'enums/price';
import { TableCellSize, TableCellTextAlign } from 'enums/ui';

import { requiredNumberValidator } from 'helpers/validators';

/**
 * Returns option formatter for the unit of measure select field
 * @param {UnitOfMeasure} unitOfMeasure
 * @returns {String}
 */
export const unitOfMeasureOptionFormatter = (unitOfMeasure) => {
  return `${unitOfMeasure.unitName} (${unitOfMeasure.unitShortName})`;
};

/**
 * Returns columns config for Product Price table
 * @returns {Object[]}
 */
export const getProductPriceTableColumns = ({
  isSubmitting,
  isUpdateDisabled,
  onUpdatePrice,
  unitOfMeasures,
  vats,
}) => [
  {
    Header: 'Aktivna',
    accessor: 'activePrice',
    align: TableCellTextAlign.CENTER,
    size: TableCellSize.ONE,
    Cell: SwitchInputCell,
    name: ProductPriceFormFields.ACTIVE,
    props: {
      disabled: true,
    },
  },
  {
    Header: 'Vrednost z DDV',
    accessor: PriceKey.TAX_INCLUDED,
    align: TableCellTextAlign.LEFT,
    size: TableCellSize.FOUR,
    Cell: PriceInputCell,
    name: ProductPriceFormFields.TAX_INCLUDED_AMOUNT,
    validate: requiredNumberValidator,
  },
  {
    Header: 'Davčna stopnja',
    accessor: 'vat',
    align: TableCellTextAlign.LEFT,
    size: TableCellSize.FOUR,
    Cell: TaxInputCell,
    name: ProductPriceFormFields.VAT,
    options: vats,
    props: {
      isDisabled: true,
      selectComponent: EntitiesSelect,
    },
  },
  {
    Header: 'Valuta',
    accessor: 'currency',
    align: TableCellTextAlign.LEFT,
    size: TableCellSize.FOUR,
    Cell: SelectInputCell,
    name: ProductPriceFormFields.CURRENCY,
    options: [CurrencyCode.EUR, CurrencyCode.USD],
    props: {
      isClearable: false,
      isSearchable: false,
      selectComponent: SimpleSelect,
      isDisabled: true,
    },
  },
  {
    Header: 'Enota mere',
    accessor: 'unitOfMeasure',
    align: TableCellTextAlign.LEFT,
    size: TableCellSize.FOUR,
    Cell: SelectInputCell,
    name: ProductPriceFormFields.UNIT_OF_MEASUREMENT,
    options: unitOfMeasures,
    props: {
      isClearable: false,
      isDisabled: true,
      formatOptionLabel: unitOfMeasureOptionFormatter,
      selectComponent: EntitiesSelect,
    },
  },
  {
    Header: '',
    accessor: 'id',
    align: TableCellTextAlign.CENTER,
    size: TableCellSize.THREE,
    Cell: UpdateProductPriceButtonCell,
    onClick: onUpdatePrice,
    props: {
      disabled: isUpdateDisabled,
      loading: isSubmitting,
    },
  },
];
