import { combineReducers } from 'redux';

import type { StatusAwarePurchaseOrdersState } from 'store/interfaces/purchaseOrdersState.types';

import finalizedPurchaseOrdersAllIdsReducer from './finalizedPurchaseOrdersAllIdsReducer';
import finalizedPurchaseOrdersIsFetchingReducer from './finalizedPurchaseOrdersIsFetchingReducer';
import finalizedPurchaseOrdersPaginationReducer from './finalizedPurchaseOrdersPaginationReducer';

/**
 * Finalized purchase orders reducers: Root reducer
 */
const finalizedPurchaseOrdersReducer = combineReducers<StatusAwarePurchaseOrdersState>({
  allIds: finalizedPurchaseOrdersAllIdsReducer,
  isFetching: finalizedPurchaseOrdersIsFetchingReducer,
  pagination: finalizedPurchaseOrdersPaginationReducer,
});

export default finalizedPurchaseOrdersReducer;
