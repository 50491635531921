import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Text } from 'components/atoms';

import './TimelineSortable.scss';

/**
 * Component rendering single sortable instance in the scope of
 * Sortable Timeline
 * @param {ComponentProps} props
 * @param {ReactNode} [props.component=Text.Regular]
 * @param {object} [props.componentProps={}]
 * @param {Boolean} [props.disabled=false]
 * @param {number} props.index
 * @param {object} props.item
 * @return {StatelessComponent}
 */
const TimelineSortable = ({ component: Component, componentProps, disabled, index, item }) => (
  <Draggable draggableId={item.id?.toString()} index={index} isDragDisabled={disabled}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={classNames('timeline-sortable', {
          'timeline-sortable--is-disabled': disabled,
          'timeline-sortable--is-dragging': snapshot.isDragging,
        })}
        style={provided.draggableProps.style}
      >
        <Component {...componentProps} />
      </div>
    )}
  </Draggable>
);

TimelineSortable.propTypes = {
  component: PropTypes.func,
  componentProps: PropTypes.shape({}),
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({}).isRequired,
};

TimelineSortable.defaultProps = {
  component: Text.Regular,
  componentProps: {},
  disabled: false,
};

export default TimelineSortable;
