import { ProductPricesEndpoints } from 'constants/endpoints';

import { ApiRequestType } from 'enums/api';

import fetchApi from './common';
import * as transformers from './transformers';

/**
 * PATCH product price api request
 * @param {Partial<ProductPrice>} productPrice
 * @param {ProductPrice} currentProductPrice
 * @returns {Promise<Payload>}
 */
export const patchProductPrice = async (productPrice, currentProductPrice) => {
  return fetchApi({
    data: transformers.transformProductPriceFormValuesForJsonPatchRequest(productPrice, currentProductPrice),
    needsAuth: true,
    method: ApiRequestType.PATCH,
    url: ProductPricesEndpoints.SINGLE(productPrice.id),
  });
};
