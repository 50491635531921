import { combineReducers } from 'redux';

import type { MerchantsState } from 'store/interfaces/merchantsState.types';

import errorReducer from './errorReducer';
import isFetchingReducer from './isFetchingReducer';
import isInitializedReducer from './isInitializedReducer';
import isSubmittingReducer from './isSubmittingReducer';
import merchantsAllIdsReducer from './merchantsAllIdsReducer';
import merchantsByIdReducer from './merchantsByIdReducer';
import merchantsLastFetchedReducer from './merchantsLastFetchedReducer';
import paginationReducer from './paginationReducer';

const merchantsReducer = combineReducers<MerchantsState>({
  allIds: merchantsAllIdsReducer,
  byId: merchantsByIdReducer,
  error: errorReducer,
  isFetching: isFetchingReducer,
  isInitialized: isInitializedReducer,
  isSubmitting: isSubmittingReducer,
  lastFetched: merchantsLastFetchedReducer,
  pagination: paginationReducer,
});

export default merchantsReducer;
