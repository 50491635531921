import PropTypes from 'prop-types';
import React from 'react';

import { Button, Text } from 'components/atoms';
import { EmptyListSvg } from 'components/elements/illustrations';

import { IconNames } from 'enums/icons';
import { PurchaseOrderType } from 'enums/purchaseOrders';
import { TextColor, TextSize } from 'enums/typography';
import { ButtonSize } from 'enums/ui';
import { allValues } from 'helpers/utility';

import './EmptyPurchaseOrdersList.scss';

/**
 * Component rendering Purchase Orders List empty state
 * @param {ComponentProps} props
 * @param {Function} props.fetchPurchaseOrders
 * @param {boolean} props.isLoading
 * @param {PurchaseOrdersStatusType} props.type
 * @return {FunctionComponent}
 */
const EmptyPurchaseOrdersList = ({ fetchPurchaseOrders, isLoading, type }) => {
  const handleButtonClick = () => {
    fetchPurchaseOrders(type);
  };

  return (
    <div className="empty-purchase-orders-list">
      <div className="empty-purchase-orders-list__illustration">
        <EmptyListSvg />
      </div>
      <Text.ExtraBold className="empty-purchase-orders-list__heading" color={TextColor.BLACK} size={TextSize.LEVEL_500}>
        Seznam naročil je prazen
      </Text.ExtraBold>
      <Text.Regular className="empty-purchase-orders-list__text">
        Trenutno ni novih naročil za prikaz. Osvežite seznam s klikom na spodnji gumb, oziroma se vrnite kasneje.
      </Text.Regular>
      <Button icon={IconNames.REFRESH} loading={isLoading} onClick={handleButtonClick} size={ButtonSize.LARGE}>
        Osveži seznam naročil
      </Button>
    </div>
  );
};

EmptyPurchaseOrdersList.propTypes = {
  fetchPurchaseOrders: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  type: PropTypes.oneOf(allValues(PurchaseOrderType)).isRequired,
};

EmptyPurchaseOrdersList.defaultProps = {
  isLoading: false,
};

export default EmptyPurchaseOrdersList;
