import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Atomic component rendering simple Form wrapper
 * @param {Object} props
 * @param {ReactNode} props.children
 * @param {String} [props.className]
 * @returns {StatelessComponent}
 */
const FormWrapper = ({ children, className }) => (
  <div className={classNames('form-wrapper', { [className]: Boolean(className) })}>{children}</div>
);

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default FormWrapper;
