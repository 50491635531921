import { PickupType } from 'enums/pickups';

import { getFormattedTimeStringFromLocalTime } from './datetime';
import { isEqual } from './utility';

/**
 * Returns true if pickup type is home deliveru
 * @function
 * @param {PickupDTO} pickup
 * @returns {boolean} boolean
 */
export const isPickupTypeHomeDelivery = (pickup) => {
  if (!pickup) {
    return false;
  }

  return isEqual(pickup?.pickupType, PickupType.HOME_DELIVERY);
};

/**
 * Returns label string for delivery type based on truthfulness
 * of isHomeDelivery
 * @param {boolean} isHomeDelivery
 * @returns {string}
 */
export const getPickupDeliveryTypeLabel = (isHomeDelivery) => {
  return isHomeDelivery ? 'Naslov za dostavo' : 'Lokacija prevzema';
};

/**
 * Returns label string for delivery date based on truthfulness
 * of isHomeDelivery
 * @param {boolean} isHomeDelivery
 * @returns {string}
 */
export const getPickupDeliveryDateLabel = (isHomeDelivery) => {
  return isHomeDelivery ? 'Datum dostave' : 'Datum prevzema';
};

/**
 * Returns label string for delivery time based on truthfulness
 * of isHomeDelivery
 * @param {boolean} isHomeDelivery
 * @returns {string}
 */
export const getPickupDeliveryTimeLabel = (isHomeDelivery) => {
  return isHomeDelivery ? 'Ura dostave' : 'Ura prevzema';
};

/**
 * Returns formatted address string from either company or pickup
 * properties, based on the truthfulness of isHomeDelivery
 * @param {object} options
 * @param {CompanyDTO} options.company
 * @param {boolean} options.isHomeDelivery
 * @param {PickupDTO} options.pickup
 * @returns {string}
 */
export const getPickupAddressBasedOnPickupType = ({ company, isHomeDelivery, pickup }) => {
  // Initialize empty object to avoid errors when accessing
  // object properties in return statement in case provided company
  // and/or pickup is undefined
  let lookupValues = {};

  if (isHomeDelivery) {
    // if home delivery, we want to look at the pickup address
    // values
    if (!pickup) {
      return null;
    }

    lookupValues = pickup;
  } else {
    // else we want to look at the company address
    if (!company) {
      return null;
    }

    lookupValues = company;
  }

  // Return formatted address string
  return `${lookupValues.address}, ${lookupValues.postCode} ${lookupValues.postName}`;
};

/**
 * Returns formatted from - to time string from pickup dto
 * properties
 * @param {PickupDTO} [pickup]
 * @returns {string}
 */
export const getFormattedFromToTimeFromPickupDto = (pickup) => {
  const formattedFromTime = getFormattedTimeStringFromLocalTime(pickup?.pickupTimeFrom);
  const formattedToTime = getFormattedTimeStringFromLocalTime(pickup?.pickupTimeTo);

  return `${formattedFromTime} - ${formattedToTime}`;
};
