import { createReducer } from '@reduxjs/toolkit';

import { closeAddDeliveryDaySidesheet, openAddDeliveryDaySidesheet } from 'store/actions/sidesheets';
import { SideSheetState } from 'store/interfaces/sideSheetsState.types';

/**
 * Reducer initial state
 * @constant
 */
const initialState = {
  isOpen: false,
  componentProps: {},
};

/**
 * Add Delivery Entry Sidesheet reducer
 */
const addDeliveryEntrySidesheetReducer = createReducer<SideSheetState>(initialState, (builder) => {
  return builder
    .addCase(openAddDeliveryDaySidesheet, (state, action) => {
      state.isOpen = true;
      state.componentProps = action.payload.props;
    })
    .addCase(closeAddDeliveryDaySidesheet, () => initialState);
});

export { initialState };
export default addDeliveryEntrySidesheetReducer;
