import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';

import { LoadingIndicator } from 'components/atoms';
import { PurchaseOrdersTable } from 'components/molecules/tables';

import { PurchaseOrderType } from 'enums/purchaseOrders';
import { PurchaseOrdersRoutes } from 'enums/routes';
import { getPathWithDynamicSegmentReplaced } from 'helpers/routing';
import { allValues } from 'helpers/utility';

import { EmptyPurchaseOrdersList } from './components';

/**
 * Component rendering Purchase Orders list in inside of
 * an infinite scroll wrapper
 * @param {ComponentProps} props
 * @param {boolean} props.empty
 * @param {Function} props.fetchMoreOrders
 * @param {Object} props.merchantsById
 * @param {ReactRef} [props.parrent]
 * @param {PurchaseOrder[]} props.purchaseOrders
 * @param {PurchaseOrdersStatusType} props.purchaseOrdersType
 * @param {boolean} props.reachedEnd
 * @returns {FunctionComponent}
 */
const ScrollablePurchaseOrdersList = ({
  empty,
  fetchMoreOrders,
  merchantsById,
  parrent,
  purchaseOrders,
  purchaseOrdersType,
  reachedEnd,
}) => {
  const history = useHistory();
  const ordersContainer = React.useRef();

  const parrentItem = React.useMemo(() => {
    return parrent ? parrent.current : window;
  }, [parrent]);

  const goToSingleOrder = (orderId) => {
    const { scrollY } = window;
    const singleOrderPath = getPathWithDynamicSegmentReplaced(PurchaseOrdersRoutes.DETAILS, orderId);
    history.push(singleOrderPath, { listOffset: scrollY });
  };

  React.useEffect(() => {
    const { height, y } = ordersContainer.current.getBoundingClientRect();

    if (!parrentItem) {
      return;
    }

    if (height + y < parrentItem.innerHeight && !reachedEnd) {
      fetchMoreOrders(purchaseOrdersType);
    }
  }, [fetchMoreOrders, reachedEnd, parrentItem, purchaseOrdersType]);

  return (
    <div ref={ordersContainer} className="scrollable-purchase-orders-list">
      {empty ? (
        <EmptyPurchaseOrdersList type={purchaseOrdersType} />
      ) : (
        <InfiniteScroll
          dataLength={purchaseOrders.length}
          hasMore={!reachedEnd}
          loader={<LoadingIndicator contained />}
          next={fetchMoreOrders}
          style={{ overflow: 'unset' }}
        >
          <PurchaseOrdersTable
            merchantsById={merchantsById}
            onOrderClick={goToSingleOrder}
            purchaseOrders={purchaseOrders}
          />
        </InfiniteScroll>
      )}
    </div>
  );
};

ScrollablePurchaseOrdersList.propTypes = {
  orders: PropTypes.array,
  fetchMoreOrders: PropTypes.func.isRequired,
  reachedEnd: PropTypes.bool.isRequired,
  merchantsById: PropTypes.shape({}).isRequired,
  parrent: PropTypes.any,
  purchaseOrdersType: PropTypes.oneOf(allValues(PurchaseOrderType)).isRequired,
  empty: PropTypes.bool,
};

ScrollablePurchaseOrdersList.defaultProps = {
  reachedEnd: false,
  empty: false,
};

export default ScrollablePurchaseOrdersList;
