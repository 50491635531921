import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { LoadingIndicator } from 'components/atoms';

import { PurchaseOrderType } from 'enums/purchaseOrders';

import { allValues, hasZeroLength } from 'helpers/utility';

import { usePaginationRequest } from 'hooks';

import { ScrollablePurchaseOrdersList } from './components';

/**
 * Component rendering list of Purchase Orders
 * @param {ComponentProps} props
 * @param {boolean} props.loading
 * @param {Function} props.fetchOrders
 * @param {Function} props.fetchOrdersNextPage
 * @param {Object} props.merchantsById
 * @param {ApiPaginationState} props.pagination
 * @param {Object[]} props.purchaseOrders
 * @param {string} props.type
 * @returns {FunctionComponent}
 */
const PurchaseOrdersList = ({
  type,
  loading,
  merchantsById,
  pagination,
  purchaseOrders,
  fetchOrdersNextPage,
  fetchOrders: fetchOrdersProp,
}) => {
  const { state: locationState } = useLocation();
  const fetchNextOrders = usePaginationRequest(pagination, fetchOrdersNextPage);

  const isPurchaseOrdersListEmpty = hasZeroLength(purchaseOrders);

  const fetchMoreOrders = React.useCallback(() => {
    fetchNextOrders({ status: type, page: parseInt(pagination.currentPage) + 1 });
  }, [fetchNextOrders, pagination.currentPage, type]);

  const fetchOrders = React.useCallback(() => {
    return fetchOrdersProp(type);
  }, [type, fetchOrdersProp]);

  React.useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  React.useEffect(() => {
    if (locationState) {
      const { listOffset } = locationState;
      window.scrollTo({ top: listOffset || 0 });
    }
  }, [locationState]);

  return (
    <div className="purchase-orders-list">
      {loading ? (
        <LoadingIndicator contained />
      ) : (
        <ScrollablePurchaseOrdersList
          empty={isPurchaseOrdersListEmpty}
          fetchMoreOrders={fetchMoreOrders}
          merchantsById={merchantsById}
          purchaseOrders={purchaseOrders}
          purchaseOrdersType={type}
          reachedEnd={!pagination.hasNextPage}
        />
      )}
    </div>
  );
};

PurchaseOrdersList.propTypes = {
  fetchOrders: PropTypes.func.isRequired,
  fetchOrdersNextPage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  merchantsById: PropTypes.shape({}).isRequired,
  pagination: PropTypes.shape({}).isRequired,
  purchaseOrders: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.oneOf(allValues(PurchaseOrderType)).isRequired,
};

PurchaseOrdersList.defaultProps = {
  loading: false,
};

export default PurchaseOrdersList;
