import React from 'react';

import optifarmLogoSvg from 'assets/OptifarmLogo.svg';

import { Image } from 'components/atoms';

/**
 * Component rendering Optifarm Logo SVG image
 */
const OptifarmLogo: React.VFC = () => <Image alt="Optifarm" src={optifarmLogoSvg} />;

export default OptifarmLogo;
