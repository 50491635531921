import { createSelector } from 'reselect';

import { valueOrEmptyObject } from 'helpers/utility';

import { companyIdFromPurchaseOrderPropSelector } from 'store/selectors/globalSelectors';
import { merchantsByIdSelector } from 'store/selectors/merchantsSelectors';
import { uiSelectedMerchantIdSelector } from 'store/selectors/uiSelectors';

/**
 * Query for selecting merchant data from selected merchant id
 * stored in the UI state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Merchant}
 */
export const merchantFromUiSelectedMerchantIdQuery = createSelector(
  [merchantsByIdSelector, uiSelectedMerchantIdSelector],
  (merchantsById, selectedMerchantId) => {
    return merchantsById?.[selectedMerchantId];
  }
);

/**
 * Query for selecting merchant general form initial data from currently
 * selected merchant
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const merchantGeneralFormInitialValuesQuery = createSelector(
  [merchantFromUiSelectedMerchantIdQuery],
  (currentMerchant) => valueOrEmptyObject(currentMerchant)
);

/**
 * Query for selecting merchant entity from the state by looking at
 * the passed purchase order prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @returns {CompanyDTO|undefined}
 */
export const merchantFromPurchaseOrderPropSelector = createSelector(
  [merchantsByIdSelector, companyIdFromPurchaseOrderPropSelector],
  (merchantsById, merchantId) => {
    if (!merchantId) {
      return;
    }

    return merchantsById[merchantId];
  }
);
