import { createLastFetchedReducer, gatherReducerCasesFromRoutines } from 'helpers/redux';

import { fetchUnitOfMeasurementsRoutine } from 'store/routines/unitOfMeasurements';

/**
 * List of success cases gathered from routines
 */
const { successCases } = gatherReducerCasesFromRoutines([fetchUnitOfMeasurementsRoutine]);

/**
 * Unit of measurements last fetched reducer
 */
const lastFetchedReducer = createLastFetchedReducer({ successCases });

export { successCases };
export default lastFetchedReducer;
