import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/atoms';
import { DeliveryTimelineEntry, Timeline } from 'components/elements';

import { deliveryTypeToLabelMap } from 'constants/delivery';

import { DeliveryType } from 'enums/delivery';
import { TextSize } from 'enums/typography';

import { allValues } from 'helpers/utility';

/**
 * Component rendering Delivery Days Timeline with title
 * @param {ComponentProps} props
 * @param {string} [props.className]
 * @param {DeliveryType} props.deliveryType
 * @param {Object[]} [props.entries=[]]
 * @param {Boolean} [props.isDraggingDisabled=false]
 * @param {Function} props.onAddNewEntry
 * @param {Function} props.onEditEntry
 * @param {Function} props.onItemDrop
 * @return {StatelessComponent}
 */
const DeliveryDaysTimeline = ({
  className,
  deliveryType,
  entries,
  isDraggingDisabled,
  onAddNewEntry,
  onEditEntry,
  onItemDrop,
}) => {
  const title = deliveryTypeToLabelMap[deliveryType];
  const mapProps = (item) => ({ title: item.region, onEdit: onEditEntry, postcodes: item.postCodes });

  return (
    <div className={classNames('delivery-days-timeline', { [className]: Boolean(className) })}>
      <Text.Regular className="display--inline-block margin-bottom--8" size={TextSize.LEVEL_350}>
        {title}
      </Text.Regular>
      <Timeline
        entries={entries}
        isDraggingDisabled={isDraggingDisabled}
        mapComponentProps={mapProps}
        onAddEntry={onAddNewEntry}
        onDrop={onItemDrop}
        sortableComponent={DeliveryTimelineEntry}
      />
    </div>
  );
};

DeliveryDaysTimeline.propTypes = {
  className: PropTypes.string,
  deliveryType: PropTypes.oneOf(allValues(DeliveryType)).isRequired,
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  isDraggingDisabled: PropTypes.bool,
  onAddNewEntry: PropTypes.func.isRequired,
  onItemDrop: PropTypes.func.isRequired,
};

DeliveryDaysTimeline.defaultProps = {
  entries: [],
  isDraggingDisabled: false,
};

export default DeliveryDaysTimeline;
