import { Text } from 'components/atoms';
import { OneLineTextCell, PriceCell, PurchaseOrderDetailsCellButton } from 'components/elements';

import { IconNames } from 'enums/icons';
import { TextColor, TextSize } from 'enums/typography';
import { ButtonType, IconPosition, TableCellSize, TableCellTextAlign } from 'enums/ui';

import { getFormattedDateString, getFormattedTimeStringFromLocalTime } from 'helpers/datetime';
import { getPurchaseOrderNumber } from 'helpers/purchaseOrders';

import { ORDER_STATUS_LABELS } from 'utils/constants';

/**
 * Returns Purchase Orders table columns config
 * @param {object} options
 * @param {Function} options.onOrderClick
 * @returns {object[]}
 */
export const getPurchaseOrdersTableColumns = ({ onOrderClick }) => {
  return [
    {
      Header: 'Id naročila',
      accessor: 'orderNumber',
      align: TableCellTextAlign.LEFT,
      alignHeader: TableCellTextAlign.CENTER,
      size: TableCellSize.THREE,
      props: {
        size: TextSize.LEVEL_300,
        color: TextColor.BLACK,
      },
      textComponent: Text.Bold,
      Cell: OneLineTextCell,
    },
    {
      Header: 'Datum naročila',
      accessor: 'createdDateTime',
      align: TableCellTextAlign.CENTER,
      size: TableCellSize.TWO,
      Cell: OneLineTextCell,
    },
    {
      Header: 'Lokacija prevzema',
      accessor: 'pickupLocation',
      align: TableCellTextAlign.CENTER,
      size: TableCellSize.FOUR,
      Cell: OneLineTextCell,
    },
    {
      Header: 'Čas prevzema',
      accessor: 'pickupTime',
      align: TableCellTextAlign.CENTER,
      size: TableCellSize.THREE,
      Cell: OneLineTextCell,
    },
    {
      Header: 'Vrednost naročila',
      accessor: 'price',
      align: TableCellTextAlign.CENTER,
      size: TableCellSize.TWO,
      Cell: PriceCell,
    },
    {
      Header: 'Stanje naročila',
      accessor: 'status',
      align: TableCellTextAlign.CENTER,
      size: TableCellSize.THREE,
      Cell: OneLineTextCell,
    },
    {
      Header: '',
      accessor: 'id',
      align: TableCellTextAlign.CENTER,
      size: TableCellSize.THREE,
      props: {
        text: 'Podrobnosti',
        type: ButtonType.GHOST,
        icon: IconNames.ARROW_RIGHT,
        iconPosition: IconPosition.RIGHT,
      },
      handleClick: onOrderClick,
      Cell: PurchaseOrderDetailsCellButton,
    },
  ];
};

/**
 * Returns mapped purchase orders data to be used in a
 * Table component
 * @param {PurchaseOrder[]} purchaseOrders
 * @param {Object} options
 * @param {Object} options.merchantsById
 * @returns {object[]}
 */
export const getPurchaseOrdersTableData = (purchaseOrders, options) => {
  const { merchantsById } = options;

  return purchaseOrders.map((purchaseOrder) => {
    const company = merchantsById[purchaseOrder.companyRef.id];
    const { pickup } = purchaseOrder;

    const pickupDate = getFormattedDateString(pickup?.pickupDate);
    const pickupTimeFrom = getFormattedTimeStringFromLocalTime(pickup?.pickupTimeFrom);

    return {
      id: purchaseOrder.id,
      orderNumber: getPurchaseOrderNumber(purchaseOrder),
      createdDateTime: getFormattedDateString(purchaseOrder.createdDateTime),
      pickupLocation: company?.address,
      pickupTime: `${pickupDate} ${pickupTimeFrom}`,
      price: purchaseOrder.totalOrderPrice.taxIncludedAmount,
      status: ORDER_STATUS_LABELS[purchaseOrder.status],
    };
  });
};
