import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import uploadImageGraphic from 'assets/upload-image-graphic.svg';

import { Icon, Image, Text, Tooltip } from 'components/atoms';
import InlineActions from 'components/elements/InlineActions/InlineActions';

import { formInputPropType } from 'constants/props';
import { IconNames } from 'enums/icons';
import { TextColor } from 'enums/typography';
import { ButtonSize, Intent } from 'enums/ui';

import './ImageInput.scss';

/**
 * Component rendering ImageInput using react dropzone and unform API
 * @param {ComponentProps} props
 * @param {boolean} [props.disabled]
 * @param {FormInput} props.input
 * @param {string} [props.label]
 * @param {string} props.name
 * @return {StatelessComponent}
 */
const ImageInput = ({ disabled, input, label, tooltipProps }) => {
  const [uploadedImage, setUploadedImage] = React.useState();

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const [file] = acceptedFiles;
      if (!file) {
        setUploadedImage(null);
      }

      const previewURL = URL.createObjectURL(file);
      setUploadedImage(previewURL);
      input.onChange(acceptedFiles);
    },
    [input]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    disabled,
    maxFiles: 1,
    multiple: false,
    onDrop,
    noClick: true,
  });

  const hasUploadedImage = Boolean(uploadedImage);

  const removeUploadedImage = React.useCallback(() => {
    setUploadedImage(null);
    input.onChange();
  }, [input]);

  const actions = React.useMemo(
    () => [
      { intent: Intent.INFO, name: 'zamenjaj sliko', onClick: open, iconProps: { icon: IconNames.REFRESH } },
      { intent: Intent.ERROR, onClick: removeUploadedImage, iconProps: { icon: IconNames.TRASH } },
    ],
    [open, removeUploadedImage]
  );

  React.useEffect(() => {
    if (!uploadedImage) {
      setUploadedImage(input.value);
    }
  }, [input, uploadedImage]);

  React.useEffect(() => {
    // Revoke object URL on component unmount
    return () => URL.revokeObjectURL(uploadedImage);
  }, [uploadedImage]);

  return (
    <div
      className={classNames('image-input', {
        'image-input--has-image': hasUploadedImage,
        'image-input--has-label': Boolean(label),
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      {Boolean(label) && (
        <label className="image-input__label" htmlFor={input.name}>
          {Boolean(tooltipProps) && (
            <Tooltip {...tooltipProps}>
              <Icon icon={IconNames.INFO_CIRCLE} />
            </Tooltip>
          )}
          {label}
        </label>
      )}

      {!hasUploadedImage ? (
        <div className="image-input__upload-container" onClick={open}>
          <Image alt="Upload image" className="image-input__upload-image" src={uploadImageGraphic} />
          <Text.Regular>
            Prenesite fotografijo v označeno območje oziroma{' '}
            <Text.Regular color={TextColor.PRIMARY}>kliknite za izbiro</Text.Regular> iz vaše naprave
          </Text.Regular>
        </div>
      ) : (
        <div className="image-input__uploaded-image">
          <Image alt="Uploaded image" src={uploadedImage} />
          {!disabled && <InlineActions actions={actions} size={ButtonSize.SMALL} />}
        </div>
      )}
    </div>
  );
};

ImageInput.propTypes = {
  disabled: PropTypes.bool,
  input: formInputPropType.isRequired,
  label: PropTypes.string,
};

ImageInput.defaultProps = {
  disabled: false,
};

export default ImageInput;
