/**
 * Returns true if request for fetching next page should be made
 * @param {Object} options
 * @param {Object} options.wrapper
 * @param {ApiPaginationState} options.pagination
 * @param {Object} options.parrentItem
 * @returns {boolean}
 */
export const shouldFetchNextPage = ({ pagination, parrentItem, wrapper }) => {
  const { height, y } = wrapper;

  if (!pagination.hasNextPage) {
    return false;
  }

  const { height: parrentHeight } = parrentItem.getBoundingClientRect();

  return height + y < parrentHeight;
};

/**
 * Returns the next page number
 * @param {ApiPaginationState} pagination
 * @returns {number}
 */
export const getFetchNextRequestPageNumber = (pagination) => {
  return parseInt(pagination?.currentPage) + 1;
};
