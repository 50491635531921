import { CommonRegex } from 'constants/regex';
import { DashboardRoutes, PurchaseOrdersRoutes } from 'enums/routes';

import { isUserRoleMerchant } from './roles';

/**
 * Returns a string with joined paths
 * @param  {...string} segments
 * @returns {string}
 */
export const getJoinedPath = (...segments) => {
  return segments.reduce((acc, segment) => {
    // Since we can receive numbers as segments (for example, entity id),
    // we first want to convert the segment to string (note: if segment is already
    // a string, this will do nothing, which is fine).
    let segmentStr = segment.toString();

    if (!segmentStr.endsWith('/')) {
      // We add the traling slash to each path segment
      // if it does not have one already
      segmentStr += '/';
    }

    if (segmentStr.startsWith('/')) {
      // If path segments starts with slash, we remove it
      // to avoid duplicated slashed on join
      segmentStr = segmentStr.substr(1);
    }

    return acc + segmentStr;
  }, '/');
};

/**
 * Returns passed path with dynamic segmented replaced by the value
 * passed to the function
 * @param {string} pathWithDynamicSegment
 * @param {...(string|number)} replaceValues
 * @returns {string}
 */
export const getPathWithDynamicSegmentReplaced = (pathWithDynamicSegment, ...replaceValues) => {
  if (!Array.isArray(replaceValues)) {
    return pathWithDynamicSegment;
  }

  let replacedPath = pathWithDynamicSegment;
  let pathHasDynamicSegment = Boolean(pathWithDynamicSegment.match(CommonRegex.DYNAMIC_PATH_SEGMENT));
  let iterationsCount = 0;

  while (pathHasDynamicSegment) {
    // Grab the replace value based on the current match index
    const replaceValue = replaceValues[iterationsCount];

    // Replace the matched string with found replace value
    replacedPath = replacedPath.replace(CommonRegex.DYNAMIC_PATH_SEGMENT, replaceValue);

    // Re-evaluate if path has any more dynamic segments
    pathHasDynamicSegment = Boolean(replacedPath.match(CommonRegex.DYNAMIC_PATH_SEGMENT));
    // Increase the iteration count so that we grab the correct replace value
    // on next iteration
    iterationsCount++;
  }

  return replacedPath;
};

/**
 * Returns correct relative path to root orders page
 * based on the user roles
 * @function
 * @param {User} user
 * @return {string} orders pathName
 */
export const getOrdersPathName = (user) => {
  if (isUserRoleMerchant(user.roles)) {
    return DashboardRoutes.ROOT;
  }

  return PurchaseOrdersRoutes.ROOT;
};

/**
 * Returns in-app (mobile app) deep link string
 * @param {Id} productId
 * @returns {String}
 */
export const getInAppProductLink = (productId) => `optifarmsmart://products/${productId}`;
