import { createAllIdsReducer } from 'helpers/redux';

import { fetchCategoriesRoutine } from 'store/routines/categories';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Success cases
 */
export const successCases = [{ type: fetchCategoriesRoutine.SUCCESS, shouldReplaceState: true }];

/**
 * Reset state cases
 */
export const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * All Ids categories reducer
 */
const categoriesAllIdsReducer = createAllIdsReducer({ successCases, resetCases });

export default categoriesAllIdsReducer;
