import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/atoms/Icon';
import LoadingIndicator from 'components/atoms/LoadingIndicator';

import { IconNames } from 'enums/icons';
import { ButtonHtmlType, ButtonSize, ButtonType, IconPosition, Intent } from 'enums/ui';
import { noDefaultEvent } from 'helpers/events';
import { isButtonHtmlTypeSubmit } from 'helpers/ui';
import { allValues } from 'helpers/utility';

import './Button.scss';

/**
 * Component rendering button of provided type. Default button type is
 * primary.
 *
 * @param {Object} props
 * @param {ReactNode} [props.children]
 * @param {string} [props.className]
 * @param {string} [props.htmlType=ButtonHtmlType.BUTTON]
 * @param {boolean} [props.disabled=false]
 * @param {string} [props.icon]
 * @param {string} [props.iconPosition=IconPosition.LEFT]
 * @param {string} [props.intent=Intent.SUCCESS]
 * @param {boolean} [props.loading=false]
 * @param {Function} props.onClick
 * @param {string} [props.size=ButtonSize.NORMAL]
 * @param {string} [props.type=ButtonType.PRIMARY]
 * @return {StatelessComponent}
 */
const Button = React.forwardRef(
  (
    {
      children,
      className,
      disabled,
      fullwidth,
      htmlType,
      icon,
      iconPosition,
      intent,
      loading,
      onClick,
      size,
      type,
      ...rest
    },
    ref
  ) => {
    const handleOnClick = React.useCallback(
      (evt) => {
        if (disabled) {
          return;
        }

        noDefaultEvent(evt, onClick);
      },
      [disabled, onClick]
    );

    const onClickProp = React.useMemo(() => {
      if (isButtonHtmlTypeSubmit(htmlType)) {
        return undefined;
      }

      return handleOnClick;
    }, [handleOnClick, htmlType]);

    return (
      <button
        {...rest}
        ref={ref}
        className={classNames(`button button--${type} button--${size} button--${intent}`, {
          [className]: Boolean(className),
          'button--fullwidth': Boolean(fullwidth),
          'button--has-icon': Boolean(icon),
          'button--has-only-icon': !children,
          [`button--icon-position-${iconPosition}`]: Boolean(icon),
          'button--loading': Boolean(loading),
          'button--no-pointer-events': Boolean(loading),
          'button--is-disabled': disabled,
        })}
        onClick={onClickProp}
        type={htmlType}
      >
        {loading && (
          <div className="button__loading-overlay">
            <LoadingIndicator />
          </div>
        )}

        <span className="button__text">{children}</span>

        {Boolean(icon) && <Icon icon={icon} />}
      </button>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullwidth: PropTypes.bool,
  htmlType: PropTypes.oneOf(allValues(ButtonHtmlType)),
  icon: PropTypes.oneOf(Object.values(IconNames)),
  iconPosition: PropTypes.oneOf(Object.values(IconPosition)),
  intent: PropTypes.oneOf(allValues(Intent)),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  type: PropTypes.oneOf(Object.values(ButtonType)),
};

Button.defaultProps = {
  disabled: false,
  htmlType: ButtonHtmlType.BUTTON,
  icon: undefined,
  iconPosition: IconPosition.LEFT,
  intent: Intent.SUCCESS,
  loading: false,
  size: ButtonSize.NORMAL,
  type: ButtonType.PRIMARY,
};

export default Button;
