import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { ButtonType, Intent } from 'enums/ui';
import { allValues } from 'helpers/utility';

import './SidebarUserMenuContent.scss';

/**
 * Component rendering the content of the SidebarUserMenu. The content
 * is a list of actions passed to the dropdown.
 * @param {ComponentProps} props
 * @param {Object[]} props.actions
 * @param {Function} props.onCloseMenu
 * @return {StatelessComponent}
 */
const SidebarUserMenuContent = ({ actions, onCloseMenu }) => (
  <React.Fragment>
    {actions.map((action) => {
      const handleActionClick = () => {
        action.callback();
        onCloseMenu();
      };

      return (
        <Button
          key={action.name}
          className="sidebar-user-menu-content"
          icon={action.icon}
          intent={action.intent || Intent.INFO}
          onClick={handleActionClick}
          type={ButtonType.LINK}
        >
          {action.name}
        </Button>
      );
    })}
  </React.Fragment>
);

SidebarUserMenuContent.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func.isRequired,
      icon: PropTypes.oneOf(allValues(IconNames)),
      name: PropTypes.string.isRequired,
      intent: PropTypes.oneOf(allValues(Intent)),
    })
  ).isRequired,
  onCloseMenu: PropTypes.func.isRequired,
};

export default SidebarUserMenuContent;
