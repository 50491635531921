import PropTypes from 'prop-types';
import React from 'react';

import { Table } from 'components/elements/table/Table';

import { pageableTableComponentsProp } from 'constants/props';

import {
  TableLoadingState as DefaultTableLoadingState,
  TableBodyEmptyStateBase,
  TableErrorState as DefaultTableErrorState,
  TableEmptyResults as DefaultTableEmptyResults,
} from './components';

/**
 * Component rendering the body of the PageableTable component
 * @see {PageableTable}
 * @param {Object} props
 * @param {Object} [props.components={}]
 * @param {Boolean} [props.hasEmptyResults=false]
 * @param {Boolean} [props.hasEmptySearchResults=false]
 * @param {Boolean} [props.hasError=false]
 * @param {Boolean} [props.isLoading=false]
 * @param {Boolean} [props.isSearching=false]
 * @param {Function} props.onRefresh
 * @param {Object} props.params
 * @param {Object} props.rest
 * @returns {StatelessComponent}
 */
const PageableTableBody = ({
  components,
  hasEmptyResults,
  hasEmptySearchResults,
  hasError,
  isLoading,
  isSearching,
  onRefresh,
  params,
  ...rest
}) => {
  // Components setup for different Table empty states. We use either component provided by the prop or the
  // default one. We also memoize this component, since it will (almost) never change dynamically.
  const TableLoadingState = React.useMemo(() => components?.LoadingState || DefaultTableLoadingState, [components]);
  const TableErrorState = React.useMemo(() => components?.ErrorState || DefaultTableErrorState, [components]);
  const TableEmptyResults = React.useMemo(() => components?.EmptyResults || DefaultTableEmptyResults, [components]);

  /**
   * The top-most check. Whenever we are in a loading or searching state, we want to display
   * the table loading state component.
   */
  if (isLoading || isSearching) {
    return (
      <TableBodyEmptyStateBase>
        <TableLoadingState />
      </TableBodyEmptyStateBase>
    );
  }

  /**
   * If we have an error (and we are currently not loading anything), we display the table
   * error state component.
   */
  if (hasError) {
    return (
      <TableBodyEmptyStateBase>
        <TableErrorState onRefresh={onRefresh} />
      </TableBodyEmptyStateBase>
    );
  }

  /**
   * If we have empty search results, we want to display empty search results
   * state. IMPORTANT: This check must happen before the generic empty results check
   * since by design, empty search results also means empty results, but not wise versa.
   */
  if (hasEmptySearchResults || hasEmptyResults) {
    return (
      <TableBodyEmptyStateBase>
        <TableEmptyResults onRefresh={onRefresh} searchKey={params?.searchKey} />
      </TableBodyEmptyStateBase>
    );
  }

  /**
   * If none of the empty state conditions are met, we return
   * the actual table component
   */
  return <Table {...rest} />;
};

PageableTableBody.propTypes = {
  components: pageableTableComponentsProp,
  isLoading: PropTypes.bool,
  isSearching: PropTypes.bool,
  hasEmptyResults: PropTypes.bool,
  hasEmptySearchResults: PropTypes.bool,
  hasError: PropTypes.bool,
  onRefresh: PropTypes.func.isRequired,
  params: PropTypes.shape({}),
};

PageableTableBody.defaultProps = {
  components: {},
  isLoading: false,
  isSearching: false,
  hasError: false,
  hasEmptyResults: false,
  hasEmptySearchResults: false,
};

export default PageableTableBody;
