import { EntityRef } from 'enums/api';

import { fetchSingleMerchantRoutine } from 'store/routines/merchants';
import { fetchSinglePickupRoutine } from 'store/routines/pickups';
import { fetchSingleProductRoutine } from 'store/routines/products';

import { merchantsByIdSelector } from 'store/selectors/merchantsSelectors';
import { pickupsByIdSelector } from 'store/selectors/pickupsSelectors';
import { productsByIdSelector } from 'store/selectors/productsSelectors';

/**
 * Map of selectors with entity ref as a key
 */
export const entityRefToSelectorMap = {
  [EntityRef.COMPANY]: merchantsByIdSelector,
  [EntityRef.PICKUP]: pickupsByIdSelector,
  [EntityRef.PRODUCT]: productsByIdSelector,
};

/**
 * Map of routines with entity ref as a key
 */
export const entityRefToRoutineMap = {
  [EntityRef.COMPANY]: fetchSingleMerchantRoutine,
  [EntityRef.PICKUP]: fetchSinglePickupRoutine,
  [EntityRef.PRODUCT]: fetchSingleProductRoutine,
};
