import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Text } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { TextColor, TextSize } from 'enums/typography';
import { Intent } from 'enums/ui';

import { allValues } from 'helpers/utility';

import './DropdownOptionGeneric.scss';

/**
 * Component rendering generic dropdown option
 * @param {Object} props
 * @param {string} props.description
 * @param {IconNames} [props.icon]
 * @param {Intent} [props.intent=Intent.NEUTRAL]
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const DropdownOptionGeneric = ({ description, icon, intent, title }) => (
  <div className={`dropdown-option dropdown-option-generic dropdown-option--${intent}`}>
    {Boolean(icon) && (
      <Text.Regular className="margin-right--8" color={TextColor.GREY}>
        <Icon fixedWidth={true} icon={icon} />
      </Text.Regular>
    )}

    <div className="dropdown-option__content">
      <Text.Regular color={TextColor.GREY} size={TextSize.LEVEL_200}>
        {title}
      </Text.Regular>

      {Boolean(description) && (
        <Text.Regular className="margin-top--4" color={TextColor.GREY_LIGHT} size={TextSize.LEVEL_200}>
          {description}
        </Text.Regular>
      )}
    </div>
  </div>
);

DropdownOptionGeneric.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.oneOf(allValues(IconNames)),
  intent: PropTypes.oneOf(allValues(Intent)),
  title: PropTypes.string.isRequired,
};

DropdownOptionGeneric.defaultProps = {
  intent: Intent.NEUTRAL,
};

export default DropdownOptionGeneric;
