import Intl from 'intl';
import 'intl/locale-data/jsonp/sl-SI';

import { CurrencyCode } from 'enums/currencies';
import { IntlLocales } from 'enums/locales';
import { PriceKey } from 'enums/price';

/**
 * Returns formatted price value
 * @param {object} options
 * @param {number} options.price
 * @param {CurrencyType} [options.currency=CurrencyCode.EUR]
 * @returns {string}
 */
export const getFormattedPriceString = ({ price, currency = CurrencyCode.EUR }) => {
  return Intl.NumberFormat(IntlLocales.SL, {
    style: 'currency',
    currency: currency,
  }).format(price);
};

/**
 * Returns formated price value from PriceDto
 * @param {Object} options
 * @param {Price} options.price
 * @param {PriceKey} [options.priceKey]
 * @param {CurrencyType} [options.currency=CurrencyCode.EUR]
 * @returns {string}
 */
export const getFormattedPriceFromPriceDto = ({
  price,
  priceKey = PriceKey.TAX_INCLUDED,
  currency = CurrencyCode.EUR,
}) => {
  return getFormattedPriceString({ price: price[priceKey], currency });
};
