import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Actionbar } from 'components/modules/navigation';

import './PageLayout.scss';

/**
 * Layout component rendering basic Page Layout
 * @param {Object} props
 * @param {ReactNode} [props.actionbarAddons]
 * @param {Object[]} [props.actionbarMenuActions]
 * @param {Object[]} [props.breadcrumbs]
 * @param {ReactNode} props.children
 * @param {Boolean} [props.hasActionbar=true]
 * @param {Boolean} [props.hideShareButton=false]
 * @return {StatelessComponent}
 */
const PageLayout = ({
  actionbarAddons,
  actionbarMenuActions,
  breadcrumbs,
  children,
  hasActionbar,
  hideShareButton,
}) => (
  <div className={classNames('page-layout', { 'page-layout--has-actionbar': hasActionbar })}>
    {hasActionbar && (
      <div className="page-layout__actionbar">
        <Actionbar
          addons={actionbarAddons}
          breadcrumbs={breadcrumbs}
          hideShareButton={hideShareButton}
          menuActions={actionbarMenuActions}
        />
      </div>
    )}

    <div className="page-layout__content">{children}</div>
  </div>
);

PageLayout.propTypes = {
  actionbarAddons: PropTypes.node,
  actionbarMenuActions: PropTypes.arrayOf(PropTypes.shape({})),
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node.isRequired,
  hasActionbar: PropTypes.bool,
  hideShareButton: PropTypes.bool,
};

PageLayout.defaultProps = {
  hasActionbar: true,
  hideShareButton: false,
};

export default PageLayout;
