import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Text, Tooltip } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { TextColor } from 'enums/typography';
import { allValues } from 'helpers/utility';

import './DataEntry.scss';

/**
 * Component rendering read-only data entry item
 * @param {ComponentProps} props
 * @param {boolean} [props.fullwidth=false]
 * @param {Number} [props.gridSpan]
 * @param {string} [props.icon]
 * @param {string} [props.label]
 * @param {object} [props.tooltipProps]
 * @param {string} props.value
 * @return {StatelessComponent}
 */
const DataEntry = ({ fullwidth, icon, label, tooltipProps, value }) => (
  <Tooltip
    className={classNames('data-entry', {
      'data-entry--has-label': Boolean(label),
      'data-entry--fullwidth': fullwidth,
    })}
    visible={Boolean(tooltipProps)}
    {...tooltipProps}
  >
    {Boolean(label) && <Text.Regular className="data-entry__label">{label}</Text.Regular>}

    <Text.Regular className="data-entry__value" color={TextColor.BLACK}>
      {value}

      {Boolean(icon) && (
        <div className="data-entry__icon">
          <Icon icon={icon} />
        </div>
      )}
    </Text.Regular>
  </Tooltip>
);

DataEntry.propTypes = {
  fullwidth: PropTypes.bool,
  gridSpan: PropTypes.number,
  icon: PropTypes.oneOf(allValues(IconNames)),
  label: PropTypes.string,
  tooltipProps: PropTypes.shape(Tooltip.propTypes),
  value: PropTypes.string.isRequired,
};

DataEntry.defaultProps = {
  fullwidth: false,
};

export default DataEntry;
