import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, FixedFooter } from 'components/atoms';

import { IconNames } from 'enums/icons';
import { SettingsRoutes } from 'enums/routes';
import { ButtonType, Intent } from 'enums/ui';

import {
  MerchantGeneralInfoFormBasicInfo,
  MerchantGeneralInfoFormPayment,
  MerchantGeneralInfoFormSystemInfo,
} from './components';

import './MerchantGeneralInfoForm.scss';

/**
 * Component rendering Merchant General Info form
 * @param {ComponentProps} props
 * @param {Function} props.handleSubmit
 * @param {Boolean} props.isSubmitting
 * @param {Boolean} props.pristine
 * @param {Boolean} props.valid
 * @return {StatelessComponent}
 */
const MerchantGeneralInfoForm = ({ handleSubmit, isSubmitting, pristine, valid }) => {
  const history = useHistory();

  const onBackButtonClick = () => {
    history.push(SettingsRoutes.ROOT);
  };

  return (
    <>
      <MerchantGeneralInfoFormBasicInfo />

      <MerchantGeneralInfoFormPayment />

      <MerchantGeneralInfoFormSystemInfo />

      <FixedFooter contentClassName="merchant-general-info-form__footer">
        <Button icon={IconNames.ARROW_LEFT} intent={Intent.INFO} onClick={onBackButtonClick} type={ButtonType.LINK}>
          Nazaj na vse nastavitve
        </Button>

        <Button disabled={pristine || !valid} icon={IconNames.SAVE} loading={isSubmitting} onClick={handleSubmit}>
          Shrani spremembe
        </Button>
      </FixedFooter>
    </>
  );
};

MerchantGeneralInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
};

MerchantGeneralInfoForm.defaultProps = {
  isSubmitting: false,
  pristine: true,
  valid: true,
};

export default MerchantGeneralInfoForm;
