import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Tooltip } from 'components/atoms';
import { Avatar } from 'components/elements';

import { imageDtoProp } from 'constants/props';

import { IconNames } from 'enums/icons';
import { AvatarSize, Intent, TooltipPosition } from 'enums/ui';

import { allValues } from 'helpers/utility';

import { SidebarUserMenuContent } from './components';
import './SidebarUserMenu.scss';

/**
 * Component rendering user avatar with dropdown menu containing
 * passed actions (for example, logout action). Used in Sidebar component.
 * @param {ComponentProps} props
 * @param {Object[]} props.actions
 * @param {User} props.user
 * @return {StatelessComponent}
 */
const SidebarUserMenu = ({ actions, user }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const dropdownIcon = isOpen ? IconNames.CARET_UP : IconNames.CARET_DOWN;

  return (
    <div className="sidebar-user-menu" onClick={handleToggleMenu}>
      <Tooltip
        animation="fade"
        content={<SidebarUserMenuContent actions={actions} onCloseMenu={handleMenuClose} />}
        onHide={handleMenuClose}
        onShow={handleMenuOpen}
        open={isOpen}
        position={TooltipPosition.TOP_CENTER}
        trigger="manual"
      >
        <div className="sidebar-user-menu__content">
          <Avatar image={user.image} name={user.name} size={AvatarSize.NORMAL} />
          <Icon icon={dropdownIcon} />
        </div>
      </Tooltip>
    </div>
  );
};

SidebarUserMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func.isRequired,
      icon: PropTypes.oneOf(allValues(IconNames)),
      name: PropTypes.string.isRequired,
      intent: PropTypes.oneOf(allValues(Intent)),
    })
  ).isRequired,
  user: PropTypes.shape({
    image: imageDtoProp,
    name: PropTypes.string,
  }).isRequired,
};

export default SidebarUserMenu;
