import * as Yup from 'yup';

import { CommonRegex } from 'constants/regex';

import { getPostCodeAndNameFromPostString } from './postCodesAndNames';
import { isEqual, isInstanceOf, isNumber, isString } from './utility';

/**
 * Returns true if passed error is instance of ValidationError
 * @param {Error} err
 * @returns {boolean}
 */
export const isCaughtErrorFormValidatorError = (err) => {
  return isInstanceOf(err, Yup.ValidationError);
};

/**
 * Returns true if form field path refers to inline form field validation.
 * @param {YupFieldBase} formField
 * @returns {Boolean}
 */
export const isInlineFormFieldPath = (formField) => formField?.path === 'this';

/**
 * Returns form field label if defined and field path otherwise
 * @param {YupFieldBase} formField
 * @return {string}
 */
export const getFormFieldLabelOrPath = (formField) => {
  if (formField.label) {
    return formField.label.toLowerCase();
  }

  // When doing inline validation, the value of path will be this
  if (isInlineFormFieldPath(formField)) {
    return '';
  }

  return formField?.path;
};

/**
 * Returns string explaining the regex that was used to validate field
 * @param {YupFieldBase} formField
 * @param {RegExp} formField.regex
 * @returns {string}
 */
export const getFormFieldExpectedMatch = ({ regex }) => {
  if (isEqual(regex, CommonRegex.DIGITS_ONLY)) {
    return 'številke';
  }

  if (isEqual(regex, CommonRegex.BASIC_EMAIL)) {
    return 'email';
  }

  // Provide generic fallback option
  return 'dovoljene znake';
};

/**
 * Returns required field error message
 * @param {YupFieldBase} formField
 * @returns {String}
 */
export const getRequiredFieldErrorMessage = (formField) => {
  if (isInlineFormFieldPath(formField)) {
    return 'To polje je obvezno.';
  }

  return `Polje ${getFormFieldLabelOrPath(formField)} je obvezno.`;
};

/**
 * Returns default field error message
 * @param {YupFieldBase} formField
 * @returns {String}
 */
export const getDefaultFieldErrorMessage = (formField) => {
  if (isInlineFormFieldPath(formField)) {
    return 'To polje vsebuje napako.';
  }

  return `Polje ${getFormFieldLabelOrPath(formField)} vsebuje napako.`;
};

/**
 * Returns object with field errors from passed error
 * object
 * @param {Yup.ValidationError} err
 * @returns {Object}
 */
export const getFormValidationFieldErrors = (err) => {
  const validationErrors = {};

  err.inner.forEach(({ message, path }) => {
    if (path) {
      validationErrors[path] = message;
    }
  });

  return validationErrors;
};

/**
 * Returns parsed form data
 * @param {object} formData
 * @returns {object}
 */
export const getParsedFormData = (formData) => {
  let parsedData = {};

  for (const entry in formData) {
    const value = formData[entry];

    // "post" entry is postString, which should be splitted into
    // postCode and postName
    if (isEqual(entry, 'post')) {
      const { postCode, postName } = getPostCodeAndNameFromPostString(value);
      parsedData.postCode = postCode;
      parsedData.postName = postName;
      continue;
    }

    // If empty string, set to undefined
    if (isString(value) && isEqual(value.length, 0)) {
      parsedData[entry] = undefined;
      continue;
    }

    // if number, set the value to parseInt
    if (isNumber(value)) {
      parsedData[entry] = parseInt(value);
      continue;
    }

    // Set the value to the current value otherwise
    parsedData[entry] = value;
  }

  return parsedData;
};

/**
 * Returns passed data merged with provided initial data
 * @param {object} options
 * @param {object} options.data
 * @param {object} options.initialData
 * @returns
 */
export const mergeWithInitialData = ({ data, initialData }) => {
  return {
    ...initialData,
    ...data,
  };
};

/**
 * Returns the parsed value of number input field
 *
 * @param {Object} options
 * @param {string} options.value
 * @param {Boolean} [options.isFloat=false]
 * @returns {number}
 */
export const parseNumberInputFieldValue = ({ value, isFloat = false }) => {
  // If we are parsing a float, we want to replace any commas with dots since the
  // parseFloat function expects decimal places to be separated with a comma
  const valueToUse = isFloat ? value.replace(',', '.') : value;

  // We pick the correct parser based on the value of isFloat option
  const parser = isFloat ? parseFloat : parseInt;
  // We parse the string value
  const parsedValue = parser(valueToUse);

  // We return the parsed value - in case of NaN, we want the value to be 0
  return Number.isNaN(parsedValue) ? 0 : parsedValue;
};
