import React from 'react';
import { Field } from 'react-final-form';

import { TextInput } from 'components/elements';
import { FieldNotEditableTooltip } from 'components/elements/tooltipContents';

import { ProductFormFields } from 'enums/formFields';

import { producerFormFieldValueFormatter } from 'helpers/formatters';

/**
 * Component rendering Product Producer form field. Must be used in scope of
 * React Final Form.
 * @returns {StatelessComponent}
 */
const ProducerFormField = () => (
  <Field
    component={TextInput}
    disabled={true}
    format={producerFormFieldValueFormatter}
    fullwidth={true}
    label="Pridelovalec"
    name={ProductFormFields.PRODUCER}
    tooltipProps={{
      content: <FieldNotEditableTooltip label="Pridelovalec" />,
    }}
  />
);

export default ProducerFormField;
