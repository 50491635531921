import PropTypes from 'prop-types';
import React from 'react';

import { paginationProp } from 'constants/props';

import TableApiStateDescription from '../TableApiStateDescription';
import TablePaginationActions from '../TablePaginationActions';

import './TableFooter.scss';

/**
 * Component rendering Pageable Table footer
 * @param {Object} props
 * @param {Function} props.onGoToNextPage,
 * @param {Function} props.onGoToPreviousPage,
 * @param {Object} props.rest
 * @return {StatelessComponent}
 */
const TableFooter = ({ onGoToNextPage, onGoToPreviousPage, ...rest }) => (
  <div className="table-footer">
    <TableApiStateDescription {...rest} />
    <TablePaginationActions onGoToNextPage={onGoToNextPage} onGoToPreviousPage={onGoToPreviousPage} {...rest} />
  </div>
);

TableFooter.propTypes = {
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  onGoToNextPage: PropTypes.func.isRequired,
  onGoToPreviousPage: PropTypes.func.isRequired,
  pagination: paginationProp.isRequired,
};

TableFooter.defaultProps = {
  hasError: false,
  isLoading: false,
};

export default TableFooter;
