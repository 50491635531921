import { createAllIdsReducer } from 'helpers/redux';

import { fetchDeliveryDaysRoutine } from 'store/routines/deliveryDays';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * Success cases
 */
const successCases = [{ type: fetchDeliveryDaysRoutine.SUCCESS, shouldReplaceState: true }];

/**
 * Reset state cases
 */
const resetCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Delivery Days reducers: All Ids reducer
 */
const deliveryDaysAllIdsReducer = createAllIdsReducer({ successCases, resetCases });

export default deliveryDaysAllIdsReducer;
