import PropTypes from 'prop-types';
import React from 'react';

import { ActionbarBreadcrumb } from './components';

import './ActionbarBreadcrumbs.scss';

/**
 * Component rendering Actionbar Breadcrumbs
 * @param {ComponentProps} props
 * @param {Object[]} props.paths
 * @return {StatelessComponent}
 */
const ActionbarBreadcrumbs = ({ paths }) => (
  <div className="actionbar-breadcrumbs">
    {paths.map((path, i) => (
      <ActionbarBreadcrumb key={`${path.path}-${path.name}`} index={i} numOfAllPaths={paths.length} path={path} />
    ))}
  </div>
);

ActionbarBreadcrumbs.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.shape({ path: PropTypes.string, name: PropTypes.string })).isRequired,
};

export default ActionbarBreadcrumbs;
