import { createStatusAwareAllIdsReducer, isPurchaseOrdersActionRelatedToExpiredOrders } from 'helpers/redux';
import { fetchPurchaseOrdersNextPageRoutine, fetchPurchaseOrdersRoutine } from 'store/routines/purchaseOrders';
import { REMOVE_SELECTED_MERCHANT } from 'store/types/ui';

/**
 * List of success cases with replace state flag
 */
const successCases = [
  { type: fetchPurchaseOrdersRoutine.SUCCESS, shouldReplaceState: true },
  { type: fetchPurchaseOrdersNextPageRoutine.SUCCESS, shouldReplaceState: false },
];

/**
 * List of reset cases
 */
const resetStateCases = [REMOVE_SELECTED_MERCHANT];

/**
 * Expired Purchase Orders reducers: All Ids Reducer
 */
const expiredPurchaseOrdersAllIdsReducer = createStatusAwareAllIdsReducer({
  resetStateCases,
  statusCheckFunction: isPurchaseOrdersActionRelatedToExpiredOrders,
  successCases,
});

export default expiredPurchaseOrdersAllIdsReducer;
