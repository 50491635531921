export enum PaymentMethod {
  CREDIT_CART = 'CREDIT_CART',
  ON_SITE = 'ON_SITE',
  PAY_PAL = 'PAY_PAL',
  PREPAID = 'PREPAID',
}

export enum PaymentStatus {
  FAIL = 'FAIL',
  OPEN = 'OPEN',
  PAYED = 'PAYED',
  PROCESSING = 'PROCESSING',
  WAITING = 'WAITING',
}
