import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { userRolesSelector } from 'store/selectors/userSelectors';

import SettingsPagesGrid from './SettingsPagesGrid';

export const mapStateToProps = createStructuredSelector({
  userRoles: userRolesSelector,
});

export default connect(mapStateToProps)(SettingsPagesGrid);
