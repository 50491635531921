import PropTypes from 'prop-types';
import React from 'react';

import { FormWrapper, Section } from 'components/atoms';
import { ProductPriceTable } from 'components/modules/products';

import { productDtoProp } from 'constants/props';

import { ProductBasicInfo, ProductEditFormFooter, ProductSystemInfo } from './components';

import './ProductEditForm.scss';

/**
 * Component rendering Product Edit Form
 * @param {Object} props
 * @param {Function} props.handleSubmit
 * @param {Boolean} props.isSubmitting
 * @param {Boolean} props.pristine
 * @param {Product} props.product
 * @param {Boolean} props.valid
 * @returns {StatelessComponent}
 */
const ProductEditForm = ({ handleSubmit, isSubmitting, pristine, product, valid }) => (
  <FormWrapper className="product-edit-form">
    <ProductBasicInfo />

    <Section title="Cena izdelka">
      <ProductPriceTable productPriceId={product.productPrice.id} />
    </Section>

    <ProductSystemInfo />

    <ProductEditFormFooter handleSubmit={handleSubmit} isSubmitting={isSubmitting} pristine={pristine} valid={valid} />
  </FormWrapper>
);

ProductEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  pristine: PropTypes.bool,
  product: productDtoProp.isRequired,
  valid: PropTypes.bool,
};

export default ProductEditForm;
