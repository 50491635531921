import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { openAddDeliveryDaySidesheet } from 'store/actions/sidesheets';
import { localDeliveryDaysPostCodesGroupedByRegionQuery } from 'store/queries/deliveryDaysQueries';

import LocalDeliveryDaysTimeline from './LocalDeliveryDaysTimeline';

const mapStateToProps = createStructuredSelector({
  entries: localDeliveryDaysPostCodesGroupedByRegionQuery,
});

const mapDispatchToProps = {
  onOpenAddDeliveryDaySidesheet: openAddDeliveryDaySidesheet,
};

export { mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(LocalDeliveryDaysTimeline);
