import PropTypes from 'prop-types';
import React from 'react';

import { getEntitiesOptionValue } from 'helpers/selects';

import Select from '../Select';

/**
 * Wrapper around Select component, accepting entity (DTO) array
 * as options prop
 * @param {Object} props
 * @param {EntityLike[]} props.options
 * @returns {StatelessComponent}
 */
const EntitiesSelect = ({ options, ...rest }) => (
  <Select {...rest} getOptionValue={getEntitiesOptionValue} options={options} />
);

EntitiesSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })).isRequired,
};

export default EntitiesSelect;
