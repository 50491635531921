import { VALID_USER_ROLES } from 'constants/roles';
import { UserRole } from 'enums/roles';

/**
 * Returns filtered list of user roles, including only
 * Optifarm related roles
 * @param {UserRoleType[]} roles
 * @returns {UserRoleType[]}
 */
export const getFilteredOptifarmUserRoles = (roles) => {
  return roles.filter((role) => role.startsWith('ofb2c-'));
};

/**
 * Returns true if passed role is included in the passed
 * array of roles
 * @param {UserRoleType[]} roles
 * @param {UserRoleType} role
 * @returns {boolean}
 */
export const isRoleIncluded = (roles, role) => {
  if (!Array.isArray(roles)) {
    return false;
  }

  return roles.includes(role);
};

/**
 * Returns true if one of provided user roles matches any of
 * the provided roles to match
 * @param {UserRoleType[]} userRoles
 * @param {UserRoleType[]} rolesToMatch
 * @returns {boolean}
 */
export const isOneOfUserRoleMatch = (userRoles, rolesToMatch) => {
  let result = false;

  if (!Array.isArray(userRoles) || !Array.isArray(rolesToMatch)) {
    return result;
  }

  for (const userRole of userRoles) {
    if (rolesToMatch.includes(userRole)) {
      result = true;
      break;
    }
  }

  return result;
};

/**
 * Returns true if user has any of the valid role
 * @param {UserRoleType[]} roles
 * @returns {boolean}
 */
export const isOneOfUserRolesValid = (roles) => isOneOfUserRoleMatch(roles, VALID_USER_ROLES);

/**
 * Returns the first promiment user role
 * @param {UserRoleType[]} roles
 * @returns {UserRoleType}
 */
export const getProminentUserRole = (roles) => {
  if (!Array.isArray(roles)) {
    return null;
  }

  return roles.find((role) => role.startsWith('ofb2c-'));
};

/**
 * Returns true if user has admin role
 * @function
 * @param {UserRoleType[]} roles
 * @returns {boolean} boolean
 */
export const isUserRoleAdmin = (roles) => {
  if (!roles) {
    return false;
  }

  return isRoleIncluded(roles, UserRole.ADMIN);
};

/**
 * Returns true if user has operator role
 * @param {UserRoleType[]} roles
 * @returns {boolean}
 */
export const isUserRoleOperator = (roles) => {
  return isRoleIncluded(roles, UserRole.OPERATOR);
};

/**
 * Returns true if user has chief merchant role
 * @param {UserRoleType[]} roles
 * @returns {boolean}
 */
export const isUserRoleChiefMerchant = (roles) => {
  return isRoleIncluded(roles, UserRole.CHIEF_MERCHANT);
};

/**
 * Returns true if user has merchant role
 * @param {UserRoleType[]} roles
 * @returns {boolean}
 */
export const isUserRoleMerchant = (roles) => {
  return isRoleIncluded(roles, UserRole.MERCHANT);
};
