import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components/atoms';
import { IconNames } from 'enums/icons';
import { ButtonSize, ButtonType, IconPosition, Intent } from 'enums/ui';
import { allValues, valueOrEmptyObject } from 'helpers/utility';

import './InlineActions.scss';

/**
 * Component rendering array of buttons as inline actions
 * @param {ComponentProps} props
 * @param {Object[]} props.actions
 * @param {string} [props.size=ButtonSize.NORMAL]
 * @return {StatelessComponent}
 */
const InlineActions = ({ actions, size }) => (
  <div className="inline-actions">
    {actions.map((action, i) => (
      <Button
        {...valueOrEmptyObject(action.iconProps)}
        key={action.name || i}
        intent={action.intent || Intent.INFO}
        onClick={action.onClick}
        size={size}
        type={ButtonType.LINK}
      >
        {action.name}
      </Button>
    ))}
  </div>
);

InlineActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      intent: PropTypes.oneOf(allValues(Intent)),
      onClick: PropTypes.func.isRequired,
      name: PropTypes.string,
      iconProps: PropTypes.shape({
        icon: PropTypes.oneOf(allValues(IconNames)),
        iconPosition: PropTypes.oneOf(allValues(IconPosition)),
      }),
    })
  ).isRequired,
  size: PropTypes.oneOf(allValues(ButtonSize)),
};

InlineActions.defaultProps = {
  size: ButtonSize.NORMAL,
};

export default InlineActions;
