import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { closeAddDeliveryDaySidesheet } from 'store/actions/sidesheets';
import { addDeliveryDaySidesheetValuesSelector } from 'store/selectors/sidesheetSelectors';

import AddDeilveryEntrySidesheet from './AddDeliveryEntrySidesheet';

/**
 * Container component containing form submit logic for rendering add/edit delivery entry sidesheet
 * @param {ComponentProps} props
 * @param {Function} props.onSave
 * @param {RestOfProps} rest
 * @return {StatelessComponent}
 */
const AddDeliveryEntrySidesheetContainer = ({ onSave, deliveryType, ...rest }) => {
  /**
   * Handles form submit
   * @param {Object} data
   * @param {FormAPI} form
   */
  const handleSubmit = async (data, form) => {
    const errors = await form.submit();

    if (!errors) {
      onSave(data);
    }
  };

  return (
    <Form
      component={AddDeilveryEntrySidesheet}
      initialValues={{ deliveryType }}
      isOpen={true}
      onSubmit={handleSubmit}
      {...rest}
    />
  );
};

AddDeliveryEntrySidesheetContainer.propTypes = {
  onSave: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  sidesheetValues: addDeliveryDaySidesheetValuesSelector,
});

const mapDispatchToProps = {
  onClose: closeAddDeliveryDaySidesheet,
};

export { AddDeliveryEntrySidesheetContainer, mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(AddDeliveryEntrySidesheetContainer);
