import { createSelector } from 'reselect';

import { getMappedStateIds } from 'helpers/redux';

import type { ReduxState } from 'store/interfaces';

/**
 * Selects the root pickups state
 * @param state
 */
const getState = (state: ReduxState) => state.pickups;

/**
 * Selects the allIds property of the pickups state
 * @param state
 */
export const pickupsAllIdsSelector = createSelector([getState], (state) => state.allIds);

/**
 * Selects the byId property of the pickups state
 * @param state
 */
export const pickupsByIdSelector = createSelector([getState], (state) => state.byId);

/**
 * Selects all pickup entities stored in the state
 * @param state
 */
export const allPickupsSelector = createSelector([pickupsAllIdsSelector, pickupsByIdSelector], getMappedStateIds);

/**
 * Selects the error property of the pickups state
 * @param state
 */
export const pickupsErrorSelector = createSelector([getState], (state) => state.error);

/**
 * Selects the isFetching property of the pickups state
 * @param state
 */
export const pickupsIsFetchingSelector = createSelector([getState], (state) => state.isFetching);

/**
 * Selects the lastFetched property of the pickups state
 * @param state
 */
export const pickupsLastFetchedSelector = createSelector([getState], (state) => state.lastFetched);
