import { purchaseOrderStatusDependenciesMap } from 'constants/purchaseOrders';

import { IconNames } from 'enums/icons';
import { PaymentMethod, PaymentStatus } from 'enums/payments';
import { PurchaseOrderStatus } from 'enums/purchaseOrders';

import { isEqual } from './utility';

/**
 * Returns formated Purchase Order order number from passed
 * purchase order id
 * @param {PurchaseOrder} purchaseOrder
 * @returns {string}
 */
export const getPurchaseOrderNumber = (purchaseOrder) => {
  return `2021-0001-${String(purchaseOrder?.id.toString()).padStart(5, '0')}`;
};

/**
 * Returns true if provide purchase order payment status is open
 * @param {PurchaseOrder} purchaseOrder
 * @returns {boolean}
 */
export const isPurchaseOrderPaymentStatusOpen = (purchaseOrder) => {
  return isEqual(purchaseOrder?.paymentStatus, PaymentStatus.OPEN);
};

/**
 * Returns true if provide purchase order payment status is payed
 * @param {PurchaseOrder} purchaseOrder
 * @returns {boolean}
 */
export const isPurchaseOrderPaymentStatusPayed = (purchaseOrder) => {
  return isEqual(purchaseOrder?.paymentStatus, PaymentStatus.PAYED);
};

/**
 * Returns true if purchase order payment method is prepaid
 * @param {PurchaseOrder} purchaseOrder
 * @returns {boolean}
 */
export const isPurchaseOrderPaymentMethodPrepaid = (purchaseOrder) => {
  return isEqual(purchaseOrder?.paymentMethod, PaymentMethod.PREPAID);
};

/**
 * Returns payment method name from provided purchase order dto
 * @param {PurchaseOrder} purchaseOrder
 * @returns {string}
 */
export const getPurchaseOrderPaymentStatusDescription = (purchaseOrder) => {
  return isPurchaseOrderPaymentStatusOpen(purchaseOrder) ? 'Naročilo še ni plačano' : 'Naročilo plačano';
};

/**
 * Returns correct icon name based on the purchase order payment
 * status
 * @param {PurchaseOrder} purchaseOrder
 * @returns {string}
 */
export const getIconNameForPurchaseOrderPaymentStatus = (purchaseOrder) => {
  if (isPurchaseOrderPaymentStatusPayed(purchaseOrder)) {
    return IconNames.CHECK;
  }

  return IconNames.EXCLAMATION_CIRCLE;
};

/**
 * Returns true if purchase order status is acknowledged
 * @param {PurchaseOrder} purchaseOrder
 * @returns {boolean}
 */
export const isPurchaseOrderStatusAcknowledged = (purchaseOrder) => {
  return isEqual(purchaseOrder?.status, PurchaseOrderStatus.ACKNOWLEDGED);
};

/**
 * Returns true if purchase order status is acknowledged
 * @param {PurchaseOrder} purchaseOrder
 * @returns {boolean}
 */
export const isPurchaseOrderStatusInProgress = (purchaseOrder) => {
  return isEqual(purchaseOrder?.status, PurchaseOrderStatus.IN_PROGRESS);
};

/**
 * Returns true if purchase order status is acknowledged
 * @param {PurchaseOrder} purchaseOrder
 * @returns {boolean}
 */
export const isPurchaseOrderStatusCancelled = (purchaseOrder) => {
  return isEqual(purchaseOrder?.status, PurchaseOrderStatus.CANCELLED);
};

/**
 * Returns true if purchase order status is acknowledged
 * @param {PurchaseOrder} purchaseOrder
 * @returns {boolean}
 */
export const isPurchaseOrderStatusPacked = (purchaseOrder) => {
  return isEqual(purchaseOrder?.status, PurchaseOrderStatus.PACKED);
};

/**
 * Returns true if purchase order status is acknowledged
 * @param {PurchaseOrder} purchaseOrder
 * @returns {boolean}
 */
export const isPurchaseOrderStatusCompleted = (purchaseOrder) => {
  return isEqual(purchaseOrder?.status, PurchaseOrderStatus.COMPLETED);
};

/**
 * Returns a list of needed completed status before update to desired status
 * can happen
 * @param {object} options
 * @param {PurchaseOrderState} options.currentStatus
 * @param {PurchaseOrderState} options.status
 * @returns {PurchaseOrderState[]}
 */
export const getMissingPurchaseOrderStatusesForStatusUpdate = ({ currentStatus, status }) => {
  const alreadyCompletedStatuses = purchaseOrderStatusDependenciesMap[currentStatus] || [];
  const neededStatuses = purchaseOrderStatusDependenciesMap[status] || [];

  return neededStatuses.filter((neededStatus) => ![...alreadyCompletedStatuses, currentStatus].includes(neededStatus));
};

/**
 * Returns true if purchase order payment status is open and payment
 * method is prepaid
 * @param {PurchaseOrder} purchaseOrder
 * @returns {boolean}
 */
export const isTransitionToCompletedPurchaseOrderStatusDisabled = (purchaseOrder) => {
  return isPurchaseOrderPaymentStatusOpen(purchaseOrder) && isPurchaseOrderPaymentMethodPrepaid(purchaseOrder);
};
