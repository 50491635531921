import React from 'react';
import { Field } from 'react-final-form';

import { Column, Flex, Grid, Section } from 'components/atoms';
import { FieldNotEditableTooltip, ImageInput, SwitchInput, TextInput } from 'components/elements';
import { CategorySelectField } from 'components/modules/categories';
import { ProducerFormField, ProductDescriptionFormField, ProductOriginFormField } from 'components/molecules';

import { ProductFormFields } from 'enums/formFields';
import { FlexAlign, FlexDirection, GridColumnCount, GridGapSize, LabelPosition } from 'enums/ui';

/**
 * Component rendering Product Basic Info section of the Product Edit
 * form
 * @see {ProductEditForm}
 * @returns {StatelessComponent}
 */
const ProductBasicInfo = () => (
  <Section isFirst={true} title="Osnovni podatki">
    <Grid numOfCols={GridColumnCount.THREE}>
      <Field component={ImageInput} disabled={true} label="Slika izdelka" name={ProductFormFields.IMAGES} />

      <Grid gridSpan={2} numOfCols={GridColumnCount.TWO}>
        <Column>
          <Field component={TextInput} fullwidth={true} label="Ime izdelka" name={ProductFormFields.NAME} />
        </Column>

        <Column>
          <CategorySelectField
            isDisabled={true}
            name={ProductFormFields.CATEGORY}
            tooltipProps={{
              content: <FieldNotEditableTooltip label="Kategorija izdelka" />,
            }}
          />
        </Column>

        <Column gap={GridGapSize.MEDIUM} gridSpan={2}>
          <ProductDescriptionFormField />
        </Column>

        <Column gap={GridGapSize.MEDIUM}>
          <ProductOriginFormField />
        </Column>

        <Column gap={GridGapSize.MEDIUM}>
          <ProducerFormField />
        </Column>

        <Column gridSpan={2}>
          <Flex align={FlexAlign.CENTER} dir={FlexDirection.ROW}>
            <Field
              className="margin-right--24"
              component={SwitchInput}
              label="Izdelek aktiven?"
              labelPosition={LabelPosition.RIGHT}
              name={ProductFormFields.ACTIVE}
            />
            <Field
              component={SwitchInput}
              label="Izdelek ima starostne omejitve?"
              labelPosition={LabelPosition.RIGHT}
              name={ProductFormFields.ADULT_CONSENT}
            />
          </Flex>
        </Column>
      </Grid>
    </Grid>
  </Section>
);

export default ProductBasicInfo;
